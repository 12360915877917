import { AlertStatus, FormControl, FormLabel, SimpleGrid, Switch, Text, useToast } from '@chakra-ui/react';
import { AlarmActionTypeEnum, ICollarAlarmResetActionContextDTO } from '@halter-corp/collar-alarm-service-client';
import { FunctionComponent } from 'react';
import * as yup from 'yup';

import { AlarmActionFormProps } from 'src/modules/monitoring/@types';
import BaseCollarActionForm from 'src/modules/monitoring/components/alarm-action-forms/collar-action-forms/base-collar-action-form';
import { Colors } from 'src/styles';
import { SERIAL_NUMBERS_INPUT_PATTERN, extractSerialNumbersFromPattern } from 'src/util/collar.util';

import { CollarAlarmActionService } from '../../services/collar-alarm-action.service';

type ResetActionFormValues = {
  serialNumbers: string;
  core: boolean;
  lora: boolean;
  comms: boolean;
  comments: string;
};

const RESET_OPTIONS = [
  { label: 'Core (STM)', value: 'core' },
  { label: 'LoRa Rejoin', value: 'lora' },
  { label: 'Comms (ESP)', value: 'comms' },
] as const;

const resetActionSchema = yup.object().shape({
  serialNumbers: yup
    .string()
    .required()
    .matches(SERIAL_NUMBERS_INPUT_PATTERN, 'Each serial number should have valid format saparated by a comma'),
  comments: yup.string(),
});

const ResetActionForm: FunctionComponent<AlarmActionFormProps> = ({
  onBack,
  onClose,
  selectedIds,
  selectedEventAlarmEntities,
  onSuccessfulSubmit,
  additionalSection,
}) => {
  const { sendBatchActionRequests } =
    CollarAlarmActionService.useBatchCollarAlarmActions<ICollarAlarmResetActionContextDTO>({
      actionType: AlarmActionTypeEnum.RESET_COLLAR,
      onSuccess: () => removeCollarEventAlarms(selectedEventAlarmEntities),
    });
  const removeCollarEventAlarms = CollarAlarmActionService.useBatchRemoveCollarEventAlarmActions({
    onSuccess: onSuccessfulSubmit,
  });
  const toast = useToast();

  const displayToastMessage = (status: AlertStatus, title: string) => {
    toast({
      status,
      title,
      duration: 3000,
      isClosable: true,
      position: 'top-right',
    });
  };

  const handleSubmit = async ({
    serialNumbers: serialNumbersString,
    lora,
    core,
    comms,
    comments,
  }: ResetActionFormValues) => {
    if (lora === false && core === false && comms === false) {
      displayToastMessage('error', 'Please select at least one reset options');
      return;
    }

    const serialNumbers = extractSerialNumbersFromPattern(serialNumbersString);
    const actionContext: ICollarAlarmResetActionContextDTO = {
      lora,
      core,
      comms,
    };

    await sendBatchActionRequests(serialNumbers, actionContext, comments);
  };

  return (
    <BaseCollarActionForm
      title="Collar Reset Action"
      initialValues={{
        serialNumbers: selectedIds.join(','),
        core: false,
        lora: false,
        comms: false,
        comments: '',
      }}
      validationSchema={resetActionSchema}
      onBack={onBack}
      onSubmit={handleSubmit}
      onClose={onClose}
      selectedEventAlarmCount={selectedEventAlarmEntities.length}
      additionalSection={additionalSection}
    >
      {({ values, handleChange }) => (
        <SimpleGrid
          columns={{ base: 1, sm: 2 }}
          rowGap={3}
          columnGap={8}
          mb={5}
        >
          <Text
            gridColumn="1 / span 2"
            fontWeight="medium"
            color={Colors.gray900}
            size="2xs"
          >
            Reset Options
          </Text>

          {RESET_OPTIONS.map((resetOption) => (
            <FormControl
              key={resetOption.value}
              display="flex"
              justifyContent="space-between"
            >
              <FormLabel htmlFor={resetOption.value}>{resetOption.label}</FormLabel>
              <Switch
                id={resetOption.value}
                name={resetOption.value}
                isChecked={values[resetOption.value]}
                onChange={handleChange}
              />
            </FormControl>
          ))}
        </SimpleGrid>
      )}
    </BaseCollarActionForm>
  );
};

export default ResetActionForm;
