import { useCallback, useMemo } from 'react';

import { ObjectKeyType, PartialRecord } from 'src/types';
import { DateRangeFilterState, DateRangeFilterType } from 'src/types/filter-types';

type UseDateRangeFilterOperationsProps<K extends ObjectKeyType> = {
  dateRangeFiltersByType: PartialRecord<K, DateRangeFilterState>;
  setDateRangeFiltersByType: (filters: PartialRecord<K, DateRangeFilterState>) => void;
};

const DEFAULT_DATE_RANGE_FILTER_TYPE: DateRangeFilterType = 'absolute';

const useDateRangeFilterOperations = <K extends ObjectKeyType>({
  dateRangeFiltersByType,
  setDateRangeFiltersByType,
}: UseDateRangeFilterOperationsProps<K>) => {
  const resetDateRangeFilters = useCallback(() => {
    setDateRangeFiltersByType({});
  }, [setDateRangeFiltersByType]);

  const setDateRangeFilter = useCallback(
    (filterType: K, dateRangeFilter: DateRangeFilterState) => {
      setDateRangeFiltersByType({ ...dateRangeFiltersByType, [filterType]: dateRangeFilter });
    },
    [dateRangeFiltersByType, setDateRangeFiltersByType],
  );

  const toggleDateRangeFilter = useCallback(
    (filterType: K) => {
      const currentFilterState = dateRangeFiltersByType[filterType];
      if (currentFilterState != null) {
        setDateRangeFiltersByType({ ...dateRangeFiltersByType, [filterType]: undefined });
      } else {
        setDateRangeFiltersByType({
          ...dateRangeFiltersByType,
          [filterType]: { type: DEFAULT_DATE_RANGE_FILTER_TYPE },
        });
      }
    },
    [dateRangeFiltersByType, setDateRangeFiltersByType],
  );

  const selectedDateRangeFilters: K[] = useMemo(
    () => Object.keys(dateRangeFiltersByType) as K[],
    [dateRangeFiltersByType],
  );

  return {
    selectedDateRangeFiltersByType: dateRangeFiltersByType,
    setDateRangeFilter,
    resetDateRangeFilters,
    selectedDateRangeFilters,
    toggleDateRangeFilter,
  };
};

export default useDateRangeFilterOperations;
