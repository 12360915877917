import { Text } from '@chakra-ui/react';
import { AlarmActionTypeEnum } from '@halter-corp/collar-alarm-service-client';
import { FC } from 'react';
import * as yup from 'yup';

import { AlarmActionFormProps } from 'src/modules/monitoring/@types';
import BaseCollarActionForm from 'src/modules/monitoring/components/alarm-action-forms/collar-action-forms/base-collar-action-form';
import { SERIAL_NUMBERS_INPUT_PATTERN, extractSerialNumbersFromPattern } from 'src/util/collar.util';

import { CollarAlarmActionService } from '../../services/collar-alarm-action.service';

type TagOffActionFormValues = {
  serialNumbers: string;
  comments: string;
};

const tagOffActionSchema = yup.object().shape({
  serialNumbers: yup
    .string()
    .required()
    .matches(SERIAL_NUMBERS_INPUT_PATTERN, 'Each serial number should have valid format saparated by a comma'),
  comments: yup.string(),
});

const TagOffActionForm: FC<AlarmActionFormProps> = ({
  onBack,
  onClose,
  selectedIds: selectedSerialNumbers,
  selectedEventAlarmEntities,
  onSuccessfulSubmit,
  additionalSection,
}) => {
  const { sendBatchActionRequests } = CollarAlarmActionService.useBatchCollarAlarmActions<object>({
    actionType: AlarmActionTypeEnum.TAG_OFF,
    onSuccess: () => removeCollarEventAlarms(selectedEventAlarmEntities),
  });
  const removeCollarEventAlarms = CollarAlarmActionService.useBatchRemoveCollarEventAlarmActions({
    onSuccess: onSuccessfulSubmit,
  });

  const handleSubmit = async ({ serialNumbers: serialNumbersString, comments }: TagOffActionFormValues) => {
    const serialNumbers = extractSerialNumbersFromPattern(serialNumbersString);
    await sendBatchActionRequests(serialNumbers, {}, comments);
  };

  return (
    <BaseCollarActionForm
      title="Tag Off Collars"
      description={
        <Text fontSize="sm">
          Please make sure collars are provisioned to farm and assigned to cows before making tag off requests.
        </Text>
      }
      initialValues={{
        serialNumbers: selectedSerialNumbers.join(', '),
        comments: '',
      }}
      validationSchema={tagOffActionSchema}
      onSubmit={handleSubmit}
      onBack={onBack}
      onClose={onClose}
      selectedEventAlarmCount={selectedEventAlarmEntities.length}
      additionalSection={additionalSection}
    />
  );
};

export default TagOffActionForm;
