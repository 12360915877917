import moment from 'moment';
import pluralize from 'pluralize';

// Override portion of moment's relative time format
// For example, instead of "a minute ago", it will be "1 minute ago"
moment.updateLocale('en', {
  relativeTime: {
    s: '1 second',
    m: '%d minute',
    h: '%d hour',
    d: '%d day',
    w: '%d week',
    M: '%d month',
    y: '%d year',
  },
});

/**
 *  @return 24-hour time format (HH:mm:ss) e.g. 23:59:59
 */
export function getTimeFormat(date: Date) {
  return moment(date).format('HH:mm:ss');
}

export function getRelativeTimestamp(date: Date): string {
  return moment(date).fromNow();
}

export function getDateTimeFormat(date: Date) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  return date.toLocaleString('en-US', options);
}

export function getDurationFormat(durationMs: number): string {
  const duration = moment.duration(durationMs);

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const parts = [];

  if (years > 0) parts.push(`${years} ${pluralize('year', years)}`);
  if (months > 0) parts.push(`${months} ${pluralize('month', months)}`);
  if (days > 0) parts.push(`${days} ${pluralize('day', days)}`);
  if (hours > 0) parts.push(`${hours} ${pluralize('hour', hours)}`);
  if (minutes > 0) parts.push(`${minutes} ${pluralize('minute', minutes)}`);
  if (seconds > 0) parts.push(`${seconds} ${pluralize('second', seconds)}`);

  return parts.join(' ');
}

export const shortFormatTimestamp = (date: Date): string => {
  const difference = moment().diff(date, 'milliseconds');

  if (difference >= moment.duration(1, 'month').asMilliseconds()) {
    return moment(date).format('MMM D, YYYY');
  }

  if (difference >= moment.duration(1, 'day').asMilliseconds()) {
    return `${Math.floor(moment.duration(difference, 'milliseconds').asDays())}d ago`;
  }

  if (difference >= moment.duration(1, 'hour').asMilliseconds()) {
    return `${Math.floor(moment.duration(difference, 'milliseconds').asHours())}h ago`;
  }

  if (difference >= moment.duration(1, 'minute').asMilliseconds()) {
    return `${Math.floor(moment.duration(difference, 'milliseconds').asMinutes())}m ago`;
  }

  return '< 1m ago';
};

export function getDurationAsUnit(durationMs: number, unit: moment.unitOfTime.Base): number {
  const duration = moment.duration(durationMs);
  return duration.as(unit);
}

export function getDurationFormatAsUnit(durationMs: number, unit: moment.unitOfTime.Base): string {
  const unitValue = getDurationAsUnit(durationMs, unit);
  return `${unitValue} ${pluralize(unit, unitValue)}`;
}

export const isValidDate = (date: Date) => !Number.isNaN(date.getTime());

export function floorDateTimeToResolution(date: Date, resolutionInSeconds: number): Date {
  const newDate = new Date(date.getTime());
  const totalSeconds = Math.floor(newDate.getTime() / 1000);
  const flooredSeconds = Math.floor(totalSeconds / resolutionInSeconds) * resolutionInSeconds;
  newDate.setTime(flooredSeconds * 1000);
  return newDate;
}
