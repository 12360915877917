import { Box, Flex, FormControl, FormLabel, Switch, Text, Tooltip } from '@chakra-ui/react';
import { AlarmActionTypeEnum, IWelfareAlarmDiagnosisActionContextDTO } from '@halter-corp/welfare-alarm-service-client';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { FC, useMemo } from 'react';
import * as yup from 'yup';

import FormikAutoComplete from 'src/components/forms/formik-auto-complete';
import FormikTextarea from 'src/components/forms/formik-text-area';
import useCommonToast from 'src/hooks/use-common-toast';
import { AlarmActionFormProps } from 'src/modules/monitoring/@types';
import { Colors } from 'src/styles';
import { formatAsScreamingSnakeCase, formatStringAsLabel } from 'src/util/string-format.util';

import useWelfareAlarmActions, { AlarmActionRequestPayload } from '../hooks/use-batch-welfare-alarm-action';
import { WelfareAthenaQueryService } from '../services/welfare-athena-query.service';
import ActionFormWrapper from 'src/components/actions/action-form-wrapper';
import ActionButtons from 'src/components/actions/action-buttons';

const DEFAULT_DIAGNOSIS_CATEGORY_OPTIONS = [
  'FALSE_ALARM',
  'HACK_BREAK',
  'GPS_DRIFT',
  'COWS_LEFT_BEHIND',
  'COWS SPOOKED',
  'BROKEN COLLAR',
];
const NON_WELFARE_CONCERN_CATEGORIES = ['FALSE_ALARM'];

const welfareDiagnosisActionSchema = yup.object().shape({
  category: yup
    .string()
    .required()
    .matches(/^[a-zA-Z\d\s_-]+$/),
  isWelfareConcern: yup.boolean().required(),
  comments: yup.string(),
});

type WelfareDiagnosisActionFormValues = {
  category: string;
  isWelfareConcern: boolean;
  comments: string;
};

const WelfareDiagnosisActionForm: FC<AlarmActionFormProps> = ({
  onClose,
  onBack,
  selectedEventAlarmEntities,
  onSuccessfulSubmit,
}) => {
  const { displayToastWithId } = useCommonToast();
  const { sendBatchActionRequests } = useWelfareAlarmActions<IWelfareAlarmDiagnosisActionContextDTO>({
    actionType: AlarmActionTypeEnum.DIAGNOSIS,
  });
  const {
    data: diagnosisCategories,
    isLoading: isDiagnosisCategoriesLoading,
    isError: isDiagnosisCategoriesError,
  } = WelfareAthenaQueryService.useWelfareDiagnosisCategories();
  if (isDiagnosisCategoriesError) {
    // If we cannot retrieve the diagnosis categories, display error toast but proceed as we can still use default categories as options.
    displayToastWithId(
      'welfare-diagnosis-action-form-error',
      'error',
      'Could not retrieve existing welfare diagnosis categories',
    );
  }

  const diagnosisCategoryOptions = useMemo(() => {
    if (diagnosisCategories == null || isDiagnosisCategoriesLoading || isDiagnosisCategoriesError) {
      return DEFAULT_DIAGNOSIS_CATEGORY_OPTIONS;
    }
    return diagnosisCategories;
  }, [diagnosisCategories, isDiagnosisCategoriesError, isDiagnosisCategoriesLoading]);

  const handleSubmit = async (props: WelfareDiagnosisActionFormValues) => {
    const { category, isWelfareConcern, comments } = props;
    const formattedCategory = formatAsScreamingSnakeCase(category);

    const diagnosisActionRequests: AlarmActionRequestPayload<IWelfareAlarmDiagnosisActionContextDTO>[] =
      selectedEventAlarmEntities.flatMap((eventAlarm) => {
        // NOTE: Each event alarm only has one alarm, so access the first one works.
        const alarm = eventAlarm.alarms[0];
        if (alarm == null) return [];
        return {
          entityId: eventAlarm.entityId,
          actionContext: {
            targetAlarm: { type: alarm.type, createdAt: alarm.eventDateTime },
            category: formattedCategory,
            isWelfareConcern,
            comments,
          },
        };
      });

    const { status } = await sendBatchActionRequests(diagnosisActionRequests);
    if (status === 'success') {
      displayToastWithId('welfare-diagnosis-action-form-success', 'success', 'Diagnosis Action Submitted!');
      onSuccessfulSubmit();
    }
  };

  return (
    <Formik
      initialValues={{
        category: '',
        isWelfareConcern: false,
        comments: '',
      }}
      validationSchema={welfareDiagnosisActionSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values, errors, setFieldValue, submitForm, isSubmitting }) => {
        return (
          <>
            <ActionFormWrapper
              title="Diagnose"
              onBack={onBack}
            >
              <Flex
                flexDir="column"
                rowGap={2}
              >
                <Flex
                  flexDir="column"
                  fontSize="sm"
                  mb={2}
                >
                  {selectedEventAlarmEntities.length > 0 ? (
                    <Text>
                      You are diagnosing <strong>{selectedEventAlarmEntities.length}</strong> selected event alarms.
                    </Text>
                  ) : (
                    <Text
                      color={Colors.red500}
                      fontWeight={600}
                    >
                      You have not selected any event alarms to diagnose.
                    </Text>
                  )}
                  <Text>Note that only event alarms are applicable for welfare diagnosis.</Text>
                </Flex>

                <FormikAutoComplete
                  id="category"
                  name="category"
                  label="Category"
                  isLoading={isDiagnosisCategoriesLoading}
                  mb={4}
                  onChangeText={(newCategory) => {
                    if (isNonWelfareConcernCategory(newCategory)) {
                      setFieldValue('isWelfareConcern', false);
                    }
                    setFieldValue('category', newCategory);
                  }}
                  autoCompleteListProps={{ height: 200 }}
                  autoCompleteProps={{ defaultValue: formatStringAsLabel(values.category) ?? '' }}
                  autoCompleteItemsProps={diagnosisCategoryOptions.map((option) => ({
                    key: option,
                    label: formatStringAsLabel(option),
                    value: option,
                  }))}
                  creatable
                  renderCreatable={(value) => (
                    <Flex
                      alignItems="center"
                      gap={2}
                    >
                      <Text>{value}</Text>
                      <Text
                        fontWeight={600}
                        fontSize="0.9em"
                        color={Colors.gray500}
                      >
                        (new option)
                      </Text>
                    </Flex>
                  )}
                  error={errors.category}
                />

                <FormControl
                  mb={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={2}
                >
                  <FormLabel
                    m={0}
                    color="gray.900"
                    id="is-welfare-concern"
                  >
                    Welfare Concern
                  </FormLabel>

                  <Tooltip
                    label={
                      isNonWelfareConcernCategory(values.category)
                        ? `Cannot be turned on if the category is "${formatStringAsLabel(values.category)}"`
                        : undefined
                    }
                    offset={[0, 10]}
                    borderRadius={5}
                    textAlign="center"
                  >
                    <Box>
                      <Switch
                        id="is-welfare-concern"
                        isChecked={values.isWelfareConcern}
                        size="lg"
                        colorScheme="orange"
                        onChange={() => setFieldValue('isWelfareConcern', !values.isWelfareConcern)}
                        isDisabled={isNonWelfareConcernCategory(values.category)}
                      />
                    </Box>
                  </Tooltip>
                </FormControl>

                <FormikTextarea
                  id="comments"
                  mb={5}
                  label="Comments (Optional)"
                  name="comments"
                  textareaProps={{ value: values.comments }}
                  onTriggerSubmit={submitForm}
                  error={errors.comments as string}
                />
              </Flex>
            </ActionFormWrapper>

            <ActionButtons
              confirmButtonText="Confirm"
              onCancel={onClose}
              onSubmit={submitForm}
              isSubmitting={isSubmitting}
              actionButtonDisabled={!isEmpty(errors) || selectedEventAlarmEntities.length === 0}
            />
          </>
        );
      }}
    </Formik>
  );
};

const isNonWelfareConcernCategory = (category: string) => {
  return NON_WELFARE_CONCERN_CATEGORIES.includes(category);
};

export default WelfareDiagnosisActionForm;
