import { BaseFilterModel } from 'src/models/base-filter.model';
import { OnOffOption, SearchFilterState } from 'src/types/filter-types';
import { SearchUtils } from 'src/util/search.util';
import { createSingleton } from 'src/util/singleton.util';

import { AlarmedTower, AlarmedTowerFilterType } from '../@types/index';

export class AlarmedTowerFilterModel extends BaseFilterModel<AlarmedTower, AlarmedTowerFilterType> {
  private constructor() {
    super();
  }

  static getInstance = createSingleton(() => new AlarmedTowerFilterModel());

  /**
   * Filters that are search based such as alarm types.
   */
  static SEARCH_FILTERS: AlarmedTowerFilterType[] = [AlarmedTowerFilterType.FARM, AlarmedTowerFilterType.ALARMS];

  /**
   * Filters that are boolean
   */
  static ON_OFF_FILTERS: AlarmedTowerFilterType[] = [
    AlarmedTowerFilterType.MAINS_POWERED,
    AlarmedTowerFilterType.ROUTER,
    AlarmedTowerFilterType.STARLINK,
  ];

  /**
   * Filters that are time range filters such as first logged date.
   */
  static DATE_RANGE_FILTERS: AlarmedTowerFilterType[] = [
    AlarmedTowerFilterType.FIRST_LOGGED_DATE,
    AlarmedTowerFilterType.LAST_LOGGED_DATE,
  ];

  getSearchFilterFunction(
    filterType: AlarmedTowerFilterType,
  ): ((tower: AlarmedTower, filterState: SearchFilterState) => boolean) | null {
    switch (filterType) {
      case AlarmedTowerFilterType.ALARMS:
        return (tower, filterState) =>
          this.filterBySearchFilterState(filterState, (option) =>
            tower.alarms.map((alarm) => alarm.type).includes(option),
          );
      case AlarmedTowerFilterType.FARM:
        return (tower, filterState) =>
          this.filterBySearchFilterState(filterState, (option) => option === tower.farm?.farmName);
      case AlarmedTowerFilterType.ENTITY_ID:
        return (tower, filterState) =>
          this.filterBySearchFilterState(filterState, (searchText) =>
            SearchUtils.matchesAnySearchPattern(tower.entityId, searchText),
          );
      default:
        return null;
    }
  }

  getOnOffFilterFunction(
    filterType: AlarmedTowerFilterType,
  ): ((item: AlarmedTower, onOffOption: OnOffOption) => boolean) | null {
    switch (filterType) {
      case AlarmedTowerFilterType.MAINS_POWERED:
        return (tower, onOffOption) => {
          if (onOffOption === OnOffOption.ON) return tower.context.mainsPowered === true;
          if (onOffOption === OnOffOption.OFF) return tower.context.mainsPowered === false;
          return true;
        };
      case AlarmedTowerFilterType.ROUTER:
        return (tower, onOffOption) => {
          if (onOffOption === OnOffOption.ON) return tower.context.router === true;
          if (onOffOption === OnOffOption.OFF) return tower.context.router === false;
          return true;
        };
      case AlarmedTowerFilterType.STARLINK:
        return (tower, onOffOption) => {
          if (onOffOption === OnOffOption.ON) return tower.context.starlink === true;
          if (onOffOption === OnOffOption.OFF) return tower.context.starlink === false;
          return true;
        };
      default:
        return null;
    }
  }

  getDateRangeFilterFunction(filterType: AlarmedTowerFilterType) {
    switch (filterType) {
      case AlarmedTowerFilterType.FIRST_LOGGED_DATE:
        return this.getDateRangeFilterMatcher((item) => item.firstLoggedDate);
      case AlarmedTowerFilterType.LAST_LOGGED_DATE:
        return this.getDateRangeFilterMatcher((item) => item.lastLoggedDate);
      default:
        return null;
    }
  }
}
