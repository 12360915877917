import { PropsWithChildren, createContext } from 'react';

import { QueryConfig } from '../@types';

export const QueryConfigContext = createContext<QueryConfig<unknown>>(null!);

export const QueryConfigContextProvider = ({
  children,
  config,
}: PropsWithChildren<{ config: QueryConfig<unknown> }>) => (
  <QueryConfigContext.Provider value={config}>{children}</QueryConfigContext.Provider>
);
