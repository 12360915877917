import { ButtonProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import LabelWithCopy from './label-with-copy';

type FarmLabelProps = {
  farm: { id: string; name?: string };
  copyButtonProps?: ButtonProps;
  dark?: boolean;
};

const FarmLabel: FunctionComponent<FarmLabelProps> = ({ farm, copyButtonProps, dark }) => (
  <LabelWithCopy
    text={farm.name ?? 'Unknown'}
    copyText={farm.id}
    tooltipLabel={`Farm ID - ${farm.id}`}
    copyButtonAriaLabel="Copy Farm ID"
    copyButtonProps={copyButtonProps}
    dark={dark}
  />
);

export default FarmLabel;
