import {
  BreakToBreakShiftNextBreakPartEnum,
  IBreakToBreakRequestDTO,
  IBreakToBreakShiftContextDTO,
  IBreakToBreakShiftOptionsDTO,
  IExitPaddockOptionDTO,
  IExitPaddockRequestDTO,
  IExitPaddockShiftContextDTO,
  IExitPaddockShiftOptionsDTO,
  IOriginDestinationPathOptionDTO,
  IScheduleBreakToBreakRequestDTO,
  IScheduleExitPaddockRequestDTO,
  IScheduleNoShiftRequestDTO,
  IShiftDTO,
  IShiftRequestDTO,
  ShiftStatusEnum,
  ShiftTargetEnum,
  ShiftTypeEnum,
  ShiftingApi,
} from '@halter-corp/shifting-service-client';

import client from '../client';

export type BreakToBreakRequestDTO = IBreakToBreakRequestDTO;
export type BreakToBreakShiftContextDTO = IBreakToBreakShiftContextDTO;
export type BreakToBreakShiftOptionsDTO = IBreakToBreakShiftOptionsDTO;
export type ExitPaddockOptionDTO = IExitPaddockOptionDTO;
export type ExitPaddockRequestDTO = IExitPaddockRequestDTO;
export type ExitPaddockShiftContextDTO = IExitPaddockShiftContextDTO;
export type ExitPaddockShiftOptionsDTO = IExitPaddockShiftOptionsDTO & {
  delayTimeForSynchronisedShiftInSeconds: number;
};
export type OriginDestinationPathOptionDTO = IOriginDestinationPathOptionDTO;
export type ScheduleBreakToBreakRequestDTO = IScheduleBreakToBreakRequestDTO;
export type ScheduleExitPaddockRequestDTO = IScheduleExitPaddockRequestDTO;
export type ScheduleNoShiftRequestDTO = IScheduleNoShiftRequestDTO;

export interface ShiftDTO extends Omit<IShiftDTO, 'context'> {
  type: ShiftTypeEnum;
  context?: Partial<ExitPaddockShiftContextDTO & BreakToBreakShiftContextDTO>;
}

export interface ExitPaddockShiftDTO extends ShiftDTO {
  type: typeof ShiftTypeEnum.EXIT_PADDOCK;
  context: ExitPaddockShiftContextDTO;
}

export interface BreakToBreakShiftDTO extends ShiftDTO {
  type: typeof ShiftTypeEnum.BREAK_TO_BREAK;
  context: BreakToBreakShiftContextDTO;
}

export interface NoShiftDTO extends ShiftDTO {
  type: typeof ShiftTypeEnum.NO_SHIFT;
}

export type ShiftRequestDTO = IShiftRequestDTO;

const shiftsApi = {
  shifting: client.resolveOpenApi(ShiftingApi),
};

export { BreakToBreakShiftNextBreakPartEnum, ShiftStatusEnum, ShiftTypeEnum, ShiftTargetEnum };

export default shiftsApi;
