import { DomainNameEnum } from '@halter-corp/alarm-registry-service-client';
import {
  AlarmActionTypeEnum,
  AlarmFilterTypeEnum,
  IPutAlarmSettingsRequestDTO,
} from '@halter-corp/collar-alarm-service-client';
import { DeviceOnFarmStatusEnum } from '@halter-corp/fleet-service-client';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { AiFillExclamationCircle, AiFillTag } from 'react-icons/ai';
import { BsUpcScan } from 'react-icons/bs';
import { FaMicrochip } from 'react-icons/fa';
import { GiBarn, GiHighGrass, GiSandsOfTime } from 'react-icons/gi';
import { PiFlowArrowFill } from 'react-icons/pi';
import { RiTimerFill } from 'react-icons/ri';
import { TbProgressCheck } from 'react-icons/tb';

import FarmLabel from 'src/components/labels/farm-label';
import {
  AlarmSettingsCompositeIdentifier,
  GenericAlarmSettings,
  GenericAlarmSettingsRequest,
  TriggerRuleDisplayItem,
} from 'src/modules/monitoring/templates/alarm-settings/@types';
import { AlarmRegistryQueries, BusinessQueries, CollarAlarmQueries } from 'src/queries';
import { FilterOption } from 'src/types/filter-types';
import { formatDuration } from 'src/util/duration.util';
import { isNotNull } from 'src/util/is-not-null.util';
import { formatStringAsLabel } from 'src/util/string-format.util';

import { CollarAlarmSettingsFilterType, CollarSettingsTriggerRule } from '../@types';
import {
  CollarAlarmSettingsFilterOptionModel,
  FarmCollarAlarmSettingsFilterOptionModel,
} from '../models/collar-alarm-settings-filter-option.model';

type MutationResponse = {
  status: 'success' | 'error';
  message: string;
  data?: GenericAlarmSettings<CollarSettingsTriggerRule>;
};

export const CollarAlarmSettingsService = {
  useAllAlarmSettings: (): {
    data: GenericAlarmSettings<CollarSettingsTriggerRule>[];
    isLoading: boolean;
    isError: boolean;
  } => {
    const { data = [], isLoading, isError } = CollarAlarmQueries.useAllAlarmSettings();

    // NOTE: filter alarm settings that are only hiding specific collars. These are made from alarm actions to de-noise collars and not serving a purpose of alarm settings.
    const filteredData = useMemo(() => {
      return data.filter((as) => as.triggerRule?.serialNumber == null);
    }, [data]);

    return {
      data: filteredData,
      isLoading,
      isError,
    };
  },

  usePutAlarmSettings: () => {
    const createAlarmSettingsMutation = CollarAlarmQueries.usePutAlarmSettingsMutation();

    return async (request: GenericAlarmSettingsRequest<CollarSettingsTriggerRule>): Promise<MutationResponse> => {
      try {
        const putCollarAlarmSettingsRequest: IPutAlarmSettingsRequestDTO = {
          alarmType: request.alarmType,
          triggerRule: request.triggerRule,
          actionType: CollarAlarmSettingsService.getValidatedActionType(request.actionType),
          filterType: CollarAlarmSettingsService.getValidatedFilterType(request.filterType),
          expiresAt: request.expiresAt,
          context: request.context,
        };
        const alarmSettings = await createAlarmSettingsMutation.mutateAsync(putCollarAlarmSettingsRequest);

        return {
          status: 'success',
          message: 'Alarm settings created successfully',
          data: alarmSettings,
        };
      } catch (error: any) {
        return {
          status: 'error',
          message: error?.response?.data?.message ?? error.message,
        };
      }
    };
  },

  useDeleteAlarmSettings: () => {
    const deleteAlarmSettingsMutation = CollarAlarmQueries.useDeleteAlarmSettingsMutation();

    return async (
      alarmSettingsIdentifier: AlarmSettingsCompositeIdentifier<CollarSettingsTriggerRule>,
    ): Promise<MutationResponse> => {
      try {
        await deleteAlarmSettingsMutation.mutateAsync({
          alarmType: alarmSettingsIdentifier.alarmType,
          triggerRule: alarmSettingsIdentifier.triggerRule,
          expiresAt: alarmSettingsIdentifier.expiresAt,
        });

        return {
          status: 'success',
          message: 'Alarm settings deleted successfully',
        };
      } catch (error) {
        return {
          status: 'error',
          message: (error as Error).message,
        };
      }
    };
  },

  useBuildSettingsTriggerRuleItems: () => {
    const { data: farmById = {} } = BusinessQueries.useFindAllFarmsQuery();

    return (alarmSettings: GenericAlarmSettings<CollarSettingsTriggerRule>): TriggerRuleDisplayItem[] => {
      const { triggerRule } = alarmSettings;
      if (triggerRule == null) return [];

      const triggerRulesListBuilder = [];
      if (triggerRule.onFarmStatus != null) {
        triggerRulesListBuilder.push({
          label: 'On Farm Status',
          value: formatStringAsLabel(triggerRule.onFarmStatus),
          icon: <TbProgressCheck />,
        });
      }
      if (triggerRule.firmwareVersion != null) {
        triggerRulesListBuilder.push({
          label: 'Firmware Version',
          value: triggerRule.firmwareVersion,
          icon: <FaMicrochip />,
        });
      }
      if (triggerRule.firmwareVersionRange != null) {
        triggerRulesListBuilder.push({
          label: 'Firmware Version Range',
          value: `${triggerRule.firmwareVersionRange.fromVersion ?? 'earliest'} ~ ${
            triggerRule.firmwareVersionRange.toVersion ?? 'latest'
          }`,
          icon: <FaMicrochip />,
        });
      }
      if (triggerRule.farmId != null) {
        triggerRulesListBuilder.push({
          label: 'Farm ID',
          value: <FarmLabel farm={{ id: triggerRule.farmId, name: farmById[triggerRule.farmId]?.name }} />,
          icon: <GiBarn />,
        });
      }
      if (triggerRule.taggedOnCattle != null) {
        triggerRulesListBuilder.push({
          label: 'Tagged On Cattle',
          value: triggerRule.taggedOnCattle ? 'Tagged On' : 'Tagged Off',
          icon: <AiFillTag />,
        });
      }
      if (triggerRule.provisionedToFarm != null) {
        triggerRulesListBuilder.push({
          label: 'Provisioned To Farm',
          value: triggerRule.provisionedToFarm ? 'Provisioned ' : 'Not Provisioned',
          icon: <GiHighGrass />,
        });
      }
      if (triggerRule.provisionedToLiveFarm != null) {
        triggerRulesListBuilder.push({
          label: 'Provisioned To Live Farm',
          value: triggerRule.provisionedToLiveFarm ? 'Provisioned ' : 'Not Provisioned',
          icon: <GiHighGrass />,
          helperText:
            'Trigger the action/filter based on whether or not collar is on live farm. Does not apply to non-provisioned collars.',
        });
      }
      if (triggerRule.dependentAlarmType != null) {
        triggerRulesListBuilder.push({
          label: 'Dependent Alarm',
          value: `${formatStringAsLabel(triggerRule.dependentAlarmType)}`,
          icon: <PiFlowArrowFill />,
          helperText: 'Trigger the action/filter if the device also has the specified dependent alarm.',
        });
      }
      if (triggerRule.exceptForAlarmType != null) {
        triggerRulesListBuilder.push({
          label: 'Apply Except For Alarm',
          value: `${formatStringAsLabel(triggerRule.exceptForAlarmType)}`,
          icon: <AiFillExclamationCircle />,
          helperText: 'Alarm type that the action/filter should not be triggered for. It is an exception.',
        });
      }
      if (triggerRule.maxTimeSinceFirstLoggedInMillis != null) {
        triggerRulesListBuilder.push({
          label: 'Minimum Alarmed Time',
          value: formatDuration(triggerRule.maxTimeSinceFirstLoggedInMillis),
          icon: <GiSandsOfTime />,
        });
      }
      if (triggerRule.maxTimeSinceOnFarmStatusChangeInMillis != null) {
        triggerRulesListBuilder.push({
          label: 'Minimum Time Since On Farm Status Change',
          value: formatDuration(triggerRule.maxTimeSinceOnFarmStatusChangeInMillis),
          icon: <RiTimerFill />,
        });
      }
      if (triggerRule.serialNumber != null) {
        triggerRulesListBuilder.push({
          label: 'Serial Number',
          value: triggerRule.serialNumber,
          icon: <BsUpcScan />,
        });
      }

      return triggerRulesListBuilder;
    };
  },

  useExistingFirmwareVersionsFromActiveAlarms: () => {
    const SNOOZE_ALARMS_VISIBLE = false;
    const collarActiveAlarmsResponse =
      CollarAlarmQueries.useActionRequiredActiveCollarAlarmContexts(SNOOZE_ALARMS_VISIBLE);

    return useMemo(() => {
      const firmwareVersions =
        collarActiveAlarmsResponse.data?.map((alarm) => alarm.context?.firmwareVersion).filter(isNotNull) ?? [];
      return sortBy(firmwareVersions);
    }, [collarActiveAlarmsResponse.data]);
  },

  useSubscribedCollarAlarmTypes: () => {
    const { data } = AlarmRegistryQueries.useAlarmSubscriptionsByDomainName(DomainNameEnum.COLLAR);

    return useMemo(() => data?.map(({ alarmType }) => alarmType) ?? [], [data]);
  },

  useAlarmSettingsFilterOptionsByType: () => {
    const { data: farmById } = BusinessQueries.useFindAllFarmsQuery();
    const farms = useMemo(() => Object.values(farmById ?? {}), [farmById]);

    const sortedFirmwareVersions = CollarAlarmSettingsService.useExistingFirmwareVersionsFromActiveAlarms();
    const alarmTypes = CollarAlarmSettingsService.useSubscribedCollarAlarmTypes();
    const sortedAlarmTypes = useMemo(() => sortBy(alarmTypes, (alarmType) => alarmType.toLowerCase()), [alarmTypes]);

    const filterOptionsByType: Map<CollarAlarmSettingsFilterType, FilterOption<unknown>[]> = useMemo(
      () =>
        new Map([
          [
            CollarAlarmSettingsFilterType.ALARM_TYPE,
            sortedAlarmTypes.map((alarmType) =>
              CollarAlarmSettingsFilterOptionModel.create(CollarAlarmSettingsFilterType.ALARM_TYPE, alarmType),
            ),
          ],
          [
            CollarAlarmSettingsFilterType.ON_FARM_STATUS,
            Object.values(DeviceOnFarmStatusEnum).map((status) =>
              CollarAlarmSettingsFilterOptionModel.create(CollarAlarmSettingsFilterType.ON_FARM_STATUS, status),
            ),
          ],
          [
            CollarAlarmSettingsFilterType.FARM,
            farms.map((farm) => FarmCollarAlarmSettingsFilterOptionModel.create(farm.name ?? '', farm.id)),
          ],
          [
            CollarAlarmSettingsFilterType.ACTION_TYPE,
            Object.values(AlarmActionTypeEnum).map((actionType) =>
              CollarAlarmSettingsFilterOptionModel.create(CollarAlarmSettingsFilterType.ACTION_TYPE, actionType),
            ),
          ],
          [
            CollarAlarmSettingsFilterType.FILTER_TYPE,
            Object.values(AlarmFilterTypeEnum).map((filterType) =>
              CollarAlarmSettingsFilterOptionModel.create(CollarAlarmSettingsFilterType.FILTER_TYPE, filterType),
            ),
          ],
          [
            CollarAlarmSettingsFilterType.FIRMWARE,
            sortedFirmwareVersions.map((firmwareVersion) =>
              CollarAlarmSettingsFilterOptionModel.create(CollarAlarmSettingsFilterType.FIRMWARE, firmwareVersion),
            ),
          ],
        ]),
      [farms, sortedFirmwareVersions, sortedAlarmTypes],
    );

    return filterOptionsByType;
  },

  getValidatedActionType: (actionType?: string): AlarmActionTypeEnum | undefined => {
    if (actionType == null) return undefined;
    if (Object.values(AlarmActionTypeEnum).includes(actionType as AlarmActionTypeEnum)) {
      return actionType as AlarmActionTypeEnum;
    }

    throw new Error(`Received invalid action type: "${actionType}".`);
  },

  getValidatedFilterType: (filterType?: string): AlarmFilterTypeEnum | undefined => {
    if (filterType == null) return undefined;
    if (Object.values(AlarmFilterTypeEnum).includes(filterType as AlarmFilterTypeEnum)) {
      return filterType as AlarmFilterTypeEnum;
    }

    throw new Error(`Received invalid filter type: "${filterType}".`);
  },
};
