import { DomainNameEnum } from '@halter-corp/alarm-registry-service-client';

import { ROUTES } from 'src/constants';
import { AlarmSettingsTemplateConfig } from 'src/modules/monitoring/templates/alarm-settings/@types';

import { CollarSettingsTriggerRule } from '../@types';
import CollarAlarmSettingsForm from '../components/collar-alarm-settings.form';
import { CollarAlarmSettingsFilterOptionModel } from '../models/collar-alarm-settings-filter-option.model';
import { CollarAlarmSettingsFilterModel } from '../models/collar-alarm-settings-filter.model';
import { CollarAlarmSettingsService } from '../services/collar-alarm-settings.service';

export const CollarAlarmSettingsTemplateConfig: AlarmSettingsTemplateConfig<CollarSettingsTriggerRule> = {
  linkConfig: { goBackLink: ROUTES.CollarMonitoring },
  entityConfig: {
    entityKey: 'collar-alarm-settings',
    domainName: DomainNameEnum.COLLAR,
    useBuildTriggerRuleItems: CollarAlarmSettingsService.useBuildSettingsTriggerRuleItems,
  },
  filterConfig: {
    useFilterOptionsByType: CollarAlarmSettingsService.useAlarmSettingsFilterOptionsByType,
    filterProcessConfig: CollarAlarmSettingsFilterModel.getInstance(),
    filterOptionConfig: CollarAlarmSettingsFilterOptionModel,
    searchFilterTypes: CollarAlarmSettingsFilterModel.SEARCH_ALARM_SETTINGS_FILTERS,
    onOffFilterTypes: CollarAlarmSettingsFilterModel.ON_OFF_ALARM_SETTINGS_FILTERS,
  },
  queryConfig: {
    useAllAlarmSettings: CollarAlarmSettingsService.useAllAlarmSettings,
    usePutAlarmSettings: CollarAlarmSettingsService.usePutAlarmSettings,
    useDeleteAlarmSettings: CollarAlarmSettingsService.useDeleteAlarmSettings,
  },
  actionConfig: {
    AlarmSettingsForm: CollarAlarmSettingsForm,
  },
};
