import { Text, TextProps, Tooltip, TooltipProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import { getDateTimeFormat, getRelativeTimestamp } from 'src/util/date-time.util';

type TimeStampLabelProps = Partial<TooltipProps> & {
  timestamp: Date;
  textProps?: TextProps;
};

const TimestampLabel: FunctionComponent<TimeStampLabelProps> = ({ timestamp, textProps = {}, ...rest }) => (
  <Tooltip
    fontSize="xs"
    hasArrow
    label={getDateTimeFormat(new Date(timestamp))}
    {...rest}
  >
    <Text
      as="time"
      width="fit-content"
      {...textProps}
    >
      {getRelativeTimestamp(new Date(timestamp))}
    </Text>
  </Tooltip>
);

export default TimestampLabel;
