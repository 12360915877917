import { createContext, useContext } from 'react';

import { useEntityFilterContext } from 'src/providers/entity-filter/hooks';
import { AnyObject } from 'src/types';

import {
  AlarmSettingsActionConfig,
  AlarmSettingsEntityConfig,
  AlarmSettingsFilterConfig,
  AlarmSettingsQueryConfig,
  GenericAlarmSettings,
} from '../@types';

export const EntityConfigContext = createContext<AlarmSettingsEntityConfig<any>>(null!);
export const FilterConfigContext = createContext<AlarmSettingsFilterConfig<any>>(null!);
export const QueryConfigContext = createContext<AlarmSettingsQueryConfig<any>>(null!);
export const ActionConfigContext = createContext<AlarmSettingsActionConfig<any>>(null!);

const useEntityConfigContext = () => useContext(EntityConfigContext);
const useFilterConfigContext = () => useContext(FilterConfigContext);
const useQueryConfigContext = <T extends AnyObject>() => useContext(QueryConfigContext) as AlarmSettingsQueryConfig<T>;
const useActionConfigContext = () => useContext(ActionConfigContext);

export const AlarmSettingsContexts = {
  // ENTITY CONFIG CONTEXT HOOKS
  useEntityConfigContext: () => {
    const context = useEntityConfigContext();
    if (context == null) throw new Error('Entity config context was not provided.');

    return context;
  },

  useBuildTriggerRuleItems: () => {
    const context = useEntityConfigContext();
    if (context == null) throw new Error('Entity config context was not provided.');

    return context.useBuildTriggerRuleItems();
  },

  // FILTER CONFIG CONTEXT HOOKS
  useFilterConfigContext: () => {
    const context = useFilterConfigContext();
    if (context == null) throw new Error('Filter config context was not provided.');

    return context;
  },

  useFilterOptionsByType: () => {
    const context = useFilterConfigContext();
    if (context == null) throw new Error('Filter context was not provided.');

    return context.useFilterOptionsByType();
  },

  // FILTER CONTEXT HOOKS
  useAlarmSettingsFilterContext: <T extends AnyObject>() => {
    const filterContext = useEntityFilterContext<GenericAlarmSettings<T>>();
    // Note: add data fetching loading and error status. Ideally remove this.
    const { isLoading, isError } = AlarmSettingsContexts.useAllAlarmSettings();

    return {
      ...filterContext,
      isLoading,
      isError,
    };
  },

  useSearchFilters: <T extends AnyObject>() => {
    const context = AlarmSettingsContexts.useAlarmSettingsFilterContext<T>();
    if (context == null) throw new Error('Filter context was not provided.');

    return context.useSearchFilters();
  },

  useOnOffFilters: <T extends AnyObject>() => {
    const context = AlarmSettingsContexts.useAlarmSettingsFilterContext<T>();
    if (context == null) throw new Error('Filter context was not provided.');

    return context.useOnOffFilters();
  },

  useDateRangeFilters: <T extends AnyObject>() => {
    const context = AlarmSettingsContexts.useAlarmSettingsFilterContext<T>();
    if (context == null) throw new Error('Filter context was not provided.');

    return context.useDateRangeFilters();
  },

  useAllAlarmSettings: <T extends AnyObject>() => {
    const context = useQueryConfigContext<T>();
    if (context == null) throw new Error('Query config context was not provided.');

    return context.useAllAlarmSettings();
  },

  useCreateAlarmSettings: <T extends AnyObject>() => {
    const context = useQueryConfigContext<T>();
    if (context == null) throw new Error('Query config context was not provided.');

    return context.usePutAlarmSettings();
  },

  useDeleteAlarmSettings: <T extends AnyObject>() => {
    const context = useQueryConfigContext<T>();
    if (context == null) throw new Error('Query config context was not provided.');

    return context.useDeleteAlarmSettings();
  },

  // ACTION CONFIG CONTEXT HOOKS
  useAlarmSettingsForm: () => {
    const context = useActionConfigContext();
    if (context == null) throw new Error('Action config context was not provided.');

    return context.AlarmSettingsForm;
  },
};
