import { Flex, FlexProps, IconButton, Image, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { InfraProductVendorEnum } from '@halter-corp/infrastructure-service-client';
import { InfraProductTypeEnum } from '@halter-corp/manufacturing-service-client';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useMemo } from 'react';
import { BsClipboard2Check } from 'react-icons/bs';
import { IoTerminalOutline } from 'react-icons/io5';
import { MdArrowForwardIos } from 'react-icons/md';

import { ICONS } from 'src/assets';
import CopyContent from 'src/components/copy-content';
import { TowerStatus } from 'src/features/tower';
import useCopyEffect from 'src/hooks/use-copy-effect';
import { useEnvironment } from 'src/providers';
import { Colors } from 'src/styles';
import { GrafanaPeriod } from 'src/types';
import { copyToClipboard } from 'src/util/clipboard.util';
import { formatStringAsLabel } from 'src/util/string-format.util';

import { InfraProduct } from '../models/infra-product';

type InfraProductCardProps = {
  infraProduct: InfraProduct;
  subInfraProducts?: InfraProduct[];
  grafanaPeriod?: GrafanaPeriod;
  grafanaStartTime?: Date;
  grafanaEndTime?: Date;
} & FlexProps;

const InfraProductCard: FC<InfraProductCardProps> = ({
  infraProduct,
  subInfraProducts,
  grafanaStartTime,
  grafanaEndTime,
  grafanaPeriod,
  ...props
}) => {
  const productImage = InfraProduct.getProductImage(infraProduct.productType, infraProduct.vendor);
  const isGroup = subInfraProducts != null;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Flex
        as="article"
        rowGap={3}
        boxShadow="sm"
        alignItems="center"
        px={3}
        py={2}
        bgColor={Colors.gray50}
        border={`1px solid ${Colors.gray200}`}
        borderRadius={5}
        ml={isGroup ? 0 : '2.12rem'}
        {...props}
      >
        {isGroup && (
          <IconButton
            minWidth={0}
            width="30px"
            height="30px"
            aria-label="Open matrix dropdown"
            icon={<MdArrowForwardIos size="13px" />}
            transform={`rotate(${isOpen ? '90' : '0'}deg)`}
            bgColor="transparent"
            _hover={{ bgColor: Colors.gray200 }}
            borderRadius={100}
            onClick={isOpen ? onClose : onOpen}
          />
        )}
        {productImage != null && (
          <Tooltip
            placement="bottom-start"
            label={infraProduct.prettyPrintProductType()}
          >
            <Image
              src={productImage}
              boxSize="30px"
              alt="Product Image"
            />
          </Tooltip>
        )}
        <CopyContent
          copyContent={infraProduct.id}
          fontSize="sm"
          width="250px"
          isTruncated
        >
          <Text isTruncated>{infraProduct.id}</Text>
        </CopyContent>

        <Text
          minW="300px"
          fontSize="sm"
        >
          {[
            infraProduct.prettyPrintProductType(),

            infraProduct.vendor != null && formatStringAsLabel(infraProduct.vendor),

            infraProduct.model != null ? formatStringAsLabel(infraProduct.model) : `T${infraProduct.productNumber}`,
          ]
            .filter(Boolean)
            .join(' · ')}
        </Text>

        {/* Status */}
        <InfraProductStatusDisplay
          infraProduct={infraProduct}
          grafanaStartTime={grafanaStartTime}
          grafanaEndTime={grafanaEndTime}
          grafanaPeriod={grafanaPeriod}
        />
        <Flex
          ml="auto"
          gap={3}
        >
          {infraProduct.productType === InfraProductTypeEnum.GATEWAY && (
            <LoraWanButton
              deviceId={infraProduct.id}
              shardId={infraProduct.shardId}
            />
          )}
          <SshButton infraProduct={infraProduct} />
        </Flex>
      </Flex>
      <AnimatePresence>
        {isOpen && (
          <Flex
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto', transition: { duration: 0.2 } }}
            exit={{ opacity: 0, height: 0, transition: { duration: 0.2 } }}
            as={motion.div}
            overflow="hidden"
            flexDir="column"
            gap={1}
          >
            {subInfraProducts?.map((subInfraProduct) => (
              <InfraProductCard
                infraProduct={subInfraProduct}
                key={subInfraProduct.id}
                grafanaPeriod={grafanaPeriod}
                grafanaStartTime={grafanaStartTime}
                grafanaEndTime={grafanaEndTime}
                columnGap={1}
              />
            ))}
          </Flex>
        )}
      </AnimatePresence>
    </>
  );
};

const InfraProductStatusDisplay: FC<{
  infraProduct: InfraProduct;
  grafanaPeriod?: GrafanaPeriod;
  grafanaStartTime?: Date;
  grafanaEndTime?: Date;
}> = ({ infraProduct, grafanaStartTime, grafanaEndTime, grafanaPeriod }) => {
  if (infraProduct.metadata.status == null) return null;

  const grafanaVariables: Record<string, string> = {
    ...(infraProduct.isGroup() ? { groupId: infraProduct.groupId } : {}),
    ...(!infraProduct.isGroup() ? { componentId: infraProduct.id } : {}),
  };

  return (
    <Flex gap={4}>
      <TowerStatus.OfflineStatus
        offline={infraProduct.metadata.status.offline}
        fontSize="sm"
        grafanaVariables={grafanaVariables}
        grafanaPeriod={grafanaPeriod}
        grafanaStartTime={grafanaStartTime}
        grafanaEndTime={grafanaEndTime}
        text={infraProduct.prettyPrintLastSeenTimestamp()}
        textTooltip={infraProduct.metadata.lastMetric?.lastSeenTimestamp}
      />
      <TowerStatus.BatteryStatus
        outOfBattery={infraProduct.metadata.status.outOfBattery}
        fontSize="sm"
        text={infraProduct.prettyPrintLastBatteryVoltage()}
        grafanaVariables={{ towerId: infraProduct.towerId }}
        grafanaPeriod={grafanaPeriod}
        grafanaStartTime={grafanaStartTime}
        grafanaEndTime={grafanaEndTime}
      />
      {infraProduct.metadata.type === 'group' && (
        <TowerStatus.FaultyStatus
          faulty={infraProduct.metadata.status.faulty}
          fontSize="sm"
        />
      )}
    </Flex>
  );
};

const SshButton: FC<{ infraProduct: InfraProduct }> = ({ infraProduct }) => {
  const { environment: env } = useEnvironment();
  const { copiedEffect, showCopiedEffect } = useCopyEffect();

  const command = useMemo(() => {
    if (infraProduct.productType === InfraProductTypeEnum.GATEWAY) {
      return `ssh root@${infraProduct.id}.lora-gateways.${env}.halter.io`;
    }
    if (infraProduct.productType === InfraProductTypeEnum.TOWER_NODE) {
      return `ssh root@${infraProduct.id}.tower-nodes.${env}.halter.io`;
    }
    if (infraProduct.productType === InfraProductTypeEnum.ROUTER) {
      if (infraProduct.vendor === InfraProductVendorEnum.UBIQUITI) {
        return `ssh halter@${infraProduct.id}.customer-networks.${env}.halter.io`;
      }
      return `ssh root@${infraProduct.id}.customer-networks.${env}.halter.io`;
    }

    return undefined;
  }, [infraProduct, env]);

  if (command === undefined) {
    return null;
  }

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    void copyToClipboard(command);
    showCopiedEffect();
  };

  const icon = copiedEffect ? <BsClipboard2Check fontSize="1rem" /> : <IoTerminalOutline fontSize="1rem" />;

  return (
    <Tooltip
      label={command}
      hasArrow
    >
      <IconButton
        aria-label="ssh"
        icon={icon}
        variant="light"
        size="sm"
        borderRadius="6px"
        onClick={handleCopy}
      />
    </Tooltip>
  );
};

const LoraWanButton: FC<{ deviceId: string; shardId?: string }> = ({ deviceId, shardId }) => {
  const { environment: env } = useEnvironment();

  if (shardId == null) return null;

  const url = `https://${shardId}.lorawan.ap-southeast-2.${env}.halter.io/console/gateways/${deviceId}`;
  if (url === undefined) {
    return null;
  }

  return (
    <Tooltip
      label="Open LoRaWAN Console"
      hasArrow
    >
      <IconButton
        aria-label="ssh"
        icon={
          <Image
            src={ICONS.LoraWanLogo}
            height="1rem"
          />
        }
        variant="light"
        size="sm"
        borderRadius="6px"
        onClick={() => window.open(url, '_blank')}
      />
    </Tooltip>
  );
};

export default InfraProductCard;
