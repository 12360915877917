import { DependencyList, useEffect as useReactEffect } from 'react';

export default function useAsyncEffect(
  effect: () => Promise<void | (() => void)>,
  deps?: DependencyList,
  onError?: (err: any) => void,
): void {
  useReactEffect(() => {
    let unmount: (() => void) | null = null;

    try {
      const asyncFunc = async () => {
        const unmountFunc = await effect();

        if (typeof unmountFunc === 'function') {
          unmount = unmountFunc;
        }
      };
      asyncFunc().catch(onError);
    } catch (e) {
      onError?.(e);
    }

    return () => {
      unmount?.();
    };
  }, deps ?? []);
}
