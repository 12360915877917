import { Button, Flex, FlexProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import { Colors } from 'src/styles';

type FormModalActionsProps = {
  onClose: () => void;
  onSubmit?: () => void;
  isSubmitting: boolean;
} & FlexProps;

const FormModalActions: FunctionComponent<FormModalActionsProps> = ({ onClose, onSubmit, isSubmitting, ...rest }) => (
  <Flex
    py={4}
    px={6}
    justifyContent="flex-end"
    bgColor={Colors.gray200}
    borderTop="1px"
    borderTopColor={Colors.gray200}
    boxShadow="dark-lg"
    {...rest}
  >
    <Button
      onClick={onClose}
      type="button"
      bgColor="transparent"
      isDisabled={isSubmitting}
    >
      Cancel
    </Button>
    <Button
      variant="dark"
      type="submit"
      isLoading={isSubmitting}
      isDisabled={isSubmitting}
      onClick={onSubmit}
      loadingText="Submitting"
    >
      Submit
    </Button>
  </Flex>
);

export default FormModalActions;
