import { BffDebugToolApi, DocsApi } from '@halter-corp/bff-debug-tool-service-client';

import client from '../client';

const bffDebugApi = {
  bffDebugApi: client.resolveOpenApi(BffDebugToolApi),
  docsApi: client.resolveOpenApi(DocsApi),
};

export default bffDebugApi;
