import {
  FeatureFlagNamesEnum,
  IFarmDTO,
  IFarmSettingsDTO,
  IFarmStatusDTO,
  IFeatureFlagDTO,
  IUpdateFeatureFlagRequestDTO,
} from '@halter-corp/business-service-client';
import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQueries,
  useQuery,
} from '@tanstack/react-query';
import { Dictionary, keyBy } from 'lodash';
import moment from 'moment';

import { BusinessApi } from 'src/data';
import { FeatureFlagContext } from 'src/modules/mini-tools/feature-flags/@types';

import client from '../query-client';

export type BusinessQueriesState = {
  farm: IFarmDTO;
  farms: {
    [farmId: string]: IFarmDTO;
  };
  featureFlags: Dictionary<IFeatureFlagDTO>;
  farmFeatureFlagStatuses: Record<string, FeatureFlagContext>;
  farmStatus: IFarmStatusDTO;
};

const BUSINESS_KEY = 'business';

export const BusinessQueryKeys = {
  farms: [BUSINESS_KEY, 'farms'] as const,
  farmById: (farmId: string) => [BUSINESS_KEY, 'farms', farmId] as const,
  featureFlagsByFarmId: (farmId: string) => [BUSINESS_KEY, 'feature-flags', farmId] as const,
  farmFeatureFlagStatusesByFlagName: (flagName: FeatureFlagNamesEnum) =>
    [BUSINESS_KEY, 'farm-feature-flag-statuses', flagName] as const,
  farmStatus: (farmId: string) => [BUSINESS_KEY, 'farm-status', farmId] as const,
  farmSettings: (farmId: string) => [BUSINESS_KEY, 'farm-settings', farmId] as const,
};

type IBusinessQueries = {
  setFarm: (farm: IFarmDTO) => IFarmDTO;
  useFarmById: (
    farmId: string,
    options?: Partial<UseQueryOptions<BusinessQueriesState['farm']>>,
  ) => UseQueryResult<BusinessQueriesState['farm']>;
  useFindAllFarmsQuery: (
    options?: Partial<UseQueryOptions<BusinessQueriesState['farms']>>,
  ) => UseQueryResult<BusinessQueriesState['farms']>;

  useAllFeatureFlagsForFarmIdQuery: (
    farmId: string,
    options?: Partial<UseQueryOptions<BusinessQueriesState['featureFlags']>>,
  ) => UseQueryResult<BusinessQueriesState['featureFlags']>;
  setFeatureFlag: (farmId: string, featureFlag: IFeatureFlagDTO) => IFeatureFlagDTO;
  useSaveFeatureFlagMutation: (
    options?: Partial<
      UseMutationOptions<
        IFeatureFlagDTO,
        undefined,
        { farmId: string; updateFeatureFlagRequestDTO: IUpdateFeatureFlagRequestDTO }
      >
    >,
  ) => UseMutationResult<
    IFeatureFlagDTO,
    undefined,
    { farmId: string; updateFeatureFlagRequestDTO: IUpdateFeatureFlagRequestDTO }
  >;
  useAllFarmFeatureFlagStatusesByFlagNames: (
    flagNames: FeatureFlagNamesEnum[],
    options?: Partial<UseQueryOptions<BusinessQueriesState['farmFeatureFlagStatuses']>>,
  ) => UseQueryResult<BusinessQueriesState['farmFeatureFlagStatuses']>[];

  useFarmStatusByFarmId: (
    farmId: string,
    options?: Partial<UseQueryOptions<IFarmStatusDTO>>,
  ) => UseQueryResult<IFarmStatusDTO>;

  useFarmStatusMaintenanceMutation: (
    options?: Partial<UseMutationOptions<IFarmStatusDTO, undefined, { farmId: string; enabled: boolean }>>,
  ) => UseMutationResult<IFarmStatusDTO, undefined, { farmId: string; enabled: boolean }>;
  setFarmStatus: (farmId: string, farmStatus: IFarmStatusDTO) => IFarmStatusDTO;

  useFarmSettingsByFarmId: (
    farmId: string,
    options?: Partial<UseQueryOptions<IFarmSettingsDTO>>,
  ) => UseQueryResult<IFarmSettingsDTO>;
};

export const BusinessQueries: IBusinessQueries = {
  setFarm: (farm) => {
    client.setClientData<'farm'>(BusinessQueryKeys.farmById(farm.id), () => farm);
    client.setClientData<'farms'>(BusinessQueryKeys.farms, (prevState) => ({
      ...prevState,
      [farm.id]: farm,
    }));
    return farm;
  },

  useFarmById: (farmId, options) =>
    useQuery({
      queryKey: BusinessQueryKeys.farmById(farmId),
      queryFn: async () => {
        const { data } = await BusinessApi.farm.findbyId(farmId);
        return data;
      },
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
    }),

  useFindAllFarmsQuery: (options) =>
    useQuery({
      queryKey: BusinessQueryKeys.farms,
      queryFn: async () => {
        const { data } = await BusinessApi.farm.findAll();
        return keyBy(data, (farm) => farm.id);
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
    }),

  setFeatureFlag: (farmId, featureFlag) => {
    client.setClientData<'featureFlags'>(BusinessQueryKeys.featureFlagsByFarmId(farmId), (prevState) => ({
      ...(prevState ?? {}),
      [featureFlag.name]: featureFlag,
    }));

    client.setClientData<'farmFeatureFlagStatuses'>(
      BusinessQueryKeys.farmFeatureFlagStatusesByFlagName(featureFlag.name),
      (prevState) => ({
        ...(prevState ?? {}),
        [farmId]: {
          farmId,
          flagName: featureFlag.name,
          ...featureFlag,
        },
      }),
    );

    return featureFlag;
  },

  useAllFeatureFlagsForFarmIdQuery: (farmId, options) =>
    useQuery({
      queryKey: BusinessQueryKeys.featureFlagsByFarmId(farmId),
      queryFn: async () => {
        const { data } = await BusinessApi.featureFlags.findAllFeatureFlags(farmId);
        return keyBy(data, (featureFlag) => featureFlag.name);
      },
      ...options,
    }),

  useAllFarmFeatureFlagStatusesByFlagNames: (flagNames, options) =>
    useQueries({
      queries: flagNames.map((flagName) => ({
        queryKey: BusinessQueryKeys.farmFeatureFlagStatusesByFlagName(flagName),
        queryFn: async () => {
          const { data } = await BusinessApi.featureFlags.findAllFarmFeatureFlagStatuses(flagName);
          const featureFlagContexts: FeatureFlagContext[] = data.map((flag) => ({
            flagName,
            ...flag,
          }));

          return keyBy(featureFlagContexts, (farmFeatureFlagStatus) => farmFeatureFlagStatus.farmId);
        },
      })),
      ...options,
    }),

  useSaveFeatureFlagMutation: (options) =>
    useMutation<
      IFeatureFlagDTO,
      undefined,
      { farmId: string; updateFeatureFlagRequestDTO: IUpdateFeatureFlagRequestDTO }
    >({
      mutationFn: async ({ farmId, updateFeatureFlagRequestDTO }) => {
        await BusinessApi.featureFlags.saveFeatureFlag(farmId, updateFeatureFlagRequestDTO);
        return {
          name: updateFeatureFlagRequestDTO.name,
          enabled: updateFeatureFlagRequestDTO.enabled,
        } as IFeatureFlagDTO;
      },
      onSuccess: (featureFlag, { farmId }) => BusinessQueries.setFeatureFlag(farmId, featureFlag),
      ...options,
    }),

  useFarmStatusByFarmId: (farmId, options) =>
    useQuery({
      queryKey: BusinessQueryKeys.farmStatus(farmId),
      queryFn: async () => {
        const { data } = await BusinessApi.farmStatus.retrieveFarmStatus(farmId);
        return data;
      },
      staleTime: moment.duration(3, 'minute').asMilliseconds(),
      refetchInterval: moment.duration(3, 'minute').asMilliseconds(),
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      ...options,
    }),

  useFarmSettingsByFarmId: (farmId, options) =>
    useQuery({
      queryKey: BusinessQueryKeys.farmSettings(farmId),
      queryFn: async () => {
        const { data } = await BusinessApi.farmSettings.getSettings(farmId);
        return data;
      },
      ...options,
    }),

  setFarmStatus: (farmId, farmStatus) => {
    client.setClientData<'farmStatus'>(BusinessQueryKeys.farmStatus(farmId), farmStatus);
    return farmStatus;
  },

  useFarmStatusMaintenanceMutation: (options) =>
    useMutation({
      mutationFn: async ({ farmId, enabled }) => {
        const { data } = enabled
          ? await BusinessApi.farmStatus.setFarmMaintenanceFlagOn(farmId)
          : await BusinessApi.farmStatus.setFarmMaintenanceFlagOff(farmId);
        return data;
      },
      onSuccess: (farmStatus, { farmId }) => BusinessQueries.setFarmStatus(farmId, farmStatus),
      ...options,
    }),
};
