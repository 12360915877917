import { BaseFilterModel } from 'src/models/base-filter.model';
import { SearchFilterState } from 'src/types/filter-types';
import { SearchUtils } from 'src/util/search.util';
import { createSingleton } from 'src/util/singleton.util';

import { PowerAlarmEntity, PowerAlarmFilterType } from '../@types';

export class PowerAlarmFilterModel extends BaseFilterModel<PowerAlarmEntity, PowerAlarmFilterType> {
  private constructor() {
    super();
  }

  static getInstance = createSingleton(() => new PowerAlarmFilterModel());

  static SEARCH_FILTERS: PowerAlarmFilterType[] = [
    PowerAlarmFilterType.ALARMS,
    PowerAlarmFilterType.SCOPE,
    PowerAlarmFilterType.FARM,
  ];

  static DATE_RANGE_FILTERS: PowerAlarmFilterType[] = [
    PowerAlarmFilterType.FIRST_LOGGED_DATE,
    PowerAlarmFilterType.LAST_LOGGED_DATE,
  ];

  private SEARCH_FILTER_FUNCTIONS_BY_FILTER_TYPE: Record<
    string,
    (alarmedEntity: PowerAlarmEntity, filterState: SearchFilterState) => boolean
  > = {
    [PowerAlarmFilterType.ALARMS]: (alarmedEntity, filterState) =>
      this.filterBySearchFilterState(filterState, (option) =>
        alarmedEntity.alarms.map((alarm) => alarm.type).includes(option),
      ),
    [PowerAlarmFilterType.FARM]: (alarmedEntity, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === alarmedEntity.farm?.farmName),
    [PowerAlarmFilterType.SCOPE]: (alarmedEntity, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === alarmedEntity.scope),
    [PowerAlarmFilterType.ENTITY_ID]: (alarmedEntity, filterState) =>
      this.filterBySearchFilterState(filterState, (searchText) =>
        SearchUtils.matchesAnySearchPattern(alarmedEntity.entityId, searchText),
      ),
  };

  getSearchFilterFunction(filterType: PowerAlarmFilterType) {
    return this.SEARCH_FILTER_FUNCTIONS_BY_FILTER_TYPE[filterType];
  }

  getOnOffFilterFunction(_: PowerAlarmFilterType) {
    return null;
  }

  getDateRangeFilterFunction(filterType: PowerAlarmFilterType) {
    switch (filterType) {
      case PowerAlarmFilterType.FIRST_LOGGED_DATE:
        return this.getDateRangeFilterMatcher((item) => item.firstLoggedDate);
      case PowerAlarmFilterType.LAST_LOGGED_DATE:
        return this.getDateRangeFilterMatcher((item) => item.lastLoggedDate);
      default:
        return null;
    }
  }
}
