import { DomainNameEnum } from '@halter-corp/alarm-registry-service-client';
import { IFarmDTO } from '@halter-corp/business-service-client';
import {
  AlarmScopeEnum,
  IEntityScopeContextDTO,
  IWelfareAlarmsContextDTO,
  IWelfareEventAlarmsContextDTO,
} from '@halter-corp/welfare-alarm-service-client';
import { chain, isEmpty, maxBy, minBy } from 'lodash';
import { useCallback, useMemo } from 'react';

import { ALARM_BEHAVIOURS } from 'src/modules/monitoring/constants';
import { AlarmRegistryQueries, BusinessQueries, WelfareAlarmQueries } from 'src/queries';
import { FilterOption } from 'src/types/filter-types';
import { CollarUtils } from 'src/util/collar.util';

import { AlarmScope, FarmContext } from '../../../@types';
import {
  WelfareAlarmEntity,
  WelfareAlarmFilterType,
  WelfareCattleContext,
  WelfareCollarContext,
  WelfareCommandContext,
  WelfareEntityScopeContext,
} from '../@types';
import { WelfareAlarmFilterOptionModel } from '../models/welfare-alarm-filter-option.model';

type CommonWelfareAlarmContextDTO = IWelfareAlarmsContextDTO | IWelfareEventAlarmsContextDTO;

type AlarmsEntityCommonProperties = {
  id: string;
  scope: AlarmScope;
  firstLoggedDate?: Date;
  lastLoggedDate?: Date;
  debugLink?: string;
};

const ALARM_SCOPES: string[] = Object.values(AlarmScopeEnum);

const WelfareMonitoringService = {
  useWelfareAlarmEntities: () => {
    const welfareActiveAlarmsResponse = WelfareMonitoringService.useWelfareActiveAlarmEntities();
    const welfareEventAlarmsResponse = WelfareMonitoringService.useWelfareEventAlarmEntities();

    const data = useMemo(() => {
      return [...welfareActiveAlarmsResponse.data, ...welfareEventAlarmsResponse.data];
    }, [welfareActiveAlarmsResponse.data, welfareEventAlarmsResponse.data]);

    const refetchData = useCallback(async () => {
      await Promise.all([welfareActiveAlarmsResponse.refetchData(), welfareEventAlarmsResponse.refetchData()]);
    }, [welfareActiveAlarmsResponse, welfareEventAlarmsResponse]);

    return {
      data,
      isLoading: welfareActiveAlarmsResponse.isLoading || welfareEventAlarmsResponse.isLoading,
      isError: welfareActiveAlarmsResponse.isError || welfareEventAlarmsResponse.isError,
      refetchData,
    };
  },

  useWelfareActiveAlarmEntities: () => {
    const {
      data = [],
      isLoading,
      isError,
      refetch,
    } = WelfareAlarmQueries.useActionRequiredWelfareActiveAlarmsContexts();
    const { data: farmById } = BusinessQueries.useFindAllFarmsQuery();
    const welfareAlarmEntities: WelfareAlarmEntity[] = useMemo(
      () => mapActiveAlarmDTOsToWelfareAlarmEntities(data, farmById ?? {}),
      [data, farmById],
    );

    return { data: welfareAlarmEntities, isLoading, isError, refetchData: refetch };
  },

  useWelfareEventAlarmEntities: () => {
    const { data = [], isLoading, isError, refetch } = WelfareAlarmQueries.useWelfareEventAlarmsContexts();
    const { data: farmById } = BusinessQueries.useFindAllFarmsQuery();
    const welfareAlarmEntities: WelfareAlarmEntity[] = useMemo(
      () => mapEventAlarmDTOsToWelfareAlarmEntities(data, farmById ?? {}),
      [data, farmById],
    );

    return { data: welfareAlarmEntities, isLoading, isError, refetchData: refetch };
  },

  useWelfareAlarmTypes: () => {
    const { data = [] } = AlarmRegistryQueries.useAlarmSubscriptionsByDomainName(DomainNameEnum.WELFARE);

    return useMemo(
      () =>
        chain(data)
          .map(({ alarmType }) => alarmType)
          .value(),
      [data],
    );
  },

  useWelfareAlarmFilterOptionsByType: () => {
    const { data: farmById } = BusinessQueries.useFindAllFarmsQuery();
    const farms = useMemo(() => Object.values(farmById ?? {}), [farmById]);
    const alarmTypes = WelfareMonitoringService.useWelfareAlarmTypes();

    const filterOptionsByType: Map<WelfareAlarmFilterType, FilterOption<unknown>[]> = useMemo(
      () =>
        new Map([
          [
            WelfareAlarmFilterType.SCOPE,
            Object.values(AlarmScopeEnum).map((status) =>
              WelfareAlarmFilterOptionModel.create(WelfareAlarmFilterType.SCOPE, status),
            ),
          ],
          [
            WelfareAlarmFilterType.ALARMS,
            alarmTypes.map((alarmType) =>
              WelfareAlarmFilterOptionModel.create(WelfareAlarmFilterType.ALARMS, alarmType),
            ),
          ],
          [
            WelfareAlarmFilterType.ALARM_BEHAVIOUR,
            ALARM_BEHAVIOURS.map((behaviour) =>
              WelfareAlarmFilterOptionModel.create(WelfareAlarmFilterType.ALARM_BEHAVIOUR, behaviour),
            ),
          ],
          [
            WelfareAlarmFilterType.FARM,
            farms.map((farm) =>
              WelfareAlarmFilterOptionModel.create(WelfareAlarmFilterType.FARM, farm.name ?? '', { farmId: farm.id }),
            ),
          ],
        ]),
      [alarmTypes, farms],
    );

    return filterOptionsByType;
  },
};

const mapEventAlarmDTOsToWelfareAlarmEntities = (
  welfareEventAlarmContexts: IWelfareEventAlarmsContextDTO[],
  farmById: Record<string, IFarmDTO>,
) => {
  const welfareAlarmEntities: WelfareAlarmEntity[] = welfareEventAlarmContexts.flatMap((alarmContext) => {
    const commonProperties = getWelfareAlarmCommonProperties(alarmContext);
    if (commonProperties == null) return [];

    return alarmContext.alarms.map((alarm) => ({
      entityId: alarmContext.referenceId,
      uniqueKey: `${alarmContext.referenceId}-${alarm.type}-${alarm.createdAt}`,
      alarmBehaviour: 'event',
      // Event alarm only contains one alarm because each alarm has different context.
      alarms: [{ type: alarm.type, eventDateTime: alarm.createdAt }],
      farm: getFarmContext(alarmContext.alarmScope, alarm.context, farmById),
      context: alarm.context,
      ...commonProperties,
    }));
  });

  return welfareAlarmEntities;
};

const mapActiveAlarmDTOsToWelfareAlarmEntities = (
  welfareAlarmContexts: IWelfareAlarmsContextDTO[],
  farmById: Record<string, IFarmDTO>,
) => {
  const welfareAlarmEntities: WelfareAlarmEntity[] = welfareAlarmContexts.flatMap((alarmContext) => {
    const commonProperties = getWelfareAlarmCommonProperties(alarmContext);
    if (commonProperties == null) return [];

    const entityContext = getScopeContext(commonProperties.scope, alarmContext.context);
    if (entityContext == null) return [];

    return {
      entityId: alarmContext.referenceId,
      uniqueKey: alarmContext.referenceId,
      alarmBehaviour: 'active',
      farm: getFarmContext(alarmContext.alarmScope, alarmContext.context, farmById),
      alarms: alarmContext.alarms.map((alarm) => ({ type: alarm.type, eventDateTime: alarm.createdAt })),
      context: entityContext,
      ...commonProperties,
    };
  });

  return welfareAlarmEntities;
};

const findAlarmScope = (alarmScope: AlarmScopeEnum): AlarmScope | undefined =>
  ALARM_SCOPES.find((scope): scope is AlarmScope => scope === alarmScope);

const getWelfareAlarmCommonProperties = (
  welfareAlarmContext: CommonWelfareAlarmContextDTO,
): AlarmsEntityCommonProperties | null => {
  const firstLoggedDate = minBy(welfareAlarmContext.alarms, (alarm) => alarm.createdAt)?.createdAt;
  const lastLoggedDate = maxBy(welfareAlarmContext.alarms, (alarm) => alarm.createdAt)?.createdAt;

  const scope = findAlarmScope(welfareAlarmContext.alarmScope);
  if (scope == null) {
    console.error(`could not find alarm scope for alarm context: ${JSON.stringify(welfareAlarmContext)}`);
    return null;
  }

  const debugLink =
    welfareAlarmContext.alarmScope === AlarmScopeEnum.COLLAR
      ? CollarUtils.getDebugLink(welfareAlarmContext.referenceId)
      : undefined;

  return {
    id: welfareAlarmContext.referenceId,
    scope,
    debugLink,
    firstLoggedDate: firstLoggedDate != null ? new Date(firstLoggedDate) : undefined,
    lastLoggedDate: lastLoggedDate != null ? new Date(lastLoggedDate) : undefined,
  };
};

const getScopeContext = (
  alarmScope: AlarmScope,
  scopeContext: IEntityScopeContextDTO,
): WelfareEntityScopeContext | null => {
  switch (alarmScope) {
    case AlarmScopeEnum.COMMAND:
      const commandContextDTO = scopeContext?.commandContext;
      if (commandContextDTO == null) return null;

      const welfareCommandContext: WelfareCommandContext = {
        farmId: commandContextDTO.farmId,
        collarCommandId: commandContextDTO.collarCommandId,
        type: commandContextDTO.type,
      };
      return { commandContext: welfareCommandContext };
    case AlarmScopeEnum.COLLAR:
      const collarContextDTO = scopeContext?.collarContext;
      if (collarContextDTO == null) return null;

      const welfareCollarContext: WelfareCollarContext = {
        farmId: isEmpty(collarContextDTO.farmId) ? undefined : collarContextDTO.farmId,
        mobId: isEmpty(collarContextDTO.mobId) ? undefined : collarContextDTO.mobId,
        cattleId: isEmpty(collarContextDTO.cattleId) ? undefined : collarContextDTO.cattleId,
        cattleName: isEmpty(collarContextDTO.cattleName) ? undefined : collarContextDTO.cattleName,
      };
      return { collarContext: welfareCollarContext };
    case AlarmScopeEnum.COW:
      const cattleContextDTO = scopeContext?.cattleContext;
      if (cattleContextDTO == null) return null;

      const welfareCattleContext: WelfareCattleContext = {
        cattleName: isEmpty(cattleContextDTO.cattleName) ? undefined : cattleContextDTO.cattleName,
        farmId: isEmpty(cattleContextDTO.farmId) ? undefined : cattleContextDTO.farmId,
        mobId: isEmpty(cattleContextDTO.mobId) ? undefined : cattleContextDTO.mobId,
        collarId: cattleContextDTO.collarId,
      };
      return { cattleContext: welfareCattleContext };
    case AlarmScope.MOB:
      const mobContextDTO = scopeContext?.mobContext;
      if (mobContextDTO == null) return null;
      return { mobContext: mobContextDTO };
    default:
      return {};
  }
};

const getFarmContext = (
  alarmScope: AlarmScopeEnum,
  scopeContext: IEntityScopeContextDTO,
  farmById: Record<string, IFarmDTO>,
): FarmContext | null => {
  switch (alarmScope) {
    case AlarmScopeEnum.COMMAND:
      const commandContext = scopeContext?.commandContext;
      if (commandContext == null) return null;

      const commandFarm = farmById[commandContext.farmId];
      if (commandFarm == null) return null;
      return {
        farmId: commandFarm.id,
        farmName: commandFarm.name,
      };
    case AlarmScopeEnum.COLLAR:
      const collarContext = scopeContext?.collarContext;
      if (collarContext == null) return null;

      const collarFarm = farmById[collarContext.farmId];
      if (collarFarm == null) return null;
      return {
        farmId: collarFarm.id,
        farmName: collarFarm.name,
      };
    case AlarmScopeEnum.COW:
      const cattleContext = scopeContext?.cattleContext;
      if (cattleContext == null) return null;

      const cattleFarm = farmById[cattleContext.farmId];
      if (cattleFarm == null) return null;
      return {
        farmId: cattleFarm.id,
        farmName: cattleFarm.name,
      };
    case AlarmScopeEnum.MOB:
      const mobContext = scopeContext?.mobContext;
      if (mobContext == null) return null;

      const mobFarm = farmById[mobContext.farmId];
      if (mobFarm == null) return null;
      return {
        farmId: mobFarm.id,
        farmName: mobFarm.name,
      };
    default:
      return null;
  }
};

export default WelfareMonitoringService;
