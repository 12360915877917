import { Box, Td, Tr } from '@chakra-ui/react';
import { Row } from '@tanstack/react-table';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import CommonLoadingSpinner from 'src/components/common-loading-spinner';
import { InfraProduct, InfraProductGroupsPanel } from 'src/features/infra-product';
import { ErrorPanel } from 'src/modules/error';
import { MonitoringContexts } from 'src/modules/monitoring/contexts';

import { AlarmedTower } from '../@types';

type ExpandedTowerRowProps = {
  towerRow: Row<AlarmedTower>;
};

const ExpandedTowerRow: FC<ExpandedTowerRowProps> = ({ towerRow }) => {
  return (
    <Tr>
      <Td
        colSpan={100} // NOTE: This is to make a cell span the entire row.
        p={0}
      >
        <AnimatePresence>
          {towerRow.getIsExpanded() && (
            <Box
              as={motion.div}
              overflowY="hidden"
              {...EXPAND_SHRINK_ANIMATION}
            >
              <Box
                px={{ base: 4, lg: 8 }}
                py={4}
              >
                <ErrorBoundary FallbackComponent={ErrorPanel}>
                  <TowerInfraProductsDisplay towerId={towerRow.original.entityId} />
                </ErrorBoundary>
              </Box>
            </Box>
          )}
        </AnimatePresence>
      </Td>
    </Tr>
  );
};

const TowerInfraProductsDisplay: FC<{ towerId: string }> = ({ towerId }) => {
  const { data, isLoading, isError } = InfraProduct.useFetchByTowerIds([towerId]);
  const [grafanaPeriod] = MonitoringContexts.useGrafanaPeriod();

  if (isError) throw new Error('Failed to fetch infra products for tower...');
  if (isLoading || data.length === 0) return <CommonLoadingSpinner />;

  return (
    <InfraProductGroupsPanel
      infraProducts={data}
      grafanaPeriod={grafanaPeriod}
    />
  );
};

const EXPAND_SHRINK_ANIMATION = {
  initial: { height: 0 },
  animate: { height: 'auto', transition: { duration: 0.2, ease: 'easeOut' } },
  exit: { height: 0, transition: { duration: 0.15, ease: 'easeIn' } },
};

export default ExpandedTowerRow;
