import { Box, Button, VStack } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import { ICONS } from 'src/assets';
import { AuthService } from 'src/services';

const AuthScreen: FunctionComponent = () => (
  <VStack
    bg="gray.900"
    flex={1}
    alignItems="center"
    justifyContent="center"
  >
    <Box maxW={500}>
      <ICONS.HalterLogoWhite width="100%" />
    </Box>
    <Box
      maxW={400}
      width="100%"
      bg="white"
      p={8}
      borderRadius={8}
    >
      <Button
        width="100%"
        onClick={AuthService.signIn}
      >
        Sign in with Okta
      </Button>
    </Box>
  </VStack>
);

export default AuthScreen;
