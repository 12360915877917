import {
  IInfraProductGroupStatusDTO,
  IInfraProductLastMetricDTO,
  IInfraProductStatusDTO,
  InfraProductTypeEnum,
  InfraProductVendorEnum,
} from '@halter-corp/infrastructure-service-client';
import { InfraProductModelEnum } from '@halter-corp/manufacturing-service-client';
import { keyBy, uniq } from 'lodash';
import pluralize from 'pluralize';
import { useMemo } from 'react';

import { IMAGES } from 'src/assets';
import { Fetchable, Model, useMemoizedCreateFetchable } from 'src/models';
import { InfrastructureQueries } from 'src/queries';
import { TowerQueries } from 'src/queries/queries/tower.queries';
import { isNotNull } from 'src/util/is-not-null.util';
import { formatStringAsLabel } from 'src/util/string-format.util';

export type InfraProductComponentStatus = Omit<IInfraProductStatusDTO, 'id'>;

export type InfraProductGroupStatus = Omit<IInfraProductGroupStatusDTO, 'id'>;

export type InfraProductLastMetric = Omit<IInfraProductLastMetricDTO, 'id'>;

export type InfraProductMetadata =
  | {
      type: 'component';
      status?: InfraProductComponentStatus;
      lastMetric?: InfraProductLastMetric;
    }
  | {
      type: 'group';
      status?: InfraProductGroupStatus;
      lastMetric?: InfraProductLastMetric;
    };

export class InfraProduct extends Model {
  readonly id!: string;

  readonly groupId!: string;

  readonly towerId!: string;

  readonly productType!: InfraProductTypeEnum;

  readonly productNumber?: number;

  readonly vendor?: InfraProductVendorEnum;

  readonly model?: InfraProductModelEnum;

  readonly macAddress?: string;

  readonly shardId?: string;

  readonly metadata!: InfraProductMetadata;

  public isGroup() {
    return this.groupId === this.id;
  }

  public prettyPrintProductType() {
    return formatStringAsLabel(this.productType);
  }

  public prettyPrintVendor() {
    if (this.vendor == null) return 'Unknown';
    return formatStringAsLabel(this.vendor);
  }

  public prettyPrintModel() {
    if (this.model == null) return 'Unknown';
    return formatStringAsLabel(this.model);
  }

  public prettyPrintLastSeenTimestamp() {
    if (this.isGroup()) {
      if (this.metadata?.status?.offline == null) return 'Unknown';
      return this.metadata.status.offline ? 'Offline' : 'Online';
    }

    const { lastMetric } = this.metadata;
    let lastSeenString = 'Never seen';
    if (lastMetric?.lastSeenTimestamp != null) {
      const lastSeenSecondsAgo = Math.max(
        Math.floor((Date.now() - new Date(lastMetric.lastSeenTimestamp).getTime()) / 1000),
        0,
      );
      if (lastSeenSecondsAgo < 60) {
        lastSeenString = `${lastSeenSecondsAgo} ${pluralize('sec', lastSeenSecondsAgo)} ago`;
      } else if (lastSeenSecondsAgo < 2 * 3600) {
        const lastSeenMinutesAgo = Math.floor(lastSeenSecondsAgo / 60);
        lastSeenString = `${lastSeenMinutesAgo} ${pluralize('min', lastSeenMinutesAgo)} ago`;
      } else if (lastSeenSecondsAgo < 48 * 3600) {
        const lastSeenHoursAgo = Math.floor(lastSeenSecondsAgo / 3600);
        lastSeenString = `${lastSeenHoursAgo} ${pluralize('hour', lastSeenHoursAgo)} ago`;
      } else {
        const lastSeenDaysAgo = Math.floor(lastSeenSecondsAgo / (3600 * 24));
        lastSeenString = `${lastSeenDaysAgo} ${pluralize('day', lastSeenDaysAgo)} ago`;
      }
    }
    return lastSeenString;
  }

  public prettyPrintLastBatteryVoltage() {
    if (this.isGroup()) {
      if (this.metadata?.status?.outOfBattery == null) return 'Unknown';
      return this.metadata.status.outOfBattery ? 'Out of Battery' : 'Good';
    }

    const { lastMetric } = this.metadata;
    if (lastMetric?.batteryVoltageInMv == null) return '';
    return `${(lastMetric.batteryVoltageInMv / 1000).toFixed(2)} V`;
  }

  static getProductImage = (productType: InfraProductTypeEnum, productVendor?: InfraProductVendorEnum) => {
    switch (productType) {
      case InfraProductTypeEnum.MESH_CASE:
        return IMAGES.Hardware.T4MeshCase;
      case InfraProductTypeEnum.WIFI_CASE:
        return IMAGES.Hardware.T4WifiCase;
      case InfraProductTypeEnum.MESH_MAST:
        return IMAGES.Hardware.T4MeshMast;
      case InfraProductTypeEnum.WIFI_MAST:
        return IMAGES.Hardware.T4WifiMast;
      case InfraProductTypeEnum.TOWER_NODE:
        return IMAGES.Hardware.MikrotikMeshNode;
      case InfraProductTypeEnum.BATTERY_CONTROLLER:
        return IMAGES.Hardware.EpeverBatteryController;
      case InfraProductTypeEnum.LED_INDICATOR:
        return IMAGES.Hardware.HalterLedBoard;
      case InfraProductTypeEnum.GATEWAY:
        if (productVendor === InfraProductVendorEnum.RAK) {
          return IMAGES.Hardware.RakGateway;
        }
        if (productVendor === InfraProductVendorEnum.TEKTELIC) {
          return IMAGES.Hardware.TektelicGateway;
        }
        break;
      case InfraProductTypeEnum.SOLAR_SWITCH:
        if (productVendor === InfraProductVendorEnum.HALTER) {
          return IMAGES.Hardware.HalterSolarSwitch;
        }
        if (productVendor === InfraProductVendorEnum.UBIQUITI) {
          return IMAGES.Hardware.UbiquitiSolarSwitch;
        }
        break;
      case InfraProductTypeEnum.ROUTER:
        if (productVendor === InfraProductVendorEnum.TELTONIKA) {
          return IMAGES.Hardware.TeltonikaRouter;
        }
        break;
      case InfraProductTypeEnum.BATTERY:
        return IMAGES.Hardware.Battery;
    }

    return null;
  };

  /**
   * TODO: Please change this once the infrastructure service updates with device - tower mappings.
   * It is currently using both tower service and infrastructure service to manage mappings which should be changed.
   * Edit: omg this became a monstrosity. Needs to be remade when tower mapping is available in infrastructure service.
   */
  static useFetchByTowerIds = (towerIds: string[]): Fetchable<InfraProduct[]> => {
    const networkDeviceContextsResponses = TowerQueries.useNetworkDeviceContextsByTowerIds(towerIds);

    const networkDeviceContexts = useMemo(() => {
      const data = networkDeviceContextsResponses.map((r) => r.data).filter(isNotNull);
      return data.map((devices) => Object.values(devices ?? [])).flat();
    }, [networkDeviceContextsResponses]);

    const deviceContextsResponses = TowerQueries.useDeviceContextsByTowerIds(towerIds);
    const deviceContexts = useMemo(() => {
      const data = deviceContextsResponses.map((r) => r.data).filter(isNotNull);
      return data
        .map((devices) => Object.values(devices ?? []))
        .flat()
        .filter((dc) => dc.type !== 'TOWER_NODE'); // tower nodes com from network device contexts (ask Sarah)
    }, [deviceContextsResponses]);

    const deviceIds = useMemo(
      () => [...deviceContexts.map((dc) => dc.id), ...networkDeviceContexts.map((ndc) => ndc.id)],
      [deviceContexts, networkDeviceContexts],
    );

    // This probably miss some devices that are non-network devices
    const networkInfraProductContextsResponses = InfrastructureQueries.useInfraProductContextByIds(deviceIds);
    const networkInfraProductContexts = useMemo(
      () => networkInfraProductContextsResponses.map((r) => r.data).filter(isNotNull),
      [networkInfraProductContextsResponses],
    );

    const groupIds = useMemo(
      () => uniq(networkInfraProductContexts.map((ipc) => ipc.groupId)),
      [networkInfraProductContexts],
    );

    const towerIdByInfraProductId = useMemo(() => {
      const towerIdByGroupIdMap = new Map<string, string>();

      networkInfraProductContexts?.forEach((infraProduct) => {
        const towerServiceDevice = [...networkDeviceContexts, ...deviceContexts].find(
          (tsd) => tsd.id === infraProduct.id,
        );
        if (towerServiceDevice != null && towerServiceDevice.towerId != null) {
          towerIdByGroupIdMap.set(infraProduct.id, towerServiceDevice.towerId);
          if (infraProduct.groupId !== '') {
            towerIdByGroupIdMap.set(infraProduct.groupId, towerServiceDevice.towerId);
          }
        }
      });

      return towerIdByGroupIdMap;
    }, [networkDeviceContexts, networkInfraProductContexts, deviceContexts]);

    const { data: allInfraProductContexts } = useMemoizedCreateFetchable(
      InfrastructureQueries.useInfraProductContextByIds(groupIds),
      InfrastructureQueries.useInfraProductContextsByGroupIds(groupIds),
      networkInfraProductContexts,
      (groupResponses, groupedComponentResponses, existingComponentData) => {
        const groupedComponentData = [
          ...groupedComponentResponses
            .map((response) => response.data)
            .filter(isNotNull)
            .flat(),
        ];
        const groupData = groupResponses
          .map((response) => response.data)
          .filter(isNotNull)
          .flat();

        const soloComponentData = existingComponentData.filter(
          (component) => !groupedComponentData.some((groupedComponent) => groupedComponent.id === component.id),
        );

        return [...groupData, ...groupedComponentData, ...soloComponentData];
      },
    );

    const componentIds = useMemo(() => {
      const groupIdsSet = new Set(groupIds);
      return allInfraProductContexts?.filter((ipc) => !groupIdsSet.has(ipc.id)).map((ipc) => ipc.id) ?? [];
    }, [allInfraProductContexts, groupIds]);

    return useMemoizedCreateFetchable(
      allInfraProductContexts,
      InfrastructureQueries.useInfraProductGroupStatusByIds(groupIds),
      InfrastructureQueries.useInfraProductStatusByIds(componentIds),
      InfrastructureQueries.useInfraProductLastMetricByIds(componentIds),
      towerIdByInfraProductId,
      deviceContexts,
      (
        products,
        groupStatusesResult,
        statusesResult,
        lastMetricsResult,
        towerIdMapping,
        towerServiceDeviceContexts,
      ) => {
        if (products == null) return [];

        const groupStatusById = keyBy(groupStatusesResult.map((r) => r.data).filter(isNotNull), (g) => g.id);
        const statusById = keyBy(statusesResult.map((r) => r.data).filter(isNotNull), (s) => s.id);
        const lastMetricById = keyBy(lastMetricsResult.map((r) => r.data).filter(isNotNull), (lm) => lm.id);
        return products.flatMap((product) => {
          const towerId = towerIdMapping.get(product.groupId === '' ? product.id : product.groupId);

          if (towerId == null) return [];

          const shardId = towerServiceDeviceContexts.find((ndc) => ndc.id === product.id)?.shardId;

          const status: InfraProductMetadata =
            product.groupId === product.id
              ? {
                  type: 'group',
                  status: groupStatusById[product.id],
                  lastMetric: lastMetricById[product.id],
                }
              : {
                  type: 'component',
                  status: statusById[product.id],
                  lastMetric: lastMetricById[product.id],
                };
          const infraProduct = InfraProduct.fromObject({
            ...product,
            model: product.model as InfraProductModelEnum,
            shardId,
            towerId,
            metadata: status,
          });

          return infraProduct;
        });
      },
    );
  };
}
