import { DomainNameEnum } from '@halter-corp/alarm-registry-service-client';

import { ROUTES } from 'src/constants';
import { AlarmSettingsTemplateConfig } from 'src/modules/monitoring/templates/alarm-settings/@types';

import { PowerSettingsTriggerRule } from '../@types';
import PowerAlarmSettingsForm from '../components/power-alarm-settings.form';
import { PowerAlarmSettingsFilterOptionModel } from '../models/power-alarm-settings-filter-option.model';
import { PowerAlarmSettingsFilterModel } from '../models/power-alarm-settings-filter.model';
import { PowerAlarmSettingsService } from '../services/power-alarm-settings.service';

export const PowerAlarmSettingsTemplateConfig: AlarmSettingsTemplateConfig<PowerSettingsTriggerRule> = {
  linkConfig: { goBackLink: ROUTES.PowerMonitoring },
  entityConfig: {
    entityKey: 'power-alarm-settings',
    domainName: DomainNameEnum.POWER,
    useBuildTriggerRuleItems: PowerAlarmSettingsService.useBuildSettingsTriggerRuleItems,
  },
  filterConfig: {
    useFilterOptionsByType: PowerAlarmSettingsService.useAlarmSettingsFilterOptionsByType,
    filterProcessConfig: PowerAlarmSettingsFilterModel.getInstance(),
    filterOptionConfig: PowerAlarmSettingsFilterOptionModel,
    searchFilterTypes: PowerAlarmSettingsFilterModel.SEARCH_ALARM_SETTINGS_FILTERS,
    dateRangeFilterTypes: PowerAlarmSettingsFilterModel.DATE_RANGE_FILTERS,
  },
  queryConfig: {
    useAllAlarmSettings: PowerAlarmSettingsService.useAllAlarmSettings,
    usePutAlarmSettings: PowerAlarmSettingsService.usePutAlarmSettings,
    useDeleteAlarmSettings: PowerAlarmSettingsService.useDeleteAlarmSettings,
  },
  actionConfig: {
    AlarmSettingsForm: PowerAlarmSettingsForm,
  },
};
