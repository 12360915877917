import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import ApplicationCommandPalette from './components/command-palette/application-command-palette';
import Dashboard from './components/dashboard';
import PageLoadingSpinner from './components/page-loading-spinner';
import { SagemakerScreen } from './modules/mini-tools/sagemaker';
import {
  DataToolCollectionScreen,
  DocumentationCollectionScreen,
  FarmhandToolCollectionScreen,
  FleetToolCollectionScreen,
  HardwareToolCollectionScreen,
  MobileToolCollectionScreen,
} from './modules/tool-collections';

const MapScreen = lazy(() => import('./modules/map').then((module) => ({ default: module.MapScreen })));

const MatrixScreen = lazy(() =>
  import('./modules/matrix').then((module) => ({
    default: module.MatrixScreen,
  })),
);

const MonitoringScreen = lazy(() =>
  import('./modules/monitoring').then((module) => ({
    default: module.MonitoringScreen,
  })),
);

const CollarMonitoringScreen = lazy(() =>
  import('./modules/monitoring/submodules/collar-monitoring').then((module) => ({
    default: module.CollarMonitoringScreen,
  })),
);
const CollarAlarmSettingsScreen = lazy(() =>
  import('./modules/monitoring/submodules/collar-monitoring').then((module) => ({
    default: module.CollarAlarmSettingsScreen,
  })),
);

const FarmOpsMonitoringScreen = lazy(() =>
  import('./modules/monitoring/submodules/farm-ops-monitoring').then((module) => ({
    default: module.FarmOpsMonitoringScreen,
  })),
);

const WelfareMonitoringScreen = lazy(() =>
  import('./modules/monitoring/submodules/welfare-monitoring').then((module) => ({
    default: module.WelfareMonitoringScreen,
  })),
);
const WelfareAlarmSettingsScreen = lazy(() =>
  import('./modules/monitoring/submodules/welfare-monitoring').then((module) => ({
    default: module.WelfareAlarmSettingsScreen,
  })),
);
const TowerMonitoringScreen = lazy(() =>
  import('./modules/monitoring/submodules/tower-monitoring').then((module) => ({
    default: module.TowerMonitoringScreen,
  })),
);
const TowerAlarmSettingsScreen = lazy(() =>
  import('./modules/monitoring/submodules/tower-monitoring').then((module) => ({
    default: module.TowerAlarmSettingsScreen,
  })),
);
const PowerMonitoringScreen = lazy(() =>
  import('./modules/monitoring/submodules/power-monitoring').then((module) => ({
    default: module.PowerMonitoringScreen,
  })),
);
const PowerAlarmSettingsScreen = lazy(() =>
  import('./modules/monitoring/submodules/power-monitoring').then((module) => ({
    default: module.PowerAlarmSettingsScreen,
  })),
);

const AlarmRegistrationScreen = lazy(() =>
  import('./modules/monitoring/submodules/alarm-registration').then((module) => ({
    default: module.AlarmRegistrationScreen,
  })),
);
const CreateAlarmRegistrationScreen = lazy(() =>
  import('./modules/monitoring/submodules/alarm-registration').then((module) => ({
    default: module.CreateAlarmRegistrationScreen,
  })),
);
const EditAlarmRegistrationScreen = lazy(() =>
  import('./modules/monitoring/submodules/alarm-registration').then((module) => ({
    default: module.EditAlarmRegistrationScreen,
  })),
);
const AlarmRegistrationDetailScreen = lazy(() =>
  import('./modules/monitoring/submodules/alarm-registration').then((module) => ({
    default: module.AlarmRegistrationDetailScreen,
  })),
);

const DbtDocsScreen = lazy(() =>
  import('./modules/mini-tools/dbt-docs').then((module) => ({ default: module.DbtDocsScreen })),
);
const ProtobufDocsScreen = lazy(() =>
  import('./modules/mini-tools/protobuf').then((module) => ({ default: module.ProtobufDocsScreen })),
);
const OpenapiClientListScreen = lazy(() =>
  import('./modules/mini-tools/openapi-clients').then((module) => ({
    default: module.OpenapiClientListScreen,
  })),
);
const OpenapiClientDocsScreen = lazy(() =>
  import('./modules/mini-tools/openapi-clients').then((module) => ({
    default: module.OpenapiClientDocsScreen,
  })),
);

const ShockCurvesScreen = lazy(() =>
  import('./modules/mini-tools/shock-curves').then((module) => ({ default: module.ShockCurvesScreen })),
);

const CollarBuildPlanScreen = lazy(() =>
  import('./modules/mini-tools/collar-build-plan').then((module) => ({ default: module.CollarBuildPlanScreen })),
);
const ReleaseChannelOverviewScreen = lazy(() =>
  import('./modules/mini-tools/firmware').then((module) => ({ default: module.ReleaseChannelOverviewScreen })),
);
const TowerBuildPlanScreen = lazy(() =>
  import('./modules/mini-tools/tower-build-plan').then((module) => ({ default: module.TowerBuildPlanScreen })),
);
const CollarHardwareScreen = lazy(() =>
  import('./modules/mini-tools/collar-hardware').then((module) => ({ default: module.CollarHardwareScreen })),
);
const NfcToolScreen = lazy(() =>
  import('./modules/mini-tools/nfc-tool').then((module) => ({ default: module.NfcToolScreen })),
);
const RefurbPrepScreen = lazy(() =>
  import('./modules/mini-tools/refurb-prep').then((module) => ({ default: module.RefurbPrepScreen })),
);

const WarehouseManagementScreen = lazy(() =>
  import('./modules/mini-tools/fleet-management/warehouse-management').then((module) => ({
    default: module.WarehouseManagementScreen,
  })),
);
const DispatchScreen = lazy(() =>
  import('./modules/mini-tools/fleet-management/dispatch').then((module) => ({ default: module.DispatchScreen })),
);
const FarmSnapshotScreen = lazy(() =>
  import('./modules/mini-tools/farm-snapshot').then((module) => ({
    default: module.FarmSnapshotScreen,
  })),
);
const FinanceCollarUsageScreen = lazy(() =>
  import('./modules/mini-tools/finance-collar-usage').then((module) => ({
    default: module.FinanceCollarUsageScreen,
  })),
);

const FeatureFlagsScreen = lazy(() =>
  import('./modules/mini-tools/feature-flags').then((module) => ({ default: module.FeatureFlagsScreen })),
);

const PackageManagerScreen = lazy(() =>
  import('./modules/mini-tools/package-manager').then((module) => ({ default: module.PackageManagerScreen })),
);

const Application = () => (
  <Dashboard>
    <Suspense fallback={<PageLoadingSpinner />}>
      <Routes>
        {/* Tool collection routes */}
        <Route
          path={ROUTES.Home}
          element={<FarmhandToolCollectionScreen />}
        />
        <Route
          path={ROUTES.DataTools}
          element={<DataToolCollectionScreen />}
      />
        <Route
          path={ROUTES.FleetTools}
          element={<FleetToolCollectionScreen />}
        />
        <Route
          path={ROUTES.HardwareTools}
          element={<HardwareToolCollectionScreen />}
        />
        <Route
          path={ROUTES.MobileTools}
          element={<MobileToolCollectionScreen />}
        />
        <Route
          path={ROUTES.Documentation}
          element={<DocumentationCollectionScreen />}
        />

        {/* Map routes */}
        <Route
          path={`${ROUTES.Map}/*`}
          element={<MapScreen />}
        />

        {/* Matrix routes */}
        <Route
          path={`${ROUTES.Matrix}/*`}
          element={<MatrixScreen />}
        />
        {/* Monitoring routes */}
        <Route
          path={`${ROUTES.Monitoring}/*`}
          element={<MonitoringScreen />}
        />

        {/* Monitoring routes */}
        <Route
          path={ROUTES.CollarMonitoring}
          element={<CollarMonitoringScreen />}
        />
        <Route
          path={ROUTES.CollarAlarmSettings}
          element={<CollarAlarmSettingsScreen />}
        />

        <Route
          path={ROUTES.FarmOpsMonitoring}
          element={<FarmOpsMonitoringScreen />}
        />

        <Route
          path={ROUTES.WelfareMonitoring}
          element={<WelfareMonitoringScreen />}
        />
        <Route
          path={ROUTES.WelfareAlarmSettings}
          element={<WelfareAlarmSettingsScreen />}
        />

        <Route
          path={ROUTES.TowerMonitoring}
          element={<TowerMonitoringScreen />}
        />
        <Route
          path={ROUTES.TowerAlarmSettings}
          element={<TowerAlarmSettingsScreen />}
        />

        <Route
          path={ROUTES.PowerMonitoring}
          element={<PowerMonitoringScreen />}
        />
        <Route
          path={ROUTES.PowerAlarmSettings}
          element={<PowerAlarmSettingsScreen />}
        />

        {/* Alarm registration routes */}
        <Route
          path={ROUTES.AlarmRegistration}
          element={<AlarmRegistrationScreen />}
        />
        <Route
          path={ROUTES.CreateAlarmRegistration}
          element={<CreateAlarmRegistrationScreen />}
        />
        <Route
          path={ROUTES.EditAlarmRegistration(':alarmType')}
          element={<EditAlarmRegistrationScreen />}
        />
        <Route
          path={ROUTES.AlarmRegistrationDetail(':alarmType')}
          element={<AlarmRegistrationDetailScreen />}
        />

        {/* Hardware routes */}
        <Route
          path={ROUTES.CollarBuildPlan}
          element={<CollarBuildPlanScreen />}
        />
        <Route
          path={ROUTES.ReleaseChannel}
          element={<ReleaseChannelOverviewScreen />}
        />
        <Route
          path={ROUTES.TowerBuildPlan}
          element={<TowerBuildPlanScreen />}
        />
        <Route
          path={ROUTES.CollarHardware}
          element={<CollarHardwareScreen />}
        />
        <Route
          path={ROUTES.NfcTool}
          element={<NfcToolScreen />}
        />
        <Route
          path={ROUTES.RefurbPrep}
          element={<RefurbPrepScreen />}
        />

        {/* Fleet routes */}
        <Route
          path={ROUTES.WarehouseManagment}
          element={<WarehouseManagementScreen />}
        />
        <Route
          path={ROUTES.Dispatch}
          element={<DispatchScreen />}
        />
        <Route
          path={ROUTES.FarmSnapshot}
          element={<FarmSnapshotScreen />}
        />

        <Route
          path={ROUTES.FinanceCollarUsage}
          element={<FinanceCollarUsageScreen />}
        />

        {/* Data Routes */}
        <Route
          path={ROUTES.Sagemaker}
          element={<SagemakerScreen />}
        />

        {/* Documentation Routes */}
        <Route
          path={ROUTES.DbtDocs}
          element={<DbtDocsScreen />}
        />
        <Route
          path={ROUTES.ProtobufDocs}
          element={<ProtobufDocsScreen />}
        />
        <Route
          path={ROUTES.OpenapiDocs}
          element={<OpenapiClientListScreen />}
        />
        <Route
          path={`${ROUTES.OpenapiDocs}/:clientName`}
          element={<OpenapiClientDocsScreen />}
        />

        {/* Mobile routes */}
        <Route
          path={ROUTES.FeatureFlags}
          element={<FeatureFlagsScreen />}
        />
        <Route
          path={ROUTES.PackageManager}
          element={<PackageManagerScreen />}
        />

        {/* Guidance routes */}
        <Route
          path={ROUTES.ShockCurves}
          element={<ShockCurvesScreen />}
        />
      </Routes>
    </Suspense>
    <ApplicationCommandPalette />
  </Dashboard>
);

export default Application;
