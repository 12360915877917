import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { PartialRecord } from 'src/types';
import { DateRangeFilterState, OnOffOption, SearchFilterState } from 'src/types/filter-types';

interface EntityFilterStateStoreState {
  searchFilters: PartialRecord<string, SearchFilterState>;
  setSearchFilters: (filters: PartialRecord<string, SearchFilterState>) => void;

  onOffFilters: PartialRecord<string, OnOffOption>;
  setOnOffFilters: (filters: PartialRecord<string, OnOffOption>) => void;

  dateRangeFilters: PartialRecord<string, DateRangeFilterState>;
  setDateRangeFilters: (filters: PartialRecord<string, DateRangeFilterState>) => void;
}

export const createEntityFilterStateStore = (entityKey: string) => {
  const storageKey = `${entityKey}-filter-storage`;

  return create(
    persist<EntityFilterStateStoreState>(
      (set) => ({
        searchFilters: {},
        setSearchFilters: (filters) => {
          set({ searchFilters: filters });
        },
        onOffFilters: {},
        setOnOffFilters: (filters) => {
          set({ onOffFilters: filters });
        },
        dateRangeFilters: {},
        setDateRangeFilters: (filters) => {
          set({ dateRangeFilters: filters });
        },
      }),
      {
        name: storageKey,
      },
    ),
  );
};
