import { DeviceRegistryApi, DeviceStatsApi } from '@halter-corp/device-service-client';

import client from '../client/client';

const DeviceApi = {
  deviceRegistryApi: client.resolveOpenApi(DeviceRegistryApi),
  deviceStatsApi: client.resolveOpenApi(DeviceStatsApi),
};

export default DeviceApi;
