import { ISettingsTriggerRuleDTO } from '@halter-corp/collar-alarm-service-client';

// FILTERS
export enum CollarAlarmSettingsFilterType {
  // Alarm settings filter types
  ALARM_TYPE = 'Alarm Type',
  ACTION_TYPE = 'Action Type',
  FILTER_TYPE = 'Filter Type',

  // Trigger rule filter types
  ON_FARM_STATUS = 'On Farm Status',
  FARM = 'Farm',
  FIRMWARE = 'Firmware',
  TAGGED_ON_CATTLE = 'Tagged On Cattle',
  PROVISIONED_TO_FARM = 'Provisioned To Farm',
  PROVISIONED_TO_LIVE_FARM = 'Provisioned To Live Farm',
}

// ALARM SETTINGS
export type CollarSettingsTriggerRule = ISettingsTriggerRuleDTO;
