export type UserFarm = {
  farmId: string;
};

export type HalterUser = {
  farmId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  farms: UserFarm[] | null;
};

export const LOCKED_FIELDS = ['farmId', 'email', 'farms'] as const;

export const ACCOUNT_ATTRIBUTES_MAP: Record<keyof HalterUser, string> = {
  farmId: 'custom:farmId',
  firstName: 'given_name',
  lastName: 'family_name',
  email: 'email',
  phoneNumber: 'phone_number',
  farms: 'custom:farms',
};
type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer E> ? E : never;

export type UpdateHalterUserRequest = Partial<Omit<HalterUser, ElementType<typeof LOCKED_FIELDS>>>;

export type UserSession = Readonly<Omit<HalterUser, 'farms'>> & {
  isHalterUser: boolean;
  farms: string[];
};
