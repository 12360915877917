import { Flex, SimpleGrid, SimpleGridProps, Stack, StackProps, TextProps } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';

type GridSectionContainerProps = {
  heading?: string | React.ReactNode;
  children: React.ReactNode;
  headingProps?: TextProps;
  gridProps?: SimpleGridProps;
} & StackProps;

const GridSectionContainer: FunctionComponent<GridSectionContainerProps> = ({
  heading,
  headingProps,
  children,
  gridProps,
  ...rest
}) => (
  <Stack
    alignItems="stretch"
    {...rest}
  >
    {heading != null && (
      <Flex
        fontSize="lg"
        fontWeight={700}
        {...headingProps}
      >
        {heading}
      </Flex>
    )}

    <SimpleGrid
      rowGap={3}
      columnGap={4}
      gridTemplateColumns={{
        base: '1fr',
        sm: 'repeat(2, 1fr)',
      }}
      {...gridProps}
    >
      {children}
    </SimpleGrid>
  </Stack>
);

export default GridSectionContainer;
