import { NumberInputProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import NumberInputGroup from '../inputs/number-input-group';
import FormikFormField, { FormikFormFieldProps } from './formik-form-field';

type FormikNumberInputProps = {
  id: string;
  unit?: string;
  numberInputProps?: NumberInputProps;
  onChange: (value: number) => void;
} & Omit<FormikFormFieldProps, 'children' | 'htmlFor' | 'onChange'>;

const FormikNumberInput: FunctionComponent<FormikNumberInputProps> = ({
  id,
  unit,
  numberInputProps,
  onChange,
  ...otherProps
}) => (
  <FormikFormField
    htmlFor={id}
    fontSize="xs"
    {...otherProps}
  >
    {([fieldProps, { error }]) => (
      <NumberInputGroup
        numberInputProps={{
          id,
          ...fieldProps,
          value: fieldProps.value ?? 0,
          isInvalid: error != null,
          onChange: (value: string) => onChange(parseInt(value, 10)),
          ...numberInputProps,
        }}
        unit={unit}
      />
    )}
  </FormikFormField>
);

export default FormikNumberInput;
