import { Flex, Link, Text } from '@chakra-ui/react';
import { Row, createColumnHelper } from '@tanstack/react-table';

import TimestampLabel from 'src/components/labels/timestamp-label';
import { TowerFlags } from 'src/features/tower';
import FarmCell from 'src/modules/monitoring/components/tables/cells/farm.cell';
import { Colors } from 'src/styles';
import { getGoLiveDashboardLink } from 'src/util/grafana.util';
import { compareRowsByNumber, compareRowsByString } from 'src/util/table.sorting.util';

import { AlarmedTower } from '../@types';
import TowerAlarmsCell from '../components/tower-alarms.cell';
import TowerTypeCell from '../components/tower-type.cell';

export const columnHelper = createColumnHelper<AlarmedTower>();

export enum TowerMonitoringTableColumn {
  TowerId = 'tower-id',
  Farm = 'farm',
  Alarms = 'alarms',
  TowerType = 'tower-type',
  Properties = 'properties',
  FirstLogged = 'first-logged',
}

export const columns = [
  columnHelper.accessor((row) => row.entityId, {
    id: TowerMonitoringTableColumn.TowerId,
    sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.entityId),
    cell: (item) =>
      item.row.original.farm?.farmId == null ? (
        <Text fontWeight={600}>{item.getValue()}</Text>
      ) : (
        <Link
          href={getGoLiveDashboardLink(item.row.original.farm?.farmId)}
          target="_blank"
          display="block"
          width="min-content"
        >
          <Text
            fontWeight={600}
            width="min-content"
          >
            {item.getValue()}
          </Text>
        </Link>
      ),
    header: () => <Text>Tower ID</Text>,
  }),
  columnHelper.accessor((row) => row.farm?.farmName, {
    id: TowerMonitoringTableColumn.Farm,
    sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.farm?.farmName),
    sortUndefined: 1, // Undefined values will be sorted with lower priority
    cell: (item) => (
      <FarmCell
        farm={item.row.original.farm}
        matrixLinkDomain="general"
        matrixTab="towers"
      />
    ),
    header: () => <Text>Farm</Text>,
  }),
  columnHelper.accessor((row) => row, {
    id: TowerMonitoringTableColumn.Alarms,
    sortingFn: (rowA, rowB) => compareRowsByNumber(rowA, rowB, (entity) => entity.alarms.length),
    sortUndefined: 1, // Undefined values will be sorted with lower priority
    cell: (item) => <TowerAlarmsCell tower={item.getValue()} />,
    header: () => <Text>Alarms</Text>,
  }),
  columnHelper.accessor((row) => row, {
    id: TowerMonitoringTableColumn.TowerType,
    enableSorting: false,
    cell: (item) => <TowerTypeCell tower={item.getValue()} />,
    header: () => <Text>Tower Type</Text>,
  }),
  columnHelper.accessor((row) => row.context, {
    id: TowerMonitoringTableColumn.Properties,
    sortingFn: (rowA, rowB) => {
      const countTrue = (item: AlarmedTower) =>
        [item.context.mainsPowered, item.context.router, item.context.starlink].filter(Boolean).length;
      return compareRowsByNumber(rowA, rowB, countTrue);
    },
    cell: (item) => {
      const { mainsPowered, router, starlink } = item.getValue();

      return (
        <Flex gap={3}>
          <TowerFlags.MainsPoweredLabel mainsPowered={mainsPowered} />
          <TowerFlags.RouterLabel router={router} />
          <TowerFlags.StarlinkLabel starlink={starlink} />
        </Flex>
      );
    },
    header: () => <Text>Properties</Text>,
  }),
  columnHelper.accessor((row) => row, {
    id: TowerMonitoringTableColumn.FirstLogged,
    sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.firstLoggedDate?.toISOString()),
    sortUndefined: 1, // Undefined values will be sorted with lower priority
    cell: (item) => {
      const { firstLoggedDate } = item.row.original;
      if (firstLoggedDate == null) return null;
      return (
        <TimestampLabel
          textProps={{ fontSize: '13px', fontWeight: 600, color: Colors.gray500 }}
          timestamp={firstLoggedDate}
        />
      );
    },
    header: () => <Text>First Logged</Text>,
  }),
];

export const canCopyColumnValue = (columnId: string) => {
  switch (columnId) {
    case TowerMonitoringTableColumn.TowerId:
      return true;
    default:
      return false;
  }
};

export const extractColumnCopyValue = (row: Row<AlarmedTower>, columnId: string): string | undefined => {
  switch (columnId) {
    case TowerMonitoringTableColumn.TowerId:
      return row.original.entityId;
    default:
      return undefined;
  }
};
