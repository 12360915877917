import {
  BusinessCleanUpApi,
  FarmApi,
  FarmSettingsApi,
  FarmStatusApi,
  FeatureFlagsApi,
} from '@halter-corp/business-service-client';

import client from '../client/client';

const BusinessApi = {
  farm: client.resolveOpenApi(FarmApi),
  businessCleanUp: client.resolveOpenApi(BusinessCleanUpApi),
  farmSettings: client.resolveOpenApi(FarmSettingsApi),
  farmStatus: client.resolveOpenApi(FarmStatusApi),
  featureFlags: client.resolveOpenApi(FeatureFlagsApi),
};

export default BusinessApi;
