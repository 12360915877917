import { Flex, Text, TextProps, useDisclosure } from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';

import ExpandIconButton from 'src/components/buttons/expand-icon-button';
import { Colors } from 'src/styles';
import { CollarUtils } from 'src/util/collar.util';

type SerialNumberCellProps = {
  serialNumber: string;
  detailsModal?: FunctionComponent<{ serialNumber: string; onClose: () => void; isOpen: boolean }>;
  textProps?: TextProps;
};

const SerialNumberCell: FunctionComponent<SerialNumberCellProps> = ({ serialNumber, detailsModal, textProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure(); // modal state
  const [isHovered, setIsHovered] = useState(false);

  const debugLink = CollarUtils.getDebugLink(serialNumber);

  return (
    <>
      <Flex
        gap={2}
        alignItems="center"
        mr={-4}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Link
          to={debugLink ?? '#'}
          target="_blank"
        >
          <Text
            fontWeight={600}
            _hover={{ color: Colors.blue600, textDecoration: 'underline', textDecorationThickness: '2px' }}
            {...textProps}
          >
            {serialNumber}
          </Text>
        </Link>

        {detailsModal != null && (
          <ExpandIconButton
            opacity={isHovered ? 1 : 0}
            bgColor={isHovered ? Colors.white : 'transparent'}
            _hover={{ bgColor: Colors.white, color: Colors.blue600 }}
            onClick={onOpen}
          />
        )}
      </Flex>

      {detailsModal?.({
        serialNumber,
        isOpen,
        onClose,
      })}
    </>
  );
};

export default SerialNumberCell;
