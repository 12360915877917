import { AthenaApi, CognitoApi } from '@halter-corp/platform-api-service-client';

import client from '../client';

const platformApiApi = {
  athenaApi: client.resolveOpenApi(AthenaApi),
  cognitoApi: client.resolveOpenApi(CognitoApi),
};

export default platformApiApi;
