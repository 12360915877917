import { DomainNameEnum } from '@halter-corp/alarm-registry-service-client';

import { ROUTES } from 'src/constants';
import { AlarmSettingsTemplateConfig } from 'src/modules/monitoring/templates/alarm-settings/@types';

import { WelfareSettingsTriggerRule } from '../@types';
import WelfareAlarmSettingsForm from '../components/welfare-alarm-settings.form';
import { WelfareAlarmSettingsFilterOptionModel } from '../models/welfare-alarm-settings-filter-option.model';
import { WelfareAlarmSettingsFilterModel } from '../models/welfare-alarm-settings-filter.model';
import { WelfareAlarmSettingsService } from '../services/welfare-alarm-settings.service';

export const WelfareAlarmSettingsTemplateConfig: AlarmSettingsTemplateConfig<WelfareSettingsTriggerRule> = {
  linkConfig: { goBackLink: ROUTES.WelfareMonitoring },
  entityConfig: {
    entityKey: 'welfare-alarm-settings',
    domainName: DomainNameEnum.WELFARE,
    useBuildTriggerRuleItems: WelfareAlarmSettingsService.useBuildSettingsTriggerRuleItems,
  },
  filterConfig: {
    useFilterOptionsByType: WelfareAlarmSettingsService.useAlarmSettingsFilterOptionsByType,
    filterProcessConfig: WelfareAlarmSettingsFilterModel.getInstance(),
    filterOptionConfig: WelfareAlarmSettingsFilterOptionModel,
    searchFilterTypes: WelfareAlarmSettingsFilterModel.SEARCH_ALARM_SETTINGS_FILTERS,
    dateRangeFilterTypes: WelfareAlarmSettingsFilterModel.DATE_RANGE_FILTERS,
  },
  queryConfig: {
    useAllAlarmSettings: WelfareAlarmSettingsService.useAllAlarmSettings,
    usePutAlarmSettings: WelfareAlarmSettingsService.usePutAlarmSettings,
    useDeleteAlarmSettings: WelfareAlarmSettingsService.useDeleteAlarmSettings,
  },
  actionConfig: {
    AlarmSettingsForm: WelfareAlarmSettingsForm,
  },
};
