import { AlarmedEntity } from '../../../@types';

// ENUMS & TYPES
//-----------------------------------------------------
export type WelfareAlarmAction = 'diagnose' | 'remove-alarm';

export enum WelfareAlarmFilterType {
  // SEARCH
  ENTITY_ID = 'Entity ID',
  FARM = 'Farm',
  ALARMS = 'Alarms',
  ALARM_BEHAVIOUR = 'Alarm Behaviour',
  SCOPE = 'Scope',
  
  // ON/OFF
  CRITICAL = 'Critical',

  // TIME RANGE
  FIRST_LOGGED_DATE = 'First Logged Date',
  LAST_LOGGED_DATE = 'Last Logged Date',
}

// WELFARE ALARMS
//-----------------------------------------------------
export type WelfareAlarmEntity = AlarmedEntity<WelfareEntityScopeContext>;

export type WelfareEntityScopeContext = {
  commandContext?: WelfareCommandContext;
  collarContext?: WelfareCollarContext;
  cattleContext?: WelfareCattleContext;
  mobContext?: WelfareMobContext;
};

export type WelfareCommandContext = {
  farmId: string;
  type: string;
  collarCommandId?: number;
};

export type WelfareCollarContext = {
  farmId?: string;
  mobId?: string;
  cattleId?: string;
  cattleName?: string;
};

export type WelfareCattleContext = {
  cattleName?: string;
  farmId?: string;
  mobId?: string;
  collarId: string;
};

export type WelfareMobContext = {
  farmId: string;
  name: string;
};
