import { ICognitoUserAttributeDTO, ICognitoUserDTO } from '@halter-corp/platform-api-service-client';
import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';

import { PlatformApiApi } from 'src/data';
import { wait } from 'src/util/promise.util';

import queryClient from '../query-client';

const COGNITO_KEY = 'cognito';

export const CognitoQueryKeys = {
  allCognitoUsers: () => [COGNITO_KEY, 'allCognitoUsers'] as const,
};

export type OtaQueriesState = {
  allCognitoUsers: ICognitoUserDTO[];
};

export const PlatformApiQueries = {
  executeAthenaQuery: async (query: string, maxAgeInMinutes: number) => {
    let { data: queryExection } = await PlatformApiApi.athenaApi.startAthenaQueryExecution({
      queryString: query,
      maxAgeInMinutes,
    });

    while (queryExection.presignedResultUrl == null) {
      if (queryExection.state === 'FAILED') {
        throw new Error(`Query failed to execute. Message: ${queryExection.stateChangeReason ?? 'No reason provided'}`);
      }

      // eslint-disable-next-line no-await-in-loop
      await wait(500);
      // eslint-disable-next-line no-await-in-loop
      queryExection = (await PlatformApiApi.athenaApi.getAthenaQueryExecutionByID(queryExection.queryId)).data;
    }

    return queryExection;
  },

  useAllCognitoUsers: (options?: Partial<UseQueryOptions<OtaQueriesState['allCognitoUsers']>>) =>
    useQuery({
      queryKey: CognitoQueryKeys.allCognitoUsers(),
      queryFn: async () => {
        const { data } = await PlatformApiApi.cognitoApi.getAllCognitoUsers();
        return data;
      },
      staleTime: moment.duration(60, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(60, 'minutes').asMilliseconds(),
      ...options,
    }),

  // Mutations
  useRemoveCognitoUser: () => {
    return useMutation({
      mutationFn: async (username: string) => {
        const { data } = await PlatformApiApi.cognitoApi.deleteCognitoUserByUsername(username);
        return data;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(CognitoQueryKeys.allCognitoUsers());
      },
    });
  },

  useRemoveCognitoUsers: () => {
    return useMutation({
      mutationFn: async (usernames: string[]) => {
        await Promise.all(usernames.map((username) => PlatformApiApi.cognitoApi.deleteCognitoUserByUsername(username)));
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(CognitoQueryKeys.allCognitoUsers());
      },
    });
  },

  useUpdateCognitoUserAttributes: () => {
    return useMutation({
      mutationFn: async (options: { username: string; updatedAttributes: ICognitoUserAttributeDTO[] }) => {
        await PlatformApiApi.cognitoApi.updateCognitoUserAttributesByUsername(options.username, {
          attributes: options.updatedAttributes,
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(CognitoQueryKeys.allCognitoUsers());
      },
    });
  },
};
