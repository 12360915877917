import { Text } from '@chakra-ui/react';
import { Row, createColumnHelper } from '@tanstack/react-table';
import { minBy } from 'lodash';

import { compareRowsByNumber, compareRowsByString } from 'src/util/table.sorting.util';

import CattleCell from '../../../components/tables/cells/cattle.cell';
import FarmCell from '../../../components/tables/cells/farm.cell';
import FirstLoggedCell from '../../../components/tables/cells/first-logged.cell';
import MobCell from '../../../components/tables/cells/mob.cell';
import SerialNumberCell from '../../../components/tables/cells/serial-number.cell';
import { PowerAlarmEntity } from '../@types';
import PowerAlarmsCell from '../components/power-alarms.cell';
import { PowerAlarmEntityUtils } from '../utils/power-alarm-entity.util';

export const columnHelper = createColumnHelper<PowerAlarmEntity>();

export enum CommonPowerTableColumns {
  SCOPE = 'scope',
  ENTITY_ID = 'entity-id',
  ALARMS = 'alarms',
  FIRST_LOGGED = 'first-logged',

  FARM = 'farm',
  MOB = 'mob',
  CATTLE = 'cattle',
  COLLAR = 'collar',
}

// COMMON COLUMNS
const alarmsColumn = columnHelper.accessor((row) => row, {
  id: CommonPowerTableColumns.ALARMS,
  sortingFn: (rowA, rowB) => compareRowsByNumber(rowA, rowB, (entity) => entity.alarms.length),
  sortUndefined: 1, // Undefined values will be sorted with lower priority
  cell: (item) => <PowerAlarmsCell powerAlarmEntity={item.getValue()} />,
  header: () => <Text>Alarms</Text>,
});

const firstLoggedColumn = columnHelper.accessor((row) => row.alarms, {
  id: CommonPowerTableColumns.FIRST_LOGGED,
  sortingFn: (rowA, rowB) =>
    compareRowsByString(rowA, rowB, (entity) => minBy(entity.alarms, (alarm) => alarm.eventDateTime)?.eventDateTime),
  sortUndefined: 1, // Undefined values will be sorted with lower priority
  cell: (item) => <FirstLoggedCell alarms={item.getValue() ?? []} />,
  header: () => <Text>First Logged</Text>,
});

const farmColumn = columnHelper.accessor((row) => row.farm?.farmName, {
  id: CommonPowerTableColumns.FARM,
  sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.farm?.farmName),
  sortUndefined: 1, // Undefined values will be sorted with lower priority
  cell: (item) => <FarmCell farm={item.row.original.farm} matrixLinkDomain='power'/>,
  header: () => <Text>Farm</Text>,
});

const mobColumn = columnHelper.accessor((row) => PowerAlarmEntityUtils.getMobId(row), {
  id: CommonPowerTableColumns.MOB,
  enableSorting: false,
  cell: (item) => {
    const entity = item.row.original;
    const mobId = PowerAlarmEntityUtils.getMobId(entity);
    if (entity.farm?.farmId == null || mobId == null) {
      return null;
    }

    return (
      <MobCell
        farmId={entity.farm?.farmId}
        mobId={mobId}
      />
    );
  },
  header: () => <Text>Mob</Text>,
});

// GENERIC POWER ENTITY COLUMNS
export const genericPowerEntityColumns = [farmColumn, mobColumn, alarmsColumn, firstLoggedColumn];

// COLLAR POWER ENTITY COLUMNS
export const collarPowerEntityColumns = [
  farmColumn,
  mobColumn,
  columnHelper.accessor((row) => PowerAlarmEntityUtils.getCattleContext(row)?.cattleName, {
    id: CommonPowerTableColumns.CATTLE,
    sortingFn: (rowA, rowB) =>
      compareRowsByString(rowA, rowB, (entity) => PowerAlarmEntityUtils.getCattleContext(entity)?.cattleName),
    sortUndefined: 1, // Undefined values will be sorted with lower priority
    cell: (item) => {
      const { collarContext } = item.row.original.context;
      if (collarContext?.cattleId == null) {
        return null;
      }
      return <CattleCell cattle={{ cattleId: collarContext.cattleId, cattleName: collarContext.cattleName }} />;
    },
    header: () => <Text>Cattle</Text>,
  }),
  columnHelper.accessor((row) => row, {
    id: CommonPowerTableColumns.ENTITY_ID,
    sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.entityId),
    cell: (item) => <SerialNumberCell serialNumber={item.getValue().entityId} />,
    header: () => <Text>Collar ID</Text>,
  }),
  alarmsColumn,
  firstLoggedColumn,
];

// MOB POWER ENTITY COLUMNS
export const mobPowerEntityColumns = [farmColumn, mobColumn, alarmsColumn, firstLoggedColumn];

// FARM POWER ENTITY COLUMNS
export const farmPowerEntityColumns = [farmColumn, alarmsColumn, firstLoggedColumn];

export const canCopyColumnValue = (columnId: string) => {
  switch (columnId) {
    case CommonPowerTableColumns.ENTITY_ID:
      return true;
    case CommonPowerTableColumns.FARM:
      return true;
    case CommonPowerTableColumns.MOB:
      return true;
    case CommonPowerTableColumns.CATTLE:
      return true;
    case CommonPowerTableColumns.COLLAR:
      return true;
    default:
      return false;
  }
};

export const extractColumnCopyValue = (row: Row<PowerAlarmEntity>, columnId: string) => {
  const entity = row.original;
  switch (columnId) {
    case CommonPowerTableColumns.ENTITY_ID:
      return entity.entityId;
    case CommonPowerTableColumns.FARM:
      return entity.farm?.farmId;
    case CommonPowerTableColumns.MOB:
      return PowerAlarmEntityUtils.getMobId(entity);
    case CommonPowerTableColumns.CATTLE:
      return PowerAlarmEntityUtils.getCattleContext(entity)?.cattleName;
    case CommonPowerTableColumns.COLLAR:
      return PowerAlarmEntityUtils.getCollarId(entity);
    default:
      return undefined;
  }
};
