import {
  IDeviceDisablementStatusDTO,
  IDeviceLocationDTO,
  IDeviceNetworkStatusDTO,
  IDevicePauseSyncStatusDTO,
  IDeviceSyncStatusDTO,
} from '@halter-corp/bff-mobile-service-client';
import {
  IDeleteFarmPreProvisioningRequestDTO,
  IDeviceDTO,
  IDevicePowerOffModeDTO,
  IDeviceStatsDTO,
  IPreProvisionToFarmRequestDTO,
} from '@halter-corp/device-service-client';
import { UseQueryResult, useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { chunk, keyBy } from 'lodash';
import moment from 'moment';

import { BffApi, DevicesApi, client } from 'src/data';

import { UseQueryOptions, createQueryOptions } from '../types';

export type DeviceQueriesState = {
  devices: Record<string, IDeviceDTO>;
  device: IDeviceDTO | null;
  deviceStats: Record<string, IDeviceStatsDTO>;
  devicePowerOffMode: IDevicePowerOffModeDTO | null;
  locations: Record<string, IDeviceLocationDTO>;
  networkStatuses: Record<string, IDeviceNetworkStatusDTO>;
  disablementStatuses: Record<string, IDeviceDisablementStatusDTO>;
  pausedStatuses: Record<string, IDevicePauseSyncStatusDTO>;
  syncStatuses: Record<string, IDeviceSyncStatusDTO>;
};

export const DevicesQueryKeys = {
  devices: () => ['devices', 'all'] as const,
  devicesByFarmId: (farmId: string) => ['devices', 'farm-id', farmId] as const,
  deviceBySerialNumber: (serialNumber: string) => ['devices', 'serial-number', serialNumber] as const,
  deviceStatsBySerialNumbers: (serialNumbers: string[]) => ['devices', 'device-stats', serialNumbers] as const,
  devicePowerOffMode: (serialNumber: string) => ['devices', 'device-power-off-mode', serialNumber] as const,
  locations: () => ['devices', 'locations', 'all'] as const,
  syncStatuses: () => ['devices', 'sync-statuses', 'all'] as const,
  networkStatuses: () => ['devices', 'network-statuses', 'all'] as const,
  disablementStatuses: () => ['devices', 'disablement-statuses', 'all'] as const,
  pausedStatuses: () => ['devices', 'paused-statuses', 'all'] as const,
};

export const DevicesQueries = {
  // Queries
  getFindAllDevicesQueryOptions: createQueryOptions<
    DeviceQueriesState['devices'],
    { serialNumbers?: string[] },
    IDeviceDTO[]
  >(({ serialNumbers }) => ({
    staleTime: moment.duration(3, 'minutes').asMilliseconds(),
    queryKey: DevicesQueryKeys.devices(),
    promise: async () => {
      const { data } = await DevicesApi.deviceRegistryApi.findAllDevices(serialNumbers);
      return { data };
    },
    mutate: (data) => keyBy(data, ({ serialNumber }) => serialNumber),
  })),

  useDevicesQuery<TData = DeviceQueriesState['devices']>(
    options: UseQueryOptions<DeviceQueriesState['devices'], TData, { serialNumbers?: string[] }> = {},
  ) {
    const query = useQuery(this.getFindAllDevicesQueryOptions<TData>(options));

    return query;
  },

  useDevicesByFarmId: (
    farmId: string,
    options: UseQueryOptions<DeviceQueriesState['devices'], DeviceQueriesState['devices'], any> = {},
  ): UseQueryResult<DeviceQueriesState['devices']> => {
    return useQuery({
      queryKey: DevicesQueryKeys.devicesByFarmId(farmId),
      queryFn: async () => {
        const { data } = await DevicesApi.deviceRegistryApi.findAllDevices(
          undefined,
          client.getRequestOptionsForFarmId(farmId),
        );
        return keyBy(data, ({ serialNumber }) => serialNumber);
      },
      staleTime: moment.duration(3, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(3, 'minutes').asMilliseconds(),
      ...options,
    });
  },

  useDeviceBySerialNumberQuery(
    options: UseQueryOptions<DeviceQueriesState['devices'], IDeviceDTO, { serialNumber: string }> = {
      serialNumber: '',
    },
  ): UseQueryResult<IDeviceDTO> {
    return useQuery({
      ...this.getFindAllDevicesQueryOptions(options),
      enabled: options.serialNumber !== '',
      select: (data) => data[options.serialNumber],
    });
  },

  useDevicesBySerialNumbersQuery<TData = DeviceQueriesState['device']>(
    serialNumbers: string[],
    options?: Partial<UseQueryOptions<DeviceQueriesState['device'], unknown, TData>>,
  ) {
    return useQueries({
      queries: serialNumbers.map((serialNumber) => ({
        queryKey: DevicesQueryKeys.deviceBySerialNumber(serialNumber),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryFn: async () => {
          const { data } = await DevicesApi.deviceRegistryApi.findBySerialNumber(serialNumber);
          return data;
        },
      })),
      ...options,
    });
  },

  useDevicePowerOffModeBySerialNumberQuery(
    options: UseQueryOptions<
      DeviceQueriesState['devicePowerOffMode'],
      IDevicePowerOffModeDTO,
      { serialNumber: string }
    >,
  ): UseQueryResult<IDevicePowerOffModeDTO> {
    return useQuery({
      queryKey: DevicesQueryKeys.devicePowerOffMode(options.serialNumber),
      queryFn: async () => {
        const { data: devicePowerOffModeResponse } = await DevicesApi.deviceRegistryApi.findDevicePowerOffMode(
          options.serialNumber,
        );
        return devicePowerOffModeResponse.devicePowerOffMode;
      },
      enabled: options.serialNumber !== '',
    });
  },

  /*
   *
   *  Device locations
   *
   * */
  getDeviceLocationsQueryOptions: createQueryOptions<DeviceQueriesState['locations'], unknown, IDeviceLocationDTO[]>(
    () => ({
      staleTime: moment.duration(3, 'minutes').asMilliseconds(),
      queryKey: DevicesQueryKeys.locations(),
      promise: () => BffApi.state.listDeviceLocations(),
      mutate: (data) => keyBy(data, ({ serialNumber }) => serialNumber),
    }),
  ),

  useDeviceLocationsQuery<TData = DeviceQueriesState['locations']>(
    options: UseQueryOptions<DeviceQueriesState['locations'], TData> = {},
  ) {
    return useQuery(this.getDeviceLocationsQueryOptions<TData>(options));
  },

  useDeviceLocationsBySerialNumbersQuery(
    {
      serialNumbers,
      ...options
    }: UseQueryOptions<DeviceQueriesState['locations'], IDeviceLocationDTO[], { serialNumbers: string[] }> = {
      serialNumbers: [],
    },
  ) {
    return useQuery(
      this.getDeviceLocationsQueryOptions({
        ...options,
        select: (data) => {
          const deviceLocations = serialNumbers
            .map((serialNumber) => data[serialNumber])
            .filter((location) => location != null);
          return deviceLocations;
        },
      }),
    );
  },

  useDeviceLocationsGroupedByPaddockId: (
    options: UseQueryOptions<DeviceQueriesState['locations'], Record<string, IDeviceLocationDTO[]>> = {},
  ) =>
    DevicesQueries.useDeviceLocationsQuery({
      ...options,
      select: (locations) => {
        const groupedLocations = Object.values(locations).reduce((acc, location) => {
          const paddockId = location?.currentPaddockId;

          if (paddockId == null) {
            return {
              ...acc,
              unknown: [...(acc.unknown ?? []), location],
            };
          }

          return {
            ...acc,
            [paddockId]: [...(acc[paddockId] ?? []), location],
          };
        }, {} as Record<string, IDeviceLocationDTO[]>);

        return groupedLocations;
      },
    }),

  useDeviceLocationsInPaddockIdQuery: (
    {
      paddockId,
      ...options
    }: UseQueryOptions<DeviceQueriesState['locations'], IDeviceLocationDTO[], { paddockId: string }> = {
      paddockId: '',
    },
  ) =>
    DevicesQueries.useDeviceLocationsQuery({
      ...options,
      select: (locations) => Object.values(locations).filter((location) => location?.currentPaddockId === paddockId),
    }),

  useDeviceLocationBySerialNumberQuery(
    {
      serialNumber,
      ...options
    }: UseQueryOptions<DeviceQueriesState['locations'], IDeviceLocationDTO, { serialNumber: string }> = {
      serialNumber: '',
    },
  ) {
    return useQuery(
      this.getDeviceLocationsQueryOptions({
        ...options,
        select: (data) => data[serialNumber],
      }),
    );
  },

  useDeiceStatsBySerialNumbers: (
    serialNumbers: string[],
    options: UseQueryOptions<DeviceQueriesState['deviceStats'], DeviceQueriesState['deviceStats'], any> = {},
  ): UseQueryResult<DeviceQueriesState['deviceStats']> => {
    return useQuery({
      queryKey: DevicesQueryKeys.deviceStatsBySerialNumbers(serialNumbers),
      staleTime: moment.duration(1, 'minute').asMilliseconds(),
      refetchInterval: moment.duration(1, 'minute').asMilliseconds(),
      queryFn: async () => {
        const deviceStats: IDeviceStatsDTO[] = [];
        for (const serialNumberChunk of chunk(serialNumbers, 100)) {
          // eslint-disable-next-line no-await-in-loop
          const { data } = await DevicesApi.deviceStatsApi.findStatsBySerialNumbers(serialNumberChunk);
          deviceStats.push(...data);
        }

        return keyBy(deviceStats, ({ serialNumber }) => serialNumber);
      },
      ...options,
    });
  },

  /*
   *
   *  Device sync statuses
   *
   * */

  getDeviceSyncStatusQueryOptions: createQueryOptions<
    DeviceQueriesState['syncStatuses'],
    { serialNumbers?: string[] },
    IDeviceSyncStatusDTO[]
  >(({ serialNumbers }) => ({
    staleTime: moment.duration(3, 'minutes').asMilliseconds(),
    queryKey: DevicesQueryKeys.syncStatuses(),
    promise: () => BffApi.state.listDeviceSyncStatuses(serialNumbers),
    mutate: (data) => keyBy(data, ({ serialNumber }) => serialNumber),
  })),

  useDeviceSyncStatusQuery<TData>(
    options: UseQueryOptions<DeviceQueriesState['syncStatuses'], TData> = {},
  ): UseQueryResult<TData> {
    return useQuery(this.getDeviceSyncStatusQueryOptions<TData>(options));
  },

  /*
   *
   *  Device network statuses
   *
   * */

  getDeviceNetworkStatusQueryOptions: createQueryOptions<
    DeviceQueriesState['networkStatuses'],
    unknown,
    IDeviceNetworkStatusDTO[]
  >(() => ({
    staleTime: moment.duration(3, 'minutes').asMilliseconds(),
    queryKey: DevicesQueryKeys.networkStatuses(),
    promise: () => BffApi.state.listDevicesNetworkStatus(),
    mutate: (data) => keyBy(data, ({ serialNumber }) => serialNumber),
  })),

  useDeviceNetworkStatusQuery<TData>(
    options: UseQueryOptions<DeviceQueriesState['networkStatuses'], TData, { serialNumbers?: string[] }> = {},
  ): UseQueryResult<TData> {
    return useQuery(this.getDeviceNetworkStatusQueryOptions<TData>(options));
  },

  useDeviceNetworkStatusBySerialNumberQuery: (serialNumber: string) =>
    DevicesQueries.useDeviceNetworkStatusQuery({
      select: (data) => data[serialNumber],
    }),

  /*
   *
   *  Device disablement statuses
   *
   * */
  getDeviceDisablementStatusQueryOptions: createQueryOptions<
    DeviceQueriesState['disablementStatuses'],
    unknown,
    IDeviceDisablementStatusDTO[]
  >(() => ({
    staleTime: moment.duration(3, 'minutes').asMilliseconds(),
    queryKey: DevicesQueryKeys.disablementStatuses(),
    promise: () => BffApi.state.listDevicesDisablementStatus(),
    mutate: (data) => keyBy(data, ({ serialNumber }) => serialNumber),
  })),

  useDeviceDisablementStatusQuery<TData>(
    options: UseQueryOptions<DeviceQueriesState['disablementStatuses'], TData, { serialNumbers?: string[] }> = {},
  ): UseQueryResult<TData> {
    return useQuery(this.getDeviceDisablementStatusQueryOptions<TData>(options));
  },

  useDeviceDisablementByStatusQuery(disablementReason: string) {
    return useQuery(
      this.getDeviceDisablementStatusQueryOptions({
        select: (data) =>
          Object.values(data).filter(
            (disablement) => disablement.disablementReasons?.includes(disablementReason) ?? false,
          ),
      }),
    );
  },

  /*
   *
   *  Device paused statuses
   *
   * */
  getDevicePausedStatusQueryOptions: createQueryOptions<
    DeviceQueriesState['pausedStatuses'],
    unknown,
    IDevicePauseSyncStatusDTO[]
  >(() => ({
    staleTime: moment.duration(3, 'minutes').asMilliseconds(),
    queryKey: DevicesQueryKeys.pausedStatuses(),
    promise: () => BffApi.state.listDevicePauseSyncStatuses(),
    mutate: (data) => keyBy(data, ({ serialNumber }) => serialNumber),
  })),

  useDevicePausedStatusQuery<TData = DeviceQueriesState['pausedStatuses']>(
    options: UseQueryOptions<DeviceQueriesState['pausedStatuses'], TData, { serialNumbers?: string[] }> = {},
  ): UseQueryResult<TData> {
    return useQuery(this.getDevicePausedStatusQueryOptions<TData>(options));
  },

  useDevicePausedStatusBySerialNumberQuery: (
    {
      serialNumber,
      ...options
    }: UseQueryOptions<DeviceQueriesState['pausedStatuses'], IDevicePauseSyncStatusDTO, { serialNumber: string }> = {
      serialNumber: '',
    },
  ) =>
    DevicesQueries.useDevicePausedStatusQuery({
      ...options,
      select: (data) => data[serialNumber],
    }),

  useDevicePausedStatusBySerialNumbersQuery: (serialNumbers: string[]) =>
    DevicesQueries.useDevicePausedStatusQuery({
      select: (data) => serialNumbers.map((serialNumber) => data[serialNumber]).filter((status) => status != null),
    }),

  // Mutations
  usePreProvisionDeviceToFarmMutation: () => {
    return useMutation({
      mutationFn: async (payload: IPreProvisionToFarmRequestDTO & { serialNumber: string }) => {
        const { serialNumber, ...request } = payload;
        const { data } = await DevicesApi.deviceRegistryApi.preProvisionToFarm(serialNumber, request);
        return data;
      },
    });
  },

  useDeprovisionDeviceToFarmMutation: () => {
    return useMutation({
      mutationFn: async (payload: IDeleteFarmPreProvisioningRequestDTO & { serialNumber: string }) => {
        const { serialNumber, ...request } = payload;
        const { data } = await DevicesApi.deviceRegistryApi.removeFarmPreProvisioning(serialNumber, request);
        return data;
      },
    });
  },
};
