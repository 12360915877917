import { Flex, Spinner } from '@chakra-ui/react';
import { Hub } from 'aws-amplify';
import { FC, PropsWithChildren, useEffect } from 'react';

import AuthScreen from '../../modules/auth/screens/auth.screen';
import AuthService from '../../services/auth.service';
import AuthContext from './context';

const AuthProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          break;
      }
    });
  }, []);

  const providedState = AuthService.useProvidedState();
  const { loading, session } = providedState;

  if (loading) {
    return (
      <Flex
        flex={1}
        bg="gray.900"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner color="white" />
      </Flex>
    );
  }

  if (session == null) {
    return <AuthScreen />;
  }

  return <AuthContext.Provider value={providedState}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
