import { Auth } from 'aws-amplify';

import { client } from 'src/data';

import * as environments from '../env-config';

export enum EnvironmentEnum {
  DEV = 'dev',
  STAGING = 'staging',
  PRODUCTION = 'prod',
}

interface AwsConfiguration {
  aws_project_region: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  oauth: {
    domain: string;
    scope: string[];
    redirectSignIn: string;
    redirectSignOut: string;
    responseType: string;
  };
}

interface HalterConfiguration {
  halter_api_url: string;
  soothsayer_tools_api_secret: string;
  soothsayer_staging_tools_api_secret: string;
}

export interface EnvironmentConfiguration {
  awsConfig: AwsConfiguration;
  halterConfig: HalterConfiguration;
}

type IConfigurationService = {
  retrieveAvailableEnvironments: () => EnvironmentEnum[];
  retrieveCurrentEnvironment: () => EnvironmentEnum;
  retrieveConfigurationForEnvironment: (environment: EnvironmentEnum) => EnvironmentConfiguration;
  retrieveCurrentConfiguration: () => EnvironmentConfiguration;
  loadConfigurationForFarmId: (farmId: string, environment: EnvironmentEnum) => void;
  loadConfiguration: (environment: EnvironmentEnum) => void;
};

const ConfigurationService: IConfigurationService = {
  retrieveAvailableEnvironments: (): EnvironmentEnum[] => Object.keys(environments) as EnvironmentEnum[],
  retrieveCurrentEnvironment: (): EnvironmentEnum => environments.environment as EnvironmentEnum,
  retrieveConfigurationForEnvironment: (environment) => environments[environment] as EnvironmentConfiguration,
  retrieveCurrentConfiguration: () => environments[environments.environment] as EnvironmentConfiguration,

  loadConfigurationForFarmId: (farmId: string, environment: EnvironmentEnum) => {
    const { halterConfig } = ConfigurationService.retrieveConfigurationForEnvironment(environment);

    client.setConfiguration({
      environment,
      baseUrl: halterConfig.halter_api_url,
      getHeaders: async () => {
        const credentials = await Auth.currentSession();

        return {
          Authorization: `Bearer ${credentials.getIdToken().getJwtToken()}`,
          'context-override': JSON.stringify({ farmId }),
        };
      },
    });
  },

  loadConfiguration: (environment: EnvironmentEnum) => {
    const { halterConfig } = ConfigurationService.retrieveConfigurationForEnvironment(environment);

    client.setConfiguration({
      environment,
      baseUrl: halterConfig.halter_api_url,
      getHeaders: async () => {
        const credentials = await Auth.currentSession();

        return {
          Authorization: `Bearer ${credentials.getIdToken().getJwtToken()}`,
        };
      },
    });
  },
};

export default ConfigurationService;
