import { Flex, FormControl, Heading, Radio, RadioGroup, Text } from '@chakra-ui/react';
import { AlarmBehaviourTypeEnum } from '@halter-corp/alarm-registry-service-client';
import {
  AlarmActionTypeEnum,
  AlarmFilterTypeEnum,
  IFirmwareVersionRangeRuleDTO,
} from '@halter-corp/collar-alarm-service-client';
import { DeviceOnFarmStatusEnum } from '@halter-corp/fleet-service-client';
import { Form, Formik } from 'formik';
import { isEmpty, omitBy, sortBy } from 'lodash';
import moment from 'moment';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import * as yup from 'yup';

import CommonLoadingSpinner from 'src/components/common-loading-spinner';
import FormikAutoComplete from 'src/components/forms/formik-auto-complete';
import FormikFarmAutoComplete from 'src/components/forms/formik-farm-auto-complete';
import FormikSelect from 'src/components/forms/formik-select';
import FormikTextarea from 'src/components/forms/formik-text-area';
import DurationInput from 'src/components/inputs/duration-input';
import FormModalActions from 'src/components/modals/form-modal-actions';
import useCommonToast from 'src/hooks/use-common-toast';
import { ANY_ALARM_TYPE } from 'src/modules/monitoring/constants';
import { AlarmSettingsFormProps, GenericAlarmSettings } from 'src/modules/monitoring/templates/alarm-settings/@types';
import { AlarmRegistryQueries, BusinessQueries } from 'src/queries';
import { AuthService } from 'src/services';
import { Colors } from 'src/styles';
import { getDurationFormat } from 'src/util/date-time.util';
import { formatStringAsLabel } from 'src/util/string-format.util';
import { safeParseBoolean } from 'src/util/string-parse.util';

import { CollarSettingsTriggerRule } from '../@types';
import { CollarAlarmSettingsService } from '../services/collar-alarm-settings.service';
import FirmwareVersionInput, { FirmwareVersionInputType } from './firmware-version.input';

type NonOptionValue = 'none';
const NONE_OPTION_VALUE: NonOptionValue = 'none';

const NON_EVENT_ALARM_ALLOWED_ACTION_TYPES = [
  AlarmActionTypeEnum.RETURN_TO_HALTER,
  AlarmActionTypeEnum.POWER_OFF_COLLAR,
];
const EVENT_ALARM_ALLOWED_ACTION_TYPES = [
  AlarmActionTypeEnum.REMOVE_ALARM,
  AlarmActionTypeEnum.RETURN_TO_HALTER,
  AlarmActionTypeEnum.POWER_OFF_COLLAR,
];

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const AVAILABLE_FILTER_TYPE_OPTIONS = Object.values(AlarmFilterTypeEnum);

export const DURATION_MS_OPTIONS = [
  moment.duration(10, 'minute').asMilliseconds(),
  moment.duration(30, 'minute').asMilliseconds(),
  moment.duration(1, 'hour').asMilliseconds(),
  moment.duration(12, 'hour').asMilliseconds(),
  moment.duration(1, 'day').asMilliseconds(),
  moment.duration(2, 'day').asMilliseconds(),
  moment.duration(1, 'week').asMilliseconds(),
  moment.duration(2, 'week').asMilliseconds(),
  moment.duration(1, 'month').asMilliseconds(),
  moment.duration(3.02, 'month').asMilliseconds(), // hack it get it to say 3 months and not 2 month 30 days
  moment.duration(6, 'month').asMilliseconds(),
  moment.duration(1.002, 'year').asMilliseconds(), // hack it get it to say 1 year and not 11 months 30 days
];

const ON_FARM_STATUS_OPTIONS = [NONE_OPTION_VALUE, ...Object.values(DeviceOnFarmStatusEnum)] as Array<
  DeviceOnFarmStatusEnum | NonOptionValue
>;
const TAGGED_ON_CATTLE_OPTIONS = [
  { label: 'none', value: NONE_OPTION_VALUE },
  { label: 'Tagged on', value: true },
  { label: 'Tagged off', value: false },
] as const;

const PROVISIONED_TO_FARM_OPTIONS = [
  { label: 'none', value: NONE_OPTION_VALUE },
  { label: 'Provisioned', value: true },
  { label: 'Not Provisioned', value: false },
] as const;

const PROVISIONED_TO_LIVE_FARM_OPTIONS = [
  { label: 'none', value: NONE_OPTION_VALUE },
  { label: 'Live', value: true },
  { label: 'Not Live', value: false },
] as const;

const collarAlarmSettingsSchema = yup.object().shape({
  alarmType: yup.string().required(),
  actionType: yup.string().oneOf(EVENT_ALARM_ALLOWED_ACTION_TYPES).optional(),
  filterType: yup.string().oneOf(AVAILABLE_FILTER_TYPE_OPTIONS).optional(),
  snoozeDurationMs: yup.number().integer().optional(), // Only for snooze filter type
  firmwareVersion: yup.string().optional(),
  onFarmStatus: yup.string().oneOf(ON_FARM_STATUS_OPTIONS).optional(),
  farmId: yup.string().optional(),
  dependentAlarmType: yup.string().optional(),
  exceptForAlarmType: yup.string().optional(),
  maxTimeSinceFirstLoggedInMillis: yup.number().integer().min(0).optional(),
  maxTimeSinceOnFarmStatusChangeInMillis: yup.number().integer().min(0).optional(),
});

export type CollarAlarmSettingsFormValues = {
  alarmType: string;
  alarmSettingsType: 'action' | 'filter';
  actionType: string | undefined;
  filterType: string | undefined;
  snoozeDurationMs?: number | undefined;
  firmwareVersionInputType: FirmwareVersionInputType;
  firmwareVersion: string | undefined;
  firmwareVersionRange: IFirmwareVersionRangeRuleDTO | undefined;
  onFarmStatus: DeviceOnFarmStatusEnum | NonOptionValue;
  farmId: string | NonOptionValue;
  taggedOnCattle: boolean | NonOptionValue;
  provisionedToFarm: boolean | NonOptionValue;
  provisionedToLiveFarm: boolean | NonOptionValue;
  dependentAlarmType: string | NonOptionValue;
  exceptForAlarmType: string | NonOptionValue;
  maxTimeSinceFirstLoggedInMillis?: number | undefined;
  maxTimeSinceOnFarmStatusChangeInMillis?: number | undefined;
  reason: string;
};

const getNonEmptyValueOrUndefined = (value: number | undefined) => {
  if (value == null || value === 0) return undefined;
  return value;
};

const isFalseyOrNone = (value: unknown) => value == null || /^none$/i.test(String(value));

type CollarAlarmSettingsFormProps = AlarmSettingsFormProps<CollarSettingsTriggerRule>;

const CollarAlarmSettingsForm: FunctionComponent<CollarAlarmSettingsFormProps> = ({
  onClose,
  onSuccessfulSubmit,
  existingAlarmSettings,
}) => {
  const { displayToast, displayToastWithId } = useCommonToast();
  const putAlarmSettings = CollarAlarmSettingsService.usePutAlarmSettings();

  const authUser = AuthService.useAuth();
  const [requestErrorMessage, setRequestErrorMessage] = useState<string | undefined>();
  const requestErrorMessageRef = useRef<HTMLParagraphElement>(null);

  const { data: farmById = {} } = BusinessQueries.useFindAllFarmsQuery();
  const farms = useMemo(() => Object.values(farmById), [farmById]);

  const {
    data: alarmDefinitionByType,
    isLoading: isAlarmDefinitionsLoading,
    isError: isAlarmDefinitionsError,
  } = AlarmRegistryQueries.useAlarmDefinitions();
  // NOTE: if alarm definition fetch has error, throw error so that it is caught by error boundary on the modal.
  if (isAlarmDefinitionsError) {
    throw new Error('Could not fetch alarm definitions...');
  }

  const collarAlarmTypes = CollarAlarmSettingsService.useSubscribedCollarAlarmTypes();
  const sortedAlarmTypes = sortBy(collarAlarmTypes, (alarmType) => alarmType);

  // Lifecycle
  //-----------------------------------------------------

  useEffect(() => {
    if (requestErrorMessage != null) requestErrorMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [requestErrorMessage]);

  // Handlers
  //-----------------------------------------------------

  const handleSubmit = async (alarmSettingsProps: CollarAlarmSettingsFormValues) => {
    const {
      farmId,
      onFarmStatus,
      taggedOnCattle,
      provisionedToFarm,
      provisionedToLiveFarm,
      dependentAlarmType,
      exceptForAlarmType,
    } = alarmSettingsProps;

    const { alarmType } = alarmSettingsProps;
    const actionType = alarmSettingsProps.alarmSettingsType === 'action' ? alarmSettingsProps.actionType : undefined;
    const filterType = alarmSettingsProps.alarmSettingsType === 'filter' ? alarmSettingsProps.filterType : undefined;

    if (actionType == null && filterType == null) {
      displayToast('error', 'Either action type or filter type is required!');
      return;
    }

    // NOTE: Either firmware version or firmware version range should be set, but not both.
    const firmwareVersion =
      alarmSettingsProps.firmwareVersionInputType === 'exact' ? alarmSettingsProps.firmwareVersion : undefined;
    const firmwareVersionRange =
      alarmSettingsProps.firmwareVersionInputType === 'range' ? alarmSettingsProps.firmwareVersionRange : undefined;

    const maxTimeSinceFirstLoggedInMillis = getNonEmptyValueOrUndefined(
      alarmSettingsProps.maxTimeSinceFirstLoggedInMillis,
    );
    const maxTimeSinceOnFarmStatusChangeInMillis = getNonEmptyValueOrUndefined(
      alarmSettingsProps.maxTimeSinceOnFarmStatusChangeInMillis,
    );

    // NOTE: Trigger rule does not exist if the alarm settings is global alarm settings type.
    // In that case, set trigger rule to undefined.
    const triggerRule = (() => {
      const ruleObject: CollarSettingsTriggerRule = omitBy(
        {
          farmId,
          onFarmStatus,
          firmwareVersion,
          firmwareVersionRange,
          taggedOnCattle,
          provisionedToFarm,
          provisionedToLiveFarm,
          dependentAlarmType,
          exceptForAlarmType,
          maxTimeSinceFirstLoggedInMillis,
          maxTimeSinceOnFarmStatusChangeInMillis,
        },
        (value) => isFalseyOrNone(value),
      );
      return isEmpty(ruleObject) ? undefined : ruleObject;
    })();

    // NOTE: Expires at only exists if the alarm settings is snooze filter type.
    // Otherwise, set expires at to undefined.
    const expiresAt = (() => {
      if (alarmSettingsProps.snoozeDurationMs != null && alarmSettingsProps.filterType === AlarmFilterTypeEnum.SNOOZE) {
        return new Date(Date.now() + alarmSettingsProps.snoozeDurationMs);
      }
      return undefined;
    })();

    // NOTE: Snooze alarm settings should always have expires at.
    // If not, display error message and return.
    if (alarmSettingsProps.filterType === AlarmFilterTypeEnum.SNOOZE && expiresAt == null) {
      displayToast('error', 'Snooze duration is required!');
      return;
    }

    // Context
    const reason = alarmSettingsProps.reason.trim() === '' ? null : alarmSettingsProps.reason.trim();
    if (reason == null) {
      displayToast('error', 'Reason is required!');
      return;
    }
    const madeBy = `${authUser.firstName} ${authUser.lastName}`;

    // NOTE: send request and handle response
    const { status, message, data } = await putAlarmSettings({
      alarmType,
      actionType,
      filterType,
      triggerRule,
      expiresAt: expiresAt?.toISOString() ?? undefined,
      context: {
        reason,
        madeBy,
      },
    });

    if (status === 'success') {
      displayToast('success', `Successfully created alarm settings with status ${status}`);
      if (data != null) onSuccessfulSubmit?.(data);
    } else if (status === 'error') {
      setRequestErrorMessage(message);
      displayToast('error', `Error: could not save alarm settings.`);
    }
  };

  const getAllowedFilersAndActionsForAlarmType = (alarmType: string) => {
    if (alarmType === ANY_ALARM_TYPE) {
      return {
        allowedActionTypes: NON_EVENT_ALARM_ALLOWED_ACTION_TYPES,
        allowedFilterTypes: AVAILABLE_FILTER_TYPE_OPTIONS,
      };
    }

    const alarmDefinition = alarmDefinitionByType?.[alarmType];
    if (alarmDefinition == null) {
      displayToastWithId(
        'non-existing-alarm-type',
        'warning',
        `Alarm definition for ${formatStringAsLabel(alarmType)} does not exist.`,
      );
      return {
        allowedActionTypes: null,
        allowedFilterTypes: null,
      };
    }

    if (alarmDefinition.alarmBehaviour === AlarmBehaviourTypeEnum.ACTIVE) {
      return {
        allowedActionTypes: NON_EVENT_ALARM_ALLOWED_ACTION_TYPES,
        allowedFilterTypes: AVAILABLE_FILTER_TYPE_OPTIONS,
      };
    }
    if (alarmDefinition.alarmBehaviour === AlarmBehaviourTypeEnum.EVENT) {
      return {
        allowedActionTypes: EVENT_ALARM_ALLOWED_ACTION_TYPES,
        allowedFilterTypes: null,
      };
    }

    throw new Error(`Unknown alarm behaviour type: ${alarmDefinition.alarmBehaviour}`);
  };

  const alarmTypeWithAnyOptionItems = useMemo(() => {
    return [
      {
        key: ANY_ALARM_TYPE,
        value: ANY_ALARM_TYPE,
        label: formatStringAsLabel(ANY_ALARM_TYPE),
      },
      ...sortedAlarmTypes.map((alarmType) => ({
        key: alarmType,
        value: alarmType,
        label: formatStringAsLabel(alarmType),
      })),
    ];
  }, []);

  const alarmTypeWithNoneOptionItems = useMemo(() => {
    return [
      {
        key: 'none',
        value: NONE_OPTION_VALUE,
        label: 'None',
      },
      ...sortedAlarmTypes.map((alarmType) => ({
        key: alarmType,
        value: alarmType,
        label: formatStringAsLabel(alarmType),
      })),
    ];
  }, [sortedAlarmTypes]);

  const initialFormValues = useMemo(() => getInitialFormValues(existingAlarmSettings), [existingAlarmSettings]);

  // Render
  //-----------------------------------------------------

  if (isAlarmDefinitionsLoading) return <CommonLoadingSpinner height="250px" />;

  return (
    <Flex flexDirection="column">
      <Formik
        initialValues={initialFormValues}
        validationSchema={collarAlarmSettingsSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, handleChange, errors, isSubmitting, setFieldValue, submitForm }) => {
          const currentAlarmType = values.alarmType;
          const alarmBehaviour: AlarmBehaviourTypeEnum | undefined =
            alarmDefinitionByType?.[currentAlarmType]?.alarmBehaviour;
          const { allowedActionTypes, allowedFilterTypes } = getAllowedFilersAndActionsForAlarmType(currentAlarmType);

          // NOTE: for event alarms, filters are not allowed. Only automated actions are allowed to remove them.
          if (alarmBehaviour === AlarmBehaviourTypeEnum.EVENT) {
            if (values.alarmSettingsType === 'filter') {
              setFieldValue('alarmSettingsType', 'action');
              setFieldValue('actionType', undefined);
            }
          }

          // NOTE: except for alarm type rule is only applicable for ANY alarm type. Hence, clean up if it is not ANY.
          if (currentAlarmType !== ANY_ALARM_TYPE && values.exceptForAlarmType !== NONE_OPTION_VALUE) {
            setFieldValue('exceptForAlarmType', NONE_OPTION_VALUE);
          }

          return (
            <Form>
              <Flex
                px={8}
                py={5}
                mb={2}
                maxHeight="550px"
                overflowY="auto"
                rowGap={5}
                flexDir="column"
              >
                <Flex
                  flexDir="column"
                  rowGap={4}
                >
                  <Heading
                    as="h4"
                    fontSize="1.1rem"
                  >
                    Basic Settings
                  </Heading>

                  <FormikAutoComplete
                    id="alarm-type"
                    name="alarmType"
                    label="Alarm Type"
                    onChangeText={(newAlarmType) => {
                      setFieldValue('alarmType', newAlarmType);

                      // Except for alarm type rule is only applicable for ANY alarm type. Hence, clean up if it is not ANY.
                      if (newAlarmType !== ANY_ALARM_TYPE) {
                        setFieldValue('exceptForAlarmType', NONE_OPTION_VALUE);
                      }
                    }}
                    autoCompleteProps={{ defaultValue: formatStringAsLabel(values.alarmType) }}
                    autoCompleteInputProps={{ name: 'alarmType' }}
                    autoCompleteItemsProps={alarmTypeWithAnyOptionItems}
                  />

                  {values.alarmSettingsType === 'action' && allowedActionTypes != null && (
                    <FormikSelect
                      id="action-type"
                      label="Action Type"
                      name="actionType"
                      selectProps={{ bg: Colors.gray100 }}
                      error={errors.actionType}
                      onChange={handleChange}
                    >
                      <option>None</option>
                      {allowedActionTypes.map((alarmActionType) => (
                        <option
                          key={alarmActionType}
                          value={alarmActionType}
                        >
                          {formatStringAsLabel(alarmActionType)}
                        </option>
                      ))}
                    </FormikSelect>
                  )}

                  {values.alarmSettingsType === 'filter' && allowedFilterTypes != null && (
                    <Flex
                      flexDir={{ base: 'column', md: 'row' }}
                      gap={4}
                    >
                      <FormikSelect
                        id="filter-type"
                        label="Filter Type"
                        name="filterType"
                        selectProps={{ bg: Colors.gray100 }}
                        error={errors.filterType}
                        onChange={handleChange}
                      >
                        <option>None</option>
                        {allowedFilterTypes.map((alarmFilterType) => (
                          <option
                            key={alarmFilterType}
                            value={alarmFilterType}
                          >
                            {formatStringAsLabel(alarmFilterType)}
                          </option>
                        ))}
                      </FormikSelect>

                      {values.filterType === AlarmFilterTypeEnum.SNOOZE && (
                        <FormControl id="snooze-duration-ms">
                          <FormikSelect
                            id="snooze-duration"
                            label="Snooze Duration"
                            name="snoozeDurationMs"
                            selectProps={{
                              bg: Colors.gray100,
                              onChange: (e) => setFieldValue('snoozeDurationMs', +e.target.value),
                            }}
                            error={errors.snoozeDurationMs}
                          >
                            <option>None</option>
                            {DURATION_MS_OPTIONS.map((durationMs) => (
                              <option
                                key={durationMs}
                                value={durationMs}
                              >
                                {getDurationFormat(durationMs)}
                              </option>
                            ))}
                          </FormikSelect>
                        </FormControl>
                      )}
                    </Flex>
                  )}

                  {/* Event alarms cannot have filters. So there is no reason to display this selection if event alarms. */}
                  {alarmBehaviour !== AlarmBehaviourTypeEnum.EVENT && (
                    <RadioGroup
                      name="alarmSettingsType"
                      value={values.alarmSettingsType}
                    >
                      <Flex gap={5}>
                        <Radio
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue('actionType', undefined);
                          }}
                          value="filter"
                        >
                          Filter
                        </Radio>
                        <Radio
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue('filterType', undefined);
                            setFieldValue('snoozeDurationMs', undefined);
                          }}
                          value="action"
                        >
                          Action
                        </Radio>
                      </Flex>
                    </RadioGroup>
                  )}
                </Flex>

                <Flex
                  flexDirection="column"
                  rowGap={4}
                  mt={2}
                >
                  <Heading
                    as="h4"
                    fontSize="1.1rem"
                  >
                    Trigger Rule
                  </Heading>

                  <FormikAutoComplete
                    id="on-farm-status"
                    name="onFarmStatus"
                    label="On Farm Status"
                    onChangeText={(onFarmStatus) => setFieldValue('onFarmStatus', onFarmStatus)}
                    autoCompleteProps={{ defaultValue: formatStringAsLabel(values.onFarmStatus) }}
                    autoCompleteInputProps={{
                      name: 'alarmType',
                      textTransform: 'capitalize',
                    }}
                    autoCompleteItemsProps={ON_FARM_STATUS_OPTIONS.map((onFarmStatus) => ({
                      key: onFarmStatus,
                      value: onFarmStatus,
                      label: formatStringAsLabel(onFarmStatus),
                    }))}
                  />

                  <FormikFarmAutoComplete
                    defaultValue={farmById[values.farmId]?.name ?? 'None'}
                    onChange={(farmId) => setFieldValue('farmId', farmId)}
                    farms={farms}
                  />

                  <FirmwareVersionInput
                    firmwareVersionInputType={values.firmwareVersionInputType}
                    firmwareVersion={values.firmwareVersion}
                    firmwareVersionError={errors.firmwareVersion}
                    firmwareVersionRange={values.firmwareVersionRange}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                  />

                  <FormikSelect
                    id="tagged-on-cattle"
                    label="Tagged On Cattle"
                    name="taggedOnCattle"
                    selectProps={{
                      bg: Colors.gray100,
                      onChange: (e) => setFieldValue('taggedOnCattle', safeParseBoolean(e.target.value) ?? 'none'),
                    }}
                    error={errors.taggedOnCattle}
                  >
                    {TAGGED_ON_CATTLE_OPTIONS.map(({ label, value }) => (
                      <option
                        key={label}
                        value={String(value)}
                      >
                        {formatStringAsLabel(label)}
                      </option>
                    ))}
                  </FormikSelect>

                  <FormikSelect
                    id="provisioned-to-farm"
                    label="Provisioned to Farm"
                    name="provisionedToFarm"
                    selectProps={{
                      bg: Colors.gray100,
                      onChange: (e) => setFieldValue('provisionedToFarm', safeParseBoolean(e.target.value) ?? 'none'),
                    }}
                    error={errors.provisionedToFarm}
                  >
                    {PROVISIONED_TO_FARM_OPTIONS.map(({ label, value }) => (
                      <option
                        key={label}
                        value={String(value)}
                      >
                        {formatStringAsLabel(label)}
                      </option>
                    ))}
                  </FormikSelect>

                  <FormikSelect
                    id="provisioned-to-live-farm"
                    label="Provisioned To Live Farm"
                    name="provisionedToLiveFarm"
                    selectProps={{
                      bg: Colors.gray100,
                      onChange: (e) =>
                        setFieldValue('provisionedToLiveFarm', safeParseBoolean(e.target.value) ?? 'none'),
                    }}
                    error={errors.provisionedToLiveFarm}
                  >
                    {PROVISIONED_TO_LIVE_FARM_OPTIONS.map(({ label, value }) => (
                      <option
                        key={label}
                        value={String(value)}
                      >
                        {formatStringAsLabel(label)}
                      </option>
                    ))}
                  </FormikSelect>
                </Flex>

                <FormikAutoComplete
                  id="dependent-alarm-type"
                  name="dependentAlarmType"
                  label="Dependent Alarm Type"
                  onChangeText={(dependentAlarmType) => setFieldValue('dependentAlarmType', dependentAlarmType)}
                  autoCompleteProps={{ defaultValue: formatStringAsLabel(values.dependentAlarmType) }}
                  autoCompleteInputProps={{
                    name: 'dependentAlarmType',
                    textTransform: 'capitalize',
                  }}
                  autoCompleteItemsProps={alarmTypeWithNoneOptionItems}
                />

                {values.alarmType === ANY_ALARM_TYPE && (
                  <FormikAutoComplete
                    id="except-for-alarm-type"
                    name="exceptForAlarmType"
                    label="Except For Alarm Type"
                    error={errors.exceptForAlarmType}
                    onChangeText={(exceptForAlarmType) => setFieldValue('exceptForAlarmType', exceptForAlarmType)}
                    autoCompleteProps={{ defaultValue: formatStringAsLabel(values.exceptForAlarmType) }}
                    autoCompleteInputProps={{
                      name: 'exceptForAlarmType',
                      textTransform: 'capitalize',
                    }}
                    autoCompleteItemsProps={alarmTypeWithNoneOptionItems}
                  />
                )}

                <DurationInput
                  label="Minimum Alarmed Time"
                  idPrefix="max-time-since-first-logged-in-millis"
                  onChange={(durationInMillis) => setFieldValue('maxTimeSinceFirstLoggedInMillis', durationInMillis)}
                  defaultDurationInMillis={initialFormValues?.maxTimeSinceFirstLoggedInMillis}
                />

                <DurationInput
                  label="Minimum Time Since On Farm Status Change"
                  idPrefix="max-time-since-on-farm-status-change-in-millis"
                  onChange={(durationInMillis) =>
                    setFieldValue('maxTimeSinceOnFarmStatusChangeInMillis', durationInMillis)
                  }
                  defaultDurationInMillis={initialFormValues?.maxTimeSinceOnFarmStatusChangeInMillis}
                />

                <Flex
                  flexDirection="column"
                  rowGap={4}
                  mt={2}
                >
                  <Heading
                    as="h4"
                    fontSize="1.1rem"
                  >
                    Context
                  </Heading>

                  <FormikTextarea
                    id="Reason"
                    label="Reason"
                    name="reason"
                    textareaProps={{ value: values.reason }}
                    onTriggerSubmit={submitForm}
                  />
                </Flex>

                {/* Request error message */}
                <Text
                  ref={requestErrorMessageRef}
                  display={requestErrorMessage == null ? 'none' : 'block'}
                  mt={1}
                  fontWeight={600}
                  color={Colors.red500}
                >
                  Error occurred: <br />
                  {requestErrorMessage}
                </Text>
              </Flex>

              <FormModalActions
                onClose={onClose}
                isSubmitting={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
};

const getInitialFormValues = (
  existingAlarmSettings?: GenericAlarmSettings<CollarSettingsTriggerRule>,
): CollarAlarmSettingsFormValues => {
  if (existingAlarmSettings == null) return DEFAULT_ALARM_SETTINGS_FORM_VALUES;

  const onFarmStatus = existingAlarmSettings.triggerRule?.onFarmStatus;

  return {
    alarmType: existingAlarmSettings.alarmType,
    alarmSettingsType: existingAlarmSettings.actionType != null ? 'action' : 'filter',
    actionType: existingAlarmSettings.actionType,
    filterType: existingAlarmSettings.filterType,
    firmwareVersionInputType: existingAlarmSettings.triggerRule?.firmwareVersionRange == null ? 'exact' : 'range',
    firmwareVersion: existingAlarmSettings.triggerRule?.firmwareVersion,
    firmwareVersionRange: existingAlarmSettings.triggerRule?.firmwareVersionRange,
    farmId: existingAlarmSettings.triggerRule?.farmId ?? NONE_OPTION_VALUE,
    onFarmStatus: isValidOnFarmStatus(onFarmStatus) ? onFarmStatus : NONE_OPTION_VALUE,
    taggedOnCattle: existingAlarmSettings.triggerRule?.taggedOnCattle ?? NONE_OPTION_VALUE,
    provisionedToFarm: existingAlarmSettings.triggerRule?.provisionedToFarm ?? NONE_OPTION_VALUE,
    provisionedToLiveFarm: existingAlarmSettings.triggerRule?.provisionedToLiveFarm ?? NONE_OPTION_VALUE,
    dependentAlarmType: existingAlarmSettings.triggerRule?.dependentAlarmType ?? NONE_OPTION_VALUE,
    exceptForAlarmType: existingAlarmSettings.triggerRule?.exceptForAlarmType ?? NONE_OPTION_VALUE,
    maxTimeSinceFirstLoggedInMillis: existingAlarmSettings.triggerRule?.maxTimeSinceFirstLoggedInMillis,
    maxTimeSinceOnFarmStatusChangeInMillis: existingAlarmSettings.triggerRule?.maxTimeSinceOnFarmStatusChangeInMillis,
    reason: existingAlarmSettings.context.reason ?? '',
  };
};

const isValidOnFarmStatus = (value: string | undefined): value is DeviceOnFarmStatusEnum => {
  if (value == null) return false;
  return Object.values(DeviceOnFarmStatusEnum).includes(value as DeviceOnFarmStatusEnum);
};

const DEFAULT_ALARM_SETTINGS_FORM_VALUES = {
  alarmType: ANY_ALARM_TYPE,
  alarmSettingsType: 'filter',
  actionType: undefined,
  filterType: AlarmFilterTypeEnum.HIDE,
  snoozeDurationMs: undefined,
  firmwareVersionInputType: 'exact',
  firmwareVersion: undefined,
  firmwareVersionRange: undefined,
  onFarmStatus: NONE_OPTION_VALUE,
  farmId: NONE_OPTION_VALUE,
  taggedOnCattle: NONE_OPTION_VALUE,
  dependentAlarmType: NONE_OPTION_VALUE,
  exceptForAlarmType: NONE_OPTION_VALUE,
  provisionedToFarm: NONE_OPTION_VALUE,
  provisionedToLiveFarm: NONE_OPTION_VALUE,
  reason: '',
} as CollarAlarmSettingsFormValues;

export default CollarAlarmSettingsForm;
