import { Select, SelectProps } from '@chakra-ui/react';
import React, { FunctionComponent, PropsWithChildren, RefObject } from 'react';

import FormikFormField, { FormikFormFieldProps } from './formik-form-field';

export type FormikInputProps = PropsWithChildren<{
  id: string;
  selectProps?: SelectProps;
  inputRef?: RefObject<HTMLInputElement>;
}> &
  Omit<FormikFormFieldProps, 'children' | 'htmlFor'>;

const FormikSelect: FunctionComponent<FormikInputProps> = (props) => {
  const { selectProps, children, id, ...otherProps } = props;

  return (
    <FormikFormField
      htmlFor={id}
      {...otherProps}
    >
      {([fieldProps, { error }]) => (
        <Select
          id={id}
          {...fieldProps}
          isInvalid={error != null}
          {...selectProps}
        >
          {children}
        </Select>
      )}
    </FormikFormField>
  );
};

export default FormikSelect;
