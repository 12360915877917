import { useMemo, useRef } from 'react';

import { Writeable } from './utility-types';

interface AbstractFetchable {
  isFetching: boolean;
}

interface LoadingFetchable extends AbstractFetchable {
  status: 'loading';
  data: undefined;
  error: null;
  isError: false;
  isLoading: true;
}

interface ErrorFetchable<TData = unknown, TError = unknown> extends AbstractFetchable {
  status: 'error';
  data: TData | undefined;
  error: TError;
  isError: true;
  isLoading: false;
}

interface SuccessFetchable<TData = unknown> extends AbstractFetchable {
  status: 'success';
  data: TData;
  error: null;
  isError: false;
  isLoading: false;
}

type Fetchable<TData = unknown, TError = unknown> =
  | LoadingFetchable
  | ErrorFetchable<TData, TError>
  | SuccessFetchable<TData>;

type InferFetchableErrors<T extends readonly unknown[]> = {
  [K in keyof T as T[K] extends Fetchable ? K : never]: T[K] extends Fetchable<unknown, infer X> ? X : never;
};

type FetchableErrors<T extends readonly unknown[]> = (InferFetchableErrors<T> | Error)[];

type ResolvedFetchableQueries<Q extends readonly unknown[]> = {
  [K in keyof Q]: Q[K] extends Fetchable<infer X> ? X : Q[K] extends Fetchable<infer X> | null ? X | null : Q[K];
};

type CreateFetchableCombinerFunction<Q extends readonly unknown[], T> = (...queries: ResolvedFetchableQueries<Q>) => T;

class UndefinedDataError extends Error {}

const combine = <Q extends unknown[], TData>(
  queries: Q,
  combiner: CreateFetchableCombinerFunction<Q, TData>,
): TData => {
  const resolvedQueries = queries.map((query) => {
    if (!isFetchable(query)) return query;

    if (query.data === undefined) throw new UndefinedDataError();

    return query.data;
  }) as ResolvedFetchableQueries<Q>;

  return combiner(...resolvedQueries);
};

/**
 * Generates memoized fetchable functions. `createFetchable` accepts one or more "input" selectors,
 * which extract values from arguments, and an "output" fetchable that receives the extracted values and should return a derived value.
 */
const createFetchable = <Q extends unknown[], TData>(
  ...args: [...inputs: Q, combiner: CreateFetchableCombinerFunction<Q, TData>]
): Fetchable<TData, FetchableErrors<Q>> => {
  const queries = args.slice(0, -1) as Q;

  const loadingFetchable = queries.find((q): q is LoadingFetchable => isLoadingFetchable(q));
  const isFetching = queries.filter(isFetchable).find((q) => q.isFetching === true) != null;

  if (loadingFetchable != null)
    return { status: 'loading', data: undefined, error: null, isLoading: true, isError: false, isFetching };

  const combiner = args[args.length - 1] as CreateFetchableCombinerFunction<Q, TData>;

  const errorFetchables = queries.filter((q): q is ErrorFetchable<TData, InferFetchableErrors<Q>> =>
    isErrorFetchable(q),
  );

  if (errorFetchables.length > 0) {
    const error = errorFetchables.map((f) => f.error);

    try {
      const data = combine(queries, combiner);

      return { status: 'error', data, error, isLoading: false, isError: true, isFetching };
    } catch (e) {
      if (e instanceof UndefinedDataError)
        return { status: 'error', data: undefined, error, isLoading: false, isError: true, isFetching };

      if (e instanceof Error)
        return { status: 'error', data: undefined, error: [...error, e], isLoading: false, isError: true, isFetching };

      throw e;
    }
  }

  try {
    const data = combine(queries, combiner);

    return { status: 'success', data, error: null, isError: false, isLoading: false, isFetching };
  } catch (error) {
    if (error instanceof UndefinedDataError)
      throw new Error(
        'All fetchables expected to be resolved. If a successful fetchable returns undefined, return null instead.',
      );

    if (error instanceof Error)
      return { status: 'error', data: undefined, error: [error], isLoading: false, isError: true, isFetching };

    throw error;
  }
};

/**
 * If the generated fetcher is called multiple times, the output will only be recalculated when the extracted values have changed.
 */
const useMemoizedCreateFetchable = <Q extends unknown[], TData>(
  ...args: [...inputs: Q, combiner: CreateFetchableCombinerFunction<Q, TData>]
): Fetchable<TData, FetchableErrors<Q>> => {
  const queries = args.slice(0, -1) as Q;
  const combiner = args[args.length - 1] as CreateFetchableCombinerFunction<Q, TData>;

  const combinerRef = useRef(combiner);
  combinerRef.current = combiner;

  return useMemo(
    () => createFetchable<Writeable<Q>, TData>(...queries, combinerRef.current),
    // ignoring as type signature ensures this to be a dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    queries.flatMap((q) => {
      if (!isFetchable(q)) return [q];

      return [q.status, q.data, q.error];
    }),
  );
};

/**
 *
 */
const defer = <T extends Fetchable>(fetchable: T): SuccessFetchable<T> => ({
  status: 'success',
  data: fetchable,
  error: null,
  isError: false,
  isLoading: false,
  isFetching: false,
});

const silent = <T extends Fetchable>(fetchable: T): T | SuccessFetchable<null> => {
  switch (fetchable.status) {
    case 'loading':
    case 'success':
      return fetchable;
    case 'error':
      return {
        status: 'success',
        data: null,
        error: null,
        isError: false,
        isLoading: false,
        isFetching: false,
      };
  }
};

const useBulk = <Q extends unknown[]>(fetchables: Q): Fetchable<ResolvedFetchableQueries<Q>, FetchableErrors<Q>> => {
  return useMemo(
    () => createFetchable(...fetchables, (...q: ResolvedFetchableQueries<Q>) => q),

    fetchables.flatMap((q) => {
      if (!isFetchable(q)) return [q];

      return [q.status, q.data, q.error];
    }),
  );
};

const isFetchable = <TData, TError>(fetchable: unknown): fetchable is Fetchable<TData, TError> => {
  if (
    fetchable == null ||
    typeof fetchable !== 'object' ||
    !('status' in fetchable) ||
    typeof (fetchable as { status: unknown }).status !== 'string'
  ) {
    return false;
  }

  switch ((fetchable as { status: string }).status) {
    case 'loading':
      return true;
    case 'error':
      return 'error' in fetchable;
    case 'success':
      return 'data' in fetchable;
    default:
      return false;
  }
};

const isErrorFetchable = <TData, TError>(fetchable: unknown): fetchable is ErrorFetchable<TData, TError> =>
  isFetchable(fetchable) && fetchable.status === 'error';

const isLoadingFetchable = (fetchable: unknown): fetchable is LoadingFetchable =>
  isFetchable(fetchable) && fetchable.status === 'loading';

const isSuccessFetchable = <TData>(fetchable: unknown): fetchable is SuccessFetchable<TData> =>
  isFetchable(fetchable) && fetchable.status === 'success';

export {
  defer,
  silent,
  useBulk,
  createFetchable,
  useMemoizedCreateFetchable,
  isFetchable,
  isErrorFetchable,
  isLoadingFetchable,
  isSuccessFetchable,
};

export default Fetchable;
