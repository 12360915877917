import { DeviceApi, JobApi, ReleaseChannelApi } from '@halter-corp/ota-service-client';

import client from '../client';

const otaApi = {
  job: client.resolveOpenApi(JobApi),
  releaseChannel: client.resolveOpenApi(ReleaseChannelApi),
  device: client.resolveOpenApi(DeviceApi),
};

export default otaApi;
