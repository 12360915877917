import { Flex, Text } from '@chakra-ui/react';
import { Row, createColumnHelper } from '@tanstack/react-table';
import { minBy } from 'lodash';

import InfoIconButton from 'src/components/buttons/info-icon-button';
import { formatStringAsLabel } from 'src/util/string-format.util';
import { compareRowsByNumber, compareRowsByString } from 'src/util/table.sorting.util';

import ContentWithCopy from '../../../../../components/content-with-copy';
import { AlarmScope } from '../../../@types';
import CattleCell from '../../../components/tables/cells/cattle.cell';
import FarmCell from '../../../components/tables/cells/farm.cell';
import FirstLoggedCell from '../../../components/tables/cells/first-logged.cell';
import MobCell from '../../../components/tables/cells/mob.cell';
import SerialNumberCell from '../../../components/tables/cells/serial-number.cell';
import { WelfareAlarmEntity } from '../@types';
import WelfareAlarmsCell from '../components/tables/cells/welfare-alarms.cell';
import { WelfareEntityUtils } from '../utils/welfare-entity.util';

export const columnHelper = createColumnHelper<WelfareAlarmEntity>();

export enum CommonWelfareTableColumns {
  ALARM_BEHAVIOUR = 'alarm-behaviour',
  SCOPE = 'scope',
  ENTITY_ID = 'entity-id',
  ALARMS = 'alarms',
  FIRST_LOGGED = 'first-logged',

  FARM = 'farm',
  MOB = 'mob',
  CATTLE = 'cattle',
  COLLAR = 'collar',
}

// COMMON COLUMNS
const alarmsColumn = columnHelper.accessor((row) => row, {
  id: CommonWelfareTableColumns.ALARMS,
  sortingFn: (rowA, rowB) => compareRowsByNumber(rowA, rowB, (entity) => entity.alarms.length),
  sortUndefined: 1, // Undefined values will be sorted with lower priority
  cell: (item) => <WelfareAlarmsCell welfareAlarmEntity={item.getValue()} />,
  header: () => <Text>Alarms</Text>,
});

const firstLoggedColumn = columnHelper.accessor((row) => row.alarms, {
  id: CommonWelfareTableColumns.FIRST_LOGGED,
  sortingFn: (rowA, rowB) =>
    compareRowsByString(rowA, rowB, (entity) => minBy(entity.alarms, (alarm) => alarm.eventDateTime)?.eventDateTime),
  sortUndefined: 1, // Undefined values will be sorted with lower priority
  cell: (item) => <FirstLoggedCell alarms={item.getValue() ?? []} />,
  header: () => <Text>First Logged</Text>,
});

const farmColumn = columnHelper.accessor((row) => row.farm?.farmName, {
  id: CommonWelfareTableColumns.FARM,
  sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.farm?.farmName),
  sortUndefined: 1, // Undefined values will be sorted with lower priority
  cell: (item) => (
    <FarmCell
      farm={item.row.original.farm}
      matrixLinkDomain="general"
    />
  ),
  header: () => <Text>Farm</Text>,
});

const mobColumn = columnHelper.accessor((row) => WelfareEntityUtils.getMobId(row), {
  id: CommonWelfareTableColumns.MOB,
  enableSorting: false,
  cell: (item) => {
    const entity = item.row.original;
    const mobId = WelfareEntityUtils.getMobId(entity);
    if (entity.farm?.farmId == null || mobId == null) return null;

    return (
      <MobCell
        farmId={entity.farm?.farmId}
        mobId={mobId}
      />
    );
  },
  header: () => <Text>Mob</Text>,
});

// GENERIC WELFARE ENTITY COLUMNS
export const genericWelfareEntityColumns = [farmColumn, mobColumn, alarmsColumn, firstLoggedColumn];

// COMMAND WELFARE ENTITY COLUMNS
export const commandWelfareEntityColumns = [
  farmColumn,
  columnHelper.accessor((row) => row, {
    id: CommonWelfareTableColumns.ENTITY_ID,
    sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.entityId),
    cell: (item) => (
      <ContentWithCopy
        id={item.getValue().entityId}
        name={item.getValue().entityId}
        scope={AlarmScope.COMMAND}
        textProps={{ maxWidth: '125px' }}
        fontWeight={600}
      />
    ),
    header: () => <Text>Command ID</Text>,
  }),
  columnHelper.accessor((row) => row.context.commandContext?.collarCommandId, {
    id: 'collar-command-id',
    sortingFn: (rowA, rowB) =>
      compareRowsByNumber(rowA, rowB, (entity) => entity.context.commandContext?.collarCommandId ?? 0),
    cell: (item) => {
      const collarCommandId = item.getValue();
      if (collarCommandId == null) return null;
      return <Text>{collarCommandId}</Text>;
    },
    header: () => (
      <Flex
        alignItems="center"
        gap={2}
      >
        <Text>Collar Command ID</Text>
        <InfoIconButton helperText="Collar command ID that the command is assigned to." />
      </Flex>
    ),
  }),
  columnHelper.accessor((row) => row.context.commandContext?.type, {
    id: 'command-type',
    sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.context.commandContext?.type),
    cell: (item) => {
      const type = item.getValue();
      if (type == null) return null;
      return <Text>{formatStringAsLabel(type)}</Text>;
    },
    header: () => <Text>Type</Text>,
  }),
  alarmsColumn,
  firstLoggedColumn,
];

// COLLAR WELFARE ENTITY COLUMNS
export const collarWelfareEntityColumns = [
  farmColumn,
  mobColumn,
  columnHelper.accessor((row) => WelfareEntityUtils.getCattleContext(row)?.cattleName, {
    id: CommonWelfareTableColumns.CATTLE,
    sortingFn: (rowA, rowB) =>
      compareRowsByString(rowA, rowB, (entity) => WelfareEntityUtils.getCattleContext(entity)?.cattleName),
    sortUndefined: 1, // Undefined values will be sorted with lower priority
    cell: (item) => {
      const { collarContext } = item.row.original.context;
      if (collarContext?.cattleId == null) {
        return null;
      }
      return <CattleCell cattle={{ cattleId: collarContext.cattleId, cattleName: collarContext.cattleName }} />;
    },
    header: () => <Text>Cattle</Text>,
  }),
  columnHelper.accessor((row) => row, {
    id: CommonWelfareTableColumns.ENTITY_ID,
    sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.entityId),
    cell: (item) => <SerialNumberCell serialNumber={item.getValue().entityId} />,
    header: () => <Text>Collar</Text>,
  }),
  alarmsColumn,
  firstLoggedColumn,
];

// COW WELFARE ENTITY COLUMNS
export const cowWelfareEntityColumns = [
  farmColumn,
  mobColumn,
  columnHelper.accessor((row) => row.entityId, {
    id: CommonWelfareTableColumns.ENTITY_ID,
    sortingFn: (rowA, rowB) =>
      compareRowsByString(rowA, rowB, (entity) => WelfareEntityUtils.getCattleContext(entity)?.cattleName),
    sortUndefined: 1, // Undefined values will be sorted with lower priority
    cell: (item) => {
      const alarmEntity = item.row.original;
      const { cattleContext } = alarmEntity.context;
      if (cattleContext == null) return null;
      return (
        <CattleCell
          cattle={{ cattleId: alarmEntity.entityId, cattleName: cattleContext.cattleName }}
          fontWeight={600}
        />
      );
    },
    header: () => <Text>Cattle</Text>,
  }),
  columnHelper.accessor((row) => row, {
    id: CommonWelfareTableColumns.COLLAR,
    sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => WelfareEntityUtils.getCollarId(entity)),
    cell: (item) => {
      const collarId = WelfareEntityUtils.getCollarId(item.getValue());
      if (collarId == null) return null;

      return (
        <SerialNumberCell
          serialNumber={collarId}
          textProps={{ fontWeight: 500 }}
        />
      );
    },
    header: () => <Text>Collar</Text>,
  }),
  alarmsColumn,
  firstLoggedColumn,
];

// MOB WELFARE ENTITY COLUMNS
export const mobWelfareEntityColumns = [
  farmColumn,
  columnHelper.accessor((row) => WelfareEntityUtils.getMobId(row), {
    id: CommonWelfareTableColumns.MOB,
    enableSorting: false,
    cell: (item) => {
      const entity = item.row.original;
      const mobId = WelfareEntityUtils.getMobId(entity);
      if (entity.farm?.farmId == null || mobId == null) return null;

      return (
        <MobCell
          farmId={entity.farm?.farmId}
          mobId={mobId}
          fontWeight={600}
        />
      );
    },
    header: () => <Text>Mob</Text>,
  }),
  alarmsColumn,
  firstLoggedColumn,
];

// FARM WELFARE ENTITY COLUMNS
export const farmWelfareEntityColumns = [
  columnHelper.accessor((row) => row.farm?.farmName, {
    id: CommonWelfareTableColumns.FARM,
    sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.farm?.farmName),
    sortUndefined: 1, // Undefined values will be sorted with lower priority
    cell: (item) => (
      <FarmCell
        farm={item.row.original.farm}
        fontWeight={600}
        matrixLinkDomain="general"
      />
    ),
    header: () => <Text>Farm</Text>,
  }),
  alarmsColumn,
  firstLoggedColumn,
];

export const canCopyWelfareMonitoringColumnValue = (columnId: string) => {
  switch (columnId) {
    case CommonWelfareTableColumns.ENTITY_ID:
      return true;
    case CommonWelfareTableColumns.FARM:
      return true;
    case CommonWelfareTableColumns.MOB:
      return true;
    case CommonWelfareTableColumns.CATTLE:
      return true;
    case CommonWelfareTableColumns.COLLAR:
      return true;
    default:
      return false;
  }
};

export const extractWelfareMonitoringColumnCopyValue = (row: Row<WelfareAlarmEntity>, columnId: string) => {
  const entity = row.original;
  switch (columnId) {
    case CommonWelfareTableColumns.ENTITY_ID:
      return entity.entityId;
    case CommonWelfareTableColumns.FARM:
      return entity.farm?.farmId;
    case CommonWelfareTableColumns.MOB:
      return WelfareEntityUtils.getMobId(entity);
    case CommonWelfareTableColumns.CATTLE:
      return WelfareEntityUtils.getCattleContext(entity)?.cattleName;
    case CommonWelfareTableColumns.COLLAR:
      return WelfareEntityUtils.getCollarId(entity);
    default:
      return undefined;
  }
};
