import { chain, sortBy } from 'lodash';
import { useMemo } from 'react';

import { OtaQueries } from 'src/queries/queries/ota.queries';

export const OtaService = {
  useReleaseChannels: (options: { sortDir?: 'asc' | 'desc' } = {}) => {
    const { sortDir } = options;

    const releaseChannels = OtaQueries.useReleaseChannels();
    if (sortDir == null) {
      return releaseChannels;
    }

    return {
      ...releaseChannels,
      data: sortBy(releaseChannels.data, (releaseChannel) => releaseChannel.id.toLowerCase(), sortDir),
    };
  },

  useReleaseChannelByFarmId: () => {
    const { data: releaseChannelMappings } = OtaQueries.useAllReleaseChannelMappings();

    const releaseChannelMappingByFarmId = useMemo(() => {
      return chain(releaseChannelMappings ?? [])
        .keyBy((mapping) => mapping.farmId)
        .mapValues((mapping) => mapping.releaseChannelId)
        .value();
    }, [releaseChannelMappings]);

    return releaseChannelMappingByFarmId;
  },
};
