import { ColumnDef, ColumnHelper, Row } from '@tanstack/react-table';
import { FunctionComponent, JSXElementConstructor, ReactElement } from 'react';

import { BaseFilterModel } from 'src/models/base-filter.model';
import { Nullable } from 'src/types';
import { FilterOption, FilterOptionConfig } from 'src/types/filter-types';

export enum MonitoringDomain {
  COLLAR = 'COLLAR',
  FARM_OPS = 'FARM_OPS',
  WELFARE = 'WELFARE',
  TOWER = 'TOWER',
  POWER = 'POWER',
}

export type FarmContext = {
  farmId: string;
  farmName?: string;
};

export type CattleContext = {
  cattleId: string;
  cattleName?: string;
};

export type Alarm = {
  type: string;
  eventDateTime: string;
  data?: object;
};

export enum AlarmScope {
  FARM = 'FARM',
  MOB = 'MOB',
  COW = 'COW',
  COLLAR = 'COLLAR',
  COMMAND = 'COMMAND',
  TOWER = 'TOWER',
}

export type AlarmBehaviour = 'active' | 'event';

export type AlarmedEntity<T> = {
  entityId: string;
  uniqueKey: string;
  alarmBehaviour: AlarmBehaviour;
  farm: Nullable<FarmContext>;
  mobId?: string;
  alarms: Alarm[];
  scope: AlarmScope;
  firstLoggedDate?: Date;
  lastLoggedDate?: Date;
  debugLink?: string;
  context: T;
};

export type MonitoringTemplateConfig<C, T extends AlarmedEntity<C>> = {
  title?: string;
  domain: MonitoringDomain;
  navLinkItems?: NavLinkItem[];
  entityConfig: EntityConfig;
  scopeConfig: ScopeConfig;
  actionConfig: MonitoringActionConfig;
  filterConfig: MonitoringFilterConfig<C>;
  rankConfig: RankConfig<T>;
  tableConfig: TableConfig<C>;
  queryConfig: QueryConfig<C>;
  settingsConfig: SettingsConfig;
};

export type EntityConfig = {
  entityKey: string; // has to be unique across all domains
  entityName: string; // display name, no need to be unique
  entityIcon?: FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
};

export type ScopeConfig =
  | {
      scopeSelectionEnabled: false;
    }
  | {
      scopeSelectionEnabled: true;
      scopes: AlarmScope[];
    };

export type SettingsConfig = {
  // If search mode is disabled, it will always only display action-required alarms.
  alarmSearchModeEnabled: boolean;
  // If grafana settings are disabled, it will not display grafana settings such as grafana period settings.
  grafanaSettingsEnabled: boolean;
  // If snooze alarms visibility is disabled, it will not display snooze alarms visibility settings.
  snoozeAlarmsVisibilityEnabled?: boolean;
};

export type NavLinkItem = {
  href: string;
  label: string;
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
};

export type AlarmActionFormProps = {
  selectedIds: string[];
  selectedEventAlarmEntities: AlarmedEntity<unknown>[];
  selectedActiveAlarmEntities: AlarmedEntity<unknown>[];
  onSuccessfulSubmit: () => void;
  onBack: () => void;
  onClose: () => void;
  additionalSection?: React.ReactNode;
};
export type AlarmActionForm = FunctionComponent<AlarmActionFormProps>;

export type AlarmActionConfig<T = string> = {
  action: T;
  /**
   * @summary Associated alarm behaviour for the action.
   * For example, if the action is 'snooze', the associated alarm behaviour is 'active' because snooze action can only be taken on active alarms.
   * If the action is 'diagnose', then the associated alarm behaviour is 'event' because diagnose action can only be taken on event alarms.
   * If the action can be taken on any alarm behaviour, then the associated alarm behaviour is 'any'.
   */
  associatedAlarmBehaviour: AlarmBehaviour | 'any';

  /**
   * @summary Optional list of allowed user emails for the action.
   * If the list is empty, then the action is allowed for all authenticated users.
   */
  allowedUserEmails?: string[];
};

export type MonitoringActionConfig<T = string> = {
  alarmActionConfigs: AlarmActionConfig[];
  getAlarmActionFormByAlarmAction: (action: T) => AlarmActionForm | undefined;
};

export type MonitoringFilterConfig<C> = {
  useFilterOptionsByType: () => Map<string, FilterOption<unknown>[]>;
  filterProcessConfig: BaseFilterModel<AlarmedEntity<C>, string>;
  filterOptionConfig: FilterOptionConfig;
  searchFilterTypes: string[];
  onOffFilterTypes?: string[];
  dateRangeFilterTypes?: string[];
};

export type RankItem = {
  name: string;
  value: number;
  selected: boolean;
};

export type RankSectionConfig<T> = {
  name: string;
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
  associatedFilterType: string;
  deriveRanks: (items: T[], selectedIds: Set<string>) => RankItem[];
  formatRankItem?: (name: string) => string;
};

export type RankSection = {
  name: string;
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
  ranks: RankItem[];
  formatRankItem?: (name: string) => string;
  associatedFilterType: string;
};

export type RankConfig<T> = {
  extraRankSectionConfigs?: RankSectionConfig<T>[];
};

export type TableConfig<C> = {
  columnHelper: ColumnHelper<AlarmedEntity<C>>;
  getColumns: (scope: AlarmScope | null) => ColumnDef<AlarmedEntity<C>, any>[];
  renderExpandedRow?: (row: Row<AlarmedEntity<C>>) => JSX.Element;
  canCopyColumn?: (columnId: string) => boolean;
  extractColumnValueForCopy?: (row: Row<AlarmedEntity<C>>, columnId: string) => string | undefined;
};

export type AlarmedEntityFetchParam = {
  searchMode: AlarmSearchMode;
  disabled?: boolean;
};

export type QueryConfig<C> = {
  useAlarmEntities: (config: AlarmedEntityFetchParam) => {
    isLoading: boolean;
    isError: boolean;
    data: AlarmedEntity<C>[];
    refetchData: () => Promise<unknown>;
  };

  // Snooze action config is optional. If not provided, the quick snooze button will not appear.
  useSnoozeAlarmAction?: () => (
    entityId: string,
    expiresAt: string,
    alarmsList: string[],
    comments?: string,
  ) => Promise<{ status: 'success' | 'error' }>;
};

// Default should be ACTION_REQUIRED
export enum AlarmSearchMode {
  ALL = 'ALL',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  NON_ACTION_REQUIRED = 'NON_ACTION_REQUIRED',
}
