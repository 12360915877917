import { Box, Flex, Select, Tooltip } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import React, { FunctionComponent, useEffect } from 'react';
import { LuChevronsLeft, LuChevronsRight } from 'react-icons/lu';
import { RiLock2Line } from 'react-icons/ri';

import { useEnvironment } from 'src/providers/environment';
import { getColorByEnvironment, getHoverColorByEnvironment } from 'src/providers/environment/utils';
import { AuthService } from 'src/services';
import { EnvironmentEnum } from 'src/services/configuration.service';

import DrawerNavigation from './drawer-navigation';
import MenuItem from './menu-item';

export type DrawerProps = {
  width: number;
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

const DRAWER_ANIM_DURATION_SECONDS = 0.25;

const Drawer: FunctionComponent<DrawerProps> = ({ width, collapsed, setCollapsed }) => {
  const { environment, setEnvironment } = useEnvironment();

  const controls = useAnimation();

  const localBuild = process.env.NODE_ENV !== 'production';

  useEffect(() => {
    void controls.start({
      width,
      transition: {
        duration: DRAWER_ANIM_DURATION_SECONDS,
      },
    });
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 250);
  }, [collapsed, controls, width]);

  const onToggleCollapsed = () => {
    setCollapsed((prevCollapsed) => !prevCollapsed);
  };

  const onSignOutPress = () => {
    void AuthService.signOut(environment);
  };

  const onEnvironmentChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setEnvironment(e.target.value as EnvironmentEnum);
  };

  return (
    <Flex
      as={motion.aside}
      animate={controls}
      initial={{ width }}
      bg="gray.900"
      position="relative"
      height="full"
      flexDirection="column"
      zIndex={10}
    >
      <Flex
        flex={1}
        flexDir="column"
        height="100%"
        overflowY="auto"
      >
        <Box p={2}>
          <Box
            borderRadius={8}
            overflow="hidden"
          >
            <Tooltip
              label={localBuild ? 'Change the environment in env-config.ts' : ''}
              placement="right"
            >
              <Select
                variant="flilled"
                aria-label="Environment Select"
                color="white"
                overflow="hidden"
                cursor="pointer"
                borderRadius={8}
                border="none"
                _hover={{
                  bg: getHoverColorByEnvironment(environment),
                }}
                backgroundColor={getColorByEnvironment(environment)}
                value={environment}
                onChange={onEnvironmentChange}
                disabled={localBuild}
              >
                <option value={EnvironmentEnum.DEV}>Development</option>
                <option value={EnvironmentEnum.STAGING}>Staging</option>
                <option value={EnvironmentEnum.PRODUCTION}>Production</option>
              </Select>
            </Tooltip>
          </Box>
        </Box>

        <DrawerNavigation collapsed={collapsed} />

        <MenuItem
          onPress={onSignOutPress}
          collapsed={collapsed}
          icon={RiLock2Line}
          text="Sign Out"
        />
        <MenuItem
          collapsed={collapsed}
          onPress={onToggleCollapsed}
          icon={collapsed ? LuChevronsRight : LuChevronsLeft}
          text="Collapse"
        />
      </Flex>
    </Flex>
  );
};

export default Drawer;
