import {
  AlarmActionConfig,
  EntityConfig,
  MonitoringActionConfig,
  MonitoringFilterConfig,
  QueryConfig,
  RankConfig,
  ScopeConfig,
  SettingsConfig,
  TableConfig,
} from '../../../@types/index';
import { farmRankConfig } from '../../../config/rank-config';
import { AlarmedCollar, AlarmedCollarContext, CollarAlarmAction } from '../@types';
import ChangeFarmActionForm from '../components/collar-action-forms/change-farm-action-form';
import PowerOffActionForm from '../components/collar-action-forms/power-off-action-form';
import RemoveEventAlarmForm from '../components/collar-action-forms/remove-event-alarm-action.form';
import ResetActionForm from '../components/collar-action-forms/reset-action-form';
import ReturnToHalterActionForm from '../components/collar-action-forms/return-to-halter-action-form';
import SnoozeActionForm from '../components/collar-action-forms/snooze-action-form';
import TagOffActionForm from '../components/collar-action-forms/tag-off-action.form';
import TagOnToFindCowForm from '../components/collar-action-forms/tag-on-to-find-cow.form';
import { AlarmedCollarFilterOptionModel } from '../models/alarmed-collar-filter-option.model';
import { AlarmedCollarFilterModel } from '../models/alarmed-collar-filter.model';
import { CollarAlarmActionService } from '../services/collar-alarm-action.service';
import CollarMonitoringService from '../services/collar-monitoring.service';
import {
  canCopyAlarmedCollarColumnValue,
  columnHelper,
  columns,
  extractAlarmedCollarColumnCopyValue,
} from './column-config';
import { onFarmStatusRankConfig } from './rank-config';

export const entityConfig: EntityConfig = {
  entityKey: 'collar',
  entityName: 'collar',
};

export const scopeConfig: ScopeConfig = {
  scopeSelectionEnabled: false,
};

export const settingsConfig: SettingsConfig = {
  alarmSearchModeEnabled: false,
  grafanaSettingsEnabled: true,
  snoozeAlarmsVisibilityEnabled: true,
};

const alarmActionConfigs: AlarmActionConfig<CollarAlarmAction>[] = [
  {
    action: 'return-to-halter',
    associatedAlarmBehaviour: 'any',
  },
  {
    action: 'snooze',
    associatedAlarmBehaviour: 'active',
  },
  {
    action: 'change-farm',
    associatedAlarmBehaviour: 'any',
  },
  {
    action: 'reset-collar',
    associatedAlarmBehaviour: 'any',
  },
  {
    action: 'power-off',
    associatedAlarmBehaviour: 'any',
  },
  {
    action: 'tag-off',
    associatedAlarmBehaviour: 'any',
  },
  {
    action: 'tag-on-to-find-cow',
    associatedAlarmBehaviour: 'any',
  },
  {
    action: 'remove-alarm',
    associatedAlarmBehaviour: 'event',
  },
];

export const actionConfig: MonitoringActionConfig = {
  alarmActionConfigs,
  getAlarmActionFormByAlarmAction: (alarmAction) => {
    switch (alarmAction as CollarAlarmAction) {
      case 'change-farm':
        return ChangeFarmActionForm;
      case 'power-off':
        return PowerOffActionForm;
      case 'reset-collar':
        return ResetActionForm;
      case 'return-to-halter':
        return ReturnToHalterActionForm;
      case 'snooze':
        return SnoozeActionForm;
      case 'tag-off':
        return TagOffActionForm;
      case 'remove-alarm':
        return RemoveEventAlarmForm;
      case 'tag-on-to-find-cow':
        return TagOnToFindCowForm;
      default:
        return undefined;
    }
  },
};

export const rankConfig: RankConfig<AlarmedCollar> = {
  extraRankSectionConfigs: [farmRankConfig, onFarmStatusRankConfig],
};

export const filterConfig: MonitoringFilterConfig<AlarmedCollarContext> = {
  useFilterOptionsByType: CollarMonitoringService.useAlarmedCollarFilterOptionsByType,
  filterProcessConfig: AlarmedCollarFilterModel.getInstance(),
  filterOptionConfig: AlarmedCollarFilterOptionModel,
  searchFilterTypes: AlarmedCollarFilterModel.SEARCH_FILTERS,
  onOffFilterTypes: AlarmedCollarFilterModel.ON_OFF_FILTERS,
  dateRangeFilterTypes: AlarmedCollarFilterModel.DATE_RANGE_FILTERS,
};

export const tableConfig: TableConfig<AlarmedCollarContext> = {
  columnHelper,
  getColumns: () => columns,
  canCopyColumn: canCopyAlarmedCollarColumnValue,
  extractColumnValueForCopy: extractAlarmedCollarColumnCopyValue,
};

export const queryConfig: QueryConfig<AlarmedCollarContext> = {
  useAlarmEntities: CollarMonitoringService.useCollarAlarmEntities,
  useSnoozeAlarmAction: CollarAlarmActionService.useCollarSnoozeAlarmAction,
};
