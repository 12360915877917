import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ObjectKeyType } from 'src/types';
import { DateRangeFilterState } from 'src/types/filter-types';
import { FilterStateValidator } from 'src/util/filter-state-validator';

type UseDateRangeFiltersSearchParamsProps<K extends ObjectKeyType> = {
  queryParamKey: string;
  setDateRangeFiltersByType: (updatedDateRangeFiltersByType: Partial<Record<K, DateRangeFilterState>>) => void;
};

function useDateRangeFiltersSearchParams<K extends ObjectKeyType>({
  queryParamKey,
  setDateRangeFiltersByType,
}: UseDateRangeFiltersSearchParamsProps<K>) {
  const [searchParams, setSearchParams] = useSearchParams(window.location.search);

  const setDateRangeFilters = useCallback(
    (updatedDateRangeFiltersByType: Partial<Record<K, DateRangeFilterState>>) => {
      if (setDateRangeFiltersByType == null) return;

      setDateRangeFiltersByType(updatedDateRangeFiltersByType);
      const updatedFiltersEncoded = btoa(JSON.stringify(updatedDateRangeFiltersByType));
      setSearchParams((prevSearchParams) => {
        prevSearchParams.set(queryParamKey, updatedFiltersEncoded);
        return prevSearchParams.toString();
      });
    },
    [setDateRangeFiltersByType, queryParamKey, setSearchParams],
  );

  const encodedFilters: string | null = searchParams.get(queryParamKey);

  useEffect(() => {
    if (encodedFilters == null) return;

    try {
      const storedFiltersByType: Record<string, unknown> = JSON.parse(atob(encodedFilters));
      const storedDateRangeFiltersByType: Record<ObjectKeyType, DateRangeFilterState> = {};

      Object.entries(storedFiltersByType).forEach(([key, value]) => {
        if (FilterStateValidator.isDateRangeFilterState(value)) {
          storedDateRangeFiltersByType[key] = value;
        } else {
          console.log('Unknown filter type:', key, value);
        }
      });

      setDateRangeFiltersByType(storedDateRangeFiltersByType);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error parsing stored filters');
    }
  }, [encodedFilters, setDateRangeFiltersByType]);

  return { setDateRangeFilters };
}

export default useDateRangeFiltersSearchParams;
