import { SimpleGrid, SimpleGridProps, Text } from '@chakra-ui/react';
import { FunctionComponent, PropsWithChildren } from 'react';

import { Colors } from 'src/styles';

type ToolboxGridProps = PropsWithChildren<{
  title?: string;
}> &
  SimpleGridProps;

const ToolboxGrid: FunctionComponent<ToolboxGridProps> = ({ title, children, ...rest }) => (
  <SimpleGrid
    flex={1}
    width="100%"
    position="relative"
    px={{ base: '3vw', lg: '2vw' }}
    py={5}
    columnGap={5}
    rowGap={3}
    backgroundColor={Colors.gray50}
    alignContent="flex-start"
    gridTemplateColumns={{ base: 'repeat(auto-fit, minmax(180px, 1fr))', md: 'repeat(auto-fit, 180px)' }}
    borderTop="2px"
    borderColor={Colors.gray100}
    {...rest}
  >
    {title != null && (
      <Text
        gridColumn="1 / -1"
        fontWeight="bold"
        fontSize="sm"
        fontFamily="Inter"
        letterSpacing="wider"
        textTransform="uppercase"
      >
        {title}
      </Text>
    )}
    {children}
  </SimpleGrid>
);

export default ToolboxGrid;
