import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ObjectKeyType } from 'src/types';
import { SearchFilterState } from 'src/types/filter-types';
import { FilterStateValidator } from 'src/util/filter-state-validator';

type UseSearchFiltersSearchParamsProps<K extends ObjectKeyType> = {
  queryParamKey: string;
  searchFiltersByType: Partial<Record<K, SearchFilterState>>;
  setSearchFiltersByType: (updatedSearchFiltersByType: Partial<Record<K, SearchFilterState>>) => void;
};

function useSearchFilterSearchParams<K extends ObjectKeyType>({
  queryParamKey,
  searchFiltersByType,
  setSearchFiltersByType,
}: UseSearchFiltersSearchParamsProps<K>) {
  const [searchParams, setSearchParams] = useSearchParams(window.location.search);

  const setSearchFilters = useCallback(
    (updatedSearchFiltersByType: Partial<Record<K, SearchFilterState>>) => {
      setSearchFiltersByType(updatedSearchFiltersByType);
      const updatedFiltersEncoded = btoa(JSON.stringify(updatedSearchFiltersByType));
      setSearchParams((prevSearchParams) => {
        prevSearchParams.set(queryParamKey, updatedFiltersEncoded);
        return prevSearchParams.toString();
      });
    },
    [setSearchFiltersByType, queryParamKey, setSearchParams],
  );

  const encodedFilters: string | null = searchParams.get(queryParamKey);

  useEffect(() => {
    if (encodedFilters == null) return;

    try {
      const storedFiltersByType: Record<string, unknown> = JSON.parse(atob(encodedFilters));
      const storedSearchFiltersByType: Record<ObjectKeyType, SearchFilterState> = {};

      Object.entries(storedFiltersByType).forEach(([key, value]) => {
        if (FilterStateValidator.isSearchFilterState(value)) {
          storedSearchFiltersByType[key] = value;
        } else {
          console.log('Unknown filter type:', key, value);
        }
      });

      setSearchFiltersByType(storedSearchFiltersByType);
    } catch (error) {
      console.error('Error parsing stored filters');
    }
  }, [encodedFilters, setSearchFiltersByType]);

  return {
    setSearchFilters,
    searchFiltersByType,
  };
}

export default useSearchFilterSearchParams;
