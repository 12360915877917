import { useState } from 'react';

type UseRefreshDataProps = {
  refetchData: () => Promise<void>;
};

function useRefreshData({ refetchData }: UseRefreshDataProps) {
  const [isRefetching, setIsRefetching] = useState(false);

  return {
    refresh: async () => {
      setIsRefetching(true);
      await refetchData();
      setIsRefetching(false);
    },
    isRefetching,
  };
}

export default useRefreshData;
