import { Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import LabelValueGroup from 'src/components/label-value-group';
import LabelWithCopy from 'src/components/labels/label-with-copy';
import { MobsQueries } from 'src/queries';
import { Colors } from 'src/styles';
import { formatStringAsLabel } from 'src/util/string-format.util';

type MobInfoSectionsProps = {
  farmId: string;
  mobId: string;
};

const MobInfoSections: FunctionComponent<MobInfoSectionsProps> = ({ farmId, mobId }) => {
  const { data: mob } = MobsQueries.useMobByFarmId({ farmId, mobId });

  return (
    <>
      <LabelValueGroup
        label="Mob"
        labelColor={Colors.teal600}
      >
        {mob != null ? (
          <LabelWithCopy
            tooltipLabel={`Mob ID: ${mob.id}`}
            text={mob.name}
            copyText={mob.id}
          />
        ) : (
          'None'
        )}
      </LabelValueGroup>
      <LabelValueGroup
        label="Location Status"
        labelColor={Colors.teal600}
      >
        {mob != null ? <Text>{formatStringAsLabel(mob.locationStatus)}</Text> : 'None'}
      </LabelValueGroup>
    </>
  );
};

export default MobInfoSections;
