import { OmitMethods } from './utility-types';

const ModelSymbol = Symbol('Model');

abstract class Model {
  private readonly _ = ModelSymbol;

  static fromObject<T extends Model>(this: new () => T, properties: OmitMethods<T>): T {
    const model = new this();

    Object.assign(model, properties);

    return model;
  }
}

export default Model;
