import { Box, Flex, FlexProps, Tooltip } from '@chakra-ui/react';
import { FC, useState } from 'react';

import CopyClipboardButton from 'src/components/buttons/copy-clipboard-button';

type CopyContentProps = {
  copyContent: string;
  tooltipContent?: string;
  children: React.ReactNode;
  useDarkClipboardButton?: boolean;
} & FlexProps;

const CopyContent: FC<CopyContentProps> = ({
  copyContent,
  tooltipContent,
  children,
  useDarkClipboardButton = false,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Flex
      gap={3}
      alignItems="center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...rest}
    >
      {children}
      <Tooltip
        label={tooltipContent}
        whiteSpace="nowrap"
        fontSize="xs"
        placement="bottom"
      >
        <Box>
          <CopyClipboardButton
            opacity={isHovered ? 1 : 0}
            copyText={copyContent}
            aria-label="Copy button"
            dark={useDarkClipboardButton}
          />
        </Box>
      </Tooltip>
    </Flex>
  );
};

export default CopyContent;
