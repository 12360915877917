import { FlexProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import ContentWithCopy from 'src/components/content-with-copy';
import { Nullable } from 'src/types';

import { AlarmScope, FarmContext } from '../../../@types';

type FarmCellProps = {
  farm: Nullable<FarmContext>;
  matrixLinkDomain: 'general' | 'power';
  /**
   * @summary - The tab to navigate to when the farm cell is clicked.
   * @example - 'towers'
   */
  matrixTab?: string;
} & FlexProps;

const FarmCell: FunctionComponent<FarmCellProps> = ({ farm, matrixLinkDomain, matrixTab, ...rest }) => {
  const { farmId, farmName } = farm ?? {};

  if (farmId == null) return null;

  const searchParams = matrixTab != null ? `?tab=${matrixTab}` : '';
  const url = `/matrix/farm/${farmId}/${matrixLinkDomain}${searchParams}`;

  return (
    <ContentWithCopy
      id={farmId}
      name={farmName}
      scope={AlarmScope.FARM}
      displayNameTooltipLabel
      textProps={{ onClick: () => window.open(url, '_blank'), _hover: { textDecoration: 'underline' } }}
      {...rest}
    />
  );
};

export default FarmCell;
