import { ILocationDTO } from '@halter-corp/infrastructure-service-client';
import { IAlarmDTO } from '@halter-corp/tower-alarm-service-client';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { InfraProduct } from 'src/features/infra-product';
import { Model } from 'src/models';
import { TowerAlarmQueries } from 'src/queries';
import { TowerQueries } from 'src/queries/queries/tower.queries';
import { isNotNull } from 'src/util/is-not-null.util';

import { getTowerType } from '../utils';

export type Location = ILocationDTO;

export enum TowerType {
  T4_MESH = 'T4 Mesh',
  T4_WIFI = 'T4 WiFi',
  T4_STARLINK = 'T4 Starlink',
  T4_OTHER = 'T4 Other',
  T2 = 'T2',
  UNKNOWN = 'Unknown',
}

export class Tower extends Model {
  readonly id!: string;

  readonly networkGroupId!: string;

  readonly location!: Location;

  readonly starlink!: boolean;

  readonly mainsPowered!: boolean;

  readonly router!: boolean;

  readonly infraProducts!: InfraProduct[];

  readonly alarms!: IAlarmDTO[];

  public getTowerType(): TowerType {
    return getTowerType(this);
  }

  public isOffline() {
    if (this.infraProducts.some((infraProduct) => infraProduct.metadata.status?.offline === true)) {
      return true;
    }
    if (this.infraProducts.every((infraProduct) => infraProduct.metadata.status?.offline === undefined)) {
      return undefined;
    }
    return false;
  }

  public isOutOfBattery() {
    if (this.infraProducts.some((infraProduct) => infraProduct.metadata.status?.outOfBattery === true)) {
      return true;
    }
    if (this.infraProducts.every((infraProduct) => infraProduct.metadata.status?.outOfBattery === undefined)) {
      return undefined;
    }
    return false;
  }

  public isFaulty() {
    return this.infraProducts.some((infraProduct) => {
      if (infraProduct.metadata.type !== 'group') return false;
      return infraProduct.metadata.status?.faulty;
    });
  }

  static useFetchByFarmId = (farmId: string) => {
    const towersResponse = TowerQueries.useTowersByFarmId(farmId);
    const towerIds = useMemo(
      () =>
        Object.values(towersResponse.data ?? {})
          .map((tower) => tower.id)
          .filter(isNotNull) ?? [],
      [towersResponse.data],
    );

    const infraProductsResponse = InfraProduct.useFetchByTowerIds(towerIds);

    const { data: towerAlarms } = TowerAlarmQueries.useActionRequiredTowerActiveAlarmsContexts();
    const towerAlarmsById = useMemo(() => {
      return keyBy(towerAlarms, (context) => context.id);
    }, [towerAlarms]);

    const towers = useMemo(() => {
      const infraProducts = infraProductsResponse.data ?? [];

      return Object.values(towersResponse.data ?? {}).map((tower) => {
        const towerInfraProducts = infraProducts.filter((infraProduct) => infraProduct.towerId === tower.id);
        const alarms = towerAlarmsById[tower.id]?.alarms ?? [];

        return Tower.fromObject({
          ...tower,
          infraProducts: towerInfraProducts,
          networkGroupId: crypto.randomUUID(), // Temp
          alarms,
        });
      });
    }, [towersResponse.data, infraProductsResponse.data, towerAlarmsById]);

    return {
      data: towers,
      isLoading: towersResponse.isLoading || infraProductsResponse.isLoading,
      isError: towersResponse.isError || infraProductsResponse.isError,
      error: towersResponse.error ?? infraProductsResponse.error,
    };
  };

  static useRefreshByFarmId = (farmId: string) => {
    const { refetch, isRefetching } = TowerQueries.useTowersByFarmId(farmId);

    return {
      refetch,
      isRefetching,
    };
  };
}
