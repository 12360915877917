import { ComponentStyleConfig } from '@chakra-ui/react';

const badgeTheme: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'full',
    fontSize: 'x-small',
  },
};

export default badgeTheme;
