export const safeParseBoolean = (value: string): boolean | null => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }

  return null;
};

/**
 * @summary Parses a comma-separated string into an array of strings
 *  with whitespace trimmed and empty strings removed.
 */
export const parseCommaSeparatedString = (value: string): string[] => {
  return value.split(',').flatMap((item) => {
    const trimmed = item.trim();
    if (trimmed === '') return [];
    return trimmed;
  });
};
