import { Row } from '@tanstack/react-table';

export const compareRowsByString = <T>(
  rowA: Row<T>,
  rowB: Row<T>,
  extractValue: (entity: T) => string | undefined,
  defaultValue = '',
) => {
  const fieldA = extractValue(rowA.original) ?? defaultValue;
  const fieldB = extractValue(rowB.original) ?? defaultValue;

  return fieldA.trim().toLowerCase() < fieldB.trim().toLowerCase() ? -1 : 1;
};

export const compareRowsByNumber = <T>(rowA: Row<T>, rowB: Row<T>, extractValue: (entity: T) => number) => {
  const fieldA = extractValue(rowA.original);
  const fieldB = extractValue(rowB.original);

  return fieldA - fieldB;
};

export const compareRowsByBoolean = <T>(rowA: Row<T>, rowB: Row<T>, extractValue: (entity: T) => boolean) => {
  const fieldA = extractValue(rowA.original) ? 1 : 0;
  const fieldB = extractValue(rowB.original) ? 1 : 0;

  return fieldA - fieldB;
};
