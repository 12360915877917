import { ComponentStyleConfig } from '@chakra-ui/react';

import { Colors } from '..';

const inputTheme: ComponentStyleConfig = {
  baseStyle: {
    field: {
      backgroundColor: `${Colors.gray100}`,
      border: `1px solid ${Colors.gray300}`,
      boxShadow: 'sm',
      _focus: {
        border: `1px solid ${Colors.blue700}`,
        outline: `1px solid ${Colors.blue700}`,
        outlineOffset: '0px',
        boxShadow: 'md',
      },
      _invalid: {
        border: `1px solid ${Colors.red500}`,
        outline: `1px solid ${Colors.red500}`,
        outlineOffset: '0px',
        boxShadow: 'md',
      },
      _disabled: {
        opacity: 0.7,
      },
    },
  },
  variants: {
    dark: {
      field: {
        backgroundColor: `${Colors.gray800}`,
        border: `1px solid ${Colors.gray600}`,
        boxShadow: 'sm',
        _focus: {
          border: `1px solid ${Colors.blue700}`,
          outline: `1px solid ${Colors.blue700}`,
          outlineOffset: '0px',
          boxShadow: 'md',
        },
        _invalid: {
          border: `1px solid ${Colors.red500}`,
          outline: `1px solid ${Colors.red500}`,
          outlineOffset: '0px',
          boxShadow: 'md',
        },
      },
    },
  },
  defaultProps: {
    variant: null,
  },
};

export default inputTheme;
