import moment from 'moment';

export const SNOOZE_DURATION_OPTIONS = [
  moment.duration(10, 'minute').asMilliseconds(),
  moment.duration(30, 'minute').asMilliseconds(),
  moment.duration(1, 'hour').asMilliseconds(),
  moment.duration(12, 'hour').asMilliseconds(),
  moment.duration(1, 'day').asMilliseconds(),
  moment.duration(2, 'day').asMilliseconds(),
  moment.duration(1, 'week').asMilliseconds(),
  moment.duration(2, 'week').asMilliseconds(),
  moment.duration(1, 'month').asMilliseconds(),
  moment.duration(3.02, 'month').asMilliseconds(), // hack it get it to say 3 months and not 2 month 30 days
  moment.duration(6, 'month').asMilliseconds(),
  moment.duration(1.002, 'year').asMilliseconds(), // hack it get it to say 1 year and not 11 months 30 days
];
