/* eslint-disable react-hooks/rules-of-hooks */
import { Text } from '@chakra-ui/react';
import { Row, createColumnHelper } from '@tanstack/react-table';
import { minBy } from 'lodash';

import MobCell from 'src/modules/monitoring/components/tables/cells/mob.cell';
import { MonitoringContexts } from 'src/modules/monitoring/contexts';
import { formatStringAsLabel } from 'src/util/string-format.util';
import { compareRowsByNumber, compareRowsByString } from 'src/util/table.sorting.util';

import CattleCell from '../../../components/tables/cells/cattle.cell';
import FarmCell from '../../../components/tables/cells/farm.cell';
import FirmwareVersionCell from '../../../components/tables/cells/firmware-version.cell';
import FirstLoggedCell from '../../../components/tables/cells/first-logged.cell';
import SerialNumberCell from '../../../components/tables/cells/serial-number.cell';
import { AlarmedCollar } from '../@types';
import AlarmedCollarDetailsModal from '../components/collar-details/collar-details.modal';
import CollarAlarmsCell from '../components/tables/table-cells/collar-alarms-cell';

export const columnHelper = createColumnHelper<AlarmedCollar>();

export const COLLAR_MONITORING_TABLE_COLUMNS = {
  collarId: 'collar-id',
  status: 'status',
  farm: 'farm',
  mob: 'mob',
  cattle: 'cattle',
  alarms: 'alarms',
  firstLogged: 'first-logged',
  firmwareVersion: 'firmware-version',
};

export const columns = [
  columnHelper.accessor((row) => row, {
    id: COLLAR_MONITORING_TABLE_COLUMNS.collarId,
    sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.entityId),
    cell: (item) => (
      <SerialNumberCell
        serialNumber={item.getValue().entityId}
        detailsModal={AlarmedCollarDetailsModal}
      />
    ),
    header: () => <Text>Collar ID</Text>,
  }),
  columnHelper.accessor((row) => row.context.onFarmStatus, {
    id: COLLAR_MONITORING_TABLE_COLUMNS.status,
    sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.context.onFarmStatus),
    cell: (item) => <Text>{formatStringAsLabel(item.getValue() ?? 'Unknown')}</Text>,
    header: () => <Text>Status</Text>,
  }),
  columnHelper.accessor((row) => row.farm?.farmName, {
    id: COLLAR_MONITORING_TABLE_COLUMNS.farm,
    sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.farm?.farmName),
    sortUndefined: 1, // Undefined values will be sorted with lower priority
    cell: (item) => (
      <FarmCell
        farm={item.row.original.farm}
        matrixLinkDomain="general"
      />
    ),
    header: () => <Text>Farm</Text>,
  }),
  columnHelper.accessor((row) => row.context.mobId, {
    id: COLLAR_MONITORING_TABLE_COLUMNS.mob,
    sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.mobId),
    sortUndefined: 1, // Undefined values will be sorted with lower priority
    cell: (item) => {
      if (item.row.original.mobId == null) return null;
      if (item.row.original.farm == null) return null;
      return (
        <MobCell
          mobId={item.row.original.mobId}
          farmId={item.row.original.farm?.farmId}
        />
      );
    },
    header: () => <Text>Mob</Text>,
  }),
  columnHelper.accessor((row) => row.context.cattle?.cattleName, {
    id: COLLAR_MONITORING_TABLE_COLUMNS.cattle,
    sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.context.cattle?.cattleName),
    sortUndefined: 1, // Undefined values will be sorted with lower priority
    cell: (item) => <CattleCell cattle={item.row.original.context.cattle} />,
    header: () => <Text>Cattle</Text>,
  }),
  columnHelper.accessor((row) => row, {
    id: COLLAR_MONITORING_TABLE_COLUMNS.alarms,
    sortingFn: (rowA, rowB) => compareRowsByNumber(rowA, rowB, (entity) => entity.alarms.length),
    sortUndefined: 1, // Undefined values will be sorted with lower priority
    cell: (item) => {
      const collar = item.getValue();
      const [grafanaPeriod] = MonitoringContexts.useGrafanaPeriod();
      return (
        <CollarAlarmsCell
          serialNumber={collar.entityId}
          farmId={collar.farm?.farmId}
          mobId={collar.context.mobId}
          alarms={collar.alarms}
          grafanaPeriod={grafanaPeriod}
        />
      );
    },
    header: () => <Text>Alarms</Text>,
  }),
  columnHelper.accessor((row) => row.alarms, {
    id: COLLAR_MONITORING_TABLE_COLUMNS.firstLogged,
    sortingFn: (rowA, rowB) =>
      compareRowsByString(rowA, rowB, (entity) => minBy(entity.alarms, (alarm) => alarm.eventDateTime)?.eventDateTime),
    sortUndefined: 1, // Undefined values will be sorted with lower priority
    cell: (item) => <FirstLoggedCell alarms={item.getValue() ?? []} />,
    header: () => <Text>First Logged</Text>,
  }),
  columnHelper.accessor((row) => row.context.firmwareVersion, {
    id: COLLAR_MONITORING_TABLE_COLUMNS.firmwareVersion,
    sortingFn: (rowA, rowB) => compareRowsByString(rowA, rowB, (entity) => entity.context.firmwareVersion),
    sortUndefined: 1, // Undefined values will be sorted with lower priority
    cell: (item) => <FirmwareVersionCell firmwareVersion={item.getValue() ?? ''} />,
    header: () => <Text>Firmware</Text>,
  }),
];

export const canCopyAlarmedCollarColumnValue = (columnId: string) => {
  switch (columnId) {
    case COLLAR_MONITORING_TABLE_COLUMNS.collarId:
    case COLLAR_MONITORING_TABLE_COLUMNS.status:
    case COLLAR_MONITORING_TABLE_COLUMNS.farm:
    case COLLAR_MONITORING_TABLE_COLUMNS.cattle:
    case COLLAR_MONITORING_TABLE_COLUMNS.firmwareVersion:
      return true;
    default:
      return false;
  }
};

export const extractAlarmedCollarColumnCopyValue = (row: Row<AlarmedCollar>, columnId: string): string | undefined => {
  const device = row.original;
  switch (columnId) {
    case COLLAR_MONITORING_TABLE_COLUMNS.collarId:
      return device.entityId;
    case COLLAR_MONITORING_TABLE_COLUMNS.status:
      return device.context.onFarmStatus;
    case COLLAR_MONITORING_TABLE_COLUMNS.farm:
      return device.farm?.farmId;
    case COLLAR_MONITORING_TABLE_COLUMNS.cattle:
      return device.context.cattle?.cattleName;
    case COLLAR_MONITORING_TABLE_COLUMNS.firmwareVersion:
      return device.context.firmwareVersion;
    default:
      return undefined;
  }
};
