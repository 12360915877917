import {
  AlarmActionTypeEnum,
  AlarmFilterTypeEnum,
  IPutAlarmSettingsRequestDTO,
} from '@halter-corp/tower-alarm-service-client';
import { chain } from 'lodash';
import { useMemo } from 'react';
import { GiBarn } from 'react-icons/gi';
import { MdOutlineElectricalServices } from 'react-icons/md';
import { PiFlowArrowFill } from 'react-icons/pi';

import {
  AlarmSettingsCompositeIdentifier,
  GenericAlarmSettings,
  GenericAlarmSettingsRequest,
  TriggerRuleDisplayItem,
} from 'src/modules/monitoring/templates/alarm-settings/@types';
import { TowerAlarmQueries } from 'src/queries';
import { FilterOption } from 'src/types/filter-types';
import { formatStringAsLabel } from 'src/util/string-format.util';

import { TowerAlarmSettingsFilterType, TowerSettingsTriggerRule } from '../@types';
import { TowerAlarmSettingsFilterOptionModel } from '../models/tower-alarm-settings-filter-option.model';

type MutationResponse = {
  status: 'success' | 'error';
  message: string;
  data?: GenericAlarmSettings<TowerSettingsTriggerRule>;
};

export const TowerAlarmSettingsService = {
  useAllAlarmSettings: (): {
    data: GenericAlarmSettings<TowerSettingsTriggerRule>[];
    isLoading: boolean;
    isError: boolean;
  } => {
    const { data = [], isLoading, isError } = TowerAlarmQueries.useAllAlarmSettings();

    return {
      data,
      isLoading,
      isError,
    };
  },

  usePutAlarmSettings: () => {
    const createAlarmSettingsMutation = TowerAlarmQueries.usePutAlarmSettingsMutation();

    return async (request: GenericAlarmSettingsRequest<TowerSettingsTriggerRule>): Promise<MutationResponse> => {
      try {
        const putTowerAlarmSettingsRequest: IPutAlarmSettingsRequestDTO = {
          alarmType: request.alarmType,
          triggerRule: request.triggerRule,
          actionType: TowerAlarmSettingsService.getValidatedActionType(request.actionType),
          filterType: TowerAlarmSettingsService.getValidatedFilterType(request.filterType),
          expiresAt: request.expiresAt,
          context: request.context,
        };
        const alarmSettings = await createAlarmSettingsMutation.mutateAsync(putTowerAlarmSettingsRequest);

        return {
          status: 'success',
          message: 'Alarm settings created successfully',
          data: alarmSettings,
        };
      } catch (error: any) {
        return {
          status: 'error',
          message: error?.response?.data?.message ?? error.message,
        };
      }
    };
  },

  useDeleteAlarmSettings: () => {
    const deleteAlarmSettingsMutation = TowerAlarmQueries.useDeleteAlarmSettingsMutation();

    return async (
      alarmSettingsIdentifier: AlarmSettingsCompositeIdentifier<TowerSettingsTriggerRule>,
    ): Promise<MutationResponse> => {
      try {
        await deleteAlarmSettingsMutation.mutateAsync({
          alarmType: alarmSettingsIdentifier.alarmType,
          triggerRule: alarmSettingsIdentifier.triggerRule,
          expiresAt: alarmSettingsIdentifier.expiresAt,
        });

        return {
          status: 'success',
          message: 'Alarm settings deleted successfully',
        };
      } catch (error: any) {
        return {
          status: 'error',
          message: error?.response?.data?.message ?? error.message,
        };
      }
    };
  },

  useBuildSettingsTriggerRuleItems: () => {
    return (alarmSettings: GenericAlarmSettings<TowerSettingsTriggerRule>): TriggerRuleDisplayItem[] => {
      const { triggerRule } = alarmSettings;
      if (triggerRule == null) return [];

      const triggerRulesListBuilder: TriggerRuleDisplayItem[] = [];

      if (triggerRule.mainsPowered != null) {
        triggerRulesListBuilder.push({
          label: 'Mains Powered',
          value: (triggerRule.mainsPowered as boolean) ? 'On' : 'Off',
          icon: <MdOutlineElectricalServices />,
        });
      }
      if (triggerRule.onLiveFarm != null) {
        triggerRulesListBuilder.push({
          label: 'On Live Farm',
          value: (triggerRule.onLiveFarm as boolean) ? 'Yes' : 'No',
          icon: <GiBarn />,
        });
      }
      if (triggerRule.dependentAlarmType != null) {
        triggerRulesListBuilder.push({
          label: 'Dependent Alarm',
          value: `${formatStringAsLabel(triggerRule.dependentAlarmType)}`,
          icon: <PiFlowArrowFill />,
          helperText: 'Trigger the action/filter if the tower also has the specified dependent alarm.',
        });
      }

      return triggerRulesListBuilder;
    };
  },

  getValidatedActionType: (actionType?: string) => {
    if (actionType == null) return undefined;
    if (Object.values(AlarmActionTypeEnum).includes(actionType as AlarmActionTypeEnum)) {
      return actionType as AlarmActionTypeEnum;
    }

    throw new Error(`Received invalid action type: "${actionType}".`);
  },

  getValidatedFilterType: (filterType?: string) => {
    if (filterType == null) return undefined;
    if (Object.values(AlarmFilterTypeEnum).includes(filterType as AlarmFilterTypeEnum)) {
      return filterType as AlarmFilterTypeEnum;
    }

    throw new Error(`Received invalid filter type: "${filterType}".`);
  },

  useAlarmSettingsFilterOptionsByType: (): Map<TowerAlarmSettingsFilterType, FilterOption<unknown>[]> => {
    const { data } = TowerAlarmQueries.useAllAlarmSettings();
    const uniqueAlarmTypes = useMemo(
      () =>
        chain(data)
          .map(({ alarmType }) => alarmType)
          .uniq()
          .sort()
          .value(),
      [data],
    );

    return useMemo(
      () =>
        new Map([
          [
            TowerAlarmSettingsFilterType.ALARM_TYPE,
            uniqueAlarmTypes.map((alarmType) =>
              TowerAlarmSettingsFilterOptionModel.create(TowerAlarmSettingsFilterType.ALARM_TYPE, alarmType),
            ),
          ],
          [
            TowerAlarmSettingsFilterType.FILTER_TYPE,
            uniqueAlarmTypes.map((filterType) =>
              TowerAlarmSettingsFilterOptionModel.create(TowerAlarmSettingsFilterType.FILTER_TYPE, filterType),
            ),
          ],
        ]),
      [uniqueAlarmTypes],
    );
  },
};
