import { SimpleGrid, SimpleGridProps, Text } from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';

import { getDurationInMillis, getDurationInfo } from 'src/util/duration.util';

import FormikNumberInput from '../forms/formik-number-input';

type DurationInputProps = {
  idPrefix: string;
  label: string;
  defaultDurationInMillis?: number;
  onChange: (durationInMillis: number) => void;
} & Partial<SimpleGridProps>;

/**
 * Custom duration input component
 */
const DurationInput: FunctionComponent<DurationInputProps> = ({
  idPrefix,
  label,
  defaultDurationInMillis,
  onChange,
  ...otherProps
}) => {
  const [durationState, setDurationState] = useState(() => {
    if (defaultDurationInMillis != null) {
      return getDurationInfo(defaultDurationInMillis);
    }
    return {
      days: 0,
      hours: 0,
      minutes: 0,
    };
  });

  const dayInputId = `${idPrefix}-duration-days`;
  const hourInputId = `${idPrefix}-duration-hours`;
  const minuteInputId = `${idPrefix}-duration-minutes`;

  const handleDurationUpdate = (unit: 'days' | 'hours' | 'minutes', value: number) => {
    const validNumberValue = Number.isNaN(value) ? 0 : value;

    setDurationState((prevState) => {
      const updatedState = {
        ...prevState,
        [unit]: validNumberValue,
      };

      const updatedDurationInMillis = getDurationInMillis(updatedState);
      onChange(updatedDurationInMillis);

      return updatedState;
    });
  };

  return (
    <SimpleGrid
      columns={3}
      spacingX={3}
      spacingY={3}
      {...otherProps}
    >
      <Text
        fontWeight={500}
        gridColumn="1/-1"
      >
        {label}
      </Text>

      <FormikNumberInput
        id={dayInputId}
        name="duration-days"
        fontSize="xs"
        numberInputProps={{ min: 0, value: durationState.days }}
        unit="D"
        onChange={(value) => handleDurationUpdate('days', value)}
      />

      <FormikNumberInput
        id={hourInputId}
        name="duration-hours"
        numberInputProps={{ min: 0, max: 23, value: durationState.hours }}
        unit="H"
        onChange={(value) => handleDurationUpdate('hours', value)}
      />

      <FormikNumberInput
        id={minuteInputId}
        name="duration-minutes"
        numberInputProps={{ min: 0, max: 59, value: durationState.minutes }}
        unit="M"
        onChange={(value) => handleDurationUpdate('minutes', value)}
      />
    </SimpleGrid>
  );
};

export default DurationInput;
