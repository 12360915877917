import { FunctionComponent } from 'react';

import FormikTextarea from 'src/components/forms/formik-text-area';

type SerialNumbersTextareaProps = {
  id?: string;
  error: string | undefined;
  onTriggerSubmit?: () => void;
};

const SerialNumbersTextarea: FunctionComponent<SerialNumbersTextareaProps> = ({
  id = 'serial-numbers',
  error,
  onTriggerSubmit,
}) => (
  <FormikTextarea
    id={id}
    mb={5}
    label="Serial Numbers"
    name="serialNumbers"
    textareaProps={{
      placeholder: 'Enter serial numbers separated by comma e.g. 004-1234-12345,004-5678-56789',
      _placeholder: { fontSize: 'sm' },
    }}
    error={error}
    onTriggerSubmit={onTriggerSubmit}
  />
);

export default SerialNumbersTextarea;
