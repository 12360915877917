import { Colors } from 'src/styles';

import { MapScope } from '../modules/map/@types';
import { SCOPE_COLORS } from '../modules/map/constants';

export const getScopeHighlightColor = (scope: MapScope) => {
  return SCOPE_COLORS[scope] ?? Colors.gray200;
};

export const isHexColor = (color: string) => /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color);

const convertHexToRgb = (hex: string) => {
  if (!isHexColor(hex)) {
    throw new Error("Color must be in HEX format, starting with '#'.");
  }

  const colorWithoutHash = hex.slice(1);

  // Convert 3-char HEX to 6-char HEX if needed
  const hexColorOfLength6 =
    colorWithoutHash.length === 3
      ? colorWithoutHash
          .split('')
          .map((char) => char + char)
          .join('')
      : colorWithoutHash;

  // Convert HEX to RGB
  const r = parseInt(hexColorOfLength6.slice(0, 2), 16);
  const g = parseInt(hexColorOfLength6.slice(2, 4), 16);
  const b = parseInt(hexColorOfLength6.slice(4, 6), 16);

  return { r, g, b };
};

const convertRgbToHex = (r: number, g: number, b: number) => {
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

export const lightenHexColor = (color: string, lighterPercent: number): string => {
  if (!isHexColor(color)) {
    throw new Error("Color must be in HEX format, starting with '#'.");
  }
  const { r, g, b } = convertHexToRgb(color);

  // Lighten
  const rLight = Math.max(0, Math.min(255, Math.floor(r * (1 + lighterPercent))));
  const gLight = Math.max(0, Math.min(255, Math.floor(g * (1 + lighterPercent))));
  const bLight = Math.max(0, Math.min(255, Math.floor(b * (1 + lighterPercent))));

  return convertRgbToHex(rLight, gLight, bLight);
};

export const darkenHexColor = (color: string, darkerPercent: number): string => {
  if (!isHexColor(color)) {
    throw new Error("Color must be in HEX format, starting with '#'.");
  }
  const { r, g, b } = convertHexToRgb(color);

  // Darken
  const rDark = Math.max(0, Math.min(255, Math.floor(r * (1 - darkerPercent))));
  const gDark = Math.max(0, Math.min(255, Math.floor(g * (1 - darkerPercent))));
  const bDark = Math.max(0, Math.min(255, Math.floor(b * (1 - darkerPercent))));

  return convertRgbToHex(rDark, gDark, bDark);
};

export const makeHexColorTransparent = (color: string, opacity: number): string => {
  if (!isHexColor(color)) {
    throw new Error("Color must be in HEX format, starting with '#'.");
  }

  const { r, g, b } = convertHexToRgb(color);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
