const ROUTES = {
  Home: '/',

  // TOOL COLLECTION ROUTES
  Documentation: '/documentation',
  DataTools: '/data',
  FleetTools: '/fleet',
  HardwareTools: '/hardware',
  MobileTools: '/mobile',

  // MAP ROUTES
  Map: '/map',
  LiveViewMap: '/map/live-view',
  DebugMap: '/map/debug',
  TowerModellingMap: '/map/tower-modelling',
  FeatureMappingMap: '/map/feature-mapping',

  // FLEET MATRIX
  Matrix: '/matrix',
  GeneralMatrix: '/matrix/fleet/general',
  PowerMatrix: '/matrix/fleet/power',

  // MONITORING ROUTES
  Monitoring: '/monitoring',
  AlarmRegistration: '/monitoring/alarm-registration',
  AlarmRegistrationDetail: (alarmType: string) => `${ROUTES.AlarmRegistration}/${alarmType}`,
  CreateAlarmRegistration: '/monitoring/alarm-registration/new',
  EditAlarmRegistration: (alarmType: string) => `${ROUTES.AlarmRegistration}/${alarmType}/edit`,

  // New collar monitoring route that gets collar alarms from collar alarm service
  CollarMonitoring: '/monitoring/collar',
  CollarAlarmSettings: '/monitoring/collar/alarm-settings',

  FarmOpsMonitoring: '/monitoring/farm-ops',

  WelfareMonitoring: '/monitoring/welfare',
  WelfareAlarmSettings: '/monitoring/welfare/alarm-settings',

  TowerMonitoring: '/monitoring/tower',
  TowerAlarmSettings: '/monitoring/tower/alarm-settings',

  PowerMonitoring: '/monitoring/power',
  PowerAlarmSettings: '/monitoring/power/alarm-settings',

  // TODO: old routes, should be removed.
  FleetMonitoring: '/fleet-monitoring',

  // DOCUMENTATION
  DbtDocs: '/dbt-docs',
  ProtobufDocs: '/protobuf-docs',
  OpenapiDocs: '/openapi-docs',
  OpenapiDocsClient: (clientName: string) => `/openapi-docs/${clientName}`,

  // MINI TOOL ROUTES
  FeatureFlags: '/feature-flags',
  PackageManager: '/package-manager',
  CollarBuildPlan: '/collar-build-plan',
  TowerBuildPlan: '/tower-build-plan',
  CollarHardware: '/collar-hardware',
  RefurbPrep: '/refurb-prep',
  FarmSnapshot: '/farm-snapshot',
  FinanceCollarUsage: '/finance-collar-usage',
  NfcTool: '/nfc-tool',
  Sagemaker: '/sagemaker',
  WarehouseManagment: '/warehouse-managment',
  Dispatch: '/dispatch',
  ReleaseChannel: '/release-channel',
  ShockCurves: '/shock-curves',
};

export default ROUTES;
