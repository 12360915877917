import { ROUTES } from 'src/constants';
import { AlarmActionConfig, MonitoringDomain, MonitoringTemplateConfig } from 'src/modules/monitoring/@types';
import { PAGE_ICONS } from 'src/modules/monitoring/constants';



import { farmRankConfig } from 'src/modules/monitoring/config/rank-config';
import { AlarmedTower, AlarmedTowerContext, TowerAlarmAction } from '../@types';
import SnoozeActionForm from '../components/action-forms/snooze-action-form';
import ExpandedTowerRow from '../components/expanded-tower-row';
import { AlarmedTowerFilterOptionModel } from '../models/alarmed-tower-filter-option.model';
import { AlarmedTowerFilterModel } from '../models/alarmed-tower-filter.model';
import TowerMonitoringService from '../services/tower-monitoring.service';
import { TowerAlarmActionService } from '../tower-alarm-settings/services/tower-alarm-action.service';
import { canCopyColumnValue, columnHelper, columns, extractColumnCopyValue } from './column-config';





const alarmActionConfigs: AlarmActionConfig<TowerAlarmAction>[] = [
  {
    action: 'snooze',
    associatedAlarmBehaviour: 'active',
  },
];

export const TowerMonitoringConfig: MonitoringTemplateConfig<AlarmedTowerContext, AlarmedTower> = {
  title: 'Tower Monitoring',
  domain: MonitoringDomain.TOWER,
  navLinkItems: [
    {
      href: ROUTES.TowerAlarmSettings,
      label: 'Tower Alarm Settings',
      icon: <PAGE_ICONS.AlarmSettings fontSize="1.2rem" />,
    },
  ],
  entityConfig: {
    entityKey: 'tower',
    entityName: 'Tower',
  },
  scopeConfig: { scopeSelectionEnabled: false },
  settingsConfig: {
    alarmSearchModeEnabled: false,
    grafanaSettingsEnabled: true,
  },
  actionConfig: {
    alarmActionConfigs,
    getAlarmActionFormByAlarmAction: (alarmAction) => {
      switch (alarmAction as TowerAlarmAction) {
        case 'snooze':
          return SnoozeActionForm;
        default:
          return undefined;
      }
    },
  },
  filterConfig: {
    useFilterOptionsByType: TowerMonitoringService.useAlarmedTowerFilterOptionsByType,
    filterProcessConfig: AlarmedTowerFilterModel.getInstance(),
    filterOptionConfig: AlarmedTowerFilterOptionModel,
    searchFilterTypes: AlarmedTowerFilterModel.SEARCH_FILTERS,
    onOffFilterTypes: AlarmedTowerFilterModel.ON_OFF_FILTERS,
    dateRangeFilterTypes: AlarmedTowerFilterModel.DATE_RANGE_FILTERS,
  },
  rankConfig: {
    extraRankSectionConfigs: [farmRankConfig],
  },
  tableConfig: {
    columnHelper,
    getColumns: () => columns,
    renderExpandedRow: (row) => <ExpandedTowerRow towerRow={row} />,
    canCopyColumn: canCopyColumnValue,
    extractColumnValueForCopy: extractColumnCopyValue,
  },
  queryConfig: {
    useAlarmEntities: TowerMonitoringService.useTowerAlarmEntities,
    useSnoozeAlarmAction: TowerAlarmActionService.useTowerSnoozeAlarmAction,
  },
};