import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { BsBank2, BsClipboardDataFill } from 'react-icons/bs';
import { FaCameraRotate, FaEarthOceania, FaMicrochip, FaTruck } from 'react-icons/fa6';
import { GoPackage } from 'react-icons/go';
import { HiOutlineDeviceMobile, HiOutlineDocumentSearch } from 'react-icons/hi';
import { IoIosConstruct } from 'react-icons/io';
import { IoFlagOutline, IoGameController, IoHardwareChipSharp } from 'react-icons/io5';
import { RiDashboardFill } from 'react-icons/ri';
import {
  TbBellRingingFilled,
  TbBuildingWarehouse,
  TbDeviceDesktopAnalytics,
  TbTools,
  TbTruckDelivery,
} from 'react-icons/tb';
import { VscRadioTower } from 'react-icons/vsc';

import { ICONS, ICON_URLS } from 'src/assets';
import Header from 'src/components/header';
import Page from 'src/components/page';
import { ROUTES } from 'src/constants';
import NavigationButton from 'src/modules/tool-collections/components/navigation-button';
import ToolboxGrid from 'src/modules/tool-collections/components/toolbox-grid';
import { Colors } from 'src/styles';

import { SagemakerAccessButton } from '../mini-tools/sagemaker';
import ToolCollectionContainer from './components/tool-collection-container';

export const FarmhandToolCollectionScreen = () => (
  <Page title="Home">
    <ToolCollectionContainer>
      <Header
        alignSelf="flex-start"
        heading="Home"
      />

      <Box
        as="main"
        height="fit-content"
        borderTop="2px"
        borderColor={Colors.gray100}
        backgroundColor={Colors.gray50}
        flex={1}
      >
        <ToolboxGrid
          as="section"
          title="Main Tools"
          border="none"
        >
          <NavigationButton
            text="Matrix"
            path={ROUTES.Matrix}
            iconType={RiDashboardFill}
          />
          <NavigationButton
            text="Map"
            path={ROUTES.LiveViewMap}
            iconType={FaEarthOceania}
          />
          <NavigationButton
            text="Monitoring"
            path={ROUTES.CollarMonitoring}
            iconType={TbDeviceDesktopAnalytics}
          />
        </ToolboxGrid>

        <ToolboxGrid
          as="section"
          title="Mini Tools"
          border="none"
        >
          <NavigationButton
            text="Documentation"
            path={ROUTES.Documentation}
            iconType={HiOutlineDocumentSearch}
          />
          <NavigationButton
            text="Data Platform"
            path={ROUTES.DataTools}
            iconType={BsClipboardDataFill}
          />
          <NavigationButton
            text="Fleet"
            path={ROUTES.FleetTools}
            iconType={FaTruck}
          />
          <NavigationButton
            text="Hardware"
            path={ROUTES.HardwareTools}
            iconType={IoHardwareChipSharp}
          />
          <NavigationButton
            text="Mobile"
            path={ROUTES.MobileTools}
            iconType={HiOutlineDeviceMobile}
          />
        </ToolboxGrid>
      </Box>
    </ToolCollectionContainer>
  </Page>
);

export const DataToolCollectionScreen: FC = () => (
  <Page title="Data Tools">
    <ToolCollectionContainer>
      <Header
        alignSelf="flex-start"
        heading="Data"
      />

      <ToolboxGrid as="main">
        <SagemakerAccessButton />
        <NavigationButton
          imageUrl={ICON_URLS.DbtLogoUrl}
          text="Athena DBT"
          path={ROUTES.DbtDocs}
        />
      </ToolboxGrid>
    </ToolCollectionContainer>
  </Page>
);

export const DocumentationCollectionScreen: FC = () => (
  <Page title="Documentation Tools">
    <ToolCollectionContainer>
      <Header
        alignSelf="flex-start"
        heading="Documentation"
      />

      <ToolboxGrid as="main">
        <NavigationButton
          imageUrl={ICON_URLS.DbtLogoUrl}
          text="Athena DBT"
          path={ROUTES.DbtDocs}
        />
        <NavigationButton
          imageUrl={ICON_URLS.ProtobufLogoUrl}
          text="Protobuf"
          path={ROUTES.ProtobufDocs}
        />
        <NavigationButton
          imageUrl={ICON_URLS.OpenapiLogoUrl}
          imageProps={{ boxSize: '80px', mt: -3, mb: 3 }}
          text="Open API"
          path={ROUTES.OpenapiDocs}
        />
      </ToolboxGrid>
    </ToolCollectionContainer>
  </Page>
);

export const FleetToolCollectionScreen: FC = () => (
  <Page title="Fleet Tools">
    <ToolCollectionContainer>
      <Header
        alignSelf="flex-start"
        heading="Fleet"
      />

      <ToolboxGrid as="main">
        <NavigationButton
          text="Warehouse"
          path={ROUTES.WarehouseManagment}
          iconType={TbBuildingWarehouse}
        />
        <NavigationButton
          text="Dispatch"
          path={ROUTES.Dispatch}
          iconType={TbTruckDelivery}
        />
        <NavigationButton
          text="Farm Snapshot"
          path={ROUTES.FarmSnapshot}
          iconType={FaCameraRotate}
        />
        <NavigationButton
          text="Finance Collar Usage"
          path={ROUTES.FinanceCollarUsage}
          iconType={BsBank2}
        />
      </ToolboxGrid>
    </ToolCollectionContainer>
  </Page>
);

export const HardwareToolCollectionScreen: FC = () => (
  <Page title="Hardware Tools">
    <ToolCollectionContainer>
      <Header
        px="2.5vw"
        heading="Hardware"
      />

      <ToolboxGrid as="main">
        <NavigationButton
          text="Collar Build Plan"
          path={ROUTES.CollarBuildPlan}
          iconType={IoIosConstruct}
        />
        <NavigationButton
          text="Collar Hardware"
          path={ROUTES.CollarHardware}
          iconType={ICONS.CollarShape}
          iconProps={{ width: '120px', height: '120px' }}
        />
        <NavigationButton
          text="Tower Build Plan"
          path={ROUTES.TowerBuildPlan}
          iconType={VscRadioTower}
        />
        <NavigationButton
          text="NFC Tool"
          path={ROUTES.NfcTool}
          iconType={IoGameController}
        />
        <NavigationButton
          text="Refurb Prep"
          path={ROUTES.RefurbPrep}
          iconType={TbTools}
        />
        <NavigationButton
          text="Release Channel"
          path={ROUTES.ReleaseChannel}
          iconType={FaMicrochip}
        />
      </ToolboxGrid>
    </ToolCollectionContainer>
  </Page>
);

export const MobileToolCollectionScreen: FC = () => (
  <Page title="Mobile Tools">
    <ToolCollectionContainer>
      <Header heading="Mobile" />

      <ToolboxGrid as="main">
        <NavigationButton
          text="Feature Flags"
          path={ROUTES.FeatureFlags}
          iconType={IoFlagOutline}
        />
        <NavigationButton
          text="Package Manager"
          path={ROUTES.PackageManager}
          iconType={GoPackage}
        />
      </ToolboxGrid>
    </ToolCollectionContainer>
  </Page>
);
