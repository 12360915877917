import { InitialStateApi, UpdateStreamApi } from '@halter-corp/bff-mobile-service-client';

import client from '../client';

const bffApi = {
  state: client.resolveOpenApi(InitialStateApi),
  updateStream: client.resolveOpenApi(UpdateStreamApi),
};

export default bffApi;
