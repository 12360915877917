import { AlarmActionConfig, MonitoringDomain, MonitoringTemplateConfig } from 'src/modules/monitoring/@types';
import { farmRankConfig } from 'src/modules/monitoring/config/rank-config';

import {
  AlarmedCollar,
  AlarmedCollarContext,
  AlarmedCollarFilterModel,
  AlarmedCollarFilterOptionModel,
  ChangeFarmActionForm,
  CollarAlarmAction,
  CollarAlarmActionService,
  SnoozeActionForm,
  TagOffActionForm,
  TagOnToFindCowForm,
  canCopyAlarmedCollarColumnValue,
  columnHelper,
  columns,
  extractAlarmedCollarColumnCopyValue,
  onFarmStatusRankConfig,
} from '../../collar-monitoring';
import FarmOpsMonitoringService from '../services/farm-ops-monitoring.service';

const alarmActionConfigs: AlarmActionConfig<CollarAlarmAction>[] = [
  {
    action: 'snooze',
    associatedAlarmBehaviour: 'active',
  },
  {
    action: 'change-farm',
    associatedAlarmBehaviour: 'any',
  },
  {
    action: 'tag-off',
    associatedAlarmBehaviour: 'any',
  },
  {
    action: 'tag-on-to-find-cow',
    associatedAlarmBehaviour: 'any',
  },
];

export const FarmOpsMonitoringConfig: MonitoringTemplateConfig<AlarmedCollarContext, AlarmedCollar> = {
  title: 'Farm Ops Monitoring',
  domain: MonitoringDomain.FARM_OPS,
  entityConfig: {
    entityKey: 'farm-ops-collar',
    entityName: 'Collar',
  },
  scopeConfig: {
    scopeSelectionEnabled: false,
  },
  settingsConfig: {
    alarmSearchModeEnabled: false,
    grafanaSettingsEnabled: true,
    snoozeAlarmsVisibilityEnabled: true,
  },
  actionConfig: {
    alarmActionConfigs,
    getAlarmActionFormByAlarmAction: (alarmAction) => {
      switch (alarmAction as CollarAlarmAction) {
        case 'change-farm':
          return ChangeFarmActionForm;
        case 'snooze':
          return SnoozeActionForm;
        case 'tag-off':
          return TagOffActionForm;
        case 'tag-on-to-find-cow':
          return TagOnToFindCowForm;
        default:
          return undefined;
      }
    },
  },
  rankConfig: {
    extraRankSectionConfigs: [farmRankConfig, onFarmStatusRankConfig],
  },
  filterConfig: {
    useFilterOptionsByType: FarmOpsMonitoringService.useFarmOpsAlarmFilterOptionsByType,
    filterProcessConfig: AlarmedCollarFilterModel.getInstance(),
    filterOptionConfig: AlarmedCollarFilterOptionModel,
    searchFilterTypes: AlarmedCollarFilterModel.SEARCH_FILTERS,
    onOffFilterTypes: AlarmedCollarFilterModel.ON_OFF_FILTERS,
    dateRangeFilterTypes: AlarmedCollarFilterModel.DATE_RANGE_FILTERS,
  },
  tableConfig: {
    columnHelper,
    getColumns: () => columns,
    canCopyColumn: canCopyAlarmedCollarColumnValue,
    extractColumnValueForCopy: extractAlarmedCollarColumnCopyValue,
  },
  queryConfig: {
    useAlarmEntities: FarmOpsMonitoringService.useFarmOpsAlarmEntities,
    useSnoozeAlarmAction: CollarAlarmActionService.useCollarSnoozeAlarmAction,
  },
};
