import { Flex, FormLabel, Select } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { TbBellSearch } from 'react-icons/tb';

import { Colors } from 'src/styles';
import { formatStringAsLabel } from 'src/util/string-format.util';

import { AlarmSearchMode } from '../../@types';
import { MonitoringContexts } from '../../contexts';

const ALARM_SEARCH_MODE_OPTIONS = Object.values(AlarmSearchMode);

const AlarmSearchModeSelect: FunctionComponent = () => {
  const [alarmSearchMode, setAlarmSearchMode] = MonitoringContexts.useAlarmSearchMode();

  return (
    <Flex flexDir="column">
      <FormLabel
        as="label"
        htmlFor="alarm-search-mode"
        display="flex"
        alignItems="center"
        gap={1}
      >
        <TbBellSearch
          fontSize="1.2em"
          color={Colors.blue600}
        />
        Alarm Search Mode
      </FormLabel>
      <Select
        id="alarm-search-mode"
        name="alarmSearchMode"
        bg={Colors.gray100}
        value={alarmSearchMode}
        onChange={(e) => setAlarmSearchMode(e.target.value as AlarmSearchMode)}
      >
        {ALARM_SEARCH_MODE_OPTIONS.map((mode) => (
          <option
            key={mode}
            value={mode}
          >
            {formatStringAsLabel(mode)}
          </option>
        ))}
      </Select>
    </Flex>
  );
};

export default AlarmSearchModeSelect;
