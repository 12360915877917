export type SearchFilterState = {
  inclusionType: FilterInclusionType;
  unionType?: FilterUnionType;
  selectedOptions: string[];
};

export type DateRangeFilterType = 'absolute' | 'relative';
export type DateRangeFilterState =
  | {
      type: 'absolute';
      startDate?: string; // Format: ISO 8601
      endDate?: string; // Format: ISO 8601
    }
  | {
      type: 'relative';
      relativeTimeInMillis: number; // Relative to current time
    };

export enum FilterInclusionType {
  INCLUDE = 'Include',
  EXCLUDE = 'Exclude',
}

export enum FilterUnionType {
  AND = 'And',
  OR = 'Or',
}

export enum OnOffOption {
  ON = 'On',
  OFF = 'Off',
  ALL = 'All',
}

export interface FilterOption<T> {
  filterType: string;
  filterText: string;
  metadata?: T;
}

export interface FilterOptionConfig {
  create<T>(filterType: string, filterText: string, metadata?: T): FilterOption<T>;

  matchesPattern<T>(filterOption: FilterOption<T>, pattern: string): boolean;

  equals<T>(filterOption: FilterOption<T>, text: string): boolean;

  equalsAny<T>(filterOption: FilterOption<T>, textList: string[]): boolean;

  formatFilterText<T>(filterOption: FilterOption<T>): string;

  formatMetadata<T>(filterOption: FilterOption<T>): string | null;

  formatMetadata<T>(filterOption: FilterOption<T>): string | null;

  formatMetadata<T>(filterOption: FilterOption<T>): string | null;
}
