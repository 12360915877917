import { EnvironmentEnum } from 'src/services/configuration.service';

export function getColorByEnvironment(env: EnvironmentEnum) {
  switch (env) {
    case EnvironmentEnum.PRODUCTION:
      return 'red500';
    case EnvironmentEnum.STAGING:
      return 'blue500';
    default:
      return 'green500';
  }
}

export function getHoverColorByEnvironment(env: EnvironmentEnum) {
  switch (env) {
    case EnvironmentEnum.PRODUCTION:
      return 'red600';
    case EnvironmentEnum.STAGING:
      return 'blue600';
    default:
      return 'green600';
  }
}
