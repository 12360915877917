const Colors = {
  transparent: 'transparent',

  black: '#000000',

  white: '#FFFFFF',
  white50: '#F8F9FA',
  white100: '#EFF2F5',
  white200: '#E5EAEC',

  cyan900: '#085A7A',
  cyan800: '#127B9E',
  cyan700: '#1D9CC2',
  cyan600: '#2AB8E6',
  cyan500: '#37CDFA',
  cyan400: '#44D3FC',
  cyan300: '#63DBFC',
  cyan200: '#9EE8FC',
  cyan100: '#C8F1FC',
  cyan75: '#DEF6FC',
  cyan50: '#F3FAFC',

  orange900: '#8A2C0D',
  orange800: '#A0410D',
  orange700: '#B45309',
  orange600: '#C05621',
  orange500: '#F76F34',
  orange400: '#FC733F',
  orange300: '#FC895E',
  orange200: '#FCB59B',
  orange100: '#FCD4C6',
  orange75: '#FCE4DB',
  orange50: '#FCF4F0',
  orange25: '#FDF8F5',

  teal600: '#018568',
  teal500: '#0C9D7D',
  teal400: '#23BA99',
  teal300: '#4FDCB9',
  teal200: '#93F4DB',
  teal100: '#C1F8EA',
  teal75: '#D8FAF2',
  teal50: '#F0FCF9',

  purple600: '#6B46C1',
  purple500: '#6B4BCC',
  purple400: '#7355D3',
  purple300: '#8D73E4',
  purple200: '#BCAAF6',
  purple100: '#D8CEF9',
  purple75: '#E7E1FB',
  purple50: '#F5F3FC',

  sky25: '#f5fcff',
  sky50: '#f0f9ff',
  sky100: '#e0f2fe',
  sky200: '#bae6fd',
  sky300: '#7dd3fc',
  sky400: '#38bdf8',
  sky500: '#0ea5e9',
  sky600: '#0284c7',
  sky700: '#0369a1',
  sky800: '#075985',
  sky900: '#0c4a6e',

  blue25: '#F5FAFF',
  blue50: '#EFF8FF',
  blue100: '#D1E9FF',
  blue200: '#B2DDFF',
  blue300: '#84CAFF',
  blue400: '#53B1FD',
  blue500: '#2E90FA',
  blue600: '#1570EF',
  blue700: '#175CD3',
  blue800: '#1849A9',
  blue900: '#194185',

  slate25: '#F7F9FA',
  slate50: '#f8fafc',
  slate100: '#f1f5f9',
  slate200: '#E2E8F0',
  slate300: '#CBD5E1',
  slate400: '#94A3B8',
  slate500: '#64748B',
  slate600: '#475569',
  slate700: '#334155',
  slate800: '#1E293B',
  slate900: '#0F172A',
  slate950: '#020617',

  gray25: '#FCFCFD',
  gray50: '#F9FAFB',
  gray100: '#F2F4F7',
  gray200: '#EAECF0',
  gray300: '#D0D5DD',
  gray400: '#98A2B3',
  gray500: '#667085',
  gray600: '#475467',
  gray700: '#344054',
  gray800: '#1D2939',
  gray900: '#101828',

  grey50: '#f5f9ff',
  grey100: '#eef2f8',
  grey200: '#e5e9ef',
  grey300: '#d5d9df',
  grey400: '#b2b6bb',
  grey500: '#92969b',
  grey600: '#6a6d72',
  grey700: '#383b40',
  grey800: '#2D2F33',
  grey900: '#181b1f',

  red25: '#FFFBFA',
  red50: '#FEF3F2',
  red100: '#FEE4E2',
  red200: '#FECDCA',
  red300: '#FDA29B',
  red400: '#F97066',
  red500: '#F04438',
  red600: '#D92D20',
  red700: '#B42318',
  red800: '#912018',
  red900: '#7A271A',

  pink25: '#FFF7FC',
  pink50: '#FDF2F8',
  pink100: '#FCE7F3',
  pink200: '#FBCFE8',
  pink300: '#F9A8D4',
  pink400: '#F472B6',
  pink500: '#EC4899',
  pink600: '#DB2777',
  pink700: '#BE185D',
  pink800: '#9D174D',
  pink900: '#831843',

  amber25: '#fffef6',
  amber50: '#fffbeb',
  amber100: '#fef3c7',
  amber200: '#fde68a',
  amber300: '#fcd34d',
  amber400: '#fbbf24',
  amber500: '#f59e0b',
  amber600: '#d97706',
  amber700: '#b45309',
  amber800: '#92400e',
  amber900: '#78350f',

  yellow25: '#FFFCF5',
  yellow50: '#FFFAEB',
  yellow100: '#FEF0C7',
  yellow200: '#FEDF89',
  yellow300: '#FEC84B',
  yellow400: '#FDB022',
  yellow500: '#F79009',
  yellow600: '#DC6803',
  yellow700: '#B54708',
  yellow800: '#93370D',
  yellow900: '#7A2E0E',

  green25: '#F6FEF9',
  green50: '#ECFDF3',
  green100: '#D1FADF',
  green200: '#A6F4C5',
  green300: '#6CE9A6',
  green400: '#32D583',
  green500: '#12B76A',
  green600: '#039855',
  green700: '#027A48',
  green800: '#05603A',
  green900: '#054F31',

  grafanaBackground: '#111217',
  grafanaPanelBackground: '#181b1f',
} as const;

export default Colors;
