import { Button, Center, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { BsCheckLg } from 'react-icons/bs';
import { FaCaretDown } from 'react-icons/fa';

import { MonitoringDomain } from 'src/modules/monitoring/@types';
import { MONITORING_DOMAIN_PROPERTIES } from 'src/modules/monitoring/constants';
import { Colors } from 'src/styles';
import { darkenHexColor } from 'src/util/color.util';
import { formatStringAsLabel } from 'src/util/string-format.util';

type DomainSelectMenuProps = {
  domain: MonitoringDomain;
  onSelect: (domain: MonitoringDomain) => void;
};

const DomainSelectMenu: FC<DomainSelectMenuProps> = ({ domain: currentDomain, onSelect }) => {
  const style = MONITORING_DOMAIN_PROPERTIES[currentDomain];

  return (
    <Menu offset={[0, 5]}>
      <MenuButton
        as={Button}
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width={{ base: 'fit-content', sm: '200px' }}
        px={{ base: 6, md: 8 }}
        bgColor={darkenHexColor(style.color, 0.2)}
        brightness={0.8}
        _hover={{
          bgColor: darkenHexColor(style.color, 0.3),
          brightness: 1,
        }}
        _active={{
          bgColor: darkenHexColor(style.color, 0.3),
          brightness: 1,
        }}
        fontSize={{ base: 'sm', lg: 'md' }}
        cursor="pointer"
        borderRadius="0px"
      >
        <Center
          as="span"
          columnGap={1}
        >
          <Center
            as="span"
            display={{ base: 'none', sm: 'flex' }}
            mr={2}
            p={2.5}
            bgColor={style.color}
            color={Colors.white}
            borderRadius={100}
            fontSize="1.15em"
          >
            {style?.icon({})}
          </Center>

          <Text
            fontWeight={700}
            color={Colors.gray50}
          >
            {formatStringAsLabel(currentDomain)}
          </Text>

          <FaCaretDown
            style={{ marginLeft: '6px' }}
            color={Colors.gray200}
          />
        </Center>
      </MenuButton>

      <MenuList
        mr={0.5}
        borderRadius={3}
        overflow="hidden"
        bgColor={Colors.gray200}
        border="transparent"
        color={Colors.gray800}
        boxShadow="md"
        p={0}
      >
        {DOMAINS.map((domain) => {
          const Icon = MONITORING_DOMAIN_PROPERTIES[domain]?.icon;

          const isSelected = domain === currentDomain;

          return (
            <MenuItem
              key={domain}
              onClick={() => onSelect(domain)}
              display="flex"
              alignItems="center"
              minW={{ base: '100px', sm: '200px' }}
              columnGap={2}
              p={4}
              cursor="pointer"
              color={Colors.gray800}
              bgColor={isSelected ? Colors.white : Colors.gray200}
              _hover={{ bgColor: Colors.white }}
              _notLast={{ borderBottom: `1px solid ${Colors.gray300}` }}
            >
              <Icon size="22px" />
              <Text fontWeight={600}>{formatStringAsLabel(domain)}</Text>
              {isSelected && (
                <BsCheckLg
                  style={{
                    marginLeft: 'auto',
                    marginRight: '5px',
                  }}
                  fontSize="1.2em"
                />
              )}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

const DOMAINS = Object.values(MonitoringDomain);

export default DomainSelectMenu;
