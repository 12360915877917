import { Flex, FlexProps } from '@chakra-ui/react';
import { FC } from 'react';

import { Alarm, AlarmScope } from 'src/modules/monitoring/@types';
import AlarmBadge from 'src/modules/monitoring/components/badges/alarm.badge';
import { GrafanaPanelEnum, getGrafanaPanelId } from 'src/modules/monitoring/utils/grafana-dashboards.util';
import { AlarmRegistryQueries } from 'src/queries';
import { GrafanaPeriod } from 'src/types';
import { formatStringAsLabel } from 'src/util/string-format.util';

type TowerAlarmGrafanaVariables = {
  towerId?: string;
};

type TowerAlarmBadgeListProps = {
  alarms: Alarm[];
  alarmScope: AlarmScope;
  grafanaVariables: TowerAlarmGrafanaVariables;
  grafanaPeriod: GrafanaPeriod;
} & FlexProps;

const TowerAlarmBadgeList: FC<TowerAlarmBadgeListProps> = ({
  grafanaVariables,
  grafanaPeriod,
  alarms,
  alarmScope,
  ...props
}) => {
  const { data: alarmDefinitionByType } = AlarmRegistryQueries.useAlarmDefinitions();

  return (
    <Flex
      rowGap={1}
      width="min-content"
      flexWrap="wrap"
      alignItems="center"
      {...props}
    >
      {alarms.map((alarm) => {
        const definition = alarmDefinitionByType?.[alarm.type];
        const grafanaPanelIds = definition?.relevantMetricNames?.flatMap(
          (metricName) => getGrafanaPanelId(metricName as GrafanaPanelEnum, alarmScope) ?? [],
        );

        return (
          <AlarmBadge
            key={alarm.type}
            size="sm"
            alarm={alarm}
            formatAlarm={({ type }) => formatStringAsLabel(type)}
            bgColor={definition?.colour}
            dashboardIds={grafanaPanelIds}
            grafanaVariables={grafanaVariables}
            grafanaPeriod={grafanaPeriod}
          />
        );
      })}
    </Flex>
  );
};

export default TowerAlarmBadgeList;
