import { FlexProps } from '@chakra-ui/react';
import { FC } from 'react';

import ContentWithCopy from 'src/components/content-with-copy';
import { MobsQueries } from 'src/queries';

import { AlarmScope } from '../../../@types';

type MobCellProps = {
  mobId: string;
  farmId: string;
} & FlexProps;

const MobCell: FC<MobCellProps> = ({ mobId, farmId, ...rest }) => {
  const { data: mob } = MobsQueries.useMobByFarmId({ farmId, mobId });

  return (
    <ContentWithCopy
      id={mobId}
      name={mob?.name}
      scope={AlarmScope.MOB}
      displayNameTooltipLabel
      {...rest}
    />
  );
};

export default MobCell;
