import {
  ComplexPaddockTypeEnum,
  DraftFeatureCollectionApi,
  FarmDatumsApi,
  FeatureApi,
  FeatureCollectionApi,
  IFeatureDTO,
  IGeoJsonFeatureTypeProperties,
  MappingChangeLogApi,
  PaddockTypeEnum,
  FeaturePropertyTypeEnum as TopographyFeatureTypeEnum,
} from '@halter-corp/topography-service-client';
import { Feature as GeoJsonFeature, Geometry } from 'geojson';

import { RemappedDTO } from 'src/types';

import client from '../client/client';

export type TopographyFeatureDTO = RemappedDTO<
  IFeatureDTO,
  {
    feature: GeoJsonFeature<Geometry, Partial<IGeoJsonFeatureTypeProperties>>;
  }
>;

const TopographyApi = {
  features: client.resolveOpenApi(FeatureApi),
  featureCollections: client.resolveOpenApi(FeatureCollectionApi),
  farmDatums: client.resolveOpenApi(FarmDatumsApi),
  draftFeatureCollections: client.resolveOpenApi(DraftFeatureCollectionApi),
  mappingChangeLog: client.resolveOpenApi(MappingChangeLogApi),
};

export { TopographyFeatureTypeEnum, PaddockTypeEnum, ComplexPaddockTypeEnum };

export default TopographyApi;
