import { Button, Image, ImageProps, Text } from '@chakra-ui/react';
import { FunctionComponent, SVGProps } from 'react';
import { IconType } from 'react-icons';
import { Link, useNavigate } from 'react-router-dom';

import { Colors } from 'src/styles';

type NavigationButtonProps = {
  text: string;
  path: string;
  iconType?: IconType | FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  iconProps?: SVGProps<SVGSVGElement>;
  imageUrl?: string;
  imageProps?: ImageProps;
};

const NavigationButton: FunctionComponent<NavigationButtonProps> = ({
  text,
  path,
  iconType,
  iconProps,
  imageUrl,
  imageProps,
}) => {
  const navigate = useNavigate();

  if (iconType != null && imageUrl != null) {
    throw new Error("NavigationBoxButton component should not receive both 'iconType' and 'imageUrl' props.");
  }

  const IconComponent = iconType;

  return (
    <Button
      as={imageUrl != null ? undefined : Link}
      to={imageUrl != null ? undefined : path}
      onClick={imageUrl != null ? () => navigate(path) : undefined}
      minH="120px"
      minW="180px"
      p="16px"
      boxShadow="1px 1px 2px rgba(0, 0, 0, 0.1)"
      flexDirection="column"
      alignItems="center"
      _hover={imageUrl != null ? undefined : { color: Colors.blue700, bgColor: Colors.gray200 }}
    >
      {iconType != null && IconComponent != null && (
        <IconComponent
          size="100px"
          color={Colors.gray700}
          {...iconProps}
        />
      )}
      {imageUrl != null && (
        <Image
          boxSize="100px"
          objectFit="contain"
          src={imageUrl}
          alt={text}
          mb={4}
          {...imageProps}
        />
      )}
      <Text mt={2}>{text}</Text>
    </Button>
  );
};

export default NavigationButton;
