import { minBy } from 'lodash';
import React, { FunctionComponent } from 'react';

import { Colors } from 'src/styles';

import TimestampLabel from '../../../../../components/labels/timestamp-label';
import { Alarm } from '../../../@types';

type FirstLoggedCellProps = {
  alarms: Alarm[];
};

const FirstLoggedCell: FunctionComponent<FirstLoggedCellProps> = ({ alarms }) => {
  const firstLoggedDate = minBy(alarms, (alarm) => alarm.eventDateTime)?.eventDateTime;
  if (firstLoggedDate == null) return null;

  return (
    <TimestampLabel
      textProps={{ fontSize: '13px', fontWeight: 600, color: Colors.gray500 }}
      timestamp={new Date(firstLoggedDate)}
    />
  );
};

export default FirstLoggedCell;
