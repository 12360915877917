import { Flex, FlexProps, Heading, HeadingProps, Text } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';

import { Colors } from 'src/styles';

type FormSectionWrapperProps = {
  title: string | JSX.Element;
  optional?: boolean;
  headingProps?: HeadingProps;
  children: React.ReactNode;
} & Omit<FlexProps, 'title'>;

const FormSectionWrapper: FunctionComponent<FormSectionWrapperProps> = ({
  title,
  optional = false,
  headingProps,
  children,
  ...rest
}) => (
  <Flex
    flexDirection="column"
    rowGap={3}
    mt={2}
    {...rest}
  >
    <Flex
      gap={2}
      alignItems="flex-end"
    >
      <Heading
        as="h4"
        color={Colors.gray700}
        fontSize="1.1rem"
        {...headingProps}
      >
        {title}
      </Heading>

      {optional && <Text color={Colors.gray500}>(optional)</Text>}
    </Flex>

    {children}
  </Flex>
);

export default FormSectionWrapper;
