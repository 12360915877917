import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import React, { FC } from 'react';

import { GrafanaPeriod } from 'src/types/grafana-types';

import { adjustGrafanaTimestampToByDashboardId } from 'src/util/grafana.util';
import GrafanaPanel from './grafana-panel';

type GrafanaPanelPopoverProps = {
  dashboardIds: string[];
  /**
   * @summary variables that need to be passed to the grafana dashboard URL e.g. serialNumber, mobId etc.
   */
  grafanaVariables?: Record<string, string>;
  grafanaTimestampFrom?: Date;
  grafanaTimestampTo?: Date;
  grafanaPeriod?: GrafanaPeriod;
  /**
   * @summary children that will be a popover trigger.
   */
  children: React.ReactNode;
};

const GrafanaPanelPopover: FC<GrafanaPanelPopoverProps> = ({
  dashboardIds,
  grafanaVariables,
  grafanaTimestampFrom,
  grafanaTimestampTo,
  grafanaPeriod,
  children,
}) => {
  return (
    <Popover
      placement="top-start"
      trigger="hover"
      closeDelay={50}
      offset={[0, 4]}
      isLazy
      lazyBehavior="keepMounted"
      strategy='fixed'
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      {dashboardIds.length > 0 && (
        <PopoverContent
          height={`${dashboardIds.length * 200 + 100}px`}
          width="600px"
          bgColor="rgb(24, 27, 31)"
          borderRadius="15px"
          border="none"
          boxShadow="4px 4px 10px black"
        >
          {dashboardIds.map((dashboardId) => (
            <GrafanaPanel
              key={dashboardId}
              dashboardId={dashboardId}
              panelNumber={1}
              variables={grafanaVariables}
              from={grafanaTimestampFrom}
              to={adjustGrafanaTimestampToByDashboardId(dashboardId, grafanaTimestampTo)}
              grafanaPeriod={grafanaPeriod}
              style={{ zIndex: -1 }}
            />
          ))}
          <PopoverArrow
            bg="rgb(24, 27, 31)"
            border="none"
            boxShadow="none"
            zIndex={10}
          />
        </PopoverContent>
      )}
    </Popover>
  );
};

export default GrafanaPanelPopover;
