import { Flex, Icon, Tooltip } from '@chakra-ui/react';
import { FC } from 'react';

import { Colors } from 'src/styles';

type DebugButtonProps = {
  icon: any;
  debugLink?: string;
  arialLabel?: string;
  tooltipLabel?: string;
};

const DebugButton: FC<DebugButtonProps> = ({ icon, debugLink, arialLabel = 'Quick Debug', tooltipLabel }) => {
  return (
    <Tooltip
      label={tooltipLabel}
      fontSize="xs"
      offset={[0, 10]}
      borderRadius={5}
    >
      <Flex
        as="button"
        px={1}
        py={0.25}
        borderRadius={100}
        bg={Colors.gray500}
        color="white"
        cursor="pointer"
        align="center"
        justify="center"
        height="20.5px"
        aria-label={arialLabel}
        onClick={() => {
          if (debugLink != null) window.open(debugLink, '_blank');
        }}
      >
        <Icon
          as={icon}
          boxSize={3}
        />
      </Flex>
    </Tooltip>
  );
};

export default DebugButton;
