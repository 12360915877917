import { FilterOption, FilterOptionConfig } from 'src/types/filter-types';
import { SearchUtils } from 'src/util/search.util';
import { formatStringAsLabel } from 'src/util/string-format.util';

import { TowerAlarmSettingsFilterType } from '../@types';

export interface TowerAlarmSettingsFilterOption<T> extends FilterOption<T> {
  filterType: TowerAlarmSettingsFilterType;
}

export const TowerAlarmSettingsFilterOptionModel: FilterOptionConfig = {
  create<T>(
    filterType: TowerAlarmSettingsFilterType,
    filterText: string,
    metadata?: T,
  ): TowerAlarmSettingsFilterOption<T> {
    return { filterType, filterText, metadata };
  },

  equals<T>(filterOption: TowerAlarmSettingsFilterOption<T>, text: string): boolean {
    return filterOption.filterText.toLowerCase() === text.trim().toLowerCase();
  },

  equalsAny<T>(filterOption: TowerAlarmSettingsFilterOption<T>, textList: string[]): boolean {
    return textList.some((text) => TowerAlarmSettingsFilterOptionModel.equals(filterOption, text));
  },

  matchesPattern<T>(filterOption: TowerAlarmSettingsFilterOption<T>, pattern: string): boolean {
    switch (filterOption.filterType) {
      case TowerAlarmSettingsFilterType.ALARM_TYPE:
      case TowerAlarmSettingsFilterType.FILTER_TYPE:
        return SearchUtils.matchesAnySearchPattern(formatStringAsLabel(filterOption.filterText), pattern);
      default:
        return SearchUtils.matchesAnySearchPattern(filterOption.filterText, pattern);
    }
  },

  formatFilterText<T>(filterOption: TowerAlarmSettingsFilterOption<T>): string {
    switch (filterOption.filterType) {
      case TowerAlarmSettingsFilterType.ALARM_TYPE:
      case TowerAlarmSettingsFilterType.FILTER_TYPE:
        return formatStringAsLabel(filterOption.filterText);
      default:
        return filterOption.filterText;
    }
  },

  formatMetadata(): string | null {
    return null;
  },
};
