import { Duration, DurationLikeObject } from 'luxon';
import pluralize from 'pluralize';

export function getDurationInMillis(durationSpec: DurationLikeObject): number {
  const { days, hours, minutes, seconds } = durationSpec;
  const duration = Duration.fromObject({
    days,
    hours,
    minutes,
    seconds,
  });

  return duration.as('milliseconds');
}

export function getDurationInfo(durationInMillis: number) {
  const duration = Duration.fromMillis(durationInMillis);

  const days = Math.floor(duration.as('days'));
  const hours = Math.floor(duration.as('hours')) % 24;
  const minutes = Math.floor(duration.as('minutes')) % 60;
  const seconds = Math.floor(duration.as('seconds')) % 60;

  return { days, hours, minutes, seconds };
}

export function formatDuration(millis: number): string {
  const { days, hours, minutes, seconds } = getDurationInfo(millis);

  const durationValues = [];

  if (days > 0) {
    durationValues.push(`${days} ${pluralize('day', days)}`);
  }
  if (hours > 0) {
    durationValues.push(`${hours} ${pluralize('hour', hours)}`);
  }
  if (minutes > 0) {
    durationValues.push(`${minutes} ${pluralize('minute', minutes)}`);
  }
  if (seconds > 0) {
    durationValues.push(`${seconds} ${pluralize('second', seconds)}`);
  }

  const formattedDuration = durationValues.join(', ');

  return formattedDuration;
}
