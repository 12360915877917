import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { BiExpandAlt } from 'react-icons/bi';

import { Optional } from 'src/types';

type ExpandIconButtonProps = Optional<IconButtonProps, 'aria-label'>;

const ExpandIconButton: FunctionComponent<ExpandIconButtonProps> = (props) => (
  <IconButton
    // eslint-disable-next-line react/destructuring-assignment
    aria-label={props['aria-label'] ?? 'Detail'}
    transition="all 0.1s ease-in-out"
    size="sm"
    {...props}
    icon={<BiExpandAlt />}
  />
);

export default ExpandIconButton;
