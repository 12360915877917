import { Flex } from '@chakra-ui/react';
import { isEmpty, partition, sortBy } from 'lodash';
import { FC, useMemo } from 'react';

import EmptyState from 'src/components/empty-state';
import { Colors } from 'src/styles';
import { GrafanaPeriod } from 'src/types';

import { InfraProduct } from '../models/infra-product';
import InfraProductCard from './infra-product.card';

type InfraProductGrouping = {
  group: InfraProduct;
  components: InfraProduct[];
};

type InfraProductGroupsPanelProps = {
  infraProducts: InfraProduct[];
  grafanaPeriod?: GrafanaPeriod;
  grafanaStartTime?: Date;
  grafanaEndTime?: Date;
};

const InfraProductGroupsPanel: FC<InfraProductGroupsPanelProps> = ({
  infraProducts,
  grafanaStartTime,
  grafanaEndTime,
  grafanaPeriod,
}) => {
  const [infraProductGroupMappings, infraProductComponentsWithNoGroup] = useMemo(() => {
    if (infraProducts == null) return [];

    const infraProductGroupMappingMap = new Map<string, InfraProductGrouping>();

    const [allGroupProducts, allComponentProducts] = partition(infraProducts, (infraProduct) => infraProduct.isGroup());
    const componentsWithNoGroup: InfraProduct[] = [];

    for (const groupProduct of allGroupProducts) {
      infraProductGroupMappingMap.set(groupProduct.id, { group: groupProduct, components: [] });
    }

    for (const component of allComponentProducts) {
      const group = infraProductGroupMappingMap.get(component.groupId);
      if (group == null) {
        componentsWithNoGroup.push(component);
        continue;
      }

      group.components.push(component);
    }

    return [[...infraProductGroupMappingMap.values()], componentsWithNoGroup] as [
      InfraProductGrouping[],
      InfraProduct[],
    ];
  }, [infraProducts]);

  const noContent = isEmpty(infraProductGroupMappings) && isEmpty(infraProductComponentsWithNoGroup);
  if (noContent) {
    return (
      <EmptyState
        message="No infra products found"
        color={Colors.gray600}
      />
    );
  }

  // Render group mappings by product id in descending order, masts first then cases
  const orderedInfraProductGroupMappings = sortBy(infraProductGroupMappings, (mapping) => mapping.group.id).reverse();

  return (
    <Flex
      rowGap={4}
      flexDir="column"
    >
      {orderedInfraProductGroupMappings.map(({ group, components }) => (
        <Flex
          key={`infra-product-grouping-${group.id}`}
          flexDir="column"
          rowGap={1}
        >
          <InfraProductCard
            w="full"
            infraProduct={group}
            subInfraProducts={components}
            grafanaStartTime={grafanaStartTime}
            grafanaEndTime={grafanaEndTime}
            grafanaPeriod={grafanaPeriod}
            columnGap={1}
          />
        </Flex>
      ))}

      {!isEmpty(infraProductComponentsWithNoGroup) && (
        <Flex
          rowGap={1}
          flexDir="column"
        >
          {infraProductComponentsWithNoGroup?.map((component) => (
            <InfraProductCard
              key={`infra-product-component-${component.id}`}
              infraProduct={component}
              grafanaStartTime={grafanaStartTime}
              grafanaEndTime={grafanaEndTime}
              grafanaPeriod={grafanaPeriod}
              columnGap={1}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default InfraProductGroupsPanel;
