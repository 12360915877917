import Battery from './hardware/battery.png';
import P4CollarBox from './hardware/box.png';
import CreateBox from './hardware/create_box.png';
import EpeverBatteryController from './hardware/epever_battery_controller.png';
import HalterLedBoard from './hardware/halter_led_board.png';
import HalterSolarSwitch from './hardware/halter_solar_switch.png';
import MikrotikMeshNode from './hardware/mikrotik_mesh_node.png';
import P4Collar from './hardware/p4.png';
import P4DoublePinWeight from './hardware/p4_double_pin_weight.png';
import P4SinglePinWeight from './hardware/p4_single_pin_weight.png';
import RakGateway from './hardware/rak_gateway.png';
import TowerComponent from './hardware/t4.png';
import T4MeshCase from './hardware/t4_mesh_case.png';
import T4MeshMast from './hardware/t4_mesh_mast.png';
import T4MeshSolarPanel from './hardware/t4_mesh_solar_panel.png';
import T4WifiCase from './hardware/t4_wifi_case.png';
import T4WifiMast from './hardware/t4_wifi_mast.png';
import T4WifiSolarPanel from './hardware/t4_wifi_solar_panel.png';
import TektelicGateway from './hardware/tektelic_gateway.png';
import TeltonikaRouter from './hardware/teltonika_router.png';
import UbiquitiSolarSwitch from './hardware/ubiquiti_solar_switch.png';
import Line from './images/line.png';
import LockIcon from './images/lock-icon.png';
import PauseIcon from './images/pause-icon.png';
import Pin from './images/pin.png';
import NewHalterLogoBlack from './logos/halter_logo_black.png';
import LoraWanLogo from './logos/lora_wan_logo.png';
import DarkLayer from './mapbox/dark.png';
import DSMIcon from './mapbox/dsm.png';
import DTMIcon from './mapbox/dtm.png';
import LightLayer from './mapbox/light.png';
import SatelliteLayer from './mapbox/satellite.png';
import StandardLayer from './mapbox/standard.png';
import DbtLogoUrl, { ReactComponent as DbtLogo } from './svgs/dbt-logo.svg';
import { ReactComponent as GrafanaLogo } from './svgs/grafana-logo.svg';
import { ReactComponent as CollarShape } from './svgs/halter-svgs/collar-shape.svg';
import { ReactComponent as HalterLogoWhite } from './svgs/halter-svgs/halter-logo-white.svg';
import OpenapiLogoUrl, { ReactComponent as OpenapiLogo } from './svgs/openapi-logo.svg';
import ProtobufLogoUrl, { ReactComponent as ProtobufLogo } from './svgs/protobuf-logo.svg';
import SagemakerLogoUrl, { ReactComponent as SagemakerLogo } from './svgs/sagemaker-logo.svg';
import { ReactComponent as SweatyCow } from './svgs/sweaty-cow.svg';

export const IMAGES = {
  Line,
  PauseIcon,
  LockIcon,
  Pin,
  Map: {
    DarkLayer,
    LightLayer,
    SatelliteLayer,
    StandardLayer,
    DTMIcon,
    DSMIcon,
  },
  Hardware: {
    P4CollarBox,
    P4Collar,
    P4SinglePinWeight,
    P4DoublePinWeight,
    CreateBox,
    TowerComponent,
    T4MeshCase,
    T4WifiCase,
    T4MeshMast,
    T4WifiMast,
    T4MeshSolarPanel,
    T4WifiSolarPanel,
    RakGateway,
    MikrotikMeshNode,
    TeltonikaRouter,
    Battery,
    TektelicGateway,
    EpeverBatteryController,
    HalterSolarSwitch,
    HalterLedBoard,
    UbiquitiSolarSwitch,
  },
} as const;

export const ICON_URLS = {
  DbtLogoUrl,
  OpenapiLogoUrl,
  ProtobufLogoUrl,
  SagemakerLogoUrl,
};

export const ICONS = {
  CollarShape,
  DbtLogo,
  GrafanaLogo,
  OpenapiLogo,
  ProtobufLogo,
  SagemakerLogo,
  SweatyCow,
  HalterLogoWhite,
  NewHalterLogoBlack,
  LoraWanLogo,
} as const;
