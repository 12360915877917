import { BaseFilterModel } from 'src/models/base-filter.model';
import { GenericAlarmSettings } from 'src/modules/monitoring/templates/alarm-settings/@types';
import { SearchFilterState } from 'src/types/filter-types';

import { PowerAlarmSettingsFilterType, PowerSettingsTriggerRule } from '../@types';

export class PowerAlarmSettingsFilterModel extends BaseFilterModel<
  GenericAlarmSettings<PowerSettingsTriggerRule>,
  PowerAlarmSettingsFilterType
> {
  private static instance: PowerAlarmSettingsFilterModel;

  private constructor() {
    super();
  }

  static getInstance(): PowerAlarmSettingsFilterModel {
    if (this.instance == null) {
      this.instance = new PowerAlarmSettingsFilterModel();
    }
    return this.instance;
  }

  /**
   * Alarm settings filter that have search options such as status, alarms, and farm.
   */
  static SEARCH_ALARM_SETTINGS_FILTERS: PowerAlarmSettingsFilterType[] = [
    PowerAlarmSettingsFilterType.ALARM_TYPE,
    PowerAlarmSettingsFilterType.FILTER_TYPE,
  ];

  static DATE_RANGE_FILTERS: PowerAlarmSettingsFilterType[] = [
    PowerAlarmSettingsFilterType.CREATED_TIME,
    PowerAlarmSettingsFilterType.UPDATED_TIME,
  ];

  getSearchFilterFunction(
    filterType: PowerAlarmSettingsFilterType,
  ): ((item: GenericAlarmSettings<PowerSettingsTriggerRule>, filterState: SearchFilterState) => boolean) | null {
    switch (filterType) {
      case PowerAlarmSettingsFilterType.ALARM_TYPE:
        return (alarmSettings, filterState) =>
          this.filterBySearchFilterState(filterState, (option) => option === alarmSettings.alarmType);
      case PowerAlarmSettingsFilterType.FILTER_TYPE:
        return (alarmSettings, filterState) =>
          this.filterBySearchFilterState(filterState, (option) => option === alarmSettings.filterType);
      default:
        return null;
    }
  }

  getOnOffFilterFunction() {
    return null;
  }

  getDateRangeFilterFunction(filterType: PowerAlarmSettingsFilterType) {
    switch (filterType) {
      case PowerAlarmSettingsFilterType.CREATED_TIME:
        return this.getDateRangeFilterMatcher(({ context }) => new Date(context.createdAt));
      case PowerAlarmSettingsFilterType.UPDATED_TIME:
        return this.getDateRangeFilterMatcher(({ context }) => new Date(context.updatedAt));
      default:
        return null;
    }
  }
}
