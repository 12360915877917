/* eslint-disable no-await-in-loop */
import {
  AlarmActionTypeEnum,
  IAlarmSettingsDTO,
  ICreatePowerAlarmActionRequestDTO,
  ICreatePowerAlarmSubscriptionRequestDTO,
  IDeleteAlarmSettingsRequestDTO,
  IPowerActiveAlarmsContextDTO,
  IPutAlarmSettingsRequestDTO,
} from '@halter-corp/power-alarm-service-client';
import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { PowerAlarmApi } from 'src/data';
import { AlarmSettingsUtils } from 'src/modules/monitoring';

import client from '../query-client';

type DeleteAlarmSettingsRequestIdentifier = IDeleteAlarmSettingsRequestDTO & {
  expiresAt?: string;
};

const POWER_ALARM_KEY = 'power-alarm';

export const PowerAlarmQueryKeys = {
  powerActiveAlarmContexts: [POWER_ALARM_KEY, 'power-active-alarms-contexts'] as const,
  alarmSettingsList: [POWER_ALARM_KEY, 'alarm-settings'] as const,
};

export type PowerAlarmQueriesState = {
  powerActiveAlarmContexts: IPowerActiveAlarmsContextDTO[];
  powerAlarmSettingsList: IAlarmSettingsDTO[];
};

export const PowerAlarmQueries = {
  // Queries
  useActionRequiredPowerActiveAlarmsContexts: (options?: Partial<UseQueryOptions<IPowerActiveAlarmsContextDTO[]>>) =>
    useQuery({
      queryKey: PowerAlarmQueryKeys.powerActiveAlarmContexts,
      queryFn: async () => {
        let paginationKey: string | undefined;

        const powerActiveAlarmContexts: IPowerActiveAlarmsContextDTO[] = [];
        do {
          const { data } = await PowerAlarmApi.powerActiveAlarm.getActionRequiredPowerActiveAlarmsContexts(
            paginationKey,
          );
          powerActiveAlarmContexts.push(...data.items);
          paginationKey = data.nextReferenceId;
        } while (paginationKey != null);

        return powerActiveAlarmContexts;
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      ...options,
    }),

  useAllAlarmSettings: (options?: Partial<UseQueryOptions<IAlarmSettingsDTO[]>>) =>
    useQuery({
      queryKey: PowerAlarmQueryKeys.alarmSettingsList,
      queryFn: async () => {
        const { data } = await PowerAlarmApi.alarmSettings.getAllAlarmSettings();
        return data;
      },
      staleTime: 10_000,
      ...options,
    }),

  // Mutations
  useCreatePowerAlarmSubscriptionMutation: () =>
    useMutation({
      mutationFn: async (request: ICreatePowerAlarmSubscriptionRequestDTO) => {
        const { data } = await PowerAlarmApi.powerAlarmSubscription.createPowerAlarmSubscription(request);
        return data;
      },
    }),

  useCreatePowerAlarmActionMutation: () =>
    useMutation({
      mutationFn: async (request: ICreatePowerAlarmActionRequestDTO) => {
        const { data } = await PowerAlarmApi.alarmAction.createPowerAlarmAction(request);
        return data;
      },
      onSuccess: (alarmAction) => {
        switch (alarmAction.actionType) {
          case AlarmActionTypeEnum.SNOOZE:
            PowerAlarmQueries.removePowerActiveAlarmsContextFromCache(alarmAction.referenceId);
            break;
        }
      },
    }),

  usePutAlarmSettingsMutation: () =>
    useMutation({
      mutationFn: async (request: IPutAlarmSettingsRequestDTO) => {
        const { data: alarmSettings } = await PowerAlarmApi.alarmSettings.putAlarmSettings(request);
        return alarmSettings;
      },
      onSuccess: PowerAlarmQueries.setAlarmSettingsToCache,
    }),

  useDeleteAlarmSettingsMutation: () =>
    useMutation({
      mutationFn: async (request: DeleteAlarmSettingsRequestIdentifier) => {
        await PowerAlarmApi.alarmSettings.deleteAlarmSettings(request);
        return { ...request };
      },
      onSuccess: PowerAlarmQueries.removeAlarmSettingsFromCache,
    }),

  // Cache
  removePowerActiveAlarmsContextFromCache: (idToRemove: string) => {
    const queryKey = PowerAlarmQueryKeys.powerActiveAlarmContexts;

    const currentPowerActiveAlarmsContexts = client.getClientData<'powerActiveAlarmContexts'>(queryKey);
    if (currentPowerActiveAlarmsContexts == null) return;

    const updatedPowerActiveAlarmsContexts = currentPowerActiveAlarmsContexts.filter(
      (context) => context.referenceId !== idToRemove,
    );

    client.setClientData<'powerActiveAlarmContexts'>(queryKey, updatedPowerActiveAlarmsContexts);
  },

  setAlarmSettingsToCache: (alarmSettings: IAlarmSettingsDTO) => {
    const currentAlarmSettingsList = client.getClientData<'powerAlarmSettingsList'>(
      PowerAlarmQueryKeys.alarmSettingsList,
    );
    if (currentAlarmSettingsList == null) return;

    const updatedAlarmSettingsList = currentAlarmSettingsList
      .filter((as) => AlarmSettingsUtils.areAlarmSettingsEqual(as, alarmSettings) === false)
      .concat(alarmSettings);

    client.setClientData<'powerAlarmSettingsList'>(PowerAlarmQueryKeys.alarmSettingsList, updatedAlarmSettingsList);
  },

  removeAlarmSettingsFromCache: (alarmSettingsIdentifier: DeleteAlarmSettingsRequestIdentifier) => {
    const currentAlarmSettingsList = client.getClientData<'powerAlarmSettingsList'>(
      PowerAlarmQueryKeys.alarmSettingsList,
    );
    if (currentAlarmSettingsList == null) return;

    const updatedAlarmSettingsList = currentAlarmSettingsList.filter(
      (as) => AlarmSettingsUtils.areAlarmSettingsEqual(as, alarmSettingsIdentifier) === false,
    );

    client.setClientData<'powerAlarmSettingsList'>(PowerAlarmQueryKeys.alarmSettingsList, updatedAlarmSettingsList);
  },
};
