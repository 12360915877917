/* eslint-disable no-await-in-loop */
import {
  FleetTrackingCheckpointEnum,
  IDeviceBoxRecordDTO,
  IDeviceOffFarmCheckpointDTO,
  IDeviceOnFarmStatesContextDTO,
  IFindDeviceOffFarmCheckpointsRequestDTO,
  IFleetTrackingDTO,
  INonSerialProductInventoryDTO,
  IPutNonSerialProductInventoryRequestDTO,
  ISetDevicesOffFarmCheckpointReachedRequestDTO,
  ISetFleetTrackingRequestDTO,
} from '@halter-corp/fleet-service-client';
import { UseQueryOptions, useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { chunk, keyBy } from 'lodash';
import moment from 'moment';

import { FleetApi } from 'src/data/api';

import { queryClient } from '..';

const FLEET_KEY = 'fleet';

const DEVICE_BOX_RECORD_KEY = 'device-box-records';
const FLEET_TRACKING_KEY = 'fleet-trackings';
const NON_SERIAL_PRODUCT_INVENTORY_KEY = 'non-serial-product-inventories';

export const FleetQueryKeys = {
  deviceOffFarmCheckpointsBySerialNumbers: (serialNumbers: string[]) =>
    [FLEET_KEY, 'device-off-farm-checkpoints', serialNumbers] as const,
  deviceOffFarmCheckpointsBySerialNumber: (serialNumber: string) =>
    [FLEET_KEY, 'device-off-farm-checkpoints', serialNumber] as const,
  deviceOnFarmStatesBySerialNumber: (serialNumber: string) =>
    [FLEET_KEY, 'device-on-farm-states', serialNumber] as const,
  deviceOnFarmStatesByFarmId: (farmId: string) => [FLEET_KEY, 'device-on-farm-states', 'farm', farmId] as const,
  deviceBoxRecordsByBoxId: (boxId: string) => [FLEET_KEY, 'device-box-records', 'box-id', boxId] as const,
  deviceBoxRecordsBySerialNumbers: (serialNumbers: string[]) =>
    [FLEET_KEY, DEVICE_BOX_RECORD_KEY, 'serial-numbers', serialNumbers] as const,
  fleetTrackingsByCheckpointAndLocationId: (checkpoint: FleetTrackingCheckpointEnum, locationId: string) =>
    [FLEET_KEY, FLEET_TRACKING_KEY, 'checkpoint', checkpoint, 'location-id', locationId] as const,
  nonSerialProductInventoriesByLocationId: (locationId: string) =>
    [FLEET_KEY, NON_SERIAL_PRODUCT_INVENTORY_KEY, 'location-id', locationId] as const,
};

export type FleetQueriesState = {
  deviceOffFarmCheckpointBySerialNumber: Record<string, IDeviceOffFarmCheckpointDTO>;
  deviceOffFarmCheckpoint: IDeviceOffFarmCheckpointDTO;
  deviceOnFarmStatesContext: IDeviceOnFarmStatesContextDTO;
  deviceOnFarmStatesContexts: IDeviceOnFarmStatesContextDTO[];
  deviceBoxRecords: IDeviceBoxRecordDTO[];
  fleetTrackings: IFleetTrackingDTO[];
  nonSerialProductInventories: INonSerialProductInventoryDTO[];
};

export const FleetQueries = {
  // Queries
  useLatestOffFarCheckpointsBySerialNumbersQuery: <TData = FleetQueriesState['deviceOffFarmCheckpointBySerialNumber']>(
    serialNumbers: string[],
    options?: Omit<
      Partial<UseQueryOptions<FleetQueriesState['deviceOffFarmCheckpointBySerialNumber'], unknown, TData>>,
      'queryKey'
    >,
  ) =>
    useQuery<FleetQueriesState['deviceOffFarmCheckpointBySerialNumber'], unknown, TData>({
      queryKey: FleetQueryKeys.deviceOffFarmCheckpointsBySerialNumbers(serialNumbers),
      queryFn: async () => {
        const request: IFindDeviceOffFarmCheckpointsRequestDTO = {
          serialNumbers,
        };
        const { data } = await FleetApi.deviceOffFarmApi.findLatestOffFarmCheckpointsForDevices(request);

        return keyBy(data.items, (device) => device.serialNumber);
      },
      ...options,
    }),

  useDeviceOffFarmCheckpointBySerialNumberQuery: <TData = FleetQueriesState['deviceOffFarmCheckpoint']>(
    serialNumber: string,
    options?: Omit<Partial<UseQueryOptions<FleetQueriesState['deviceOffFarmCheckpoint'], unknown, TData>>, 'queryKey'>,
  ) =>
    useQuery<FleetQueriesState['deviceOffFarmCheckpoint'], unknown, TData>({
      queryKey: FleetQueryKeys.deviceOffFarmCheckpointsBySerialNumber(serialNumber),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      queryFn: async () => {
        const { data } = await FleetApi.deviceOffFarmApi.findLatestOffFarmCheckpointsForDevices({
          serialNumbers: [serialNumber],
        });
        return data.items[0] ?? null;
      },
      ...options,
    }),

  useDeviceOnFarmStatesByFarmId: <TData = FleetQueriesState['deviceOnFarmStatesContexts']>(
    farmId: string,
    options?: Omit<
      Partial<UseQueryOptions<FleetQueriesState['deviceOnFarmStatesContexts'], unknown, TData>>,
      'queryKey'
    >,
  ) =>
    useQuery<FleetQueriesState['deviceOnFarmStatesContexts'], unknown, TData>({
      queryKey: FleetQueryKeys.deviceOnFarmStatesByFarmId(farmId),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      queryFn: async () => {
        const deviceOnFarmStatesContexts: IDeviceOnFarmStatesContextDTO[] = [];
        let nextSerialNumber: string | undefined;

        do {
          const { data } = await FleetApi.deviceOnFarmApi.findOnFarmStatesForDevices({ farmId });
          deviceOnFarmStatesContexts.push(...data.items);
          nextSerialNumber = data.nextSerialNumber;
        } while (nextSerialNumber != null);

        return deviceOnFarmStatesContexts;
      },
      ...options,
    }),

  useDeviceOnFarmStatesBySerialNumberQuery: <TData = FleetQueriesState['deviceOnFarmStatesContext']>(
    serialNumber: string,
    options?: Omit<
      Partial<UseQueryOptions<FleetQueriesState['deviceOnFarmStatesContext'], unknown, TData>>,
      'queryKey'
    >,
  ) =>
    useQuery<FleetQueriesState['deviceOnFarmStatesContext'], unknown, TData>({
      queryKey: FleetQueryKeys.deviceOnFarmStatesBySerialNumber(serialNumber),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      queryFn: async () => {
        const { data } = await FleetApi.deviceOnFarmApi.findDeviceOnFarmStatesBySerialNumber(serialNumber);
        return data;
      },
      ...options,
    }),

  useFleetTrackingsByCheckpointAndLocationId: <TData = FleetQueriesState['fleetTrackings']>(
    checkpoint: FleetTrackingCheckpointEnum,
    locationId: string,
    options?: Omit<Partial<UseQueryOptions<FleetQueriesState['fleetTrackings'], unknown, TData>>, 'queryKey'>,
  ) =>
    useQuery<FleetQueriesState['fleetTrackings'], unknown, TData>({
      queryKey: FleetQueryKeys.fleetTrackingsByCheckpointAndLocationId(checkpoint, locationId),
      queryFn: async () => {
        const { data } = await FleetApi.fleetTrackingApi.findAllFleetTrackingsByCheckpointAndLocationId(
          checkpoint,
          locationId,
        );
        return data;
      },
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchInterval: moment.duration(1, 'minutes').asMilliseconds(),
      ...options,
    }),

  useDeviceBoxRecordsByBoxId: <TData = FleetQueriesState['deviceBoxRecords']>(
    boxId: string,
    options?: Omit<Partial<UseQueryOptions<FleetQueriesState['deviceBoxRecords'], unknown, TData>>, 'queryKey'>,
  ) =>
    useQuery<FleetQueriesState['deviceBoxRecords'], unknown, TData>({
      queryKey: FleetQueryKeys.deviceBoxRecordsByBoxId(boxId),
      queryFn: async () => {
        const { data } = await FleetApi.deviceBoxRecordApi.findAllDeviceBoxRecordsByBoxId(boxId);
        return data;
      },
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      ...options,
    }),

  useDeviceBoxRecordsByBoxIds: <TData = FleetQueriesState['deviceBoxRecords']>(
    boxIds: string[],
    options?: Partial<UseQueryOptions<FleetQueriesState['deviceBoxRecords'], unknown, TData>>,
  ) =>
    useQueries({
      queries: boxIds.map((boxId) => ({
        queryKey: FleetQueryKeys.deviceBoxRecordsByBoxId(boxId),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryFn: async () => {
          const { data } = await FleetApi.deviceBoxRecordApi.findAllDeviceBoxRecordsByBoxId(boxId);
          return data;
        },
        ...options,
      })),
    }),

  useDeviceBoxRecordsBySerialNumbers: <TData = FleetQueriesState['deviceBoxRecords']>(
    serialNumbers: string[],
    options?: Omit<Partial<UseQueryOptions<FleetQueriesState['deviceBoxRecords'], unknown, TData>>, 'queryKey'>,
  ) =>
    useQuery<FleetQueriesState['deviceBoxRecords'], unknown, TData>({
      queryKey: FleetQueryKeys.deviceBoxRecordsBySerialNumbers(serialNumbers),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      queryFn: async () => {
        const { data } = await FleetApi.deviceBoxRecordApi.searchDeviceBoxRecords({ serialNumbers });
        return data;
      },
      ...options,
    }),

  useNonSerialProductInventories: <TData = FleetQueriesState['nonSerialProductInventories']>(
    locationId: string,
    options?: Omit<
      Partial<UseQueryOptions<FleetQueriesState['nonSerialProductInventories'], unknown, TData>>,
      'queryKey'
    >,
  ) =>
    useQuery<FleetQueriesState['nonSerialProductInventories'], unknown, TData>({
      queryKey: FleetQueryKeys.nonSerialProductInventoriesByLocationId(locationId),
      queryFn: async () => {
        const { data } = await FleetApi.nonSerialProductInventoryApi.findAllNonSerialProductInventoriesByLocationId(
          locationId,
        );
        return data;
      },
      ...options,
    }),

  // Mutations
  useSetOffFarmCheckpointMutation: () =>
    useMutation({
      mutationFn: async (request: ISetDevicesOffFarmCheckpointReachedRequestDTO) => {
        await FleetApi.deviceOffFarmApi.setDevicesOffFarmCheckpointReached(request);
      },
    }),

  useSetFleetTrackingMutation: () =>
    useMutation({
      mutationFn: async (request: ISetFleetTrackingRequestDTO) => {
        const { data } = await FleetApi.fleetTrackingApi.setFleetTrackings(request);
        return data;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([FLEET_KEY, FLEET_TRACKING_KEY]);
      },
    }),

  usePutBatchDeviceBoxRecordsMutation: () =>
    useMutation({
      mutationFn: async (requests: IDeviceBoxRecordDTO[]) => {
        for (const requestChunk of chunk(requests, 20)) {
          await Promise.all(requestChunk.map((request) => FleetApi.deviceBoxRecordApi.putDeviceBoxRecord(request)));
        }
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([FLEET_KEY, DEVICE_BOX_RECORD_KEY]);
      },
    }),

  usePutNonSerialProductInventoryMutation: () =>
    useMutation({
      mutationFn: async (request: IPutNonSerialProductInventoryRequestDTO) => {
        await FleetApi.nonSerialProductInventoryApi.putNonSerialProductInventory(request);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([FLEET_KEY, NON_SERIAL_PRODUCT_INVENTORY_KEY]);
      },
    }),
};
