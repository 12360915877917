import { Flex, Text } from '@chakra-ui/react';
import { AlarmActionTypeEnum } from '@halter-corp/collar-alarm-service-client';
import { FC } from 'react';
import { BsExclamationCircle } from 'react-icons/bs';
import { HiOutlineLightBulb } from 'react-icons/hi';
import * as yup from 'yup';

import { AlarmActionFormProps } from 'src/modules/monitoring/@types';
import BaseCollarActionForm from 'src/modules/monitoring/components/alarm-action-forms/collar-action-forms/base-collar-action-form';
import { Colors } from 'src/styles';
import { SERIAL_NUMBERS_INPUT_PATTERN, extractSerialNumbersFromPattern } from 'src/util/collar.util';

import { CollarAlarmActionService } from '../../services/collar-alarm-action.service';

const tagOnToFindCowActionSchema = yup.object().shape({
  serialNumbers: yup
    .string()
    .required()
    .matches(SERIAL_NUMBERS_INPUT_PATTERN, 'Each serial number should have valid format saparated by a comma'),
  comments: yup.string(),
});

type TagOnToFindCowFormValues = {
  serialNumbers: string;
  comments: string;
};

const TagOnToFindCowForm: FC<AlarmActionFormProps> = ({
  onBack,
  onClose,
  selectedIds: selectedSerialNumbers,
  selectedEventAlarmEntities,
  onSuccessfulSubmit,
}) => {
  const { sendBatchActionRequests } = CollarAlarmActionService.useBatchCollarAlarmActions<object>({
    actionType: AlarmActionTypeEnum.TAG_ON_TO_FIND_COW,
    onSuccess: () => removeCollarEventAlarms(selectedEventAlarmEntities),
  });
  const removeCollarEventAlarms = CollarAlarmActionService.useBatchRemoveCollarEventAlarmActions({
    onSuccess: onSuccessfulSubmit,
  });

  const handleSubmit = async ({ serialNumbers: serialNumbersString, comments }: TagOnToFindCowFormValues) => {
    const serialNumbers = extractSerialNumbersFromPattern(serialNumbersString);
    await sendBatchActionRequests(serialNumbers, {}, comments);
  };

  return (
    <BaseCollarActionForm
      title="Tag On To Find Cow"
      description={
        <Flex
          flexDir="column"
          rowGap={2}
          fontSize="sm"
        >
          <Text>
            <Flex
              as="span"
              display="inline-flex"
              alignItems="center"
            >
              <HiOutlineLightBulb
                fontSize="1.3em"
                color={Colors.yellow600}
                style={{ display: 'inline' }}
              />
            </Flex>
            &nbsp;This action will assign these collars to an arbitrary cow named{' '}
            <Text
              as="strong"
              color={Colors.gray700}
            >
              Find Me N
            </Text>{' '}
            (N is a number). This is to help farmers find and re-assign collars.
          </Text>
          <Text
            display="flex"
            alignItems="center"
          >
            <BsExclamationCircle
              fontSize="1.1em"
              color={Colors.orange600}
              style={{ display: 'inline' }}
            />
            &nbsp;The collars should be unassigned to cow before making the request.
          </Text>
        </Flex>
      }
      initialValues={{
        serialNumbers: selectedSerialNumbers.join(', '),
        comments: '',
      }}
      validationSchema={tagOnToFindCowActionSchema}
      onSubmit={handleSubmit}
      onBack={onBack}
      onClose={onClose}
      selectedEventAlarmCount={selectedEventAlarmEntities.length}
    />
  );
};

export default TagOnToFindCowForm;
