import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import GrafanaPanel from 'src/components/grafana-panel';
import { Alarm, AlarmScope } from 'src/modules/monitoring/@types';
import { GrafanaPanelEnum, getGrafanaPanelId } from 'src/modules/monitoring/utils/grafana-dashboards.util';
import { AlarmRegistryQueries } from 'src/queries';
import { Colors } from 'src/styles';
import { getRelativeTimestamp } from 'src/util/date-time.util';
import { formatStringAsLabel } from 'src/util/string-format.util';

type AlarmPanelsProps = {
  alarm: Alarm;
  serialNumber: string;
  mobId?: string;
};

const AlarmPanels: FunctionComponent<AlarmPanelsProps> = ({ alarm, serialNumber, mobId }) => {
  const { data: alarmDefinition } = AlarmRegistryQueries.useAlarmDefinitionByAlarmType(alarm.type);
  const grafanaPanelIds = alarmDefinition?.relevantMetricNames?.flatMap(
    (metricName) => getGrafanaPanelId(metricName as GrafanaPanelEnum, AlarmScope.COLLAR) ?? [],
  );

  if (grafanaPanelIds == null) return null;

  return (
    <Flex
      flexDir="column"
      gap={2}
    >
      <Flex
        gap={2}
        alignItems="center"
      >
        <Text
          fontWeight={600}
          color={alarmDefinition?.colour ?? Colors.gray500}
        >
          {formatStringAsLabel(alarm.type)}
        </Text>

        <Text
          fontSize="xs"
          fontWeight={700}
          color={Colors.gray500}
        >
          ({getRelativeTimestamp(new Date(alarm.eventDateTime))})
        </Text>
      </Flex>

      <SimpleGrid
        gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        rowGap={2}
        columnGap={2}
      >
        {grafanaPanelIds.map((dashboardId) => (
          <Box
            key={dashboardId}
            height="250px"
            bgColor="rgb(24, 27, 31)"
            borderRadius="15px"
            boxShadow="lg"
          >
            <GrafanaPanel
              key={dashboardId}
              dashboardId={dashboardId}
              panelNumber={1}
              variables={{ serialNumber, mobId: mobId ?? '' }}
            />
          </Box>
        ))}
      </SimpleGrid>
    </Flex>
  );
};

export default AlarmPanels;
