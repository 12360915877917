import { capitalize } from 'lodash';

export function formatStringAsLabel(value: string): string {
  // Format: FALLEN_OFF_COW -> Fallen Off Cow
  // Split by dash, underscore, or space
  const words = value.split(/[-_\s]/);
  const formatted = words.map((word) => capitalize(word.toLowerCase())).join(' ');

  return formatted;
}

export const formatAsScreamingSnakeCase = (value: string) => {
  // Format: fallen off cow -> FALLEN_OFF_COW
  return value
    .trim()
    .split(/[\s-]+/)
    .join('_')
    .toUpperCase();
};

export const getInitials = (name: string) =>
  name
    .split(' ')
    .slice(0, 2)
    .map((word) => {
      const characters = [...word];
      return characters[0]?.toUpperCase();
    })
    .join('');
