import { Configuration, CreateApi } from '@halter-corp/cloud-rf-client';

const toolsConfiguration = new Configuration({
  basePath: 'https://soothsayer-0.tools.halter.io',
});

const stagingToolsConfiguration = new Configuration({
  basePath: 'https://soothsayer-0.staging-tools.halter.io',
});

const CloudRFApi = {
  toolsCreateApi: new CreateApi(toolsConfiguration),
  stagingToolsCreateApi: new CreateApi(stagingToolsConfiguration),
};

export default CloudRFApi;
