import { Center, CenterProps, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { RxValueNone } from 'react-icons/rx';

type EmptyStateProps = {
  message: string;
} & CenterProps;

const EmptyState: FC<EmptyStateProps> = ({ message, ...props }) => {
  return (
    <Center
      flexDir="column"
      rowGap={3}
      height="100%"
      {...props}
    >
      <RxValueNone fontSize="3rem" />
      <Text>{message}</Text>
    </Center>
  );
};

export default EmptyState;
