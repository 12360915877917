import { Box, Flex, Heading, Stack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FC, PropsWithChildren } from 'react';

import BackButton from 'src/components/buttons/back-button';
import { Colors } from 'src/styles';

import { slideRight } from './animations';

type ActionFormWrapperProps = PropsWithChildren<{
  title: string;
  onBack: () => void;
}>;

const ActionFormWrapper: FC<ActionFormWrapperProps> = ({ title, onBack, children }) => {
  return (
    <Box
      key="form-action"
      as={motion.div}
      p={4}
      flex={1}
      overflowY="auto"
      variants={slideRight}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <Stack
        mx={6}
        my={1}
        color={Colors.gray600}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Heading
            as="h3"
            fontSize="lg"
          >
            {title}
          </Heading>
          <BackButton onBack={onBack} />
        </Flex>

        {children}
      </Stack>
    </Box>
  );
};

export default ActionFormWrapper;
