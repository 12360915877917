import { FlexProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import ContentWithCopy from 'src/components/content-with-copy';

import { AlarmScope, CattleContext } from '../../../@types';

type CattleCellProps = {
  cattle: CattleContext | undefined;
} & FlexProps;

const CattleCell: FunctionComponent<CattleCellProps> = ({ cattle, ...rest }) => {
  const { cattleId, cattleName } = cattle ?? {};

  return (
    <ContentWithCopy
      id={cattleId ?? ''}
      name={cattleName}
      scope={AlarmScope.COW}
      displayNameTooltipLabel
      {...rest}
    />
  );
};

export default CattleCell;
