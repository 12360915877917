import { Variants } from 'framer-motion';

export const slideLeft: Variants = {
  hidden: { opacity: 0, x: -100, position: 'absolute' },
  show: {
    opacity: 1,
    x: 0,
    position: 'static',
    transition: { type: 'tween', ease: 'easeInOut', duration: 0.18, delay: 0.12 },
  },
  exit: { opacity: 0, x: -100, position: 'absolute', transition: { type: 'tween', ease: 'easeInOut', duration: 0.3 } },
};

export const slideRight: Variants = {
  hidden: { opacity: 0, x: 100, position: 'absolute' },
  show: {
    opacity: 1,
    x: 0,
    position: 'static',
    transition: { type: 'tween', ease: 'easeInOut', duration: 0.18, delay: 0.12 },
  },
  exit: { opacity: 0, x: 100, position: 'absolute', transition: { type: 'tween', ease: 'easeInOut', duration: 0.3 } },
};
