import { DomainNameEnum } from '@halter-corp/alarm-registry-service-client';
import { IoIosAlarm, IoMdSettings } from 'react-icons/io';
import { LiaHatCowboySideSolid } from 'react-icons/lia';
import { MdHealthAndSafety, MdOutlineCellTower } from 'react-icons/md';
import { RiBatteryChargeLine } from 'react-icons/ri';
import { TbBellRingingFilled } from 'react-icons/tb';

import { ICONS } from 'src/assets';
import { ROUTES } from 'src/constants';
import { Colors } from 'src/styles';

import { AlarmBehaviour, MonitoringDomain } from '../@types';

// TODO: Should be a better way to do this.
export const COMMON_FILTER_TYPE = {
  ENTITY_ID: 'Entity ID',
  FARM: 'Farm',
  ALARMS: 'Alarms',
};

export const MONITORING_DOMAIN_ICONS = {
  Collar: ICONS.CollarShape,
  FarmOps: LiaHatCowboySideSolid,
  Welfare: MdHealthAndSafety,
  Tower: MdOutlineCellTower,
  Power: RiBatteryChargeLine,
};

export const MONITORING_DOMAIN_PROPERTIES = {
  [MonitoringDomain.COLLAR]: {
    color: Colors.blue700,
    icon: MONITORING_DOMAIN_ICONS.Collar,
    domain: MonitoringDomain.COLLAR,
    route: ROUTES.CollarMonitoring,
  },
  [MonitoringDomain.FARM_OPS]: {
    color: Colors.purple600,
    icon: MONITORING_DOMAIN_ICONS.FarmOps,
    domain: MonitoringDomain.FARM_OPS,
    route: ROUTES.FarmOpsMonitoring,
  },
  [MonitoringDomain.WELFARE]: {
    color: Colors.red600,
    icon: MONITORING_DOMAIN_ICONS.Welfare,
    domain: MonitoringDomain.WELFARE,
    route: ROUTES.WelfareMonitoring,
  },
  [MonitoringDomain.TOWER]: {
    color: Colors.slate700,
    icon: MONITORING_DOMAIN_ICONS.Tower,
    domain: MonitoringDomain.TOWER,
    route: ROUTES.TowerMonitoring,
  },
  [MonitoringDomain.POWER]: {
    color: Colors.orange600,
    icon: MONITORING_DOMAIN_ICONS.Power,
    domain: MonitoringDomain.POWER,
    route: ROUTES.PowerMonitoring,
  },
};

/**
 * @summary The maximum number of entities that can be selected for an action at once.
 */
export const MAX_ENTITY_COUNT_FOR_ACTION = 1_000;

export const ALARM_BEHAVIOURS: AlarmBehaviour[] = ['active', 'event'];

/**
 * @summary Special alarm type that can be used for any alarm types e.g. filtering for any alarm types.
 *  It is treated as a wildcard.
 */
export const ANY_ALARM_TYPE = 'ANY';

export const PAGE_ICONS = {
  AlarmSettings: IoMdSettings,
  AlarmRegistraion: TbBellRingingFilled,
};

// TODO: Remove.
// This is a tempoarary hack to distinguish collar alarms and farm ops alarms
// since both collar alarms and farm ops alarms are coming from collar alarm service.
export const FARM_OPS_ALARM_TYPES = new Set([
  'POSITION_OUTSIDE_FARM_BOUNDARY',
  'COLLAR_OFF_COW',
  'TAGGED_OFF_BUT_HIGH_MOVEMENT',
  'POSITION_FAR_FROM_DATUM',
  'PROVISIONED_TO_WRONG_FARM',
  'COLLAR_BACKWARDS',
  'TAGGED_ON_BUT_NO_CATTLE_NAME',
]);

export * from './snooze-duration';
