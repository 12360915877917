import { useContext, useMemo } from 'react';

import { useEnvironment } from 'src/providers';
import { useEntityFilterContext } from 'src/providers/entity-filter/hooks';
import { AuthService } from 'src/services';
import { EnvironmentEnum } from 'src/services/configuration.service';

import { AlarmedEntity, MonitoringFilterConfig, QueryConfig, RankConfig, TableConfig } from '../@types';
import useRefreshData from '../hooks/use-refresh-data';
import { EntityConfigContext } from './entity-config.context';
import { MonitoringActionConfigContext } from './monitoring-action-config.context';
import { MonitoringFilterConfigContext } from './monitoring-filter-config.context';
import { MonitoringGlobalStateContext } from './monitoring-global-state.context';
import { QueryConfigContext } from './query-config.context';
import { RankConfigContext } from './rank-config.context';
import { ScopeConfigContext } from './scope-config.context';
import { SettingsConfigContext } from './settings-config.contex';
import { TableConfigContext } from './table-config.context';

const useEntityConfigContext = () => useContext(EntityConfigContext);
const useScopeConfigContext = () => useContext(ScopeConfigContext);
const useMonitoringActionConfigContext = () => useContext(MonitoringActionConfigContext);
const useQueryConfigContext = <T>() => useContext(QueryConfigContext) as QueryConfig<T>;
const useMonitoringFilterConfigContext = <T>() =>
  useContext(MonitoringFilterConfigContext) as MonitoringFilterConfig<T>;
const useMonitoringGlobalStateContext = () => useContext(MonitoringGlobalStateContext);
const useTableConfigContext = <T>() => useContext(TableConfigContext) as TableConfig<T>;
const useRankConfigContext = <T>() => useContext(RankConfigContext) as RankConfig<T>;
const useSettingConfigContext = () => useContext(SettingsConfigContext);

export const MonitoringContexts = {
  // ENTITY CONFIG CONTEXT HOOKS
  useEntityConfigContext: () => {
    const context = useEntityConfigContext();
    if (context == null) throw new Error('Entity config context was not provided.');

    return context;
  },

  // SCOPE CONFIG HOOKS
  useScopeConfigContext: () => {
    const context = useScopeConfigContext();
    if (context == null) throw new Error('Scope config context was not provided.');

    return context;
  },

  // ACTION CONFIG HOOKS
  useMonitoringActionConfigContext: () => {
    const context = useMonitoringActionConfigContext();
    if (context == null) throw new Error('Action config context was not provided');

    return context;
  },

  /**
   * @summary Retrieves a list of actions that are allowed for the user based on their email.
   * In non-production environments, all actions are allowed.
   */
  useAllowedActionConfigsForUser: () => {
    const { alarmActionConfigs } = MonitoringContexts.useMonitoringActionConfigContext();
    const { environment } = useEnvironment();
    const { email } = AuthService.useAuth();

    return useMemo(() => {
      if (environment !== EnvironmentEnum.PRODUCTION) return alarmActionConfigs;

      return alarmActionConfigs.filter((alarmAction) => {
        if (alarmAction.allowedUserEmails == null) return true;
        return alarmAction.allowedUserEmails.includes(email);
      });
    }, [alarmActionConfigs, environment, email]);
  },

  /**
   * @summary Retrieves a list of actions that are allowed for the selected alarms based on their alarm behaviour.
   */
  useAllowedActionsForUserAndSelectedAlarms: <T>(selectedAlarms: AlarmedEntity<T>[]) => {
    const alarmActionsAllowedForUser = MonitoringContexts.useAllowedActionConfigsForUser();

    return useMemo(() => {
      const hasNoSelection = selectedAlarms.length === 0;
      const selectedAlarmBehaviours = selectedAlarms.map((entity) => entity.alarmBehaviour);

      return alarmActionsAllowedForUser.flatMap((actionConfig) => {
        if (actionConfig.associatedAlarmBehaviour === 'any') return actionConfig.action;
        // NOTE: if the user hasn't selected anything, show all active alarm actions that can be taken without explicit selection of alarms.
        if (actionConfig.associatedAlarmBehaviour === 'active' && hasNoSelection) return actionConfig.action;
        if (selectedAlarmBehaviours.includes(actionConfig.associatedAlarmBehaviour)) return actionConfig.action;
        return [];
      });
    }, [alarmActionsAllowedForUser, selectedAlarms]);
  },

  // GLOBAL STATE HOOKS
  useAlarmSearchMode: () => {
    const context = useMonitoringGlobalStateContext();
    if (context == null) throw new Error('Monitoring global state context was not provided');

    return context.useAlarmSearchMode();
  },

  useGrafanaPeriod: () => {
    const context = useMonitoringGlobalStateContext();
    if (context == null) throw new Error('Monitoring global state context was not provided');

    return context.useGrafanaPeriod();
  },

  useItemsPerPage: () => {
    const context = useMonitoringGlobalStateContext();
    if (context == null) throw new Error('Monitoring global state context was not provided');

    return context.useItemsPerPage();
  },

  useSnoozeAlarmVisibility: () => {
    const context = useMonitoringGlobalStateContext();
    if (context == null) throw new Error('Monitoring global state context was not provided');

    return context.useSnoozeAlarmsVisibility();
  },

  useSelectedAlarmBehaviour: () => {
    const context = useMonitoringGlobalStateContext();
    if (context == null) throw new Error('Monitoring global state context was not provided');

    return context.useSelectedAlarmBehaviour();
  },

  useSelectedScope: () => {
    const context = useMonitoringGlobalStateContext();
    if (context == null) throw new Error('Monitoring global state context was not provided');

    return context.useSelectedScope();
  },

  useAlarmKeySelection: () => {
    const context = useMonitoringGlobalStateContext();
    if (context == null) throw new Error('Monitoring global state context was not provided');

    return context.useAlarmKeySelection();
  },

  // FILTER CONFIG CONTEXT HOOKS
  useMonitoringFilterConfigContext: () => {
    const context = useMonitoringFilterConfigContext();
    if (context == null) throw new Error('Filter config context was not provided.');

    return context;
  },

  useFilterOptionsByType: () => {
    const context = useMonitoringFilterConfigContext();
    if (context == null) throw new Error('Filter context was not provided.');

    return context.useFilterOptionsByType();
  },

  // FILTER CONTEXT HOOKS
  useAlarmEntityFilterContext: <C>() => {
    const filterContext = useEntityFilterContext<AlarmedEntity<C>>();
    const { isLoading, isError } = MonitoringContexts.useAlarmEntities<C>();
    const { selectedScope } = MonitoringContexts.useSelectedScope();

    return {
      ...filterContext,
      isLoading,
      isError,
      // NOTE: filtered items for any scopes e.g. farm, mob, cow, collar etc.
      filteredItems: filterContext.filteredItems,
      // NOTE: filtered items for currently selected scope.
      filteredItemsForSelectedScope: useMemo(() => {
        if (selectedScope == null) return filterContext.filteredItems;
        return filterContext.filteredItems.filter((item) => item.scope === selectedScope);
      }, [filterContext.filteredItems, selectedScope]),
    };
  },

  useFilteredAlarmedEntitiesForSelectedScope: <C>() => {
    const filterContext = useEntityFilterContext<AlarmedEntity<C>>();
    const { selectedScope } = MonitoringContexts.useSelectedScope();

    const scopeFilteredItems = useMemo(() => {
      if (selectedScope == null) return filterContext.filteredItems;
      return filterContext.filteredItems.filter((item) => item.scope === selectedScope);
    }, [filterContext.filteredItems, selectedScope]);

    return scopeFilteredItems;
  },

  useSearchFilters: <T>() => {
    const context = MonitoringContexts.useAlarmEntityFilterContext<T>();
    if (context == null) throw new Error('Filter context was not provided.');

    return context.useSearchFilters();
  },

  useOnOffFilters: <T>() => {
    const context = MonitoringContexts.useAlarmEntityFilterContext<T>();
    if (context == null) throw new Error('Filter context was not provided.');

    return context.useOnOffFilters();
  },

  useDateRangeFilters: <T>() => {
    const context = MonitoringContexts.useAlarmEntityFilterContext<T>();
    if (context == null) throw new Error('Filter context was not provided.');

    return context.useDateRangeFilters();
  },

  // QUERY CONFIG CONTEXT HOOKS
  useSnoozeAlarmAction: () => {
    const context = useQueryConfigContext();
    if (context == null) throw new Error('Monitoring query config context was not provided.');

    return context.useSnoozeAlarmAction?.();
  },

  useAlarmEntities: <C>() => {
    const context = useQueryConfigContext<C>();
    if (context == null) throw new Error('Monitoring query config context was not provided.');

    // Note: getting config for data fetching
    const [searchMode] = MonitoringContexts.useAlarmSearchMode();

    return context.useAlarmEntities({ searchMode });
  },

  useRefetchAlarmedEntities: <T>() => {
    const { refetchData } = MonitoringContexts.useAlarmEntities<T>();
    return useRefreshData({
      refetchData: async () => {
        await refetchData();
      },
    });
  },

  // TABLE CONFIG CONTEXT HOOKS
  useTableConfigContext: <T>() => {
    const context = useTableConfigContext<T>();
    if (context == null) throw new Error('Table config context was not provided');

    return context;
  },

  // RANK CONFIG CONTEXT HOOKS
  useRankConfigContext: () => {
    const context = useRankConfigContext();
    if (context == null) throw new Error('Rank config context was not provided');

    return context;
  },

  // SETTINGS CONFIG CONTEXT HOOKS
  useSettingConfigContext: () => {
    const context = useSettingConfigContext();
    if (context == null) throw new Error('Settings config context was not provided');

    return context;
  },
};
