import { DeviceOnFarmStatusEnum } from '@halter-corp/fleet-service-client';

import { AlarmedEntity, CattleContext } from '../../../@types';

// ENUMS & TYPES
//-----------------------------------------------------

export type CollarAlarmAction =
  | 'return-to-halter'
  | 'snooze'
  | 'change-farm'
  | 'reset-collar'
  | 'power-off'
  | 'remove-alarm'
  | 'tag-off'
  | 'tag-on-to-find-cow';

export enum AlarmedCollarFilterType {
  // SEARCH
  ENTITY_ID = 'Entity ID',
  FARM = 'Farm',
  ALARMS = 'Alarms',
  STATUS = 'Status',
  CATTLE = 'Cattle',
  BATCH = 'Batch',
  FIRMWARE = 'Firmware',
  ALARM_BEHAVIOUR = 'Alarm Behaviour',
  RELEASE_CHANNEL = 'Release Channel',

  // ON/OFF
  PROVISIONED_TO_FARM = 'Provisioned To Farm',
  TAGGED_ON_CATTLE = 'Tagged On Cattle',
  BATCH_84_PLUS_COLLARS = 'Batch 84+ Collars',

  // TIME RANGE
  FIRST_LOGGED_DATE = 'First Logged Date',
  LAST_LOGGED_DATE = 'Last Logged Date',
}

export enum AlarmCategory {
  POWER = 'POWER',
  COLLAR = 'COLLAR',
  COMMS = 'COMMS',
  COWG = 'COWG',
  BIT_ERROR = 'BIT_ERROR',
  FIRMWARE = 'FIRMWARE',
  HARDWARE = 'HARDWARE',
  DUTY_CYCLE = 'DUTY_CYCLE',
  UNKNOWN = 'UNKNOWN',
}

// COLLAR ALARMS
//-----------------------------------------------------

export type AlarmedCollarContext = {
  onFarmStatus?: DeviceOnFarmStatusEnum;
  cattle?: CattleContext;
  mobId?: string;
  batch?: string;
  firmwareVersion?: string;
  releaseChannel?: string;
};

export type AlarmedCollar = AlarmedEntity<AlarmedCollarContext>;
