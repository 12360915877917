import { BaseFilterModel } from 'src/models/base-filter.model';
import { OnOffOption, SearchFilterState } from 'src/types/filter-types';
import { SearchUtils } from 'src/util/search.util';
import { createSingleton } from 'src/util/singleton.util';

import { WelfareAlarmEntity, WelfareAlarmFilterType } from '../@types';

export class WelfareAlarmFilterModel extends BaseFilterModel<WelfareAlarmEntity, WelfareAlarmFilterType> {
  private constructor() {
    super();
  }

  static getInstance = createSingleton(() => new WelfareAlarmFilterModel());

  static SEARCH_FILTERS: WelfareAlarmFilterType[] = [
    WelfareAlarmFilterType.ALARMS,
    WelfareAlarmFilterType.ALARM_BEHAVIOUR,
    WelfareAlarmFilterType.SCOPE,
    WelfareAlarmFilterType.FARM,
  ];

  static ON_OFF_ALARM_SETTINGS_FILTERS: WelfareAlarmFilterType[] = [WelfareAlarmFilterType.CRITICAL];

  static DATE_RANGE_FILTERS: WelfareAlarmFilterType[] = [
    WelfareAlarmFilterType.FIRST_LOGGED_DATE,
    WelfareAlarmFilterType.LAST_LOGGED_DATE,
  ];

  private SEARCH_FILTER_FUNCTIONS_BY_FILTER_TYPE: Record<
    string,
    (alarmedEntity: WelfareAlarmEntity, filterState: SearchFilterState) => boolean
  > = {
    [WelfareAlarmFilterType.ALARMS]: (alarmedEntity, filterState) =>
      this.filterBySearchFilterState(filterState, (option) =>
        alarmedEntity.alarms.map((alarm) => alarm.type).includes(option),
      ),
    [WelfareAlarmFilterType.ALARM_BEHAVIOUR]: (alarmedEntity, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => alarmedEntity.alarmBehaviour === option),
    [WelfareAlarmFilterType.FARM]: (alarmedEntity, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === alarmedEntity.farm?.farmName),
    [WelfareAlarmFilterType.SCOPE]: (alarmedEntity, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === alarmedEntity.scope),
    [WelfareAlarmFilterType.ENTITY_ID]: (alarmedEntity, filterState) =>
      this.filterBySearchFilterState(filterState, (searchText) =>
        SearchUtils.matchesAnySearchPattern(alarmedEntity.entityId, searchText),
      ),
  };

  private createOnOffFilterFunction =
    (extractValue: (alarmedEntity: WelfareAlarmEntity) => boolean | undefined) =>
    (alarmedEntity: WelfareAlarmEntity, onOffOption: OnOffOption) => {
      if (onOffOption === OnOffOption.ON) return extractValue(alarmedEntity) === true;
      if (onOffOption === OnOffOption.OFF) return extractValue(alarmedEntity) === false;
      // If ALL, filter the alarm settings with the specific criteria being existed
      if (onOffOption === OnOffOption.ALL) return extractValue(alarmedEntity) != null;
      return false;
    };

  private ON_OFF_FILTER_FUNCTIONS_BY_FILTER_TYPE: Record<
    string,
    (alarmedEntity: WelfareAlarmEntity, onOffOption: OnOffOption) => boolean
  > = {
    [WelfareAlarmFilterType.CRITICAL]: this.createOnOffFilterFunction((alarmedEntity) =>
      alarmedEntity.alarms.map((alarm) => alarm.type).some((type) => type.toLowerCase().includes('critical')),
    ),
  };

  getSearchFilterFunction(filterType: WelfareAlarmFilterType) {
    return this.SEARCH_FILTER_FUNCTIONS_BY_FILTER_TYPE[filterType];
  }

  getOnOffFilterFunction(filterType: WelfareAlarmFilterType) {
    return this.ON_OFF_FILTER_FUNCTIONS_BY_FILTER_TYPE[filterType];
  }

  getDateRangeFilterFunction(filterType: WelfareAlarmFilterType) {
    switch (filterType) {
      case WelfareAlarmFilterType.FIRST_LOGGED_DATE:
        return this.getDateRangeFilterMatcher((item) => item.firstLoggedDate);
      case WelfareAlarmFilterType.LAST_LOGGED_DATE:
        return this.getDateRangeFilterMatcher((item) => item.lastLoggedDate);
      default:
        return null;
    }
  }
}
