import { FilterOption, FilterOptionConfig } from 'src/types/filter-types';
import { SearchUtils } from 'src/util/search.util';
import { formatStringAsLabel } from 'src/util/string-format.util';

import { AlarmedCollarFilterType } from '../@types';

export interface AlarmedCollarFilterOption<T> extends FilterOption<T> {
  filterType: AlarmedCollarFilterType;
}

export const AlarmedCollarFilterOptionModel: FilterOptionConfig = {
  create<T>(filterType: AlarmedCollarFilterType, filterText: string, metadata?: T): AlarmedCollarFilterOption<T> {
    return { filterType, filterText, metadata };
  },

  equals<T>(filterOption: AlarmedCollarFilterOption<T>, text: string): boolean {
    switch (filterOption.filterType) {
      case AlarmedCollarFilterType.FARM:
        const farmFilterOption = filterOption as AlarmedDeviceFarmFilterOption;
        const equalFarmName = farmFilterOption.filterText.toLowerCase() === text.trim().toLowerCase();
        const equalFarmId = farmFilterOption.metadata.farmId.toLowerCase() === text.trim().toLowerCase();
        return equalFarmName || equalFarmId;
      default:
        return filterOption.filterText.toLowerCase() === text.trim().toLowerCase();
    }
  },

  equalsAny<T>(filterOption: AlarmedCollarFilterOption<T>, textList: string[]): boolean {
    return textList.some((text) => AlarmedCollarFilterOptionModel.equals(filterOption, text));
  },

  matchesPattern<T>(filterOption: AlarmedCollarFilterOption<T>, pattern: string): boolean {
    switch (filterOption.filterType) {
      case AlarmedCollarFilterType.FARM:
        const farmFilterOption = filterOption as AlarmedDeviceFarmFilterOption;
        const matchesFarmName = SearchUtils.matchesAnySearchPattern(farmFilterOption.filterText, pattern);
        const matchesFarmId = SearchUtils.matchesAnySearchPattern(farmFilterOption.metadata.farmId, pattern);
        return matchesFarmName || matchesFarmId;
      case AlarmedCollarFilterType.STATUS:
      case AlarmedCollarFilterType.ALARMS:
      case AlarmedCollarFilterType.ALARM_BEHAVIOUR:
        return SearchUtils.matchesAnySearchPattern(formatStringAsLabel(filterOption.filterText), pattern);
      default:
        return SearchUtils.matchesAnySearchPattern(filterOption.filterText, pattern);
    }
  },

  formatFilterText<T>(filterOption: AlarmedCollarFilterOption<T>): string {
    switch (filterOption.filterType) {
      case AlarmedCollarFilterType.STATUS:
      case AlarmedCollarFilterType.ALARMS:
      case AlarmedCollarFilterType.ALARM_BEHAVIOUR:
        return formatStringAsLabel(filterOption.filterText);
      default:
        return filterOption.filterText;
    }
  },

  formatMetadata<T>(filterOption: AlarmedCollarFilterOption<T>): string | null {
    switch (filterOption.filterType) {
      case AlarmedCollarFilterType.FARM:
        const farmFilterOption = filterOption as AlarmedDeviceFarmFilterOption;
        return `ID: ${farmFilterOption.metadata.farmId}`;
      default:
        return null;
    }
  },
};

export interface AlarmedDeviceFarmFilterOption extends AlarmedCollarFilterOption<{ farmId: string }> {
  filterType: AlarmedCollarFilterType.FARM;
  metadata: { farmId: string };
}

export const AlarmedDeviceFarmFilterOptionModel = {
  create(farmName: string, farmId: string): AlarmedDeviceFarmFilterOption {
    return {
      filterType: AlarmedCollarFilterType.FARM,
      filterText: farmName,
      metadata: { farmId },
    };
  },
};
