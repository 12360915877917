import { Text, TextProps } from '@chakra-ui/react';
import pluralize from 'pluralize';
import { FC, memo } from 'react';

/**
 * @summary This component is used to display the effect message of the action to snooze active alarms and remove event alarms.
 */
const CollarActionAlarmSnoozeAndRemovalEffectMessage: FC<{ serialNumberCount: number; selectedEventAlarmCount?: number } & TextProps> = memo(
  ({ serialNumberCount, selectedEventAlarmCount = 0, ...props }) => {
    return (
      <Text
        fontSize="sm"
        {...props}
      >
        This action will snooze alarms for {serialNumberCount} {pluralize('collar', serialNumberCount)} for 1 hour
        {selectedEventAlarmCount > 0 && (
          <>
            {' '}
            and remove your <strong>{selectedEventAlarmCount}</strong> selected event{' '}
            {pluralize('alarms', selectedEventAlarmCount)}
          </>
        )}
        .
      </Text>
    );
  },
);

export default CollarActionAlarmSnoozeAndRemovalEffectMessage;
