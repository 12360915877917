import { AlarmScope } from '../@types';

export enum CollarGrafanaPanelEnum {
  DUTY_CYCLES = 'DUTY_CYCLES',
  LORA_CHECKINS = 'LORA_CHECKINS',
  CURRENTS = 'CURRENTS',
  ODBA = 'ODBA',
  BATTERY_SOC = 'BATTERY_SOC',
  BATTERY_FORECAST_SOC = 'BATTERY_FORECAST_SOC',
  BATTERY_VOLTAGE = 'BATTERY_VOLTAGE',
  BIT_ERROR = 'BIT_ERROR',
  SHOCK_TIMELINE = 'SHOCK_TIMELINE',
  ALARM_HISTORY = 'ALARM_HISTORY',
  UP_TIME = 'UP_TIME',
  BOOT_REASON = 'BOOT_REASON',
  PITCH = 'PITCH',
  PITCH_HISTOGRAM = 'PITCH_HISTOGRAM',
  SOLAR_GENERATION = 'SOLAR_GENERATION',
  LORA_FRAME_COUNTER = 'LORA_FRAME_COUNTER',
  CRASH_REPORT = 'CRASH_REPORT',
  WIFI_LOCATION = 'WIFI_LOCATION',
  LORA_LOCATION = 'LORA_LOCATION',
  SHOCK_CHARGE_VOLTAGE = 'SHOCK_CHARGE_VOLTAGE',
  LORA_RSSI = 'LORA_RSSI',
  LORA_RSSI_MOB_COMPARISON = 'LORA_RSSI_MOB_COMPARISON',
  WIFI_BSSID = 'WIFI_BSSID',
  GPS_SNR = 'GPS_SNR',
  COMMAND_IDS = 'COMMAND_IDS',
  SHOCK_REPORTS = 'SHOCK_REPORTS',
  COW_SHOCK_REPORT_STATS = 'COW_SHOCK_REPORT_STATS',
  DEVICE_STATUS = 'DEVICE_STATUS',
  MAGNETIC_MAGNITUDE = 'MAGNETIC_MAGNITUDE',
  MAGNETIC_MAGNITUDE_STATS = 'MAGNETIC_MAGNITUDE_STATS',
}

export enum CollarGroupGrafanaPanelEnum {
  MOB_SHOCK_REPORT_STATS = 'MOB_SHOCK_REPORT_STATS',
  BATTERY_PERCENTILES = 'BATTERY_PERCENTILES',
  GROUP_STATS = 'GROUP_STATS',
  GPS_DUTY_CYCLE_PERCENTILES = 'GPS_DUTY_CYCLE_PERCENTILES',
  SOLAR_GENERATION_PERCENTILES = 'SOLAR_GENERATION_PERCENTILES',
}

export enum CommandGrafanaPanelEnum {
  TRANSITION_REPORT = 'TRANSITION_REPORT',
}

export enum TowerGrafanaPanelEnum {
  UP_TIME = 'UP_TIME',
  BATTERY_VOLTAGE = 'BATTERY_VOLTAGE',
  SOLAR_GENERATION = 'SOLAR_GENERATION',
  GATEWAY_CHECKINS = 'GATEWAY_CHECKINS',
  SOLAR_SWITCH_CHECKINS = 'SOLAR_SWITCH_CHECKINS',
  TOWER_NODE_CHECKINS = 'TOWER_NODE_CHECKINS',
  TOWER_METRICS = 'TOWER_METRICS',
}

export type GrafanaPanelEnum =
  | CollarGrafanaPanelEnum
  | CollarGroupGrafanaPanelEnum
  | CommandGrafanaPanelEnum
  | TowerGrafanaPanelEnum;

export const getPanelEnumForScope = (scope: AlarmScope) => {
  switch (scope) {
    case AlarmScope.COLLAR:
    case AlarmScope.COW:
      return CollarGrafanaPanelEnum;
    case AlarmScope.FARM:
    case AlarmScope.MOB:
      return CollarGroupGrafanaPanelEnum;
    case AlarmScope.COMMAND:
      return CommandGrafanaPanelEnum;
    case AlarmScope.TOWER:
      return TowerGrafanaPanelEnum;
  }
};

const getCollarGrafanaPanelId = (panel: CollarGrafanaPanelEnum) => {
  switch (panel) {
    case CollarGrafanaPanelEnum.DUTY_CYCLES:
      return 'b253ead3-6fbc-4d21-89b2-714615076de8';
    case CollarGrafanaPanelEnum.LORA_CHECKINS:
      return 'bd2d9579-32f4-4e45-9a9a-904c5971f5bb';
    case CollarGrafanaPanelEnum.CURRENTS:
      return 'dc636229-5244-4392-898c-fa6a9bd43519';
    case CollarGrafanaPanelEnum.ODBA:
      return 'f642940c-c58d-416c-8856-b1e3e748e26e';
    case CollarGrafanaPanelEnum.BATTERY_SOC:
      return 'dcbbd429-35d5-4c05-9339-24b967b5c584';
    case CollarGrafanaPanelEnum.BATTERY_FORECAST_SOC:
      return 'be10bd64-24d2-492b-a5e3-f143f7f3790e';
    case CollarGrafanaPanelEnum.BATTERY_VOLTAGE:
      return 'b0f25b59-3980-4c99-a199-0410352ee988';
    case CollarGrafanaPanelEnum.BIT_ERROR:
      return 'dd012f6f-ec07-4682-bb57-bd0f6216d6ad';
    case CollarGrafanaPanelEnum.SHOCK_TIMELINE:
      return 'a9aaf0d9-59c5-4163-884b-440519dcbc88';
    case CollarGrafanaPanelEnum.ALARM_HISTORY:
      return 'f0c24847-dacb-452b-a62d-bbf41486ea89';
    case CollarGrafanaPanelEnum.UP_TIME:
      return 'bd54f334-0760-4078-9af7-f04f5faafdf0';
    case CollarGrafanaPanelEnum.BOOT_REASON:
      return 'e554c1ff-be60-4e79-ac29-22c4d622f16d';
    case CollarGrafanaPanelEnum.PITCH:
      return 'd3a339e9-5f43-42f4-a09a-0808e1fb08e5';
    case CollarGrafanaPanelEnum.PITCH_HISTOGRAM:
      return 'bad0d8a2-5154-41c5-b659-4d4cc8885b92';
    case CollarGrafanaPanelEnum.SOLAR_GENERATION:
      return 'f1afa483-e535-4c7d-b082-b9189a09a48d';
    case CollarGrafanaPanelEnum.LORA_FRAME_COUNTER:
      return 'f9f6fe00-f7e7-48f0-8aeb-9cddfb4a4642';
    case CollarGrafanaPanelEnum.CRASH_REPORT:
      return 'c793529e-517d-4b38-9521-0189f5283635';
    case CollarGrafanaPanelEnum.WIFI_LOCATION:
      return 'c8a1aaf1-85d4-4339-bb03-4a2309b63272';
    case CollarGrafanaPanelEnum.LORA_LOCATION:
      return 'd3407a44-6161-41dd-bdb4-e7a2da8d805e';
    case CollarGrafanaPanelEnum.SHOCK_CHARGE_VOLTAGE:
      return 'fc5e8e3d-f4f0-48f5-8206-29ae87b7c858';
    case CollarGrafanaPanelEnum.LORA_RSSI:
      return 'ff198950-cccf-4133-973b-38507ff9365f';
    case CollarGrafanaPanelEnum.LORA_RSSI_MOB_COMPARISON:
      return 'b1c48e12-5e78-4173-9e53-ebe3b791f54e';
    case CollarGrafanaPanelEnum.WIFI_BSSID:
      return 'dc3c5846-75ad-4b94-894e-cd135494a4fb';
    case CollarGrafanaPanelEnum.GPS_SNR:
      return 'fc6a9c6f-ffe5-4a17-ad4a-89dab1d9c8b5';
    case CollarGrafanaPanelEnum.COMMAND_IDS:
      return 'af9bbaa3-bb23-4ba6-a4f1-73419ab32ed9';
    case CollarGrafanaPanelEnum.SHOCK_REPORTS:
      return 'db288c2c-067c-44f1-a132-081c320e8665';
    case CollarGrafanaPanelEnum.COW_SHOCK_REPORT_STATS:
      return 'fec650d7-3ac5-4a69-9a6b-cc217c77917c';
    case CollarGrafanaPanelEnum.DEVICE_STATUS:
      return 'c3212dd4-b156-4d57-bca0-35fa4f4892b9';
    case CollarGrafanaPanelEnum.MAGNETIC_MAGNITUDE:
      return 'ef2a9f19-d934-4065-ae0b-4b7045f30d54';
    case CollarGrafanaPanelEnum.MAGNETIC_MAGNITUDE_STATS:
      return 'b8575471-c4f1-474a-981a-0fd41b3b3b91';
  }
};

// MOB & FARM scopes
const getCollarGroupGrafanaPanelId = (panel: CollarGroupGrafanaPanelEnum) => {
  switch (panel) {
    case CollarGroupGrafanaPanelEnum.BATTERY_PERCENTILES:
      return 'fa56bdec-654b-44e6-9cfc-33ded40c9210';
    case CollarGroupGrafanaPanelEnum.GROUP_STATS:
      return 'aa77356f-5161-4761-a3ad-8d27fa4d9c37';
    case CollarGroupGrafanaPanelEnum.MOB_SHOCK_REPORT_STATS:
      return 'fa16962d-5be3-4291-b4fe-751616e966dc';
    case CollarGroupGrafanaPanelEnum.GPS_DUTY_CYCLE_PERCENTILES:
      return 'd1051a79-6ede-4ee4-84d0-edb404070193';
    case CollarGroupGrafanaPanelEnum.SOLAR_GENERATION_PERCENTILES:
      return 'df9fb878-b95f-4d02-8255-d3d7b6b9d7d4';
  }
};

const getCommandGrafanaPanelId = (panel: CommandGrafanaPanelEnum) => {
  switch (panel) {
    case CommandGrafanaPanelEnum.TRANSITION_REPORT:
      return 'c695f5f3-4543-42ea-a342-59fc3d37a563';
  }
};

export const getTowerGrafanaPanelId = (panel: TowerGrafanaPanelEnum) => {
  switch (panel) {
    case TowerGrafanaPanelEnum.UP_TIME:
      return 'ced84a62-20e0-4043-952a-6c86693cf6b4';
    case TowerGrafanaPanelEnum.BATTERY_VOLTAGE:
      return 'e25aa569-1e8f-4fe7-a46f-a5ff40168cf7';
    case TowerGrafanaPanelEnum.SOLAR_GENERATION:
      return 'af23fad9-1ba3-489f-89be-37223f5125c6';
    case TowerGrafanaPanelEnum.GATEWAY_CHECKINS:
      return 'caf45b8a-9e82-43fe-8fb2-ec1abf554729';
    case TowerGrafanaPanelEnum.SOLAR_SWITCH_CHECKINS:
      return 'd0028198-6183-4874-8cb3-fce33f4eca75';
    case TowerGrafanaPanelEnum.TOWER_NODE_CHECKINS:
      return 'eaf814fa-8c53-4b35-adb9-0d3822c12b61';
    case TowerGrafanaPanelEnum.TOWER_METRICS:
      return 'fa65f895-90d1-46bb-a84f-f10ab9eeebbf';
  }
};

export const getGrafanaPanelId = (panel: GrafanaPanelEnum, scope: AlarmScope) => {
  switch (scope) {
    case AlarmScope.COLLAR:
    case AlarmScope.COW:
      return getCollarGrafanaPanelId(panel as CollarGrafanaPanelEnum);
    case AlarmScope.FARM:
    case AlarmScope.MOB:
      return getCollarGroupGrafanaPanelId(panel as CollarGroupGrafanaPanelEnum);
    case AlarmScope.COMMAND:
      return getCommandGrafanaPanelId(panel as CommandGrafanaPanelEnum);
    case AlarmScope.TOWER:
      return getTowerGrafanaPanelId(panel as TowerGrafanaPanelEnum);
  }
};
