import {
  Modal as ChakraModal,
  ModalProps as ChakraModalProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import { Colors } from 'src/styles';

type BaseModalProps = ChakraModalProps & {
  onClose: () => void;
  isOpen: boolean;
  headerText: string | React.ReactNode;
  children: React.ReactNode;
  modalContentProps?: ModalContentProps;
  modalHeaderProps?: ModalHeaderProps;
};

const BaseModal: FunctionComponent<BaseModalProps> = ({
  onClose,
  isOpen,
  headerText,
  children,
  modalContentProps = {},
  modalHeaderProps = {},
  ...rest
}) => (
  <ChakraModal
    isOpen={isOpen}
    isCentered
    onClose={onClose}
    scrollBehavior="inside"
    motionPreset="slideInBottom"
    {...rest}
  >
    <ModalOverlay />
    <ModalContent
      maxHeight="none"
      px={0}
      borderRadius={10}
      bgColor={Colors.gray50}
      boxShadow="2xl"
      overflow="hidden"
      {...modalContentProps}
    >
      <ModalHeader
        mx={{ base: 4, md: 8 }}
        px={0}
        fontSize={{ base: 'xl', md: '2xl' }}
        borderBottom="2px"
        color={Colors.gray700}
        borderBottomColor={Colors.gray300}
        {...modalHeaderProps}
      >
        {headerText}
      </ModalHeader>
      <ModalCloseButton
        bgColor={Colors.gray700}
        color={Colors.white}
        borderRadius={100}
        size="sm"
      />
      {children}
    </ModalContent>
  </ChakraModal>
);

export default BaseModal;
