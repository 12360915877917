import { ProductVendorEnum } from '@halter-corp/discovery-service-client';
import { InfraProductTypeEnum } from '@halter-corp/manufacturing-service-client';

import { InfraProduct } from 'src/features/infra-product';

import { TowerType } from '../models/tower';

/**
 * NOTE: This logic may change.
 */
export const getTowerType = (tower: { infraProducts: InfraProduct[]; starlink: boolean }) => {
  const { infraProducts, starlink } = tower;
  if (starlink) return TowerType.T4_STARLINK;

  const isWifiTower =
    infraProducts.some((product) => product.productType === InfraProductTypeEnum.WIFI_CASE) ||
    infraProducts.some((product) => product.productType === InfraProductTypeEnum.WIFI_MAST);
  if (isWifiTower) return TowerType.T4_WIFI;

  const isMeshTower =
    infraProducts.some((product) => product.productType === InfraProductTypeEnum.MESH_CASE) ||
    infraProducts.some((product) => product.productType === InfraProductTypeEnum.MESH_MAST);
  if (isMeshTower) return TowerType.T4_MESH;

  /**
   * NOTE: the tower is T2 if
   *  * gateway vendor is Tektelic
   *  * it has a solar switch
   *  * it does not have a tower node
   * some T2 towers have tower nodes which we can distinguish by checking if it has a solar switch
   * References: 
   *  * https://github.com/halter-corp/playbooks-comms/blob/main/go-scripts/infrastructure_service_backfill_tower_nodes_and_gateways/infrastructure_service_backfill_tower_nodes_and_gateways.go
   *  * https://github.com/halter-corp/playbooks-comms/pull/190
   */
  const hasSolarSwitch = infraProducts.some((product) => product.productType === InfraProductTypeEnum.SOLAR_SWITCH);
  if (hasSolarSwitch) {
    return TowerType.T2;
  }

  const gateway = infraProducts.find((product) => product.productType === InfraProductTypeEnum.GATEWAY);
  if (gateway == null) return TowerType.UNKNOWN; // Should not happen assuming devices are backfilled.
  if (gateway.vendor === ProductVendorEnum.TEKTELIC) {
    return TowerType.T2;
  }

  const hasTowerNode = infraProducts.some((product) => product.productType === InfraProductTypeEnum.TOWER_NODE);
  if (!hasTowerNode) {
    return TowerType.T2;
  }

  return TowerType.T4_OTHER;
};
