import { ITowerContextDTO } from '@halter-corp/tower-alarm-service-client';
import { AlarmedEntity } from 'src/modules/monitoring/@types';

export type TowerAlarmAction = 'snooze';

export enum AlarmedTowerFilterType {
  // SEARCH
  ENTITY_ID = 'Entity ID',
  ALARMS = 'Alarms',
  FARM = 'Farm',


  // BOOLEAN
  MAINS_POWERED = 'Mains Powered',
  ROUTER = 'Router',
  STARLINK = 'Starlink',

  // TIME RANGE
  FIRST_LOGGED_DATE = 'First Logged Date',
  LAST_LOGGED_DATE = 'Last Logged Date',
}

export type AlarmedTowerContext = ITowerContextDTO;

export type AlarmedTower = AlarmedEntity<AlarmedTowerContext>;
