import { PropsWithChildren, createContext } from 'react';

import { MonitoringActionConfig } from '../@types';

export const MonitoringActionConfigContext = createContext<MonitoringActionConfig>(null!);

export const MonitoringActionConfigContextProvider = ({
  children,
  config,
}: PropsWithChildren<{ config: MonitoringActionConfig }>) => (
  <MonitoringActionConfigContext.Provider value={config}>{children}</MonitoringActionConfigContext.Provider>
);
