import { IFarmDTO } from '@halter-corp/business-service-client';
import { ICollarActiveAlarmsContextDTO, ICollarContextDTO } from '@halter-corp/collar-alarm-service-client';
import { DeviceOnFarmStatusEnum } from '@halter-corp/fleet-service-client';
import { chain, isEmpty, maxBy, minBy, sortBy, uniq } from 'lodash';
import { useMemo } from 'react';

import { AlarmScope } from 'src/modules/monitoring/@types';
import { ALARM_BEHAVIOURS, FARM_OPS_ALARM_TYPES } from 'src/modules/monitoring/constants';
import { MonitoringContexts } from 'src/modules/monitoring/contexts';
import { BusinessQueries, CollarAlarmQueries } from 'src/queries';
import { OtaService } from 'src/services';
import { FilterOption } from 'src/types';
import { CollarUtils } from 'src/util/collar.util';

import {
  AlarmedCollar,
  AlarmedCollarContext,
  AlarmedCollarFilterOptionModel,
  AlarmedCollarFilterType,
  AlarmedDeviceFarmFilterOptionModel,
} from '../../collar-monitoring';

const FarmOpsMonitoringService = {
  useFarmOpsAlarmEntities: () => {
    const farmOpsActiveAlarmsResponse = FarmOpsMonitoringService.useFarmOpsActiveAlarmEntities();

    return {
      data: farmOpsActiveAlarmsResponse.data,
      isLoading: farmOpsActiveAlarmsResponse.isLoading,
      isError: farmOpsActiveAlarmsResponse.isError,
      refetchData: farmOpsActiveAlarmsResponse.refetch,
    };
  },

  useFarmOpsActiveAlarmEntities: () => {
    const [snoozeAlarmsVisible] = MonitoringContexts.useSnoozeAlarmVisibility();
    const {
      data: alarmsContexts,
      isLoading: isAlarmsContextsLoading,
      isError: isAlarmsContextsError,
      refetch,
    } = CollarAlarmQueries.useActionRequiredActiveCollarAlarmContexts(snoozeAlarmsVisible);
    const { data: farmById } = BusinessQueries.useFindAllFarmsQuery();

    const releaseChannelByFarmId = OtaService.useReleaseChannelByFarmId();

    const alarmedCollars: AlarmedCollar[] = useMemo(() => {
      const farmOpsAlarmsContexts = alarmsContexts?.flatMap((alarmContext) => {
        const farmOpsAlarms = alarmContext.alarms.filter((alarm) => FARM_OPS_ALARM_TYPES.has(alarm.type));
        if (farmOpsAlarms.length === 0) return [];

        return {
          ...alarmContext,
          alarms: farmOpsAlarms,
        };
      });

      return mapActiveAlarmDTOsToCollarAlarmEntities(
        farmOpsAlarmsContexts ?? [],
        farmById ?? {},
        releaseChannelByFarmId,
      );
    }, [alarmsContexts, releaseChannelByFarmId, farmById]);

    return {
      data: alarmedCollars,
      isLoading: isAlarmsContextsLoading,
      isError: isAlarmsContextsError,
      refetch,
    };
  },

  useFarmOpsAlarmFilterOptionsByType: () => {
    const { data: farmById } = BusinessQueries.useFindAllFarmsQuery();
    const farms = useMemo(() => Object.values(farmById ?? {}), [farmById]);

    const { data: alarmedCollars = [] } = FarmOpsMonitoringService.useFarmOpsAlarmEntities();
    const { data: releaseChannels } = OtaService.useReleaseChannels({ sortDir: 'asc' });

    const firmwareVersionOptions = useMemo(
      () =>
        chain(alarmedCollars)
          .flatMap((alarmContext) => alarmContext.context.firmwareVersion ?? [])
          .uniq()
          .orderBy((fwv) => fwv, ['asc'])
          .value(),
      [alarmedCollars],
    );

    const batchOptions = useMemo(() => {
      const uniqueBatches = uniq(alarmedCollars.flatMap((a) => CollarUtils.getCollarBatch(a.entityId) ?? []));
      return sortBy(uniqueBatches, (batch) => parseInt(batch, 10));
    }, [alarmedCollars]);

    const filterOptionsByType: Map<AlarmedCollarFilterType, FilterOption<unknown>[]> = useMemo(
      () =>
        new Map([
          [
            AlarmedCollarFilterType.STATUS,
            Object.values(DeviceOnFarmStatusEnum).map((status) =>
              AlarmedCollarFilterOptionModel.create(AlarmedCollarFilterType.STATUS, status),
            ),
          ],
          [
            AlarmedCollarFilterType.ALARMS,
            [...FARM_OPS_ALARM_TYPES].map((alarm) =>
              AlarmedCollarFilterOptionModel.create(AlarmedCollarFilterType.ALARMS, alarm),
            ),
          ],
          [
            AlarmedCollarFilterType.ALARM_BEHAVIOUR,
            ALARM_BEHAVIOURS.map((alarmBehaviour) =>
              AlarmedCollarFilterOptionModel.create(AlarmedCollarFilterType.ALARM_BEHAVIOUR, alarmBehaviour),
            ),
          ],
          [
            AlarmedCollarFilterType.FARM,
            farms.map((farm) => AlarmedDeviceFarmFilterOptionModel.create(farm.name ?? '', farm.id)),
          ],
          [
            AlarmedCollarFilterType.BATCH,
            batchOptions.map((batch) => AlarmedCollarFilterOptionModel.create(AlarmedCollarFilterType.BATCH, batch)),
          ],
          [
            AlarmedCollarFilterType.FIRMWARE,
            firmwareVersionOptions.map((firmwareVersion) =>
              AlarmedCollarFilterOptionModel.create(AlarmedCollarFilterType.FIRMWARE, firmwareVersion),
            ),
          ],
          [
            AlarmedCollarFilterType.RELEASE_CHANNEL,
            (releaseChannels ?? []).map((releaseChannel) =>
              AlarmedCollarFilterOptionModel.create(AlarmedCollarFilterType.RELEASE_CHANNEL, releaseChannel.id),
            ),
          ],
        ]),
      [farms, firmwareVersionOptions, releaseChannels, batchOptions],
    );

    return filterOptionsByType;
  },
};

const mapActiveAlarmDTOsToCollarAlarmEntities = (
  collarActiveAlarmContexts: ICollarActiveAlarmsContextDTO[],
  farmById: Record<string, IFarmDTO>,
  releaseChannelByFarmId: Record<string, string | undefined>,
) => {
  const collarAlarmEntities: AlarmedCollar[] = collarActiveAlarmContexts.map((alarmContext) => {
    const farmDTO = alarmContext.context.farmId != null ? farmById[alarmContext.context.farmId] : undefined;
    const releaseChannel = farmDTO != null ? releaseChannelByFarmId[farmDTO.id] : undefined;

    const firstLoggedDate = minBy(alarmContext.alarms, (alarm) => alarm.createdAt)?.createdAt;
    const lastLoggedDate = maxBy(alarmContext.alarms, (alarm) => alarm.createdAt)?.createdAt;
    const debugLink = CollarUtils.getDebugLink(alarmContext.serialNumber);

    return {
      entityId: alarmContext.serialNumber,
      uniqueKey: alarmContext.serialNumber,
      scope: AlarmScope.COLLAR,
      alarmBehaviour: 'active' as const,
      farm: farmDTO != null ? { farmId: farmDTO.id, farmName: farmDTO.name } : undefined,
      mobId: alarmContext.context.mobId,
      alarms: alarmContext.alarms.map((alarm) => ({ type: alarm.type, eventDateTime: alarm.createdAt })),
      context: convertToAlarmedCollarContext(alarmContext.context, releaseChannel),
      id: alarmContext.serialNumber,
      firstLoggedDate: firstLoggedDate != null ? new Date(firstLoggedDate) : undefined,
      lastLoggedDate: lastLoggedDate != null ? new Date(lastLoggedDate) : undefined,
      debugLink,
    };
  });

  return collarAlarmEntities;
};

const convertToAlarmedCollarContext = (
  contextDTO: ICollarContextDTO,
  releaseChannel?: string,
): AlarmedCollarContext => {
  const batch = CollarUtils.getCollarBatch(contextDTO.serialNumber);
  const cattle = isEmpty(contextDTO.cattleId)
    ? undefined
    : {
        cattleId: contextDTO.cattleId,
        cattleName: isEmpty(contextDTO.cattleName) ? undefined : contextDTO.cattleName,
      };

  return {
    onFarmStatus: isEmpty(contextDTO.onFarmStatus) ? undefined : (contextDTO.onFarmStatus as DeviceOnFarmStatusEnum),
    cattle,
    mobId: isEmpty(contextDTO.mobId) ? undefined : contextDTO.mobId,
    firmwareVersion: isEmpty(contextDTO.firmwareVersion) ? undefined : contextDTO.firmwareVersion,
    batch,
    releaseChannel,
  };
};

export default FarmOpsMonitoringService;
