import { BuildPlanApi, CollarContextApi } from '@halter-corp/manufacturing-service-client';

import client from '../client';

const manufacturingApi = {
  buildPlan: client.resolveOpenApi(BuildPlanApi),
  collarContext: client.resolveOpenApi(CollarContextApi),
};

export default manufacturingApi;
