import { FunctionComponent, useMemo, useState } from 'react';

import 'react-cmdk/dist/cmdk.css';

import { useAuthSignOutAction } from '@aws-amplify/ui-react/internal';
import CommandPalette, { filterItems, getItemIndex, useHandleOpenCommandPalette } from 'react-cmdk';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'src/constants';
import { RouteItems } from 'src/constants/route-items';

import EnvironmentCommandPalette from './environment-command-palette';
import { CommandPalettePage } from './types';

const ApplicationCommandPalette: FunctionComponent = () => {
  const [page, setPage] = useState<CommandPalettePage>('root');
  const [searchText, setSearchText] = useState('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useHandleOpenCommandPalette(setIsOpen);

  const navigate = useNavigate();
  const signOutAction = useAuthSignOutAction();

  const handleChangePage = (newPage: CommandPalettePage) => {
    setPage(newPage);
    setSearchText('');
  };

  const filteredItems = useMemo(
    () =>
      filterItems(
        [
          {
            heading: '',
            id: 'home',
            items: [
              {
                id: 'home',
                children: 'Home',
                icon: 'HomeIcon',
                onClick: () => navigate(ROUTES.Home),
              },
            ],
          },
          ...RouteItems.map((routeItem) => {
            // Note: if route is a standalone module (e.g. feature flags), make the module itself an item.
            if (routeItem.type === 'standalone') {
              return {
                heading: routeItem.text,
                id: routeItem.text,
                items: [
                  {
                    id: routeItem.text,
                    children: routeItem.text,
                    icon: routeItem.icon,
                    onClick: () => navigate(routeItem.path),
                  },
                ],
              };
            }

            return {
              heading: routeItem.text,
              id: routeItem.text,
              items: routeItem.childItems.map((nestedRouteItem) => ({
                id: nestedRouteItem.text,
                children: nestedRouteItem.text,
                icon: nestedRouteItem.icon,
                onClick: () => navigate(nestedRouteItem.path),
              })),
            };
          }),
          {
            heading: 'Others',
            id: 'others',
            items: [
              {
                id: 'environmeent',
                children: 'Environment',
                icon: 'CogIcon',
                closeOnSelect: false,
                onClick: () => handleChangePage('environment'),
              },
              {
                id: 'log-out',
                children: 'Log out',
                icon: 'ArrowRightOnRectangleIcon',
                onClick: signOutAction,
              },
            ],
          },
        ],
        searchText,
      ),
    [navigate, searchText, signOutAction],
  );

  return (
    <CommandPalette
      onChangeSearch={setSearchText}
      onChangeOpen={setIsOpen}
      search={searchText}
      isOpen={isOpen}
      page={page}
    >
      <CommandPalette.Page id="root">
        {filteredItems.length > 0 ? (
          filteredItems.map((list) => (
            <CommandPalette.List
              key={list.id}
              heading={list.heading}
            >
              {list.items.map(({ id, ...rest }) => (
                <CommandPalette.ListItem
                  key={id}
                  index={getItemIndex(filteredItems, id)}
                  {...rest}
                />
              ))}
            </CommandPalette.List>
          ))
        ) : (
          <CommandPalette.FreeSearchAction />
        )}
      </CommandPalette.Page>

      <EnvironmentCommandPalette
        searchText={searchText}
        onNavigateBack={() => handleChangePage('root')}
      />
    </CommandPalette>
  );
};

export default ApplicationCommandPalette;
