import { Box, Flex, Grid } from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';
import { MdBattery50, MdError, MdMyLocation, MdOutlineNetworkCell } from 'react-icons/md';

import { Alarm, AlarmScope } from 'src/modules/monitoring/@types';
import AlarmBadge from 'src/modules/monitoring/components/badges/alarm.badge';
import QuickDebugBadge from 'src/modules/monitoring/components/badges/quick-debug-badge';
import {
  CollarGrafanaPanelEnum,
  GrafanaPanelEnum,
  getGrafanaPanelId,
} from 'src/modules/monitoring/utils/grafana-dashboards.util';
import { AlarmRegistryQueries } from 'src/queries';
import { GrafanaPeriod } from 'src/types';
import { formatStringAsLabel } from 'src/util/string-format.util';

type CollarAlarmsCellProps = {
  serialNumber: string;
  farmId?: string;
  mobId?: string;
  alarms: Alarm[];
  grafanaPeriod: GrafanaPeriod;
};

const CollarAlarmsCell: FunctionComponent<CollarAlarmsCellProps> = ({
  serialNumber,
  farmId,
  mobId,
  alarms,
  grafanaPeriod,
}) => {
  const [quickDebugVisible, setQuickDebugVisible] = useState(false);

  const { data: alarmDefinitionByType } = AlarmRegistryQueries.useAlarmDefinitions();

  const grafanaVariables = {
    serialNumber,
    mobId: mobId ?? '',
  };

  const farmVar = farmId != null ? `farmId=${farmId}` : '';
  const mobVar = `collarId=${serialNumber}`;
  const liveViewUrl = `/map/live-view?${[farmVar, mobVar].join('&')}`;

  return (
    <Flex
      gap="1rem"
      onMouseEnter={() => setQuickDebugVisible(true)}
      onMouseLeave={() => setQuickDebugVisible(false)}
    >
      <Flex
        rowGap={1}
        maxWidth="200px"
        width="min-content"
        flexWrap="wrap"
        alignItems="center"
      >
        {alarms.length === 0 && '-'}
        {alarms.map((alarm) => {
          const definition = alarmDefinitionByType?.[alarm.type];
          const grafanaPanelIds = definition?.relevantMetricNames?.flatMap(
            (metricName) => getGrafanaPanelId(metricName as GrafanaPanelEnum, AlarmScope.COLLAR) ?? [],
          );

          return (
            <AlarmBadge
              key={alarm.type}
              size="sm"
              alarm={alarm}
              formatAlarm={({ type }) => formatStringAsLabel(type)}
              bgColor={definition?.colour}
              dashboardIds={grafanaPanelIds}
              grafanaVariables={grafanaVariables}
              grafanaPeriod={grafanaPeriod}
            />
          );
        })}
      </Flex>
      <Box opacity={quickDebugVisible ? 1 : 0}>
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={1}
        >
          <QuickDebugBadge
            key="location"
            icon={MdMyLocation}
            dashboardVariables={grafanaVariables}
            dashboardIds={[
              getGrafanaPanelId(CollarGrafanaPanelEnum.ODBA, AlarmScope.COLLAR),
              getGrafanaPanelId(CollarGrafanaPanelEnum.LORA_LOCATION, AlarmScope.COLLAR),
            ]}
            link={liveViewUrl}
            tooltipLabel="Location"
          />
          <QuickDebugBadge
            key="comms"
            icon={MdOutlineNetworkCell}
            dashboardVariables={grafanaVariables}
            dashboardIds={[
              getGrafanaPanelId(CollarGrafanaPanelEnum.LORA_RSSI, AlarmScope.COLLAR),
              getGrafanaPanelId(CollarGrafanaPanelEnum.LORA_CHECKINS, AlarmScope.COLLAR),
            ]}
            tooltipLabel="Comms"
          />
          <QuickDebugBadge
            key="battery"
            icon={MdBattery50}
            dashboardVariables={grafanaVariables}
            dashboardIds={[
              getGrafanaPanelId(CollarGrafanaPanelEnum.BATTERY_SOC, AlarmScope.COLLAR),
              getGrafanaPanelId(CollarGrafanaPanelEnum.BATTERY_VOLTAGE, AlarmScope.COLLAR),
            ]}
            tooltipLabel="Battery"
          />
          <QuickDebugBadge
            key="bit-errors"
            icon={MdError}
            dashboardVariables={grafanaVariables}
            dashboardIds={[
              getGrafanaPanelId(CollarGrafanaPanelEnum.UP_TIME, AlarmScope.COLLAR),
              getGrafanaPanelId(CollarGrafanaPanelEnum.BIT_ERROR, AlarmScope.COLLAR),
            ]}
            tooltipLabel="Bit Errors"
          />
        </Grid>
      </Box>
    </Flex>
  );
};

export default CollarAlarmsCell;
