import {
  AlarmActionTypeEnum,
  ICollarAlarmReturnToHalterActionContextDTO,
} from '@halter-corp/collar-alarm-service-client';
import { FunctionComponent } from 'react';
import * as yup from 'yup';

import FormikInput from 'src/components/forms/formik-input';
import { AlarmActionFormProps } from 'src/modules/monitoring/@types';
import BaseCollarActionForm from 'src/modules/monitoring/components/alarm-action-forms/collar-action-forms/base-collar-action-form';
import { SERIAL_NUMBERS_INPUT_PATTERN, extractSerialNumbersFromPattern } from 'src/util/collar.util';

import { CollarAlarmActionService } from '../../services/collar-alarm-action.service';

const returnToHalterActionSchema = yup.object().shape({
  serialNumbers: yup
    .string()
    .required()
    .matches(SERIAL_NUMBERS_INPUT_PATTERN, 'Each serial number should have valid format saparated by a comma'),
  faultType: yup.string().required(),
});

type ReturnToHalterActionFormValues = {
  serialNumbers: string;
  faultType: string;
};

const ReturnToHalterActionForm: FunctionComponent<AlarmActionFormProps> = ({
  onBack,
  onSuccessfulSubmit,
  onClose,
  selectedIds,
  selectedEventAlarmEntities,
  additionalSection,
}) => {
  const { sendBatchActionRequests } =
    CollarAlarmActionService.useBatchCollarAlarmActions<ICollarAlarmReturnToHalterActionContextDTO>({
      actionType: AlarmActionTypeEnum.RETURN_TO_HALTER,
      onSuccess: () => removeCollarEventAlarms(selectedEventAlarmEntities),
    });
  const removeCollarEventAlarms = CollarAlarmActionService.useBatchRemoveCollarEventAlarmActions({
    onSuccess: onSuccessfulSubmit,
  });

  const handleSubmit = async ({ serialNumbers: serialNumbersString, faultType }: ReturnToHalterActionFormValues) => {
    const serialNumbers = extractSerialNumbersFromPattern(serialNumbersString);
    const actionContext: ICollarAlarmReturnToHalterActionContextDTO = {
      faultType,
    };

    await sendBatchActionRequests(serialNumbers, actionContext);
  };

  return (
    <BaseCollarActionForm
      title="Return To Halter Task"
      initialValues={{
        serialNumbers: selectedIds.join(','),
        faultType: '',
        comments: '',
      }}
      validationSchema={returnToHalterActionSchema}
      onBack={onBack}
      onSubmit={handleSubmit}
      onClose={onClose}
      additionalSection={additionalSection}
      selectedEventAlarmCount={selectedEventAlarmEntities.length}
      commentsRequired={false}
    >
      {({ values, errors }) => (
        <FormikInput
          id="faultType"
          name="faultType"
          label="Reason"
          inputProps={{ value: values.faultType, placeholder: 'e.g. Brown out' }}
          error={errors.faultType}
        />
      )}
    </BaseCollarActionForm>
  );
};

export default ReturnToHalterActionForm;
