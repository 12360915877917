import { createContext } from 'react';

import { EnvironmentEnum } from 'src/services/configuration.service';

export type EnvironmentContextType = {
  initialized: boolean;
  environment: EnvironmentEnum;
  setEnvironment: (environment: EnvironmentEnum) => void;
};

export const EnvironmentContext = createContext<EnvironmentContextType | null>(null);
