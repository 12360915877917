import { AlarmScope, CattleContext } from '../../../@types';
import { PowerAlarmEntity } from '../@types';

export const PowerAlarmEntityUtils = {
  getCollarId: (powerEntity: PowerAlarmEntity): string | undefined => {
    switch (powerEntity.scope) {
      case AlarmScope.COLLAR:
        return powerEntity.entityId;
      default:
        return undefined;
    }
  },

  getCattleId: (powerEntity: PowerAlarmEntity): string | undefined => {
    switch (powerEntity.scope) {
      case AlarmScope.COLLAR:
        return powerEntity.context.collarContext!.cattleId;
      default:
        return undefined;
    }
  },

  getCattleContext: (powerEntity: PowerAlarmEntity): CattleContext | undefined => {
    switch (powerEntity.scope) {
      case AlarmScope.COLLAR:
        const collarContext = powerEntity.context.collarContext!;
        if (collarContext.cattleId == null) return undefined;
        return { cattleId: collarContext.cattleId, cattleName: collarContext.cattleName };
      default:
        return undefined;
    }
  },

  getMobId: (powerEntity: PowerAlarmEntity): string | undefined => {
    switch (powerEntity.scope) {
      case AlarmScope.COLLAR:
        return powerEntity.context.collarContext!.mobId;
      case AlarmScope.MOB:
        return powerEntity.entityId;
      default:
        return undefined;
    }
  },

  getFarmId: (powerEntity: PowerAlarmEntity): string | undefined => powerEntity.farm?.farmId,
};
