import { ROUTES } from 'src/constants';
import {
  AlarmActionConfig,
  AlarmScope,
  MonitoringDomain,
  MonitoringTemplateConfig,
} from 'src/modules/monitoring/@types';
import { farmRankConfig } from 'src/modules/monitoring/config/rank-config';
import { PAGE_ICONS } from 'src/modules/monitoring/constants';

import { PowerAlarmAction, PowerAlarmEntity, PowerEntityScopeContext } from '../@types';
import SnoozeActionForm from '../components/action-forms/snooze-action-form';
import { PowerAlarmFilterOptionModel } from '../models/power-alarm-filter-option.model';
import { PowerAlarmFilterModel } from '../models/power-alarm-filter.model';
import { PowerAlarmActionService } from '../services/power-alarm-action.service';
import PowerMonitoringService from '../services/power-monitoring.service';
import {
  canCopyColumnValue,
  collarPowerEntityColumns,
  columnHelper,
  extractColumnCopyValue,
  farmPowerEntityColumns,
  genericPowerEntityColumns,
  mobPowerEntityColumns,
} from './column-config';

const alarmActionConfigs: AlarmActionConfig<PowerAlarmAction>[] = [
  {
    action: 'snooze',
    associatedAlarmBehaviour: 'active',
  },
];

export const PowerMonitoringConfig: MonitoringTemplateConfig<PowerEntityScopeContext, PowerAlarmEntity> = {
  title: 'Power Monitoring',
  domain: MonitoringDomain.POWER,
  navLinkItems: [
    {
      href: ROUTES.PowerAlarmSettings,
      label: 'Power Alarm Settings',
      icon: <PAGE_ICONS.AlarmSettings fontSize="1.2rem" />,
    },
  ],
  entityConfig: {
    entityKey: 'power',
    entityName: 'Power',
  },
  scopeConfig: {
    scopeSelectionEnabled: true,
    scopes: [AlarmScope.COLLAR, AlarmScope.MOB, AlarmScope.FARM],
  },
  settingsConfig: {
    alarmSearchModeEnabled: false,
    grafanaSettingsEnabled: true,
  },
  actionConfig: {
    alarmActionConfigs,
    getAlarmActionFormByAlarmAction: (alarmAction) => {
      switch (alarmAction as PowerAlarmAction) {
        case 'snooze':
          return SnoozeActionForm;
        default:
          return undefined;
      }
    },
  },
  filterConfig: {
    useFilterOptionsByType: PowerMonitoringService.usePowerAlarmFilterOptionsByType,
    filterProcessConfig: PowerAlarmFilterModel.getInstance(),
    filterOptionConfig: PowerAlarmFilterOptionModel,
    searchFilterTypes: PowerAlarmFilterModel.SEARCH_FILTERS,
    dateRangeFilterTypes: PowerAlarmFilterModel.DATE_RANGE_FILTERS,
  },
  rankConfig: {
    extraRankSectionConfigs: [farmRankConfig],
  },
  tableConfig: {
    columnHelper,
    getColumns: (scope: AlarmScope | null) => {
      switch (scope) {
        case AlarmScope.COLLAR:
          return collarPowerEntityColumns;
        case AlarmScope.MOB:
          return mobPowerEntityColumns;
        case AlarmScope.FARM:
          return farmPowerEntityColumns;
        default:
          return genericPowerEntityColumns;
      }
    },
    canCopyColumn: canCopyColumnValue,
    extractColumnValueForCopy: extractColumnCopyValue,
  },
  queryConfig: {
    useAlarmEntities: PowerMonitoringService.usePowerAlarmEntities,
    useSnoozeAlarmAction: PowerAlarmActionService.usePowerSnoozeAlarmAction,
  },
};
