import { Box, ButtonProps, Flex, FlexProps, Text, TextProps, Tooltip } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import { FC, useState } from 'react';

import CopyClipboardButton from 'src/components/buttons/copy-clipboard-button';

type ContentWithCopyProps = {
  id: string;
  name?: string;
  scope?: string;
  displayNameTooltipLabel?: boolean;
  dark?: boolean;
  small?: boolean;
  defaultName?: string;
  textProps?: TextProps;
  buttonProps?: ButtonProps;
} & FlexProps;

const ContentWithCopy: FC<ContentWithCopyProps> = ({
  id,
  name,
  scope,
  displayNameTooltipLabel = false,
  dark = false,
  small = false,
  defaultName = '',
  textProps,
  buttonProps,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const formattedScope = capitalize(scope);

  return (
    <Flex
      gap={3}
      alignItems="center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...rest}
    >
      <Tooltip
        label={displayNameTooltipLabel ? name ?? defaultName : undefined}
        hasArrow
      >
        <Text
          isTruncated
          overflow="hidden"
          {...textProps}
        >
          {name ?? defaultName}
        </Text>
      </Tooltip>
      {id != null && (
        <Tooltip
          hasArrow
          whiteSpace="nowrap"
          fontSize="xs"
          label={`${formattedScope} ID - ${id}`}
        >
          <Box>
            <CopyClipboardButton
              opacity={isHovered ? 1 : 0}
              copyText={id}
              aria-label={`Copy ${formattedScope} ID`}
              dark={dark}
              height={small ? '1.5rem' : '2rem'}
              {...buttonProps}
            />
          </Box>
        </Tooltip>
      )}
    </Flex>
  );
};

export default ContentWithCopy;
