import { ISettingsTriggerRuleDTO } from '@halter-corp/welfare-alarm-service-client';

export enum WelfareAlarmSettingsFilterType {
  ALARM_TYPE = 'Alarm Type',
  FILTER_TYPE = 'Filter Type',

  CREATED_TIME = 'Created Time',
  UPDATED_TIME = 'Updated Time',
}

export type WelfareSettingsTriggerRule = ISettingsTriggerRuleDTO;
