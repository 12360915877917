import { keys } from 'lodash';

import { PartialRecord } from 'src/types';
import {
  DateRangeFilterState,
  FilterInclusionType,
  FilterUnionType,
  OnOffOption,
  SearchFilterState,
} from 'src/types/filter-types';

export abstract class BaseFilterModel<Entity, FilterType extends string> {
  abstract getSearchFilterFunction(
    filterType: FilterType,
  ): ((item: Entity, filterState: SearchFilterState) => boolean) | null;
  abstract getOnOffFilterFunction(filterType: FilterType): ((item: Entity, onOffOption: OnOffOption) => boolean) | null;
  abstract getDateRangeFilterFunction(
    filterType: FilterType,
  ): ((item: Entity, filterState: DateRangeFilterState) => boolean) | null;

  protected getDateRangeFilterMatcher(
    extractDate: (item: Entity) => Date | undefined,
  ): (item: Entity, filterState: DateRangeFilterState) => boolean {
    return (item: Entity, filterState: DateRangeFilterState): boolean => {
      const attributeDate = extractDate(item);
      if (attributeDate == null) return false;

      if (filterState.type === 'absolute') {
        const { startDate, endDate } = filterState;
        if (startDate == null && endDate == null) return true;
        if (startDate != null && attributeDate < new Date(startDate)) return false;
        if (endDate != null && attributeDate > new Date(endDate)) return false;
      }

      if (filterState.type === 'relative') {
        const { relativeTimeInMillis } = filterState;
        const timeDiffFromNow = Math.abs(new Date().getTime() - attributeDate.getTime());
        if (timeDiffFromNow > relativeTimeInMillis) return false;
      }

      return true;
    };
  }

  protected filterBySearchFilterState(
    filterState: SearchFilterState,
    filterMatcher: (option: string) => boolean,
  ): boolean {
    if (filterState.inclusionType === FilterInclusionType.INCLUDE) {
      if (filterState.unionType === FilterUnionType.AND) {
        return filterState.selectedOptions.every(filterMatcher);
      }
      return filterState.selectedOptions.some(filterMatcher);
    }

    if (filterState.inclusionType === FilterInclusionType.EXCLUDE) {
      return filterState.selectedOptions.some(filterMatcher) === false;
    }

    throw new Error(`Invalid filter union type or inclusion type: ${JSON.stringify(filterState)}`);
  }

  applySearchFiltersByType(
    items: Entity[],
    filterOptionsByType?: PartialRecord<FilterType, SearchFilterState>,
  ): Entity[] {
    const selectedFilterTypes = keys(filterOptionsByType ?? {});
    if (selectedFilterTypes.length === 0) return items;

    return items.filter((item) =>
      selectedFilterTypes.every((filterType) => {
        const filterState = filterOptionsByType![filterType as FilterType]!;
        if (filterState.selectedOptions.length === 0) return true;
        const filterFunction = this.getSearchFilterFunction(filterType as FilterType);
        if (filterFunction == null) return true;

        return filterFunction(item, filterState);
      }),
    );
  }

  applyDateRangeFiltersByType(items: Entity[], filterOptionsByType?: PartialRecord<FilterType, DateRangeFilterState>) {
    const selectedFilterTypes = keys(filterOptionsByType ?? {});
    if (selectedFilterTypes.length === 0) return items;

    return items.filter((item) =>
      selectedFilterTypes.every((filterType) => {
        const filterState = filterOptionsByType![filterType as FilterType];
        if (filterState == null) return true;

        const filterFunction = this.getDateRangeFilterFunction(filterType as FilterType);
        if (filterFunction == null) return true;

        return filterFunction(item, filterState);
      }),
    );
  }

  applyOnOffFiltersByType(items: Entity[], onOffFilterOptionByType?: PartialRecord<FilterType, OnOffOption>): Entity[] {
    const selectedFilterTypes = keys(onOffFilterOptionByType ?? {});
    if (selectedFilterTypes.length === 0) return items;

    return items.filter((item) =>
      selectedFilterTypes.every((filterType) => {
        const onOffOption = onOffFilterOptionByType![filterType as FilterType];
        const filterFunction = this.getOnOffFilterFunction(filterType as FilterType);
        if (filterFunction == null) return true;

        return filterFunction(item, onOffOption as OnOffOption);
      }),
    );
  }

  applyFilters(
    items: Entity[],
    searchFilterOptionsByType?: PartialRecord<FilterType, SearchFilterState>,
    onOffFilterOptionByType?: PartialRecord<FilterType, OnOffOption>,
    dateRangeFiltersByType?: PartialRecord<FilterType, DateRangeFilterState>,
  ): Entity[] {
    const itemsWithSearchFiltersApplied = this.applySearchFiltersByType(items, searchFilterOptionsByType);

    const itemsWIthOnOffFiltersApplied = this.applyOnOffFiltersByType(
      itemsWithSearchFiltersApplied,
      onOffFilterOptionByType,
    );

    return this.applyDateRangeFiltersByType(itemsWIthOnOffFiltersApplied, dateRangeFiltersByType);
  }
}
