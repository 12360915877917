import { Box, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { BiSolidCalendarStar } from 'react-icons/bi';

import { Colors } from 'src/styles';

/**
 * @summary Simple label component that displays event alarm icon and
 *  displays popover to provide more explanation when hover over the icon.
 */
const EventAlarmIndicator: FC = () => {
  return (
    <Popover
      placement="bottom"
      trigger="hover"
      isLazy
    >
      <PopoverTrigger>
        <Box>
          <BiSolidCalendarStar
            aria-label="Event Alarm"
            color={Colors.gray600}
            fontSize="17px"
          />
        </Box>
      </PopoverTrigger>

      <PopoverContent
        width="240px"
        bgColor={`${Colors.gray800}ee`}
        borderRadius="5px"
        border="none"
        boxShadow="2px 2px 5px black"
      >
        <PopoverArrow bgColor={`${Colors.gray800}ee`} />
        <PopoverBody
          display="flex"
          flexDir="column"
          rowGap={3}
          py={3}
          borderRadius="inherit"
          color={Colors.gray100}
          fontWeight={500}
          whiteSpace="normal"
          textTransform="none"
        >
          <Text
            fontWeight={500}
            lineHeight="19px"
          >
            <Text
              color={Colors.orange200}
              as="strong"
            >
              Event Alarm
            </Text>
            <br />A one-off event and cannot be resolved on its own. It should be resolved via an action.
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default memo(EventAlarmIndicator);
