import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GrafanaPeriod } from 'src/types/grafana-types';

import { AlarmBehaviour, AlarmSearchMode } from '../@types';
import { createMonitoringSelectionStore } from './monitoring-selection.store';
import { createMonitoringSettingsStore } from './monitoring-settings.store';

type MonitoringGlobalStateConfig = {
  entityKey: string;
};

// ALARM SEARCH MODE HOOK
const ALARM_SEARCH_MODE_QUERY_KEY = 'alarmSearchMode';

export const MonitoringGlobalStateHooksGenerator = {
  useGenerateMonitoringSettingsHooks: ({ entityKey }: MonitoringGlobalStateConfig) => {
    const useMonitoringSettingsStore = useMemo(() => createMonitoringSettingsStore(entityKey), [entityKey]);

    const useAlarmSearchMode = (): [AlarmSearchMode, (newSearchMode: AlarmSearchMode) => void] => {
      const { alarmSearchMode: alarmSearchModeState, setAlarmSearchMode } = useMonitoringSettingsStore();
      const [searchParams, setSearchParams] = useSearchParams();
      const currentSearchModeParam = searchParams.get(ALARM_SEARCH_MODE_QUERY_KEY);

      useEffect(() => {
        if (isValidAlarmSearchMode(currentSearchModeParam) && currentSearchModeParam !== alarmSearchModeState) {
          setAlarmSearchMode(currentSearchModeParam as AlarmSearchMode);
        }
      }, [currentSearchModeParam, alarmSearchModeState, setAlarmSearchMode]);

      const handleChangeAlarmSearchMode = (newSearchMode: AlarmSearchMode) => {
        setSearchParams((prevSearchParams) => {
          prevSearchParams.set(ALARM_SEARCH_MODE_QUERY_KEY, newSearchMode.toString());
          return prevSearchParams;
        });

        setAlarmSearchMode(newSearchMode);
      };

      return [alarmSearchModeState, handleChangeAlarmSearchMode];
    };

    const useGrafanaPeriod = (): [GrafanaPeriod, (period: GrafanaPeriod) => void] =>
      useMonitoringSettingsStore((state) => [state.grafanaPeriod, state.setGrafanaPeriod]);

    const useItemsPerPage = (): [number, (newItemsPerPage: number) => void] =>
      useMonitoringSettingsStore((state) => [state.itemsPerPage, state.setItemsPerPage]);

    const useSnoozeAlarmsVisibility = (): [boolean | undefined, (visible: boolean) => void] =>
      useMonitoringSettingsStore((state) => [state.snoozeAlarmsVisible, state.setSnoozeAlarmsVisible]);

    return {
      useAlarmSearchMode,
      useGrafanaPeriod,
      useItemsPerPage,
      useSnoozeAlarmsVisibility,
    };
  },

  useGenerateMonitoringSelectionHooks: () => {
    const useMonitoringSelectionStore = useMemo(() => createMonitoringSelectionStore(), []);

    const useAlarmKeySelection = () => {
      const { alarmKeySelection, setAlarmKeySelection } = useMonitoringSelectionStore((state) => state);

      const clearAlarmKeySelection = () => setAlarmKeySelection({});

      return {
        alarmKeySelection,
        setAlarmKeySelection,
        clearAlarmKeySelection,
      };
    };

    const useSelectedAlarmBehaviour = () => {
      const { selectedAlarmBehaviour, setSelectedAlarmBehaviour } = useMonitoringSelectionStore((state) => state);

      return [selectedAlarmBehaviour, setSelectedAlarmBehaviour] as [
        AlarmBehaviour,
        (newBehaviour: AlarmBehaviour) => void,
      ];
    };

    const useSelectedScope = () => {
      const { selectedScope, setSelectedScope } = useMonitoringSelectionStore((state) => state);

      const clearSelectedScope = () => setSelectedScope(null);

      return {
        selectedScope,
        setSelectedScope,
        clearSelectedScope,
      };
    };

    return {
      useAlarmKeySelection,
      useSelectedAlarmBehaviour,
      useSelectedScope,
    };
  },
};

const isValidAlarmSearchMode = (mode: string | null): boolean =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  Object.values(AlarmSearchMode).includes(mode as any);
