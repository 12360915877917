import AthenaQueryService from 'src/services/athena-queries.service';

const WELFARE_QUERY_PREFIX = 'welfare-monitoring';

type DiagnosisCategoryResult = {
  category: string;
};

export const WelfareAthenaQueryService = {
  useWelfareDiagnosisCategories: () => {
    const queryString = `
      SELECT distinct category 
      FROM "halter"."welfare_alarm_action_event_diagnosis";
    `;

    const { data: categoryResults, ...rest } = AthenaQueryService.useQuery<DiagnosisCategoryResult>(
      [WELFARE_QUERY_PREFIX, 'diagnosis-categories'],
      queryString,
    );

    return {
      data: categoryResults?.map(({ category }) => category) ?? [],
      ...rest,
    };
  },
};
