import {
  IFarmLotaJobDTO,
  IReleaseChannelDTO,
  IReleaseChannelMappingDTO,
  IUpdateOtaDeviceContextRequestDTO,
} from '@halter-corp/ota-service-client';
import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import moment from 'moment';

import { OtaApi } from 'src/data';

const OTA_KEY = 'ota';

export const OtaQueryKeys = {
  farmLotaJobs: () => [OTA_KEY, 'farm-lota-jobs'] as const,
  releaseChannels: () => [OTA_KEY, 'release-channels'] as const,
  releaseChannelMappings: () => [OTA_KEY, 'release-channel-mappings'] as const,
};

export type OtaQueriesState = {
  farmLotaJobs: Record<string, IFarmLotaJobDTO>;
  releaseChannels: IReleaseChannelDTO[];
  releaseChannelMappings: IReleaseChannelMappingDTO[];
};

export const OtaQueries = {
  // Queries
  useFarmLotaJobs: (options?: Partial<UseQueryOptions<OtaQueriesState['farmLotaJobs']>>) =>
    useQuery({
      queryKey: OtaQueryKeys.farmLotaJobs(),
      queryFn: async () => {
        const { data } = await OtaApi.job.getAllFarmLotaJobs();
        return keyBy(data, (item) => item.farmId);
      },
      staleTime: moment.duration(3, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(3, 'minutes').asMilliseconds(),
      ...options,
    }),

  useReleaseChannels: (options?: Partial<UseQueryOptions<OtaQueriesState['releaseChannels']>>) =>
    useQuery({
      queryKey: OtaQueryKeys.releaseChannels(),
      queryFn: async () => {
        const { data } = await OtaApi.releaseChannel.getAllReleaseChannels();
        return data;
      },
      staleTime: moment.duration(3, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(3, 'minutes').asMilliseconds(),
      ...options,
    }),

  useAllReleaseChannelMappings: (options?: Partial<UseQueryOptions<OtaQueriesState['releaseChannelMappings']>>) =>
    useQuery({
      queryKey: OtaQueryKeys.releaseChannelMappings(),
      queryFn: async () => {
        const { data } = await OtaApi.releaseChannel.getAllReleaseChannelMappings();
        return data;
      },
      staleTime: moment.duration(3, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(3, 'minutes').asMilliseconds(),
      ...options,
    }),

  // Mutations
  useUpdateOtaDeviceContextByIdMutation: () => {
    return useMutation({
      mutationFn: async (payload: IUpdateOtaDeviceContextRequestDTO & { id: string }) => {
        const { id, ...request } = payload;
        const { data } = await OtaApi.device.updateOtaDeviceContextByID(id, request);
        return data;
      },
    });
  },
};
