import { Badge, Flex, Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import GridSectionContainer from 'src/components/grid-section-contanier';
import LabelValueGroup from 'src/components/label-value-group';
import TimestampLabel from 'src/components/labels/timestamp-label';
import { DevicesQueries, FleetQueries } from 'src/queries';
import { Colors } from 'src/styles';
import { formatStringAsLabel } from 'src/util/string-format.util';

import { AlarmedCollar } from '../../../@types';

type CollarStatusDetailProps = {
  collar: AlarmedCollar;
};

const CollarStatusDetail: FunctionComponent<CollarStatusDetailProps> = ({ collar }) => {
  const { data: deviceOnFarmStates } = FleetQueries.useDeviceOnFarmStatesBySerialNumberQuery(collar.entityId);
  const { data: deviceOffFarmCheckpoint } = FleetQueries.useDeviceOffFarmCheckpointBySerialNumberQuery(collar.entityId);
  const { data: devicePowerOffMode } = DevicesQueries.useDevicePowerOffModeBySerialNumberQuery({
    serialNumber: collar.entityId,
  });

  return (
    <GridSectionContainer heading="Collar Status">
      <LabelValueGroup
        label="Off Farm Checkpoint"
        labelColor={Colors.blue600}
      >
        <Text>{formatStringAsLabel(deviceOffFarmCheckpoint?.checkpoint ?? '')}</Text>
      </LabelValueGroup>

      <LabelValueGroup
        label="On Farm Status"
        labelColor={Colors.blue600}
      >
        <Text>{collar.context.onFarmStatus != null ? formatStringAsLabel(collar.context.onFarmStatus) : 'None'}</Text>
      </LabelValueGroup>

      {deviceOnFarmStates?.states != null && (
        <LabelValueGroup
          label="On Farm States"
          labelColor={Colors.blue600}
        >
          <Flex
            gap={1}
            flexDir="column"
          >
            {deviceOnFarmStates.states.map((state) => (
              <Badge
                key={state}
                width="fit-content"
                px={2}
                py={0.25}
                borderRadius={100}
                fontSize="xx-small"
                bg={Colors.gray600}
                color="white"
              >
                {formatStringAsLabel(state)}
              </Badge>
            ))}
          </Flex>
        </LabelValueGroup>
      )}

      {devicePowerOffMode != null && (
        <LabelValueGroup
          label="Powered Off At"
          labelColor={Colors.blue600}
        >
          <TimestampLabel
            textProps={{ width: 'fit-content' }}
            timestamp={new Date(devicePowerOffMode.poweredOffAt)}
          />
        </LabelValueGroup>
      )}
    </GridSectionContainer>
  );
};

export default CollarStatusDetail;
