import { FC, PropsWithChildren, createContext, useMemo } from 'react';

import { GrafanaPeriod } from 'src/types/grafana-types';

import { AlarmBehaviour, AlarmScope, AlarmSearchMode } from '../@types';
import { MonitoringGlobalStateHooksGenerator } from '../generators';

type IMonitoringGlobalStateContext = {
  useSelectedAlarmBehaviour: () => [AlarmBehaviour, (newBehaviour: AlarmBehaviour) => void];
  useAlarmSearchMode: () => [AlarmSearchMode, (newSearchMode: AlarmSearchMode) => void];
  useGrafanaPeriod: () => [GrafanaPeriod, (period: GrafanaPeriod) => void];
  useItemsPerPage: () => [number, (newItemsPerPage: number) => void];
  useSnoozeAlarmsVisibility: () => [boolean | undefined, (visible: boolean) => void];
  useAlarmKeySelection: () => {
    alarmKeySelection: Record<string, boolean>;
    setAlarmKeySelection: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    clearAlarmKeySelection: () => void;
  };
  useSelectedScope: () => {
    selectedScope: AlarmScope | null;
    setSelectedScope: React.Dispatch<React.SetStateAction<AlarmScope | null>>;
    clearSelectedScope: () => void;
  };
};

export const MonitoringGlobalStateContext = createContext<IMonitoringGlobalStateContext>(null!);

export const MonitoringGlobalStateContextProvider: FC<PropsWithChildren<{ entityKey: string }>> = ({
  entityKey,
  children,
}) => {
  const settingsHooks = MonitoringGlobalStateHooksGenerator.useGenerateMonitoringSettingsHooks({ entityKey });
  const selectionHooks = MonitoringGlobalStateHooksGenerator.useGenerateMonitoringSelectionHooks();

  const value = useMemo(
    () => ({
      ...settingsHooks,
      ...selectionHooks,
    }),
    [settingsHooks, selectionHooks],
  );

  return <MonitoringGlobalStateContext.Provider value={value}>{children}</MonitoringGlobalStateContext.Provider>;
};
