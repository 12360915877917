import {
  IconButton,
  List,
  ListIcon,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import moment from 'moment';
import { FunctionComponent } from 'react';
import { BiRadioCircle } from 'react-icons/bi';
import { FaCheck } from 'react-icons/fa';

import HourClockIcon from 'src/components/icons/hour-clock-icon';
import { Colors } from 'src/styles';
import { getDurationAsUnit, getDurationFormatAsUnit } from 'src/util/date-time.util';

export const QUICK_SNOOZE_DURATION_MS_OPTIONS = [
  moment.duration(1, 'hour').asMilliseconds(),
  moment.duration(12, 'hour').asMilliseconds(),
  moment.duration(1, 'day').asMilliseconds(),
];

export const DEFAULT_QUICK_SNOOZE_DURATION_MS = moment.duration(1, 'day').asMilliseconds();

type QuickSnoozePopoverProps = {
  triggerAlarmAction: (snoozeDurationMs: number) => void;
};

const QuickSnoozePopover: FunctionComponent<QuickSnoozePopoverProps> = ({ triggerAlarmAction }) => {
  const handleSubmit = (snoozeDurationMs: number) => {
    triggerAlarmAction(snoozeDurationMs);
  };

  return (
    <Formik
      initialValues={{ snoozeDurationMs: DEFAULT_QUICK_SNOOZE_DURATION_MS.toString() }}
      onSubmit={(values) => handleSubmit(parseInt(values.snoozeDurationMs, 10))}
    >
      {({ values, setFieldValue, submitForm, isSubmitting }) => (
        <Popover
          placement="left"
          trigger="hover"
          openDelay={500}
          isLazy
        >
          <PopoverTrigger>
            <IconButton
              aria-label="Quick Snooze"
              icon={
                <HourClockIcon
                  hours={getDurationAsUnit(+values.snoozeDurationMs, 'hour')}
                  size="19px"
                  fontSize="8px"
                />
              }
              boxShadow="sm"
              variant="light"
              type="submit"
              isLoading={isSubmitting}
              onClick={submitForm}
            />
          </PopoverTrigger>

          <PopoverContent
            width="200px"
            bgColor={`${Colors.gray800}`}
            borderRadius="5px"
            border="none"
            boxShadow="4px 4px 10px black"
          >
            <PopoverArrow
              boxShadow="none"
              bgColor={`${Colors.gray800}`}
              zIndex={10}
            />
            <PopoverBody
              p={0}
              overflow="hidden"
              borderRadius="inherit"
            >
              <List>
                <ListItem
                  py={3}
                  pl={3}
                  fontSize="md"
                  fontWeight={600}
                  color={Colors.gray200}
                >
                  Snooze
                </ListItem>

                {QUICK_SNOOZE_DURATION_MS_OPTIONS.map((durationOption) => {
                  const isSelected = durationOption === +values.snoozeDurationMs;
                  return (
                    <ListItem
                      key={durationOption}
                      py={3}
                      pl={3}
                      fontWeight={600}
                      color={Colors.gray200}
                      cursor="pointer"
                      _hover={{ bg: Colors.gray700 }}
                      _notLast={{
                        borderBottom: `1px solid ${Colors.gray200}`,
                      }}
                      onClick={() => setFieldValue('snoozeDurationMs', String(durationOption))}
                    >
                      {isSelected ? (
                        <ListIcon
                          as={FaCheck}
                          mr="14px"
                        />
                      ) : (
                        <ListIcon
                          as={BiRadioCircle}
                          fontSize="1.2rem"
                        />
                      )}

                      {getDurationFormatAsUnit(durationOption, 'hour')}
                    </ListItem>
                  );
                })}
              </List>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </Formik>
  );
};

export default QuickSnoozePopover;
