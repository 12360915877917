import { HttpsAgent } from 'agentkeepalive';
import Axios from 'axios';

import Client, { ClientConfiguration, OpenApiClassType } from './interface';

const HTTPS_AGENT = new HttpsAgent();
export const axiosInstance = Axios.create({
  httpsAgent: HTTPS_AGENT,
});

let requestInterceptorId: number | null = null;

const client: Client = {
  instance: axiosInstance,

  setConfiguration: (configuration: ClientConfiguration) => {
    if (requestInterceptorId != null) axiosInstance.interceptors.request.eject(requestInterceptorId);

    requestInterceptorId = axiosInstance.interceptors.request.use(async (config) => {
      const defaultHeaders = await configuration.getHeaders();

      const newHeaders = config.headers;
      newHeaders.set('Authorization', defaultHeaders.Authorization);
      if (newHeaders['context-override'] == null) {
        newHeaders.set('context-override', defaultHeaders['context-override']);
      }

      return {
        ...config,
        baseURL: configuration.baseUrl,
        headers: newHeaders,
      };
    });
  },

  resolveOpenApi: <T>(OpenApi: OpenApiClassType<T>): T => new OpenApi(undefined, '', axiosInstance),

  getRequestOptionsForFarmId: (farmId?: string) => ({
    headers: {
      'context-override': JSON.stringify({ farmId }),
    },
  }),
};

export default client;
