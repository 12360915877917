import { ISettingsTriggerRuleDTO } from '@halter-corp/tower-alarm-service-client';

export enum TowerAlarmSettingsFilterType {
  ALARM_TYPE = 'Alarm Type',
  FILTER_TYPE = 'Filter Type',

  MAINS_POWERED = 'Mains Powered',
  ON_LIVE_FARM = 'On Live Farm',

  CREATED_TIME = 'Created Time',
  UPDATED_TIME = 'Updated Time',
}

export type TowerSettingsTriggerRule = ISettingsTriggerRuleDTO;
