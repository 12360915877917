import { PropsWithChildren, createContext } from 'react';

import { MonitoringFilterConfig } from '../@types';

export const MonitoringFilterConfigContext = createContext<MonitoringFilterConfig<unknown>>(null!);

export const MonitoringFilterConfigContextProvider = ({
  children,
  config,
}: PropsWithChildren<{ config: MonitoringFilterConfig<any> }>) => (
  <MonitoringFilterConfigContext.Provider value={config}>{children}</MonitoringFilterConfigContext.Provider>
);
