import { Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { HomeRouteItem, RouteItemsByTool } from 'src/constants/route-items';
import { Colors } from 'src/styles';

import MenuItem from './menu-item';

type DrawerNavigationProps = {
  collapsed?: boolean;
};

const DrawerNavigation: FC<DrawerNavigationProps> = ({ collapsed = false }) => (
  <Flex
    as="nav"
    flexDir="column"
    mb="auto"
    overflowY="auto"
    rowGap={4}
    className="hide-scrollbar"
  >
    <MenuItem
      key={HomeRouteItem.text}
      collapsed={collapsed}
      {...HomeRouteItem}
    />

    {NAVIGATION_SECTIONS.map(({ name, collapsedName, collapsedPaddingLeft, items }) => (
      <Flex
        key={name}
        flexDir="column"
      >
        <Text
          display="flex"
          alignItems="center"
          height="16px"
          // NOTE: set custom padding left while collapsed to make the text aligned at center.
          // This is to avoid centering the content when collapsed because this causes issues with animation (drawer animation 0.25 seconds but the centering happens with no delay)
          pl={collapsed ? collapsedPaddingLeft : 4}
          mb={1}
          fontWeight="bold"
          fontSize={collapsed ? '2xs' : 'xs'}
          color={Colors.gray300}
          fontFamily="Inter"
          letterSpacing="wider"
          textTransform="uppercase"
          whiteSpace="nowrap"
        >
          {collapsed ? collapsedName : name}
        </Text>

        {items.map((props) => (
          <MenuItem
            key={props.path}
            collapsed={collapsed}
            {...props}
          />
        ))}
      </Flex>
    ))}
  </Flex>
);

const NAVIGATION_SECTIONS = [
  {
    name: 'Main Tools',
    collapsedName: 'Main',
    collapsedPaddingLeft: '13.5px',
    items: RouteItemsByTool.mainTools,
  },
  {
    name: 'Mini Tools',
    collapsedName: 'Mini',
    collapsedPaddingLeft: '16px',
    items: RouteItemsByTool.miniTools,
  },
];

export default DrawerNavigation;
