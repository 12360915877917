import { Flex } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { uniq } from 'lodash';
import { useCallback, useMemo } from 'react';
import { PiCalendarBlankLight } from 'react-icons/pi';

import BaseModal from 'src/components/modals/base-modal';
import { Colors } from 'src/styles';

import { AlarmedEntity } from '../../@types';
import { MonitoringContexts } from '../../contexts';
import EmptyState from '../empty-state';
import AlarmActionSelect from './alarm-action-select';

type AlarmActionModalProps<T> = {
  onClose: () => void;
  isOpen: boolean;

  selectedAlarmAction: string | null;
  setSelectedAlarmAction: (action: string | null) => void;

  /**
   * @summary List of selected alarm entities that the action will be taken on.
   */
  selectedAlarmEntities: AlarmedEntity<T>[];

  /**
   * @summary List of allowed alarm actions that can be taken on the selected entity.
   */
  allowedAlarmActions: string[];

  onSuccessfulAction?: () => void;
};

const AlarmActionModal = <T,>({
  onClose,
  isOpen,
  selectedAlarmAction,
  setSelectedAlarmAction,
  allowedAlarmActions,
  selectedAlarmEntities,
  onSuccessfulAction,
}: AlarmActionModalProps<T>) => {
  const { entityName } = MonitoringContexts.useEntityConfigContext();
  const { getAlarmActionFormByAlarmAction } = MonitoringContexts.useMonitoringActionConfigContext();

  const handleClose = useCallback(() => {
    setSelectedAlarmAction(null);
    onClose();
  }, [setSelectedAlarmAction, onClose]);

  const ActionComponent = useMemo(() => {
    if (selectedAlarmAction == null) return null;
    return getAlarmActionFormByAlarmAction(selectedAlarmAction) ?? null;
  }, [selectedAlarmAction, getAlarmActionFormByAlarmAction]);

  if (selectedAlarmAction != null && ActionComponent == null) {
    throw new Error('Alarm action form is not found for a given action.');
  }

  const selectedEntityIds = useMemo(() => {
    return uniq(selectedAlarmEntities.map((entity) => entity.entityId));
  }, [selectedAlarmEntities]);

  const selectedEventAlarmEntities = useMemo(() => {
    return selectedAlarmEntities.filter((entity) => entity.alarmBehaviour === 'event');
  }, [selectedAlarmEntities]);

  const selectedActiveAlarmEntities = useMemo(() => {
    return selectedAlarmEntities.filter((entity) => entity.alarmBehaviour === 'active');
  }, [selectedAlarmEntities]);

  const hasNoActions = allowedAlarmActions.length === 0;

  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      headerText="Actions"
      size="xl"
    >
      <Flex
        p={0}
        flexDir="column"
        position="relative"
        height={hasNoActions ? 400 : 550}
      >
        <AnimatePresence>
          {hasNoActions && (
            <EmptyState
              key="empty-state"
              flex={1}
              icon={
                <PiCalendarBlankLight
                  fontSize={100}
                  color={Colors.gray500}
                />
              }
              message="No actions available ..."
              textProps={{ fontSize: 'md' }}
            />
          )}

          {selectedAlarmAction == null && (
            <AlarmActionSelect
              entityName={entityName}
              alarmActions={allowedAlarmActions}
              selectedAlarmEntityCount={selectedAlarmEntities.length}
              onSelectAction={setSelectedAlarmAction}
              onClose={onClose}
            />
          )}

          {ActionComponent != null && (
            <ActionComponent
              selectedIds={selectedEntityIds}
              selectedEventAlarmEntities={selectedEventAlarmEntities}
              selectedActiveAlarmEntities={selectedActiveAlarmEntities}
              onSuccessfulSubmit={() => {
                onSuccessfulAction?.();
                onClose();
              }}
              onBack={() => setSelectedAlarmAction(null)}
              onClose={handleClose}
            />
          )}
        </AnimatePresence>
      </Flex>
    </BaseModal>
  );
};

export default AlarmActionModal;
