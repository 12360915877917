import { Textarea, TextareaProps } from '@chakra-ui/react';
import { FunctionComponent, KeyboardEvent } from 'react';

import FormikFormField, { FormikFormFieldProps } from './formik-form-field';

export type FormikTextareaProps = {
  id: string;
  textareaProps?: TextareaProps;
  onTriggerSubmit?: () => void;
} & Omit<FormikFormFieldProps, 'children' | 'htmlFor'>;

const FormikTextarea: FunctionComponent<FormikTextareaProps> = (props) => {
  const { textareaProps, id, onTriggerSubmit, ...otherProps } = props;

  /**
   * Trigger submit form when user press cmd (ctrl) + enter
   */
  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.metaKey === true && event.key === 'Enter') {
      onTriggerSubmit?.();
    }
  };

  return (
    <FormikFormField
      htmlFor={id}
      {...otherProps}
    >
      {([fieldProps, { error }]) => (
        <Textarea
          {...fieldProps}
          id={id}
          value={fieldProps.value ?? ''}
          isInvalid={error != null}
          onKeyDown={handleKeyDown}
          {...textareaProps}
        />
      )}
    </FormikFormField>
  );
};

export default FormikTextarea;
