/**
 * Regex pattern for serial numbers separated by comma
 * e.g. 004-1234-12345,004-5678-56789
 */
export const SERIAL_NUMBERS_INPUT_PATTERN = /^\s*(\d{2,3}-\d{4}-\d{5}\s*(,\s*\d{2,3}-\d{4}-\d{5})*)\s*$/;
export const SERIAL_NUMBER_PATTERN = /^(\d{2,3})-(\d{4})-(\d{5})$/;
export const extractSerialNumbersFromPattern = (serialNumbers: string): string[] => {
  const serialNumbersTrimmed = serialNumbers.trim();
  if (serialNumbersTrimmed === '') {
    return [];
  }
  return serialNumbersTrimmed.split(',').flatMap((serialNumber) => {
    const serialNumberTrimmed = serialNumber.trim();
    if (!SERIAL_NUMBER_PATTERN.test(serialNumberTrimmed)) {
      return [];
    }

    return serialNumberTrimmed;
  });
};

export const COLLAR_BATCH_REGEX = /^\d{1,4}$/;
const RELEASE_FIRMWARE_VERSION_REGEX_PATTERN = /^release\/(\d+)\.(\d+)-(\d+)$/;

export const CollarUtils = {
  getCollarBatch: (serialNumber: string): string | undefined => {
    const batch: string | undefined = serialNumber.split('-')[1];
    if (batch == null) return undefined;
    if (/^\d+$/i.test(batch) === false) return undefined;

    const parsedBatch = parseInt(batch, 10);
    if (Number.isNaN(parsedBatch)) return undefined;

    return String(parsedBatch);
  },

  formatBatch: (batch: string) => batch.padStart(4, '0'),

  isBatch84PlusCollar: (batch?: string): boolean => {
    const batchNumber = Number(batch);
    if (Number.isNaN(batchNumber)) return false;

    if (batchNumber < 9000) return batchNumber >= 84;
    return batchNumber >= 9052;
  },

  deNormalizeMacAddress: (macAddress: string) =>
    macAddress.replace(new RegExp('([0-9A-Fa-f]{2})'.repeat(6), 'g'), '$1:$2:$3:$4:$5:$6').toUpperCase(),

  isReleaseFirmwareVersion: (firmwareVersion: string) => RELEASE_FIRMWARE_VERSION_REGEX_PATTERN.test(firmwareVersion),

  getDebugLink: (serialNumber: string) =>
    `https://halter.grafana.net/d/VQetH7i4z/collar-debug?var-serialNumber=${serialNumber}`,
};
