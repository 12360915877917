import { Box, CloseButton, Flex, Select, Tooltip, useOutsideClick } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FunctionComponent, useRef } from 'react';
import { RiLock2Line } from 'react-icons/ri';

import { useEnvironment } from 'src/providers/environment';
import { getColorByEnvironment, getHoverColorByEnvironment } from 'src/providers/environment/utils';
import { AuthService } from 'src/services';
import { EnvironmentEnum } from 'src/services/configuration.service';
import { Colors } from 'src/styles';

import DrawerNavigation from './drawer-navigation';
import MenuItem from './menu-item';

export type MobileDrawerProps = {
  isOpen: boolean;
  onClose(): void;
};

const MobileDrawer: FunctionComponent<MobileDrawerProps> = ({ isOpen, onClose }) => {
  const { environment, setEnvironment } = useEnvironment();
  const drawerRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: drawerRef,
    handler: onClose,
  });

  const isLocalBuild = process.env.NODE_ENV !== 'production';

  const onSignOutPress = () => {
    void AuthService.signOut(environment);
  };

  const onEnvironmentChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setEnvironment(e.target.value as EnvironmentEnum);
  };

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <Box
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            position="fixed"
            top="0px"
            left="0px"
            width="100vw"
            height="100vh"
            backgroundColor={`${Colors.black}55`}
            mt="0px !important"
            zIndex={100}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isOpen && (
          <Flex
            as={motion.aside}
            ref={drawerRef}
            initial={{ x: -250 }}
            animate={{
              x: 0,
              transition: { ease: 'easeOut', duration: 0.25 },
            }}
            exit={{
              x: -250,
              transition: { ease: 'easeIn', duration: 0.15 },
            }}
            bg="gray.900"
            position="fixed"
            top={0}
            left={0}
            height="100vh"
            mt="0px !important"
            pt={2}
            width={250}
            flexDirection="column"
            zIndex={120}
          >
            <CloseButton
              position="absolute"
              top="20px "
              left="105%"
              bg="gray.900"
              borderRadius={100}
              color={Colors.white}
              onClick={onClose}
            />
            <Flex
              flex={1}
              flexDirection="column"
            >
              <Box p={2}>
                <Box
                  borderRadius={8}
                  overflow="hidden"
                >
                  <Tooltip
                    label={isLocalBuild ? 'Change the environment in env-config.ts' : ''}
                    placement="bottom"
                  >
                    <Select
                      variant="flilled"
                      color="white"
                      overflow="hidden"
                      cursor="pointer"
                      borderRadius={8}
                      _hover={{
                        bg: getHoverColorByEnvironment(environment),
                      }}
                      backgroundColor={getColorByEnvironment(environment)}
                      value={environment}
                      onChange={onEnvironmentChange}
                      disabled={isLocalBuild}
                    >
                      <option value={EnvironmentEnum.DEV}>Development</option>
                      <option value={EnvironmentEnum.STAGING}>Staging</option>
                      <option value={EnvironmentEnum.PRODUCTION}>Production</option>
                    </Select>
                  </Tooltip>
                </Box>
              </Box>

              <DrawerNavigation />

              <MenuItem
                onPress={onSignOutPress}
                icon={RiLock2Line}
                text="Sign Out"
                mt="auto"
                pb={2}
              />
            </Flex>
          </Flex>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileDrawer;
