import {
  ICreateTowerFarmMappingDTO,
  ICreateTowerRequestDTO,
  IDeviceContextDTO,
  IDeviceStatusDTO,
  INetworkDeviceContextDTO,
  ITowerDTO,
  IUpdateTowerRequestDTO,
} from '@halter-corp/tower-service-client';
import { UseQueryOptions, useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import moment from 'moment';

import { TowerApi } from 'src/data';

const TOWER_KEY = 'tower';

export const TowerQueryKeys = {
  towersByFarmId: (farmId: string) => [TOWER_KEY, 'tower', 'farm', farmId],
  deviceContextByTowerId: (towerId: string) => [TOWER_KEY, 'device-context', 'tower', towerId],
  deviceContextsByFarmId: (farmId: string) => [TOWER_KEY, 'device-context', 'farm', farmId],
  networkDeviceContextsByTowerId: (towerId: string) => [TOWER_KEY, 'network-device-context', 'tower', towerId],
  deviceStatusById: (deviceId: string) => [TOWER_KEY, 'device-status', deviceId],
};

export type TowerQueriesState = {
  towers: Record<string, ITowerDTO>;
  deviceContexts: Record<string, IDeviceContextDTO>;
  networkDeviceContexts: Record<string, INetworkDeviceContextDTO>;
};

export const TowerQueries = {
  // Queries
  useTowersByFarmId: (farmId: string, options?: Partial<UseQueryOptions<Record<string, ITowerDTO>>>) =>
    useQuery({
      queryKey: TowerQueryKeys.towersByFarmId(farmId),
      queryFn: async () => {
        const { data } = await TowerApi.tower.getAllTowersByFarmID(farmId);
        return keyBy(data, 'id');
      },
      staleTime: moment.duration(3, 'minutes').asMilliseconds(),
      ...options,
    }),

  useDeviceContextsByFarmId: (farmId: string, options?: Partial<UseQueryOptions<Record<string, IDeviceContextDTO>>>) =>
    useQuery({
      queryKey: TowerQueryKeys.deviceContextsByFarmId(farmId),
      queryFn: async () => {
        const { data } = await TowerApi.deviceContext.getAllDeviceContextsByFarmID(farmId);
        return keyBy(data, ({ id }) => id);
      },
      staleTime: moment.duration(3, 'minutes').asMilliseconds(),
      ...options,
    }),

  useDeviceContextsByTowerId: (
    towerId: string,
    options?: Partial<UseQueryOptions<TowerQueriesState['deviceContexts']>>,
  ) =>
    useQuery({
      queryKey: TowerQueryKeys.deviceContextByTowerId(towerId),
      queryFn: async () => {
        const { data } = await TowerApi.deviceContext.getAllDeviceContextsByTowerID(towerId);
        return keyBy(data, ({ id }) => id);
      },
      staleTime: moment.duration(3, 'minutes').asMilliseconds(),
      ...options,
    }),

  useDeviceContextsByTowerIds: (
    towerIds: string[],
    options?: Partial<UseQueryOptions<TowerQueriesState['deviceContexts']>>,
  ) =>
    useQueries({
      queries: towerIds.map((towerId) => ({
        queryKey: TowerQueryKeys.deviceContextByTowerId(towerId),
        queryFn: async () => {
          const { data } = await TowerApi.deviceContext.getAllDeviceContextsByTowerID(towerId);
          return keyBy(data, ({ id }) => id);
        },
        staleTime: moment.duration(3, 'minutes').asMilliseconds(),
        ...options,
      })),
    }),

  useNetworkDeviceContextsByTowerId: (
    towerId: string,
    options?: Partial<UseQueryOptions<TowerQueriesState['networkDeviceContexts']>>,
  ) =>
    useQuery({
      queryKey: TowerQueryKeys.networkDeviceContextsByTowerId(towerId),
      queryFn: async () => {
        const { data } = await TowerApi.networkDeviceContext.getAllNetworkDeviceContextsByTowerID(towerId);
        return keyBy(data, ({ id }) => id);
      },
      staleTime: moment.duration(3, 'minutes').asMilliseconds(),
      ...options,
    }),

  useNetworkDeviceContextsByTowerIds: (
    towerIds: string[],
    options?: Partial<UseQueryOptions<TowerQueriesState['networkDeviceContexts']>>,
  ) =>
    useQueries({
      queries: towerIds.map((towerId) => ({
        queryKey: TowerQueryKeys.networkDeviceContextsByTowerId(towerId),
        queryFn: async () => {
          const { data } = await TowerApi.networkDeviceContext.getAllNetworkDeviceContextsByTowerID(towerId);
          return keyBy(data, ({ id }) => id);
        },
        staleTime: moment.duration(3, 'minutes').asMilliseconds(),
        ...options,
      })),
    }),

  useDeviceStatusesByIds: (deviceIds: string[], options?: Partial<UseQueryOptions<IDeviceStatusDTO>>) =>
    useQueries({
      queries: deviceIds.map((deviceId) => ({
        queryKey: TowerQueryKeys.deviceStatusById(deviceId),
        queryFn: async () => {
          const { data } = await TowerApi.deviceStatus.getDeviceStatusByID(deviceId);
          return data;
        },
        staleTime: moment.duration(3, 'minutes').asMilliseconds(),
        ...options,
      })),
    }),

  // Mutations
  useCreateTowerMutation: () =>
    useMutation({
      mutationFn: async (request: ICreateTowerRequestDTO) => {
        await TowerApi.tower.createTower(request);
      },
    }),

  useUpdateTowerMutation: () =>
    useMutation({
      mutationFn: async ({ id, ...request }: IUpdateTowerRequestDTO & { id: string }) => {
        await TowerApi.tower.updateTowerByID(id, request);
      },
    }),

  useDeleteTowerMutation: () =>
    useMutation({
      mutationFn: async (id: string) => {
        await TowerApi.tower.deleteTowerByID(id);
      },
    }),

  useCreateTowerFarmMappingMutation: () =>
    useMutation({
      mutationFn: async (request: ICreateTowerFarmMappingDTO) => {
        const { data } = await TowerApi.tower.createTowerFarmMapping(request);
        return data;
      },
    }),

  useDeleteTowerFarmMappingMutation: () =>
    useMutation({
      mutationFn: async ({ farmId, towerId }: { farmId: string; towerId: string }) => {
        await TowerApi.tower.deleteTowerFarmMappingByFarmIDAndTowerID(farmId, towerId);
        return { towerId };
      },
    }),
};
