import { FilterOption, FilterOptionConfig } from 'src/types/filter-types';
import { SearchUtils } from 'src/util/search.util';
import { formatStringAsLabel } from 'src/util/string-format.util';

import { WelfareAlarmFilterType } from '../@types';

export interface WelfareAlarmFilterOption<T> extends FilterOption<T> {
  filterType: WelfareAlarmFilterType;
}

export const WelfareAlarmFilterOptionModel: FilterOptionConfig = {
  create<T>(filterType: WelfareAlarmFilterType, filterText: string, metadata?: T): WelfareAlarmFilterOption<T> {
    return { filterType, filterText, metadata };
  },

  equals<T>(filterOption: WelfareAlarmFilterOption<T>, text: string): boolean {
    switch (filterOption.filterType) {
      case WelfareAlarmFilterType.FARM:
        const farmFilterOption = filterOption as WelfareAlarmFarmFilterOption;
        const equalFarmName = farmFilterOption.filterText.toLowerCase() === text.trim().toLowerCase();
        const equalFarmId = farmFilterOption.metadata.farmId.toLowerCase() === text.trim().toLowerCase();
        return equalFarmName || equalFarmId;
      default:
        return filterOption.filterText.toLowerCase() === text.trim().toLowerCase();
    }
  },

  equalsAny<T>(filterOption: WelfareAlarmFilterOption<T>, textList: string[]): boolean {
    return textList.some((text) => WelfareAlarmFilterOptionModel.equals(filterOption, text));
  },

  matchesPattern<T>(filterOption: WelfareAlarmFilterOption<T>, pattern: string): boolean {
    switch (filterOption.filterType) {
      case WelfareAlarmFilterType.FARM:
        const farmFilterOption = filterOption as WelfareAlarmFarmFilterOption;
        const matchesFarmName = SearchUtils.matchesAnySearchPattern(farmFilterOption.filterText, pattern);
        const matchesFarmId = SearchUtils.matchesAnySearchPattern(farmFilterOption.metadata.farmId, pattern);
        return matchesFarmName || matchesFarmId;
      case WelfareAlarmFilterType.SCOPE:
      case WelfareAlarmFilterType.ALARMS:
        return SearchUtils.matchesAnySearchPattern(formatStringAsLabel(filterOption.filterText), pattern);
      default:
        return SearchUtils.matchesAnySearchPattern(filterOption.filterText, pattern);
    }
  },

  formatFilterText<T>(filterOption: WelfareAlarmFilterOption<T>): string {
    switch (filterOption.filterType) {
      case WelfareAlarmFilterType.ALARMS:
      case WelfareAlarmFilterType.ALARM_BEHAVIOUR:
      case WelfareAlarmFilterType.SCOPE:
        return formatStringAsLabel(filterOption.filterText);
      default:
        return filterOption.filterText;
    }
  },

  formatMetadata<T>(filterOption: WelfareAlarmFilterOption<T>): string | null {
    switch (filterOption.filterType) {
      case WelfareAlarmFilterType.FARM:
        const farmFilterOption = filterOption as WelfareAlarmFarmFilterOption;
        return `ID: ${farmFilterOption.metadata.farmId}`;
      default:
        return null;
    }
  },
};

export interface WelfareAlarmFarmFilterOption extends WelfareAlarmFilterOption<{ farmId: string }> {
  filterType: WelfareAlarmFilterType.FARM;
  metadata: { farmId: string };
}
