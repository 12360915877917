import { MdHealthAndSafety } from 'react-icons/md';

import { ROUTES } from 'src/constants';
import { PAGE_ICONS } from 'src/modules/monitoring/constants';

import {
  AlarmActionConfig,
  AlarmScope,
  EntityConfig,
  MonitoringActionConfig,
  MonitoringFilterConfig,
  NavLinkItem,
  QueryConfig,
  RankConfig,
  ScopeConfig,
  SettingsConfig,
  TableConfig,
} from '../../../@types';
import { farmRankConfig } from '../../../config/rank-config';
import { WelfareAlarmAction, WelfareAlarmEntity, WelfareEntityScopeContext } from '../@types';
import WelfareDiagnosisActionForm from '../components/welfare-diagnosis-action.form';
import WelfareRemoveEventAlarmActionForm from '../components/welfare-remove-event-alarm-action.form';
import { WelfareAlarmFilterOptionModel } from '../models/welfare-alarm-filter-option.model';
import { WelfareAlarmFilterModel } from '../models/welfare-alarm-filter.model';
import WelfareMonitoringService from '../services/welfare-monitoring.service';
import {
  canCopyWelfareMonitoringColumnValue,
  collarWelfareEntityColumns,
  columnHelper,
  commandWelfareEntityColumns,
  cowWelfareEntityColumns,
  extractWelfareMonitoringColumnCopyValue,
  farmWelfareEntityColumns,
  genericWelfareEntityColumns,
  mobWelfareEntityColumns,
} from './column-config';

export const navLinkItems: NavLinkItem[] = [
  {
    href: ROUTES.WelfareAlarmSettings,
    label: 'Welfare Alarm Settings',
    icon: <PAGE_ICONS.AlarmSettings fontSize="1.2rem" />,
  },
];

export const entityConfig: EntityConfig = {
  entityKey: 'welfare',
  entityName: 'welfare',
  entityIcon: MdHealthAndSafety,
};

export const scopeConfig: ScopeConfig = {
  scopeSelectionEnabled: true,
  scopes: [AlarmScope.COMMAND, AlarmScope.COLLAR, AlarmScope.COW, AlarmScope.MOB, AlarmScope.FARM],
};

export const settingsConfig: SettingsConfig = {
  alarmSearchModeEnabled: false,
  grafanaSettingsEnabled: true,
};

const alarmActions: AlarmActionConfig<WelfareAlarmAction>[] = [
  {
    action: 'diagnose',
    associatedAlarmBehaviour: 'event',
  },
  {
    action: 'remove-alarm',
    associatedAlarmBehaviour: 'event',
    // Only allow certain users to be able to remove event alarms. Other users should only use diagnosis action to remove event alarms.
    allowedUserEmails: ['sam.mcgillicudy@halter.co.nz', 'sarah.kyoung.kim@halter.co.nz', 'yunkeun.jo@halter.co.nz'],
  },
];

export const actionConfig: MonitoringActionConfig = {
  alarmActionConfigs: alarmActions,
  getAlarmActionFormByAlarmAction: (alarmAction) => {
    switch (alarmAction as WelfareAlarmAction) {
      case 'diagnose':
        return WelfareDiagnosisActionForm;
      case 'remove-alarm':
        return WelfareRemoveEventAlarmActionForm;
      default:
        return undefined;
    }
  },
};

export const filterConfig: MonitoringFilterConfig<WelfareEntityScopeContext> = {
  useFilterOptionsByType: WelfareMonitoringService.useWelfareAlarmFilterOptionsByType,
  filterProcessConfig: WelfareAlarmFilterModel.getInstance(),
  filterOptionConfig: WelfareAlarmFilterOptionModel,
  searchFilterTypes: WelfareAlarmFilterModel.SEARCH_FILTERS,
  onOffFilterTypes: WelfareAlarmFilterModel.ON_OFF_ALARM_SETTINGS_FILTERS,
  dateRangeFilterTypes: WelfareAlarmFilterModel.DATE_RANGE_FILTERS,
};

export const rankConfig: RankConfig<WelfareAlarmEntity> = {
  extraRankSectionConfigs: [farmRankConfig],
};

const getColumnsByScope = (scope: AlarmScope | null) => {
  switch (scope) {
    case AlarmScope.COLLAR:
      return collarWelfareEntityColumns;
    case AlarmScope.COW:
      return cowWelfareEntityColumns;
    case AlarmScope.MOB:
      return mobWelfareEntityColumns;
    case AlarmScope.FARM:
      return farmWelfareEntityColumns;
    case AlarmScope.COMMAND:
      return commandWelfareEntityColumns;
    default:
      return genericWelfareEntityColumns;
  }
};

export const tableConfig: TableConfig<WelfareEntityScopeContext> = {
  columnHelper,
  getColumns: getColumnsByScope,
  canCopyColumn: canCopyWelfareMonitoringColumnValue,
  extractColumnValueForCopy: extractWelfareMonitoringColumnCopyValue,
};

export const queryConfig: QueryConfig<WelfareEntityScopeContext> = {
  useAlarmEntities: WelfareMonitoringService.useWelfareAlarmEntities,
};
