import React, { FunctionComponent } from 'react';

import { GrafanaPeriod } from 'src/types/grafana-types';
import { getDashboardUrl } from 'src/util/grafana.util';

type GrafanaPanelProps = {
  dashboardId: string;
  panelNumber?: number;
  variables?: Record<string, string>;
  baseUrl?: string;
  style?: React.CSSProperties;
  from?: Date;
  to?: Date;
  lightTheme?: boolean;
  grafanaPeriod?: GrafanaPeriod;
};

const GrafanaPanel: FunctionComponent<GrafanaPanelProps> = ({
  dashboardId,
  panelNumber,
  variables,
  baseUrl = 'https://grafana-oss.tools.halter.io',
  style,
  from,
  to,
  lightTheme = false,
  grafanaPeriod = '7d',
}) => {
  const url = getDashboardUrl(panelNumber, variables, grafanaPeriod, lightTheme, baseUrl, dashboardId, from, to);

  const defaultStyle: React.CSSProperties = {
    borderRadius: '10px',
    width: '100%',
    height: '100%',
  };
  const mergedStyle = { ...defaultStyle, ...style };

  return (
    <iframe
      title="Grafana Monitoring Alarm Panel"
      src={url}
      style={mergedStyle}
    />
  );
};

export default GrafanaPanel;
