import { Flex } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import Header from 'src/components/header';
import Page from 'src/components/page';

import SagemakerAccessButton from '../components/sagemaker-access-button';

const SagemakerScreen: FunctionComponent = () => (
  <Page title="Sagemaker Studio Access">
    <Flex
      width="100%"
      flexDirection="column"
    >
      <Header
        alignSelf="flex-start"
        heading="Sagemaker Studio Access"
      />

      {/* Docs link buttons */}
      <Flex
        flexDirection="row"
        wrap="wrap"
        px={{ base: 4, lg: 8 }}
        py={5}
        gap={5}
      >
        <SagemakerAccessButton />
      </Flex>
    </Flex>
  </Page>
);
export default SagemakerScreen;
