import { BaseFilterModel } from 'src/models/base-filter.model';
import { GenericAlarmSettings } from 'src/modules/monitoring/templates/alarm-settings/@types';
import { OnOffOption, SearchFilterState } from 'src/types/filter-types';

import { TowerAlarmSettingsFilterType, TowerSettingsTriggerRule } from '../@types';

export class TowerAlarmSettingsFilterModel extends BaseFilterModel<
  GenericAlarmSettings<TowerSettingsTriggerRule>,
  TowerAlarmSettingsFilterType
> {
  private static instance: TowerAlarmSettingsFilterModel;

  private constructor() {
    super();
  }

  static getInstance(): TowerAlarmSettingsFilterModel {
    if (this.instance == null) {
      this.instance = new TowerAlarmSettingsFilterModel();
    }
    return this.instance;
  }

  /**
   * Alarm settings filter that have search options such as status, alarms, and farm.
   */
  static SEARCH_ALARM_SETTINGS_FILTERS: TowerAlarmSettingsFilterType[] = [
    TowerAlarmSettingsFilterType.ALARM_TYPE,
    TowerAlarmSettingsFilterType.FILTER_TYPE,
  ];

  static ON_OFF_FILTERS: TowerAlarmSettingsFilterType[] = [TowerAlarmSettingsFilterType.MAINS_POWERED];

  static DATE_RANGE_FILTERS: TowerAlarmSettingsFilterType[] = [
    TowerAlarmSettingsFilterType.CREATED_TIME,
    TowerAlarmSettingsFilterType.UPDATED_TIME,
  ];

  getSearchFilterFunction(
    filterType: TowerAlarmSettingsFilterType,
  ): ((item: GenericAlarmSettings<TowerSettingsTriggerRule>, filterState: SearchFilterState) => boolean) | null {
    switch (filterType) {
      case TowerAlarmSettingsFilterType.ALARM_TYPE:
        return (alarmSettings, filterState) =>
          this.filterBySearchFilterState(filterState, (option) => option === alarmSettings.alarmType);
      case TowerAlarmSettingsFilterType.FILTER_TYPE:
        return (alarmSettings, filterState) =>
          this.filterBySearchFilterState(filterState, (option) => option === alarmSettings.filterType);
      default:
        return null;
    }
  }

  getOnOffFilterFunction(
    filterType: TowerAlarmSettingsFilterType,
  ): ((item: GenericAlarmSettings<TowerSettingsTriggerRule>, onOffOption: OnOffOption) => boolean) | null {
    switch (filterType) {
      case TowerAlarmSettingsFilterType.MAINS_POWERED:
        return (alarmSettings, onOffOption) => {
          if (onOffOption === OnOffOption.ON) return alarmSettings.triggerRule?.mainsPowered === true;
          if (onOffOption === OnOffOption.OFF) return alarmSettings.triggerRule?.mainsPowered === false;
          // If the option is ALL (either ON or OFF in this case), return true if the value is not null
          return alarmSettings.triggerRule?.mainsPowered != null;
        };
      case TowerAlarmSettingsFilterType.ON_LIVE_FARM:
        return (alarmSettings, onOffOption) => {
          if (onOffOption === OnOffOption.ON) return alarmSettings.triggerRule?.onLiveFarm === true;
          if (onOffOption === OnOffOption.OFF) return alarmSettings.triggerRule?.onLiveFarm === false;
          // If the option is ALL (either ON or OFF in this case), return true if the value is not null
          return alarmSettings.triggerRule?.onLiveFarm != null;
        }
      default:
        return null;
    }
  }

  getDateRangeFilterFunction(filterType: TowerAlarmSettingsFilterType) {
    switch (filterType) {
      case TowerAlarmSettingsFilterType.CREATED_TIME:
        return this.getDateRangeFilterMatcher(({ context }) => new Date(context.createdAt));
      case TowerAlarmSettingsFilterType.UPDATED_TIME:
        return this.getDateRangeFilterMatcher(({ context }) => new Date(context.updatedAt));
      default:
        return null;
    }
  }
}
