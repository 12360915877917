import { DomainNameEnum } from '@halter-corp/alarm-registry-service-client';
import { maxBy, minBy } from 'lodash';
import { useMemo } from 'react';

import { AlarmScope } from 'src/modules/monitoring/@types';
import { AlarmRegistryQueries, BusinessQueries } from 'src/queries';
import { TowerAlarmQueries } from 'src/queries/queries/tower-alarm.queries';
import { FilterOption } from 'src/types';

import { AlarmedTower, AlarmedTowerFilterType } from '../@types';
import {
  AlarmedTowerFarmFilterOptionModel,
  AlarmedTowerFilterOptionModel,
} from '../models/alarmed-tower-filter-option.model';

const TowerMonitoringService = {
  useTowerAlarmEntities: () => {
    const towerActiveAlarmsResponse = TowerMonitoringService.useTowerActiveAlarmEntities();

    return {
      data: towerActiveAlarmsResponse.data,
      isLoading: towerActiveAlarmsResponse.isLoading,
      isError: towerActiveAlarmsResponse.isError,
      refetchData: towerActiveAlarmsResponse.refetch,
    };
  },

  useTowerActiveAlarmEntities: () => {
    const {
      data: alarmsContexts,
      isLoading: isAlarmsContextsLoading,
      isError: isAlarmsContextsError,
      refetch,
    } = TowerAlarmQueries.useActionRequiredTowerActiveAlarmsContexts();

    const { data: farmById } = BusinessQueries.useFindAllFarmsQuery();

    const alarmedTowers: AlarmedTower[] = useMemo(() => {
      if (alarmsContexts == null) return [];

      return alarmsContexts.map((alarmContext) => {
        const firstLoggedDate = minBy(alarmContext.alarms, (alarm) => alarm.createdAt)?.createdAt;
        const lastLoggedDate = maxBy(alarmContext.alarms, (alarm) => alarm.createdAt)?.createdAt;

        const farmDTO = alarmContext.context.farmId != null ? farmById?.[alarmContext.context.farmId] : null;

        return {
          entityId: alarmContext.id,
          uniqueKey: alarmContext.id,
          alarmBehaviour: 'active',
          scope: AlarmScope.TOWER,
          farm: farmDTO != null ? { farmId: farmDTO.id, farmName: farmDTO.name } : undefined,
          alarms: alarmContext.alarms.map((alarm) => ({
            type: alarm.type,
            eventDateTime: alarm.createdAt,
          })),
          firstLoggedDate: firstLoggedDate != null ? new Date(firstLoggedDate) : undefined,
          lastLoggedDate: lastLoggedDate != null ? new Date(lastLoggedDate) : undefined,
          alarmedEntity: alarmContext,
          context: alarmContext.context,
        };
      });
    }, [alarmsContexts, farmById]);

    return {
      data: alarmedTowers,
      isLoading: isAlarmsContextsLoading,
      isError: isAlarmsContextsError,
      refetch,
    };
  },

  useAlarmedTowerFilterOptionsByType: () => {
    const { data } = AlarmRegistryQueries.useAlarmSubscriptionsByDomainName(DomainNameEnum.TOWER);
    const alarmTypeOptions = useMemo(() => data?.map((alarm) => alarm.alarmType) ?? [], [data]);

    const { data: farmById } = BusinessQueries.useFindAllFarmsQuery();
    const farms = useMemo(() => Object.values(farmById ?? {}), [farmById]);

    const filterOptionsByType: Map<AlarmedTowerFilterType, FilterOption<unknown>[]> = useMemo(() => {
      return new Map([
        [
          AlarmedTowerFilterType.ALARMS,
          alarmTypeOptions.map((alarm) => AlarmedTowerFilterOptionModel.create(AlarmedTowerFilterType.ALARMS, alarm)),
        ],
        [
          AlarmedTowerFilterType.FARM,
          farms.map((farm) => AlarmedTowerFarmFilterOptionModel.create(farm.name ?? '', farm.id)),
        ],
      ]);
    }, [alarmTypeOptions, farms]);

    return filterOptionsByType;
  },

  useTowerAlarmTypes: () => {
    const { data = [] } = AlarmRegistryQueries.useAlarmSubscriptionsByDomainName(DomainNameEnum.TOWER);

    return useMemo(() => data.map((alarm) => alarm.alarmType), [data]);
  },
};
export default TowerMonitoringService;
