import {
  AlarmActionApi,
  AlarmSettingsApi,
  PowerActiveAlarmApi,
  PowerAlarmSubscriptionApi,
} from '@halter-corp/power-alarm-service-client';

import client from '../client';

const powerAlarmApi = {
  powerActiveAlarm: client.resolveOpenApi(PowerActiveAlarmApi),
  powerAlarmSubscription: client.resolveOpenApi(PowerAlarmSubscriptionApi),
  alarmAction: client.resolveOpenApi(AlarmActionApi),
  alarmSettings: client.resolveOpenApi(AlarmSettingsApi),
};

export default powerAlarmApi;
