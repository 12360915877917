import { Flex, Text } from '@chakra-ui/react';
import { AutoCompleteInputProps, AutoCompleteItemProps } from '@choc-ui/chakra-autocomplete';
import { capitalize } from 'lodash';
import { FunctionComponent, useMemo } from 'react';

import { Colors } from 'src/styles';

import FormikAutoComplete from './formik-auto-complete';

type FormikFarmAutoCompleteProps = {
  defaultValue: string;
  onChange: (farmId: string) => void;
  farms: { id: string; name?: string }[];
  /**
   * @summary Boolean to incidate whether include a "none" option in the list
   */
  includeNoneOption?: boolean;
  /**
   * @summary Value of the none option. Can be customized to be different e.g. "N/A", "-"
   * @default "none"
   */
  noneOptionValue?: string;
  autoCompleteInputProps?: AutoCompleteInputProps;
};

const FormikFarmAutoComplete: FunctionComponent<FormikFarmAutoCompleteProps> = ({
  defaultValue,
  onChange,
  farms,
  autoCompleteInputProps,
  includeNoneOption = true,
  noneOptionValue = 'none',
  ...rest
}) => {
  const autoCompleteItems = useMemo(() => {
    const items: AutoCompleteItemProps[] = [];
    if (includeNoneOption) {
      items.push({
        key: noneOptionValue,
        value: noneOptionValue,
        label: capitalize(noneOptionValue),
      });
    }

    farms.forEach((farm) => {
      items.push({
        key: farm.id,
        value: farm.id,
        label: farm.name,
        children: (
          <Flex flexDir="column">
            <Text color={Colors.gray700}>{farm.name}</Text>
            <Text
              color={Colors.gray500}
              fontSize="sm"
            >
              ID: {farm.id}
            </Text>
          </Flex>
        ),
      });
    });

    return items;
  }, [farms, includeNoneOption, noneOptionValue]);

  return (
    <FormikAutoComplete
      id="farm-id"
      name="farmId"
      label="Farm"
      onChangeText={(farmId) => onChange(farmId)}
      autoCompleteProps={{
        defaultValue,
        openOnFocus: true,
        listAllValuesOnFocus: true,
      }}
      autoCompleteInputProps={{
        name: 'farmId',
        textTransform: 'capitalize',
        bgColor: Colors.gray100,
        ...autoCompleteInputProps,
      }}
      autoCompleteListProps={{
        width: '100%',
        top: '85%',
      }}
      autoCompleteItemsProps={autoCompleteItems}
      {...rest}
    />
  );
};

export default FormikFarmAutoComplete;
