import { QueryKey, QueryClient as ReactQueryClient, SetDataOptions } from '@tanstack/react-query';

import State from './state';
import { QueryFilters, Updater } from './types';

class QueryClient extends ReactQueryClient {
  setClientData = <Key extends keyof State>(
    queryKey: QueryKey,
    updater: Updater<State[Key] | undefined, State[Key]>,
    options?: SetDataOptions,
  ): State[Key] | undefined => this.setQueryData(queryKey, updater, options);

  getClientData = <Key extends keyof State>(queryKey: QueryKey, filters?: QueryFilters) =>
    this.getQueryData<State[Key]>(queryKey, filters);
}

const client = new QueryClient();

export default client;
