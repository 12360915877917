import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import { Colors } from 'src/styles';

type HourClockIconProps = {
  hours: number;
  size: string;
  fontSize: string;
} & BoxProps;

const HourClockIcon: FunctionComponent<HourClockIconProps> = ({ hours, size, fontSize, ...rest }) => (
  <Flex
    width={size}
    height={size}
    borderRadius={100}
    border={`2px solid ${Colors.gray700}`}
    justifyContent="center"
    alignItems="center"
    position="relative"
    {...rest}
  >
    <Text
      fontSize={fontSize}
      fontWeight={600}
    >
      {hours}
    </Text>

    {/* Clock ears */}
    <Box
      position="absolute"
      top="-15%"
      left="-15%"
      width="30%"
      height="30%"
      borderRadius={100}
      bg={Colors.gray700}
    />
    <Box
      position="absolute"
      top="-15%"
      right="-15%"
      width="30%"
      height="30%"
      borderRadius={100}
      bg={Colors.gray700}
    />
  </Flex>
);

export default HourClockIcon;
