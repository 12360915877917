import { FilterOption, FilterOptionConfig } from 'src/types/filter-types';
import { SearchUtils } from 'src/util/search.util';
import { formatStringAsLabel } from 'src/util/string-format.util';

import { CollarAlarmSettingsFilterType } from '../@types';

export interface CollarAlarmSettingsFilterOption<T> extends FilterOption<T> {
  filterType: CollarAlarmSettingsFilterType;
}

export const CollarAlarmSettingsFilterOptionModel: FilterOptionConfig = {
  create<T>(
    filterType: CollarAlarmSettingsFilterType,
    filterText: string,
    metadata?: T,
  ): CollarAlarmSettingsFilterOption<T> {
    return { filterType, filterText, metadata };
  },

  equals<T>(filterOption: CollarAlarmSettingsFilterOption<T>, text: string): boolean {
    switch (filterOption.filterType) {
      case CollarAlarmSettingsFilterType.FARM:
        const farmFilterOption = filterOption as FarmAlarmSettingsFilterOption;
        const equalFarmName = farmFilterOption.filterText.toLowerCase() === text.trim().toLowerCase();
        const equalFarmId = farmFilterOption.metadata.farmId.toLowerCase() === text.trim().toLowerCase();
        return equalFarmName || equalFarmId;
      default:
        return filterOption.filterText.toLowerCase() === text.trim().toLowerCase();
    }
  },

  equalsAny<T>(filterOption: CollarAlarmSettingsFilterOption<T>, textList: string[]): boolean {
    return textList.some((text) => CollarAlarmSettingsFilterOptionModel.equals(filterOption, text));
  },

  matchesPattern<T>(filterOption: CollarAlarmSettingsFilterOption<T>, pattern: string): boolean {
    switch (filterOption.filterType) {
      case CollarAlarmSettingsFilterType.FARM:
        const farmFilterOption = filterOption as FarmAlarmSettingsFilterOption;
        const matchesFarmName = SearchUtils.matchesAnySearchPattern(farmFilterOption.filterText, pattern);
        const matchesFarmId = SearchUtils.matchesAnySearchPattern(farmFilterOption.metadata.farmId, pattern);
        return matchesFarmName || matchesFarmId;
      case CollarAlarmSettingsFilterType.ALARM_TYPE:
      case CollarAlarmSettingsFilterType.ON_FARM_STATUS:
      case CollarAlarmSettingsFilterType.ACTION_TYPE:
      case CollarAlarmSettingsFilterType.FILTER_TYPE:
        return SearchUtils.matchesAnySearchPattern(formatStringAsLabel(filterOption.filterText), pattern);
      default:
        return SearchUtils.matchesAnySearchPattern(filterOption.filterText, pattern);
    }
  },

  formatFilterText<T>(filterOption: CollarAlarmSettingsFilterOption<T>): string {
    switch (filterOption.filterType) {
      case CollarAlarmSettingsFilterType.ALARM_TYPE:
      case CollarAlarmSettingsFilterType.ON_FARM_STATUS:
      case CollarAlarmSettingsFilterType.ACTION_TYPE:
      case CollarAlarmSettingsFilterType.FILTER_TYPE:
        return formatStringAsLabel(filterOption.filterText);
      default:
        return filterOption.filterText;
    }
  },

  formatMetadata<T>(filterOption: CollarAlarmSettingsFilterOption<T>): string | null {
    switch (filterOption.filterType) {
      case CollarAlarmSettingsFilterType.FARM:
        const farmFilterOption = filterOption as FarmAlarmSettingsFilterOption;
        return `ID: ${farmFilterOption.metadata.farmId}`;
      default:
        return null;
    }
  },
};

export interface FarmAlarmSettingsFilterOption extends CollarAlarmSettingsFilterOption<{ farmId: string }> {
  filterType: CollarAlarmSettingsFilterType.FARM;
  metadata: { farmId: string };
}

export const FarmCollarAlarmSettingsFilterOptionModel = {
  create(farmName: string, farmId: string): FarmAlarmSettingsFilterOption {
    return {
      filterType: CollarAlarmSettingsFilterType.FARM,
      filterText: farmName,
      metadata: { farmId },
    };
  },
};
