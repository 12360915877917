import { ButtonProps, IconButton, Tooltip, TooltipProps } from '@chakra-ui/react';
import { FC } from 'react';
import { FaInfo } from 'react-icons/fa6';

type InfoIconButtonProps = {
  helperText?: React.ReactNode;
  ariaLabel?: string;
  tooltipProps?: Partial<TooltipProps>;
  buttonProps?: ButtonProps;
};

const InfoIconButton: FC<InfoIconButtonProps> = ({ helperText, ariaLabel = 'Info', tooltipProps, buttonProps }) => {
  return (
    <Tooltip
      label={helperText}
      textAlign="center"
      pt={1}
      pb={2}
      borderRadius={5}
      {...tooltipProps}
    >
      <IconButton
        aria-label={ariaLabel}
        minWidth="0px"
        width="20px"
        height="20px"
        icon={<FaInfo fontSize="0.7rem" />}
        borderRadius={100}
        {...buttonProps}
      />
    </Tooltip>
  );
};

export default InfoIconButton;
