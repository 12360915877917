import moment from 'moment';

import { ConfigurationService } from 'src/services';
import { GrafanaPeriod } from 'src/types';

export function getDashboardUrl(
  panelNumber: number | undefined,
  variables: Record<string, string> | undefined,
  period: GrafanaPeriod,
  lightTheme: boolean,
  baseUrl: string,
  dashboardId: string,
  from?: Date,
  to?: Date,
) {
  const mode = panelNumber != null ? 'd-solo' : 'd';
  const panel = panelNumber != null ? `panelId=${panelNumber}` : '';
  const environment = ConfigurationService.retrieveCurrentEnvironment().toString();
  const variableString =
    variables != null
      ? Object.keys(variables)
          .map((key) => `var-${key}=${variables[key]}`)
          .join('&')
          .concat(`&var-env=${environment}`)
      : '';

  const time = `from=${from?.getTime() ?? `now-${period}`}&to=${to?.getTime() ?? 'now'}`;

  const theme = `theme=${lightTheme ? 'light' : 'dark'}`;
  const kiosk = 'kiosk';

  const url = `${baseUrl}/${mode}/${dashboardId}?${panel}&${variableString}&${time}&${theme}&${kiosk}`;

  return url;
}

export function subtractTimeByGrafanaPeriod(fromTime: Date, grafanaPeriod: GrafanaPeriod) {
  switch (grafanaPeriod) {
    case '24h':
      return moment(fromTime).subtract(1, 'day').toDate();
    case '7d':
      return moment(fromTime).subtract(7, 'days').toDate();
    case '14d':
      return moment(fromTime).subtract(14, 'days').toDate();
    case '30d':
      return moment(fromTime).subtract(30, 'days').toDate();
  }
}

export function adjustGrafanaTimestampToByDashboardId(dashboardId: string, grafanaTimestampTo: Date | undefined) {
  switch (dashboardId) {
    case 'be10bd64-24d2-492b-a5e3-f143f7f3790e':
      return moment().add(7, 'day').startOf('day').toDate();
    default:
      return grafanaTimestampTo;
  }
}

export function getGoLiveDashboardLink(farmId: string) {
  return `https://halter.grafana.net/d/c91eb439-a206-47ad-a452-de603f4c78de/go-live-dashboard?var-farmId=${farmId}`;
}
