import { DomainNameEnum } from '@halter-corp/alarm-registry-service-client';

import { ROUTES } from 'src/constants';
import { AlarmSettingsTemplateConfig } from 'src/modules/monitoring/templates/alarm-settings/@types';

import { TowerSettingsTriggerRule } from '../@types';
import TowerAlarmSettingsForm from '../components/tower-alarm-settings.form';
import { TowerAlarmSettingsFilterOptionModel } from '../models/tower-alarm-settings-filter-option.model';
import { TowerAlarmSettingsFilterModel } from '../models/tower-alarm-settings-filter.model';
import { TowerAlarmSettingsService } from '../services/tower-alarm-settings.service';

export const TowerAlarmSettingsTemplateConfig: AlarmSettingsTemplateConfig<TowerSettingsTriggerRule> = {
  linkConfig: { goBackLink: ROUTES.TowerMonitoring },
  entityConfig: {
    entityKey: 'tower-alarm-settings',
    domainName: DomainNameEnum.TOWER,
    useBuildTriggerRuleItems: TowerAlarmSettingsService.useBuildSettingsTriggerRuleItems,
  },
  filterConfig: {
    useFilterOptionsByType: TowerAlarmSettingsService.useAlarmSettingsFilterOptionsByType,
    filterProcessConfig: TowerAlarmSettingsFilterModel.getInstance(),
    filterOptionConfig: TowerAlarmSettingsFilterOptionModel,
    searchFilterTypes: TowerAlarmSettingsFilterModel.SEARCH_ALARM_SETTINGS_FILTERS,
    onOffFilterTypes: TowerAlarmSettingsFilterModel.ON_OFF_FILTERS,
    dateRangeFilterTypes: TowerAlarmSettingsFilterModel.DATE_RANGE_FILTERS,
  },
  queryConfig: {
    useAllAlarmSettings: TowerAlarmSettingsService.useAllAlarmSettings,
    usePutAlarmSettings: TowerAlarmSettingsService.usePutAlarmSettings,
    useDeleteAlarmSettings: TowerAlarmSettingsService.useDeleteAlarmSettings,
  },
  actionConfig: {
    AlarmSettingsForm: TowerAlarmSettingsForm,
  },
};
