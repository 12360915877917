import { Text } from '@chakra-ui/react';
import { Formik } from 'formik';
import pluralize from 'pluralize';
import { FC } from 'react';

import ActionButtons from 'src/components/actions/action-buttons';
import ActionFormWrapper from 'src/components/actions/action-form-wrapper';
import { AlarmActionFormProps } from 'src/modules/monitoring/@types';

import { CollarAlarmActionService } from '../../services/collar-alarm-action.service';

const RemoveEventAlarmForm: FC<AlarmActionFormProps> = ({
  onBack,
  onClose,
  selectedEventAlarmEntities,
  onSuccessfulSubmit,
}) => {
  const removeCollarEventAlarms = CollarAlarmActionService.useBatchRemoveCollarEventAlarmActions({
    onSuccess: onSuccessfulSubmit,
  });

  const handleSubmit = async () => {
    await removeCollarEventAlarms(selectedEventAlarmEntities);
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={async (_, { setSubmitting }) => {
        await handleSubmit();
        setSubmitting(false);
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <>
          <ActionFormWrapper
            title="Remove Alarms"
            onBack={onBack}
          >
            <Text
              mb={2}
              fontSize="sm"
            >
              This action will completely remove <Text as="strong">{selectedEventAlarmEntities.length}</Text> event{' '}
              {pluralize('alarm', selectedEventAlarmEntities.length)}.
              <br />
              Note that you can only remove event alarms.
            </Text>
          </ActionFormWrapper>

          <ActionButtons
            confirmButtonText="Confirm"
            onCancel={onClose}
            onSubmit={submitForm}
            isSubmitting={isSubmitting}
          />
        </>
      )}
    </Formik>
  );
};

export default RemoveEventAlarmForm;
