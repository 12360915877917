import {
  AlarmActionTypeEnum,
  AlarmFilterTypeEnum,
  IPutAlarmSettingsRequestDTO,
} from '@halter-corp/power-alarm-service-client';
import { chain } from 'lodash';
import { useMemo } from 'react';
import { GiBarn } from 'react-icons/gi';

import FarmLabel from 'src/components/labels/farm-label';
import {
  AlarmSettingsCompositeIdentifier,
  GenericAlarmSettings,
  GenericAlarmSettingsRequest,
  TriggerRuleDisplayItem,
} from 'src/modules/monitoring/templates/alarm-settings/@types';
import { BusinessQueries, PowerAlarmQueries } from 'src/queries';
import { FilterOption } from 'src/types/filter-types';

import { PowerAlarmSettingsFilterType, PowerSettingsTriggerRule } from '../@types';
import { PowerAlarmSettingsFilterOptionModel } from '../models/power-alarm-settings-filter-option.model';

type MutationResponse = {
  status: 'success' | 'error';
  message: string;
  data?: GenericAlarmSettings<PowerSettingsTriggerRule>;
};

export const PowerAlarmSettingsService = {
  useAllAlarmSettings: (): {
    data: GenericAlarmSettings<PowerSettingsTriggerRule>[];
    isLoading: boolean;
    isError: boolean;
  } => {
    const { data = [], isLoading, isError } = PowerAlarmQueries.useAllAlarmSettings();

    return {
      data,
      isLoading,
      isError,
    };
  },

  usePutAlarmSettings: () => {
    const createAlarmSettingsMutation = PowerAlarmQueries.usePutAlarmSettingsMutation();

    return async (request: GenericAlarmSettingsRequest<PowerSettingsTriggerRule>): Promise<MutationResponse> => {
      try {
        const putPowerAlarmSettingsRequest: IPutAlarmSettingsRequestDTO = {
          alarmType: request.alarmType,
          triggerRule: request.triggerRule,
          actionType: PowerAlarmSettingsService.getValidatedActionType(request.actionType),
          filterType: PowerAlarmSettingsService.getValidatedFilterType(request.filterType),
          expiresAt: request.expiresAt,
          context: request.context,
        };
        const alarmSettings = await createAlarmSettingsMutation.mutateAsync(putPowerAlarmSettingsRequest);

        return {
          status: 'success',
          message: 'Alarm settings created successfully',
          data: alarmSettings,
        };
      } catch (error: any) {
        return {
          status: 'error',
          message: error?.response?.data?.message ?? error.message,
        };
      }
    };
  },

  useDeleteAlarmSettings: () => {
    const deleteAlarmSettingsMutation = PowerAlarmQueries.useDeleteAlarmSettingsMutation();

    return async (
      alarmSettingsIdentifier: AlarmSettingsCompositeIdentifier<PowerSettingsTriggerRule>,
    ): Promise<MutationResponse> => {
      try {
        await deleteAlarmSettingsMutation.mutateAsync({
          alarmType: alarmSettingsIdentifier.alarmType,
          triggerRule: alarmSettingsIdentifier.triggerRule,
          expiresAt: alarmSettingsIdentifier.expiresAt,
        });

        return {
          status: 'success',
          message: 'Alarm settings deleted successfully',
        };
      } catch (error: any) {
        return {
          status: 'error',
          message: error?.response?.data?.message ?? error.message,
        };
      }
    };
  },

  useBuildSettingsTriggerRuleItems: () => {
    const { data: farmById = {} } = BusinessQueries.useFindAllFarmsQuery();

    return (alarmSettings: GenericAlarmSettings<PowerSettingsTriggerRule>): TriggerRuleDisplayItem[] => {
      const { triggerRule } = alarmSettings;
      if (triggerRule == null) return [];

      const triggerRulesListBuilder: TriggerRuleDisplayItem[] = [];
      if (triggerRule.farmId != null) {
        triggerRulesListBuilder.push({
          label: 'Farm ID',
          value: <FarmLabel farm={{ id: triggerRule.farmId, name: farmById[triggerRule.farmId]?.name }} />,
          icon: <GiBarn />,
        });
      }

      return triggerRulesListBuilder;
    };
  },

  getValidatedActionType: (actionType?: string) => {
    if (actionType == null) return undefined;
    if (Object.values(AlarmActionTypeEnum).includes(actionType as AlarmActionTypeEnum)) {
      return actionType as AlarmActionTypeEnum;
    }

    throw new Error(`Received invalid action type: "${actionType}".`);
  },

  getValidatedFilterType: (filterType?: string) => {
    if (filterType == null) return undefined;
    if (Object.values(AlarmFilterTypeEnum).includes(filterType as AlarmFilterTypeEnum)) {
      return filterType as AlarmFilterTypeEnum;
    }

    throw new Error(`Received invalid filter type: "${filterType}".`);
  },

  useAlarmSettingsFilterOptionsByType: (): Map<PowerAlarmSettingsFilterType, FilterOption<unknown>[]> => {
    const { data } = PowerAlarmQueries.useAllAlarmSettings();
    const uniqueAlarmTypes = useMemo(
      () =>
        chain(data)
          .map(({ alarmType }) => alarmType)
          .uniq()
          .sort()
          .value(),
      [data],
    );

    return useMemo(
      () =>
        new Map([
          [
            PowerAlarmSettingsFilterType.ALARM_TYPE,
            uniqueAlarmTypes.map((alarmType) =>
              PowerAlarmSettingsFilterOptionModel.create(PowerAlarmSettingsFilterType.ALARM_TYPE, alarmType),
            ),
          ],
          [
            PowerAlarmSettingsFilterType.FILTER_TYPE,
            uniqueAlarmTypes.map((filterType) =>
              PowerAlarmSettingsFilterOptionModel.create(PowerAlarmSettingsFilterType.FILTER_TYPE, filterType),
            ),
          ],
        ]),
      [uniqueAlarmTypes],
    );
  },
};
