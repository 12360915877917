import { useEffect, useMemo, useState } from 'react';

const DRAWER_STATE_LOCAL_STORAGE_KEY = 'drawer_expanded';
const EXPANDED_WIDTH = 250;
const COLLAPSED_WIDTH = 56;

function useDrawer() {
  const [drawerCollapsed, setDrawerCollapsed] = useState(() => {
    const savedState = localStorage.getItem(DRAWER_STATE_LOCAL_STORAGE_KEY);
    return savedState != null ? savedState === 'true' : true;
  });

  const drawerWidth = useMemo(() => (drawerCollapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH), [drawerCollapsed]);

  useEffect(() => {
    localStorage.setItem(DRAWER_STATE_LOCAL_STORAGE_KEY, String(drawerCollapsed));
  }, [drawerCollapsed]);

  return {
    drawerCollapsed,
    setDrawerCollapsed,
    drawerWidth,
  };
}

export default useDrawer;
