import { Box, Flex, FlexProps } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';

import { Colors } from 'src/styles';

type LabelValueGroupProps = {
  label: React.ReactNode;
  labelColor?: string;
  labelFontWeight?: number;
  children: React.ReactNode;
} & FlexProps;

const LabelValueGroup: FunctionComponent<LabelValueGroupProps> = ({
  label,
  labelColor = Colors.gray600,
  labelFontWeight = 600,
  children,
  ...rest
}) => (
  <Flex
    flexDir="column"
    gap={1}
    {...rest}
  >
    <Box
      color={labelColor}
      fontWeight={labelFontWeight}
      whiteSpace="nowrap"
    >
      {label}
    </Box>
    {children}
  </Flex>
);

export default LabelValueGroup;
