import {
  AlarmApi,
  AlarmDefinitionApi,
  AlarmJobApi,
  AlarmSubscriptionApi,
  CleanUpApi,
} from '@halter-corp/alarm-registry-service-client';

import client from '../client';

const alarmRegistryApi = {
  alarmDefinition: client.resolveOpenApi(AlarmDefinitionApi),
  alarmSubscription: client.resolveOpenApi(AlarmSubscriptionApi),
  alarmJob: client.resolveOpenApi(AlarmJobApi),
  alarm: client.resolveOpenApi(AlarmApi),
  cleanUp: client.resolveOpenApi(CleanUpApi),
};

export default alarmRegistryApi;
