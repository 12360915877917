import { Flex, Radio, RadioGroup, SimpleGrid } from '@chakra-ui/react';
import { IFirmwareVersionRangeRuleDTO } from '@halter-corp/collar-alarm-service-client';
import React, { FunctionComponent, useMemo } from 'react';

import FormikSelect from 'src/components/forms/formik-select';
import { Colors } from 'src/styles';
import { CollarUtils } from 'src/util/collar.util';

import { CollarAlarmSettingsService } from '../services/collar-alarm-settings.service';

export type FirmwareVersionInputType = 'exact' | 'range';

const NoneOption = () => <option value="">None</option>;

type FirmwareVersionInputProps = {
  firmwareVersionInputType: FirmwareVersionInputType;
  firmwareVersion?: string;
  firmwareVersionError?: string;
  firmwareVersionRange?: IFirmwareVersionRangeRuleDTO;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void;
  onChange: (e: React.ChangeEvent<unknown>) => void;
};

const FirmwareVersionInput: FunctionComponent<FirmwareVersionInputProps> = ({
  firmwareVersionInputType,
  firmwareVersion,
  firmwareVersionError,
  firmwareVersionRange,
  setFieldValue,
  onChange,
}) => {
  // NOTE: "Exact" firmware version rule accepts any firmware versions, including non-release versions.
  const existingFirmwareVersions = CollarAlarmSettingsService.useExistingFirmwareVersionsFromActiveAlarms();
  const existingReleaseFirmwareVersions = existingFirmwareVersions.filter((fwv) =>
    CollarUtils.isReleaseFirmwareVersion(fwv),
  );

  const firmwareVersionOptions = useMemo(
    () =>
      existingFirmwareVersions.map((fv) => (
        <option
          key={fv}
          value={fv}
        >
          {fv}
        </option>
      )),
    [existingFirmwareVersions],
  );
  const releaseFirmwareVersionOptions = useMemo(
    () =>
      existingReleaseFirmwareVersions.map((fv) => (
        <option
          key={fv}
          value={fv}
        >
          {fv}
        </option>
      )),
    [existingReleaseFirmwareVersions],
  );

  return (
    <Flex
      flexDir="column"
      gap={2}
    >
      {firmwareVersionInputType === 'exact' ? (
        <FormikSelect
          label="Firmware Version"
          id="firmware-version"
          name="firmwareVersion"
          error={firmwareVersionError}
          selectProps={{ bg: Colors.gray100, value: firmwareVersion, onChange }}
        >
          <NoneOption />
          {firmwareVersionOptions}
        </FormikSelect>
      ) : (
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          columnGap={5}
        >
          <FormikSelect
            label="From Firmware Version"
            id="from-firmware-version"
            name="fromFirmwareVersion"
            selectProps={{
              bg: Colors.gray100,
              value: firmwareVersionRange?.fromVersion,
              onChange: (e) =>
                setFieldValue('firmwareVersionRange', { ...firmwareVersionRange, fromVersion: e.target.value }),
            }}
          >
            <NoneOption />
            {releaseFirmwareVersionOptions}
          </FormikSelect>
          <FormikSelect
            label="To Firmware Version"
            id="to-firmware-version"
            name="toFirmwareVersion"
            selectProps={{
              bg: Colors.gray100,
              value: firmwareVersionRange?.toVersion,
              onChange: (e) =>
                setFieldValue('firmwareVersionRange', { ...firmwareVersionRange, toVersion: e.target.value }),
            }}
          >
            <NoneOption />
            {releaseFirmwareVersionOptions}
          </FormikSelect>
        </SimpleGrid>
      )}
      <RadioGroup
        name="firmwareVersionInputType"
        value={firmwareVersionInputType}
      >
        <Flex gap={5}>
          <Radio
            onChange={onChange}
            value="exact"
          >
            Exact
          </Radio>
          <Radio
            onChange={onChange}
            value="range"
          >
            Range
          </Radio>
        </Flex>
      </RadioGroup>
    </Flex>
  );
};

export default FirmwareVersionInput;
