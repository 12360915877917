import { isEqual, omitBy } from 'lodash';

import { AnyObject } from 'src/types';

import { AlarmSettingsCompositeIdentifier, GenericAlarmSettings } from '../@types';

export const AlarmSettingsUtils = {
  getUniqueIdentifierKey: <T extends AnyObject>(alarmSettings: GenericAlarmSettings<T>): string => {
    const identifier: AlarmSettingsCompositeIdentifier<T> = {
      alarmType: alarmSettings.alarmType,
      triggerRule: alarmSettings.triggerRule,
      expiresAt: alarmSettings.expiresAt,
    };

    return JSON.stringify(identifier);
  },

  areTriggerRulesEqual: <T extends AnyObject>(triggerRule1: T | undefined, triggerRule2: T | undefined): boolean => {
    if (triggerRule1 == null || triggerRule2 == null) {
      return triggerRule1 === triggerRule2;
    }

    const triggerRule1WithNoNullValues = omitBy(triggerRule1, (value) => value == null);
    const triggerRule2WithNoNullValues = omitBy(triggerRule2, (value) => value == null);

    return isEqual(triggerRule1WithNoNullValues, triggerRule2WithNoNullValues);
  },

  areAlarmSettingsEqual: <T extends AnyObject>(
    alarmSettings1: AlarmSettingsCompositeIdentifier<T>,
    alarmSettings2: AlarmSettingsCompositeIdentifier<T>,
  ) => {
    const hasSameAlarmType = alarmSettings1.alarmType === alarmSettings2.alarmType;
    const hasSameTriggerRule = AlarmSettingsUtils.areTriggerRulesEqual(
      alarmSettings1.triggerRule,
      alarmSettings2.triggerRule,
    );

    return hasSameAlarmType && hasSameTriggerRule;
  },
};
