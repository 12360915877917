import { BaseFilterModel } from 'src/models/base-filter.model';
import { GenericAlarmSettings } from 'src/modules/monitoring/templates/alarm-settings/@types';
import { SearchFilterState } from 'src/types/filter-types';

import { WelfareAlarmSettingsFilterType, WelfareSettingsTriggerRule } from '../@types';

export class WelfareAlarmSettingsFilterModel extends BaseFilterModel<
  GenericAlarmSettings<WelfareSettingsTriggerRule>,
  WelfareAlarmSettingsFilterType
> {
  private static instance: WelfareAlarmSettingsFilterModel;

  private constructor() {
    super();
  }

  static getInstance(): WelfareAlarmSettingsFilterModel {
    if (this.instance == null) {
      this.instance = new WelfareAlarmSettingsFilterModel();
    }
    return this.instance;
  }

  /**
   * Alarm settings filter that have search options such as status, alarms, and farm.
   */
  static SEARCH_ALARM_SETTINGS_FILTERS: WelfareAlarmSettingsFilterType[] = [
    WelfareAlarmSettingsFilterType.ALARM_TYPE,
    WelfareAlarmSettingsFilterType.FILTER_TYPE,
  ];

  static DATE_RANGE_FILTERS: WelfareAlarmSettingsFilterType[] = [
    WelfareAlarmSettingsFilterType.CREATED_TIME,
    WelfareAlarmSettingsFilterType.UPDATED_TIME,
  ];

  getSearchFilterFunction(
    filterType: WelfareAlarmSettingsFilterType,
  ): ((item: GenericAlarmSettings<WelfareSettingsTriggerRule>, filterState: SearchFilterState) => boolean) | null {
    switch (filterType) {
      case WelfareAlarmSettingsFilterType.ALARM_TYPE:
        return (alarmSettings, filterState) =>
          this.filterBySearchFilterState(filterState, (option) => option === alarmSettings.alarmType);
      case WelfareAlarmSettingsFilterType.FILTER_TYPE:
        return (alarmSettings, filterState) =>
          this.filterBySearchFilterState(filterState, (option) => option === alarmSettings.filterType);
      default:
        return null;
    }
  }

  getOnOffFilterFunction() {
    return null;
  }

  getDateRangeFilterFunction(filterType: WelfareAlarmSettingsFilterType) {
    switch (filterType) {
      case WelfareAlarmSettingsFilterType.CREATED_TIME:
        return this.getDateRangeFilterMatcher(({ context }) => new Date(context.createdAt));
      case WelfareAlarmSettingsFilterType.UPDATED_TIME:
        return this.getDateRangeFilterMatcher(({ context }) => new Date(context.updatedAt));
      default:
        return null;
    }
  }
}
