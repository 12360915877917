import { Box, Flex, Text } from '@chakra-ui/react';
import { AlarmFilterTypeEnum } from '@halter-corp/tower-alarm-service-client';
import { Form, Formik } from 'formik';
import { isEmpty, omitBy, sortBy } from 'lodash';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import * as yup from 'yup';

import FormSectionWrapper from 'src/components/form-section-wrapper';
import FormikAutoComplete from 'src/components/forms/formik-auto-complete';
import FormikSelect from 'src/components/forms/formik-select';
import FormikTextarea from 'src/components/forms/formik-text-area';
import FormModalActions from 'src/components/modals/form-modal-actions';
import useCommonToast from 'src/hooks/use-common-toast';
import { ANY_ALARM_TYPE, SNOOZE_DURATION_OPTIONS } from 'src/modules/monitoring/constants';
import { AlarmSettingsFormProps, GenericAlarmSettings } from 'src/modules/monitoring/templates/alarm-settings/@types';
import { AuthService } from 'src/services';
import { Colors } from 'src/styles';
import { getDurationFormat } from 'src/util/date-time.util';
import { formatStringAsLabel } from 'src/util/string-format.util';
import { safeParseBoolean } from 'src/util/string-parse.util';

import TowerMonitoringService from '../../services/tower-monitoring.service';
import { TowerSettingsTriggerRule } from '../@types';
import { TowerAlarmSettingsService } from '../services/tower-alarm-settings.service';

type NonOptionValue = 'none';
const NONE_OPTION_VALUE: NonOptionValue = 'none';
const ALARM_FILTER_TYPE_OPTIONS = Object.values(AlarmFilterTypeEnum);

const MAINS_POWERED_OPTIONS = [
  {
    key: 'none',
    value: NONE_OPTION_VALUE,
    label: 'None',
  },
  {
    key: 'on',
    value: true,
    label: 'On',
  },
  {
    key: 'off',
    value: false,
    label: 'Off',
  },
] as const;

const ON_LIVE_FARM_OPTIONS = [
  {
    key: 'none',
    value: NONE_OPTION_VALUE,
    label: 'None',
  },
  {
    key: 'yes',
    value: true,
    label: 'Yes',
  },
  {
    key: 'no',
    value: false,
    label: 'No',
  },
] as const;

const isNoneOrNullable = (value: unknown) => value == null || /^none$/i.test(String(value));

const towerAlarmSettingsSchema = yup.object().shape({
  alarmType: yup.string().required(),
  filterType: yup.string().oneOf(ALARM_FILTER_TYPE_OPTIONS).required(),
  snoozeDurationMs: yup.number().integer().optional(), // Only for snooze filter type

  // settings context
  reason: yup.string().required(),
});

export type TowerAlarmSettingsFormValues = {
  alarmType: string;
  filterType: AlarmFilterTypeEnum;
  snoozeDurationMs?: number;

  // trigger rule
  mainsPowered: boolean | NonOptionValue;
  onLiveFarm: boolean | NonOptionValue;
  dependentAlarmType: string | NonOptionValue;

  // settings context
  reason: string;
};

type TowerAlarmSettingsFormProps = AlarmSettingsFormProps<TowerSettingsTriggerRule>;

const TowerAlarmSettingsForm: FC<TowerAlarmSettingsFormProps> = ({
  onClose,
  onSuccessfulSubmit,
  existingAlarmSettings,
}) => {
  const putAlarmSettings = TowerAlarmSettingsService.usePutAlarmSettings();
  const authUser = AuthService.useAuth();

  const { displayToast } = useCommonToast();
  const towerAlarmTypes = TowerMonitoringService.useTowerAlarmTypes();
  const combinedAlarmTypeOptions = [ANY_ALARM_TYPE, ...towerAlarmTypes];
  const sortedTowerAlarmTypes = sortBy(towerAlarmTypes, (alarmType) => alarmType);

  const [requestErrorMessage, setRequestErrorMessage] = useState<string | undefined>();
  const requestErrorMessageRef = useRef<HTMLParagraphElement>(null);

  const handleSubmit = async (formValues: TowerAlarmSettingsFormValues) => {
    const { filterType, alarmType, reason } = formValues;

    const triggerRule = (() => {
      const ruleObject: TowerSettingsTriggerRule = omitBy(
        {
          mainsPowered: formValues.mainsPowered,
          onLiveFarm: formValues.onLiveFarm,
          dependentAlarmType: formValues.dependentAlarmType,
        },
        (value) => isNoneOrNullable(value),
      );
      return isEmpty(ruleObject) ? undefined : ruleObject;
    })();

    // NOTE: Expires at only exists if the alarm settings is snooze filter type.
    // Otherwise, set expires at to undefined.
    if (formValues.filterType === AlarmFilterTypeEnum.SNOOZE && formValues.snoozeDurationMs == null) {
      setRequestErrorMessage('Snooze duration is required if the filter type is snooze!');
      return;
    }
    const expiresAt =
      formValues.filterType === AlarmFilterTypeEnum.SNOOZE
        ? new Date(Date.now() + formValues.snoozeDurationMs!)
        : undefined;

    const madeBy = `${authUser.firstName} ${authUser.lastName}`;

    const { status, message, data } = await putAlarmSettings({
      alarmType,
      filterType,
      triggerRule,
      expiresAt: expiresAt?.toISOString(),
      context: {
        reason,
        madeBy,
      },
    });

    if (status === 'success') {
      displayToast('success', `Successfully created alarm settings with status ${status}`);
      if (data != null) onSuccessfulSubmit?.(data);
    } else {
      setRequestErrorMessage(message);
      displayToast('error', `Error: could not save alarm settings.`);
    }
  };

  useEffect(() => {
    if (requestErrorMessage != null) requestErrorMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [requestErrorMessage]);

  const initialFormValues = useMemo(() => getInitialFormValues(existingAlarmSettings), [existingAlarmSettings]);

  // Render
  //-----------------------------------------------------

  return (
    <Box>
      <Formik
        initialValues={initialFormValues}
        validationSchema={towerAlarmSettingsSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values);
          setSubmitting(false);
        }}
        validateOnChange={false}
      >
        {({ values, handleChange, errors, isSubmitting, setFieldValue, submitForm }) => {
          const dependentAlarmTypeOptions = [
            {
              key: 'none',
              value: NONE_OPTION_VALUE,
              label: 'None',
            },
            ...sortedTowerAlarmTypes.map((alarmType) => ({
              key: alarmType,
              value: alarmType,
              label: formatStringAsLabel(alarmType),
            })),
          ].filter((alarmType) => alarmType.value !== values.alarmType);

          return (
            <Form>
              <Flex
                px={8}
                py={4}
                mb={2}
                maxHeight="550px"
                overflowY="auto"
                rowGap={5}
                flexDir="column"
              >
                {/* Section 1: base settings */}
                <FormSectionWrapper title="General Settings">
                  <FormikAutoComplete
                    id="alarm-type"
                    name="alarmType"
                    label="Alarm Type"
                    onChangeText={(newAlarmType) => setFieldValue('alarmType', newAlarmType)}
                    autoCompleteProps={{ defaultValue: formatFormDefaultValue(values.alarmType) }}
                    autoCompleteInputProps={{ name: 'alarmType' }}
                    autoCompleteItemsProps={combinedAlarmTypeOptions.map((alarmType) => ({
                      key: alarmType,
                      value: alarmType,
                      label: formatStringAsLabel(alarmType),
                    }))}
                  />

                  <Flex
                    flexDir={{ base: 'column', md: 'row' }}
                    gap={4}
                  >
                    <FormikSelect
                      id="filter-type"
                      label="Filter Type"
                      name="filterType"
                      selectProps={{
                        bg: Colors.gray100,
                      }}
                      error={errors.filterType}
                      onChange={handleChange}
                    >
                      {ALARM_FILTER_TYPE_OPTIONS.map((alarmFilterType) => (
                        <option
                          key={alarmFilterType}
                          value={alarmFilterType}
                        >
                          {formatStringAsLabel(alarmFilterType)}
                        </option>
                      ))}
                    </FormikSelect>

                    {values.filterType === AlarmFilterTypeEnum.SNOOZE && (
                      <FormikSelect
                        id="snooze-duration"
                        label="Snooze Duration"
                        name="snoozeDurationMs"
                        selectProps={{
                          bg: Colors.gray100,
                          onChange: (e) => setFieldValue('snoozeDurationMs', +e.target.value),
                        }}
                        error={errors.snoozeDurationMs}
                      >
                        <option value="">-</option>
                        {SNOOZE_DURATION_OPTIONS.map((durationMs) => (
                          <option
                            key={durationMs}
                            value={durationMs}
                          >
                            {getDurationFormat(durationMs)}
                          </option>
                        ))}
                      </FormikSelect>
                    )}
                  </Flex>
                </FormSectionWrapper>

                {/* Section 2: trigger rule */}
                <FormSectionWrapper title="Trigger Rule">
                  <FormikSelect
                    id="mains-powered"
                    label="Mains Powered"
                    name="mainsPowered"
                    selectProps={{
                      onChange: (e) =>
                        setFieldValue('mainsPowered', safeParseBoolean(e.target.value) ?? NONE_OPTION_VALUE),
                    }}
                    error={errors.mainsPowered}
                  >
                    {MAINS_POWERED_OPTIONS.map(({ label, value }) => (
                      <option
                        key={label}
                        value={String(value)}
                      >
                        {formatStringAsLabel(label)}
                      </option>
                    ))}
                  </FormikSelect>

                  <FormikSelect
                    id="on-live-farm"
                    label="On Live Farm"
                    name="onLiveFarm"
                    selectProps={{
                      onChange: (e) =>
                        setFieldValue('onLiveFarm', safeParseBoolean(e.target.value) ?? NONE_OPTION_VALUE),
                    }}
                    error={errors.mainsPowered}
                  >
                    {ON_LIVE_FARM_OPTIONS.map(({ label, value }) => (
                      <option
                        key={label}
                        value={String(value)}
                      >
                        {formatStringAsLabel(label)}
                      </option>
                    ))}
                  </FormikSelect>

                  <FormikAutoComplete
                    id="dependent-alarm-type"
                    name="dependentAlarmType"
                    label="Dependent Alarm Type"
                    onChangeText={(dependentAlarmType) => setFieldValue('dependentAlarmType', dependentAlarmType)}
                    autoCompleteProps={{ defaultValue: formatStringAsLabel(values.dependentAlarmType) }}
                    autoCompleteInputProps={{
                      name: 'dependentAlarmType',
                      textTransform: 'capitalize',
                    }}
                    autoCompleteItemsProps={dependentAlarmTypeOptions}
                  />
                </FormSectionWrapper>

                {/* Section 3: alarm settings context */}

                <FormSectionWrapper title="Context">
                  <FormikTextarea
                    id="Reason"
                    label="Reason"
                    name="reason"
                    textareaProps={{ value: values.reason }}
                    onTriggerSubmit={submitForm}
                  />
                </FormSectionWrapper>

                {/* Request error message */}
                <Text
                  ref={requestErrorMessageRef}
                  display={requestErrorMessage == null ? 'none' : 'block'}
                  mt={1}
                  fontWeight={600}
                  color={Colors.red500}
                >
                  Error occured: <br />
                  {requestErrorMessage}
                </Text>
              </Flex>

              <FormModalActions
                onClose={onClose}
                isSubmitting={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

const DEFAULT_FILTER_TYPE = AlarmFilterTypeEnum.HIDE;

const getInitialFormValues = (
  existingAlarmSettings?: GenericAlarmSettings<TowerSettingsTriggerRule>,
): TowerAlarmSettingsFormValues => {
  if (existingAlarmSettings == null) return DEFAULT_ALARM_SETTINGS_FORM_VALUES;
  const formValues: TowerAlarmSettingsFormValues = {
    alarmType: existingAlarmSettings.alarmType,
    filterType: isValidFilterType(existingAlarmSettings.filterType)
      ? existingAlarmSettings.filterType
      : DEFAULT_FILTER_TYPE,
    reason: existingAlarmSettings.context.reason,
    mainsPowered: existingAlarmSettings?.triggerRule?.mainsPowered ?? NONE_OPTION_VALUE,
    onLiveFarm: existingAlarmSettings?.triggerRule?.onLiveFarm ?? NONE_OPTION_VALUE,
    dependentAlarmType: existingAlarmSettings.triggerRule?.dependentAlarmType ?? NONE_OPTION_VALUE,
  };

  return formValues;
};

const DEFAULT_ALARM_SETTINGS_FORM_VALUES: TowerAlarmSettingsFormValues = {
  alarmType: '',
  filterType: DEFAULT_FILTER_TYPE,
  reason: '',
  mainsPowered: NONE_OPTION_VALUE,
  onLiveFarm: NONE_OPTION_VALUE,
  dependentAlarmType: NONE_OPTION_VALUE,
};

const isValidFilterType = (filterType: string | undefined): filterType is AlarmFilterTypeEnum => {
  if (filterType == null) return false;
  return ALARM_FILTER_TYPE_OPTIONS.includes(filterType as AlarmFilterTypeEnum);
};

const formatFormDefaultValue = (value: string) => {
  if (value === '') return '-';
  return formatStringAsLabel(value);
};

export default TowerAlarmSettingsForm;
