import { AlarmedEntity } from '../../../@types';

// ENUMS & TYPES
//-----------------------------------------------------
export type PowerAlarmAction = 'snooze';

export enum PowerAlarmFilterType {
  // SEARCH
  ENTITY_ID = 'Entity ID',
  FARM = 'Farm',
  ALARMS = 'Alarms',
  SCOPE = 'Scope',

  // TIME RANGE
  FIRST_LOGGED_DATE = 'First Logged Date',
  LAST_LOGGED_DATE = 'Last Logged Date',
}

// POWER ALARMS
//-----------------------------------------------------
export type PowerAlarmEntity = AlarmedEntity<PowerEntityScopeContext>;

export type PowerEntityScopeContext = {
  collarContext?: PowerCollarContext;
  mobContext?: PowerMobContext;
  farmContext?: PowerFarmContext;
};

export type PowerCollarContext = {
  farmId?: string;
  mobId?: string;
  cattleId?: string;
  cattleName?: string;
};

export type PowerMobContext = {
  farmId: string;
  name: string;
};

export type PowerFarmContext = {
  name?: string;
};
