import { Box, ButtonProps, Flex, Text, Tooltip } from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';

import CopyClopboardButton from 'src/components/buttons/copy-clipboard-button';

type LabelWithCopyProps = {
  text: string;
  copyText: string;
  copyButtonAriaLabel?: string;
  copyButtonProps?: ButtonProps;
  tooltipLabel?: string;
  dark?: boolean;
};

const LabelWithCopy: FunctionComponent<LabelWithCopyProps> = ({
  text,
  copyText,
  copyButtonProps,
  copyButtonAriaLabel = 'Copy',
  tooltipLabel,
  dark,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Tooltip
      hasArrow
      whiteSpace="nowrap"
      fontSize="xs"
      offset={[0, 10]}
      label={tooltipLabel}
    >
      <Flex
        position="relative"
        width="fit-content"
        alignItems="center"
        gap={2}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Text
          isTruncated
          maxWidth="200px"
          overflow="hidden"
        >
          {text}
        </Text>
        <Box
          position="absolute"
          left="110%"
        >
          <CopyClopboardButton
            opacity={isHovered ? 1 : 0}
            copyText={copyText}
            aria-label={copyButtonAriaLabel}
            dark={dark}
            {...copyButtonProps}
          />
        </Box>
      </Flex>
    </Tooltip>
  );
};

export default LabelWithCopy;
