import { Box, Flex } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { MdMyLocation } from 'react-icons/md';

import QuickDebugBadge from 'src/modules/monitoring/components/badges/quick-debug-badge';
import { MonitoringContexts } from 'src/modules/monitoring/contexts';

import { AlarmedTower } from '../@types';
import TowerAlarmBadgeList from './tower-alarm-badge.list';

type TowerAlarmsCellProps = {
  tower: AlarmedTower;
};

const TowerAlarmsCell: FC<TowerAlarmsCellProps> = ({ tower }) => {
  const [grafanaPeriod] = MonitoringContexts.useGrafanaPeriod();
  const [quickDebugVisible, setQuickDebugVisible] = useState(false);

  const grafanaVariables = { towerId: tower.entityId };

  const farmVar = tower.farm?.farmId != null ? `farmId=${tower.farm.farmId}` : '';
  const towerVar = `towerId=${tower.entityId}`;

  const liveViewUrl = `/map/live-view?${[farmVar, towerVar].join('&')}`;

  return (
    <Flex
      rowGap={1}
      maxWidth="200px"
      width="min-content"
      flexWrap="wrap"
      alignItems="center"
    >
      <Flex
        gap="1rem"
        onMouseEnter={() => setQuickDebugVisible(true)}
        onMouseLeave={() => setQuickDebugVisible(false)}
      >
        <TowerAlarmBadgeList
          grafanaVariables={grafanaVariables}
          grafanaPeriod={grafanaPeriod}
          alarms={tower.alarms}
          alarmScope={tower.scope}
        />
        <Box opacity={quickDebugVisible ? 1 : 0}>
          <Flex gap={1}>
            <QuickDebugBadge
              icon={MdMyLocation}
              link={liveViewUrl}
              tooltipLabel="Live View"
            />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default TowerAlarmsCell;
