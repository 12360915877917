import { ButtonProps, Flex, FlexProps, Heading, HeadingProps, IconButton, useDisclosure } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { RxHamburgerMenu } from 'react-icons/rx';

import useWindowSize from 'src/hooks/use-window-size';
import { Colors } from 'src/styles';

import MobileDrawer from './drawers/mobile-drawer';

type HeaderProps = FlexProps & {
  heading?: React.ReactNode;
  children?: React.ReactNode;
  headingProps?: HeadingProps;
  iconButtonProps?: ButtonProps;
};

const Header: FunctionComponent<HeaderProps> = ({ heading, children, headingProps, iconButtonProps, ...rest }) => {
  const { isNarrowScreen } = useWindowSize();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        px={{ base: 4, lg: 8 }}
        py={5}
        as="header"
        alignItems="center"
        {...rest}
      >
        {/* Mobile hamburger menu */}
        <IconButton
          display={isNarrowScreen ? 'flex' : 'none'}
          onClick={onOpen}
          bgColor="transparent"
          _hover={{ bgColor: Colors.gray200 }}
          borderRadius={100}
          aria-label="Toggle sidebar"
          icon={<RxHamburgerMenu fontSize="1.35rem" />}
          mr={2}
          {...iconButtonProps}
        />

        {/* Heading */}
        {heading != null && (
          <Heading
            as="h1"
            size="lg"
            textTransform="capitalize"
            {...headingProps}
          >
            {heading}
          </Heading>
        )}

        {/* Other header elements */}
        {children}
      </Flex>

      {/* Mobile sidebar drawer */}
      {isNarrowScreen && (
        <MobileDrawer
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default Header;
