import { SetStateAction } from 'react';
import { create } from 'zustand';

import { AlarmBehaviour, AlarmScope } from '../@types/index';

interface MonitoringSelectionStore {
  selectedAlarmBehaviour: AlarmBehaviour;
  setSelectedAlarmBehaviour: React.Dispatch<SetStateAction<AlarmBehaviour>>;
  alarmKeySelection: Record<string, boolean>;
  setAlarmKeySelection: React.Dispatch<SetStateAction<Record<string, boolean>>>;
  selectedScope: AlarmScope | null;
  setSelectedScope: React.Dispatch<SetStateAction<AlarmScope | null>>;
}

export const createMonitoringSelectionStore = () => {
  return create<MonitoringSelectionStore>((set) => ({
    selectedAlarmBehaviour: 'active',
    setSelectedAlarmBehaviour: (update) => {
      if (typeof update === 'function') {
        set((state) => ({ selectedAlarmBehaviour: update(state.selectedAlarmBehaviour) }));
      } else {
        set({ selectedAlarmBehaviour: update });
      }
    },
    alarmKeySelection: {},
    setAlarmKeySelection: (update) => {
      if (typeof update === 'function') {
        set((state) => ({ alarmKeySelection: update(state.alarmKeySelection) }));
      } else {
        set({ alarmKeySelection: update });
      }
    },
    selectedScope: null,
    setSelectedScope: (update) => {
      if (typeof update === 'function') {
        set((state) => ({ selectedScope: update(state.selectedScope) }));
      } else {
        set({ selectedScope: update });
      }
    },
  }));
};
