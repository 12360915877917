import {
  ICreateTowerFarmMappingRequestDTO,
  ICreateTowerRequestDTO,
  IInfraProductContextDTO,
  IInfraProductGroupStatusDTO,
  IInfraProductLastMetricDTO,
  IInfraProductStatusDTO,
  IPaddockStatusDTO,
  ITowerCoverageStudyDTO,
  ITowerDTO,
  ITowerDependencyDTO,
  ITowerFarmMappingDTO,
  ITowerMetadataDTO,
  IUpdateTowerMetadataRequestDTO,
  IUpdateTowerRequestDTO,
} from '@halter-corp/infrastructure-service-client';
import { UseQueryOptions, useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import moment from 'moment';

import InfrastructureApi from 'src/data/api/infrastructure.api';

import { queryClient } from '..';

const NOT_FOUND_STATUS_CODE = 404;

const INFRASTRUCTURE_KEY = 'infrastructure';

export type UpdateTowerMetadataRequestProps = IUpdateTowerMetadataRequestDTO & { id: string };

export const InfrastructureQueryKeys = {
  infraProductContext: (infraProductId: string) => [INFRASTRUCTURE_KEY, 'infra-product-context', 'id', infraProductId],
  infraProductContextsByGroupId: (groupId: string) => [
    INFRASTRUCTURE_KEY,
    'infra-product-context',
    'group-id',
    groupId,
  ],
  infraProductContextsByTowerId: (towerId: string) => [
    INFRASTRUCTURE_KEY,
    'infra-product-context',
    'tower-id',
    towerId,
  ],

  infraProductStatus: (infraProductId: string) => [INFRASTRUCTURE_KEY, 'infra-product-status', infraProductId],
  infraProductGroupStatus: (groupId: string) => [INFRASTRUCTURE_KEY, 'infra-product-group-status', groupId],
  infraProductLastMetric: (infraProductId: string) => [INFRASTRUCTURE_KEY, 'infra-product-last-metric', infraProductId],

  towersByLatLngAndAndRadius: (latitude: number, longitude: number, radius: number) => [
    INFRASTRUCTURE_KEY,
    'towers-by-lat-lng-and-radius',
    latitude,
    longitude,
    radius,
  ],
  towerMetadataById: (towerId: string) => [INFRASTRUCTURE_KEY, 'tower-metadata', 'tower-id', towerId],
  towerFarmMappingByTowerId: (towerId: string) => [INFRASTRUCTURE_KEY, 'tower-farm-mapping', 'tower-id', towerId],
  towerDependencyId: (towerId: string) => [INFRASTRUCTURE_KEY, 'tower-dependency', 'tower-id', towerId],

  towerCoverageStudyById: (towerId: string) => [INFRASTRUCTURE_KEY, 'tower-coverage-study', 'tower-id', towerId],

  paddockStatusesByFarmId: (farmId: string) => [INFRASTRUCTURE_KEY, 'paddock-status', 'farm-id', farmId],
};

export type InfrastructureQueriesState = {
  infraProductContext: IInfraProductContextDTO;
  infraProductContexts: IInfraProductContextDTO[];
  infraProductStatus: IInfraProductStatusDTO;
  infraProductGroupStatus: IInfraProductGroupStatusDTO;
  infraProductLastMetric: IInfraProductLastMetricDTO;
  paddockStatus: IPaddockStatusDTO;
};

export const InfrastructureQueries = {
  // Queries
  useInfraProductContextByIds: (
    ids: string[],
    options?: Partial<UseQueryOptions<InfrastructureQueriesState['infraProductContext']>>,
  ) =>
    useQueries({
      queries: ids.map((id) => ({
        queryKey: InfrastructureQueryKeys.infraProductContext(id),
        queryFn: async () => {
          const { data } = await InfrastructureApi.infraProductApi.getInfraProductContextByID(id);
          return data;
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      })),
      ...options,
    }),

  useInfraProductContextsByGroupIds: (
    groupIds: string[],
    options?: Partial<UseQueryOptions<InfrastructureQueriesState['infraProductContexts']>>,
  ) =>
    useQueries({
      queries: groupIds.map((groupId) => ({
        queryKey: InfrastructureQueryKeys.infraProductContextsByGroupId(groupId),
        queryFn: async () => {
          const { data } = await InfrastructureApi.infraProductApi.getAllInfraProductContextsByGroupID(groupId);
          return data;
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      })),
      ...options,
    }),

  useInfraProductContextsByTowerIds: (
    towerIds: string[],
    options?: Partial<UseQueryOptions<InfrastructureQueriesState['infraProductContexts']>>,
  ) =>
    useQueries({
      queries: towerIds.map((towerId) => ({
        queryKey: InfrastructureQueryKeys.infraProductContextsByTowerId(towerId),
        queryFn: async () => {
          const { data } = await InfrastructureApi.infraProductApi.getAllInfraProductContextsByTowerID(towerId);
          return data;
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      })),
      ...options,
    }),

  useInfraProductStatusByIds: (
    ids: string[],
    options?: Partial<UseQueryOptions<InfrastructureQueriesState['infraProductStatus']>>,
  ) =>
    useQueries({
      queries: ids.map((id) => ({
        queryKey: InfrastructureQueryKeys.infraProductStatus(id),
        queryFn: async () => {
          const { data } = await InfrastructureApi.infraProductApi.getInfraProductStatusByID(id);
          return data;
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      })),
      ...options,
    }),

  useInfraProductGroupStatusByIds: (
    ids: string[],
    options?: Partial<UseQueryOptions<InfrastructureQueriesState['infraProductStatus']>>,
  ) =>
    useQueries({
      queries: ids.map((id) => ({
        queryKey: InfrastructureQueryKeys.infraProductGroupStatus(id),
        queryFn: async () => {
          const { data } = await InfrastructureApi.infraProductGroupApi.getInfraProductGroupStatusByID(id);
          return data;
        },
      })),
      ...options,
    }),

  useInfraProductLastMetricById: (
    id: string,
    options: UseQueryOptions<
      InfrastructureQueriesState['infraProductLastMetric'],
      InfrastructureQueriesState['infraProductLastMetric'],
      any
    > = {},
  ) =>
    useQuery({
      queryKey: InfrastructureQueryKeys.infraProductLastMetric(id),
      queryFn: async () => {
        const { data } = await InfrastructureApi.infraProductApi.getInfraProductLastMetricByID(id);
        return data;
      },
      staleTime: moment.duration(1, 'minute').asMilliseconds(),
      refetchInterval: moment.duration(1, 'minute').asMilliseconds(),
      ...options,
    }),

  useInfraProductLastMetricByIds: (
    ids: string[],
    options?: Partial<UseQueryOptions<InfrastructureQueriesState['infraProductLastMetric']>>,
  ) =>
    useQueries({
      queries: ids.map((id) => ({
        queryKey: InfrastructureQueryKeys.infraProductLastMetric(id),
        queryFn: async () => {
          const { data } = await InfrastructureApi.infraProductApi.getInfraProductLastMetricByID(id);
          return data;
        },
        staleTime: moment.duration(1, 'minute').asMilliseconds(),
        refetchInterval: moment.duration(1, 'minute').asMilliseconds(),
      })),
      ...options,
    }),

  useTowersByLatLng: (
    props: { latitude: number; longitude: number; radius: number },
    options?: Partial<UseQueryOptions<ITowerDTO[]>>,
  ) =>
    useQuery({
      queryKey: InfrastructureQueryKeys.towersByLatLngAndAndRadius(props.latitude, props.longitude, props.radius),
      queryFn: async () => {
        const { data } = await InfrastructureApi.towerApi.getAllTowersByLatLngAndRadius(
          props.latitude,
          props.longitude,
          props.radius,
        );
        return data;
      },
      staleTime: moment.duration(3, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(3, 'minutes').asMilliseconds(),
      ...options,
    }),

  useTowerMetadataByIds: (towerIds: string[], options?: Partial<UseQueryOptions<ITowerMetadataDTO>>) => {
    return useQueries({
      queries: towerIds.map((towerId) => ({
        queryKey: InfrastructureQueryKeys.towerMetadataById(towerId),
        queryFn: async () => {
          const { data } = await InfrastructureApi.towerApi.getTowerMetadataByID(towerId);
          return data;
        },
        staleTime: moment.duration(3, 'minute').asMilliseconds(),
        refetchInterval: moment.duration(3, 'minute').asMilliseconds(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        ...options,
      })),
    });
  },

  useTowerFarmMappingsByTowerIds: (towerIds: string[], options?: Partial<UseQueryOptions<ITowerFarmMappingDTO[]>>) => {
    return useQueries({
      queries: towerIds.map((towerId) => ({
        queryKey: InfrastructureQueryKeys.towerFarmMappingByTowerId(towerId),
        queryFn: async () => {
          const { data } = await InfrastructureApi.towerMappingApi.getAllTowerFarmMappingsByTowerID(towerId);
          return data;
        },
        staleTime: moment.duration(3, 'minute').asMilliseconds(),
        refetchInterval: moment.duration(3, 'minute').asMilliseconds(),
        ...options,
      })),
    });
  },

  useTowerFarmMappingById: (towerId: string, options?: Partial<UseQueryOptions<ITowerFarmMappingDTO[]>>) => {
    return useQuery({
      queryKey: InfrastructureQueryKeys.towerFarmMappingByTowerId(towerId),
      queryFn: async () => {
        const { data } = await InfrastructureApi.towerMappingApi.getAllTowerFarmMappingsByTowerID(towerId);
        return data;
      },
      staleTime: moment.duration(3, 'minute').asMilliseconds(),
      refetchInterval: moment.duration(3, 'minute').asMilliseconds(),
      ...options,
    });
  },

  useTowerDependenciesByIds: (towerIds: string[], options?: Partial<UseQueryOptions<ITowerDependencyDTO | null>>) => {
    return useQueries({
      queries: towerIds.map((towerId) => ({
        queryKey: InfrastructureQueryKeys.towerDependencyId(towerId),
        queryFn: async () => {
          try {
            const { data } = await InfrastructureApi.towerDependencyApi.getTowerDependencyByTowerID(towerId);
            return data;
          } catch (err: any) {
            const statusCode = err.response?.status;
            if (statusCode === NOT_FOUND_STATUS_CODE) {
              return null;
            }
            throw err;
          }
        },
        staleTime: moment.duration(3, 'minute').asMilliseconds(),
        refetchInterval: moment.duration(3, 'minute').asMilliseconds(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        ...options,
      })),
    });
  },

  useTowerCoverageStudyById: (towerId: string, options?: Partial<UseQueryOptions<ITowerCoverageStudyDTO | null>>) => {
    return useQuery({
      queryKey: InfrastructureQueryKeys.towerCoverageStudyById(towerId),
      queryFn: async () => {
        try {
          const { data } = await InfrastructureApi.towerCoverageApi.getTowerCoverageStudyByID(towerId);
          return data;
        } catch (err: any) {
          const statusCode = err.response?.status;
          if (statusCode === NOT_FOUND_STATUS_CODE) {
            return null;
          }
          throw err;
        }
      },
      staleTime: moment.duration(3, 'minute').asMilliseconds(),
      refetchInterval: moment.duration(3, 'minute').asMilliseconds(),
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      ...options,
    });
  },

  useTowerCoverageStudiesByIds: (
    towerIds: string[],
    options?: Partial<UseQueryOptions<ITowerCoverageStudyDTO | null>>,
  ) => {
    return useQueries({
      queries: towerIds.map((towerId) => ({
        queryKey: InfrastructureQueryKeys.towerCoverageStudyById(towerId),
        queryFn: async () => {
          try {
            const { data } = await InfrastructureApi.towerCoverageApi.getTowerCoverageStudyByID(towerId);
            return data;
          } catch (err: any) {
            const statusCode = err.response?.status;
            if (statusCode === NOT_FOUND_STATUS_CODE) {
              return null;
            }
            throw err;
          }
        },
        staleTime: moment.duration(3, 'minute').asMilliseconds(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        ...options,
      })),
    });
  },

  usePaddockStatusesByFarmId: (farmId: string, options?: Partial<UseQueryOptions<Record<string, IPaddockStatusDTO>>>) =>
    useQuery({
      queryKey: InfrastructureQueryKeys.paddockStatusesByFarmId(farmId),
      queryFn: async () => {
        const { data } = await InfrastructureApi.paddockApi.getAllPaddockStatusesByFarmID(farmId);
        return keyBy(data, (paddockStatus) => paddockStatus.id);
      },
      staleTime: moment.duration(3, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(3, 'minutes').asMilliseconds(),
      ...options,
    }),

  // Mutations
  useCreateTowerMutation: () =>
    useMutation({
      mutationFn: async (request: ICreateTowerRequestDTO) => {
        await InfrastructureApi.towerApi.createTower(request);
      },
    }),

  useUpdateTowerMutation: () =>
    useMutation({
      mutationFn: async ({ id, ...request }: IUpdateTowerRequestDTO & { id: string }) => {
        await InfrastructureApi.towerApi.updateTowerByID(id, request);
      },
    }),

  useDeleteTowerMutation: () =>
    useMutation({
      mutationFn: async (id: string) => {
        await InfrastructureApi.towerApi.deleteTowerByID(id);
      },
    }),

  useUpdateTowerMetadataMutation: () =>
    useMutation({
      mutationFn: async ({ id, ...request }: UpdateTowerMetadataRequestProps) => {
        await InfrastructureApi.towerApi.updateTowerMetadataByID(id, request);
      },
    }),

  useCreateTowerFarmMappingMutation: () =>
    useMutation({
      mutationFn: async (request: ICreateTowerFarmMappingRequestDTO) => {
        const { data } = await InfrastructureApi.towerMappingApi.createTowerFarmMapping(request);
        return data;
      },
      onSuccess: async ({ towerId }) => {
        await queryClient.invalidateQueries(InfrastructureQueryKeys.towerFarmMappingByTowerId(towerId));
      },
    }),

  useDeleteTowerFarmMappingMutation: () =>
    useMutation({
      mutationFn: async ({ farmId, towerId }: { farmId: string; towerId: string }) => {
        await InfrastructureApi.towerMappingApi.deleteTowerFarmMappingByFarmIDAndTowerID(farmId, towerId);
        return { towerId };
      },
      onSuccess: async ({ towerId }) => {
        await queryClient.invalidateQueries(InfrastructureQueryKeys.towerFarmMappingByTowerId(towerId));
      },
    }),

  usePutTowerCoverageStudyMutation: () =>
    useMutation({
      mutationFn: async (request: ITowerCoverageStudyDTO) => {
        await InfrastructureApi.towerCoverageApi.putTowerCoverageStudy(request);
      },
    }),

  usePutTowerDependencyMutation: () =>
    useMutation({
      mutationFn: async (request: ITowerDependencyDTO) => {
        await InfrastructureApi.towerDependencyApi.putTowerDependency(request);
      },
    }),

  useDeleteTowerDependencyMutation: () =>
    useMutation({
      mutationFn: async (towerId: string) => {
        await InfrastructureApi.towerDependencyApi.deleteTowerDependencyByTowerID(towerId);
      },
    }),
};
