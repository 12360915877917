import {
  AlarmActionApi,
  AlarmSettingsApi,
  CollarActiveAlarmApi,
  CollarAlarmSubscriptionApi,
  CollarEventAlarmApi,
} from '@halter-corp/collar-alarm-service-client';

import client from '../client';

const collarAlarmApi = {
  collarActiveAlarm: client.resolveOpenApi(CollarActiveAlarmApi),
  collarEventAlarm: client.resolveOpenApi(CollarEventAlarmApi),
  collarAlarmSubscription: client.resolveOpenApi(CollarAlarmSubscriptionApi),
  alarmAction: client.resolveOpenApi(AlarmActionApi),
  alarmSettings: client.resolveOpenApi(AlarmSettingsApi),
};

export default collarAlarmApi;
