import { Text, Tooltip } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

type FirmwareVersionCellProps = {
  firmwareVersion: string;
};

const FirmwareVersionCell: FunctionComponent<FirmwareVersionCellProps> = ({ firmwareVersion }) => (
  <Tooltip
    label={firmwareVersion}
    hasArrow
  >
    <Text
      isTruncated
      maxWidth="150px"
      overflow="hidden"
    >
      {firmwareVersion}
    </Text>
  </Tooltip>
);

export default FirmwareVersionCell;
