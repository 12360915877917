import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { IconType } from 'react-icons';

import GrafanaPanel from 'src/components/grafana-panel';
import { Colors } from 'src/styles';

type DebugPanelsGroupProps = {
  icon: IconType;
  dashboardIds: string[];
  label: string;
  serialNumber: string;
};

const QuickDebugPanels: FunctionComponent<DebugPanelsGroupProps> = ({ icon, dashboardIds, label, serialNumber }) => (
  <Flex
    flexDir="column"
    gap={2}
  >
    <Flex
      gap={1}
      alignItems="center"
    >
      {icon({ fontSize: '1.2em' })}
      <Text
        fontWeight={600}
        color={Colors.gray700}
      >
        {label}
      </Text>
    </Flex>

    <SimpleGrid
      gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))"
      rowGap={2}
      columnGap={2}
    >
      {dashboardIds.map((dashboardId) => (
        <Box
          key={dashboardId}
          height="250px"
          bgColor="rgb(24, 27, 31)"
          borderRadius="15px"
          boxShadow="lg"
        >
          <GrafanaPanel
            key={dashboardId}
            dashboardId={dashboardId}
            panelNumber={1}
            variables={{ serialNumber }}
          />
        </Box>
      ))}
    </SimpleGrid>
  </Flex>
);

export default QuickDebugPanels;
