import { useEffect, useRef, useState } from 'react';

const COPY_EFFECT_DURATION_MS = 2000;

function useCopyEffect() {
  const [copiedEffect, setCopiedEffect] = useState(false);
  const copyEffectTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (!copiedEffect) return;

    copyEffectTimerRef.current = setTimeout(() => {
      setCopiedEffect(false);
    }, COPY_EFFECT_DURATION_MS);

    return () => {
      if (copyEffectTimerRef.current != null) clearTimeout(copyEffectTimerRef.current);
    };
  }, [copiedEffect]);

  return {
    copiedEffect,
    showCopiedEffect: () => setCopiedEffect(true),
  };
}

export default useCopyEffect;
