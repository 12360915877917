import { FilterOption, FilterOptionConfig } from 'src/types/filter-types';
import { SearchUtils } from 'src/util/search.util';
import { formatStringAsLabel } from 'src/util/string-format.util';

import { AlarmedTowerFilterType } from '../@types';

export interface AlarmedTowerFilterOption<T> extends FilterOption<T> {
  filterType: AlarmedTowerFilterType;
}

export const AlarmedTowerFilterOptionModel: FilterOptionConfig = {
  create<T>(filterType: AlarmedTowerFilterType, filterText: string, metadata?: T): AlarmedTowerFilterOption<T> {
    return { filterType, filterText, metadata };
  },

  equals<T>(filterOption: AlarmedTowerFilterOption<T>, text: string): boolean {
    switch (filterOption.filterType) {
      case AlarmedTowerFilterType.FARM:
        const farmFilterOption = filterOption as AlarmedTowerFarmFilterOption;
        const matchesFarmName = SearchUtils.matchesAnySearchPattern(farmFilterOption.filterText, text);
        const matchesFarmId = SearchUtils.matchesAnySearchPattern(farmFilterOption.metadata.farmId, text);
        return matchesFarmName || matchesFarmId;
      default:
        return filterOption.filterText.trim().toLowerCase() === text.trim().toLowerCase();
    }
  },

  equalsAny<T>(filterOption: AlarmedTowerFilterOption<T>, textList: string[]): boolean {
    return textList.some((text) => AlarmedTowerFilterOptionModel.equals(filterOption, text));
  },

  matchesPattern<T>(filterOption: AlarmedTowerFilterOption<T>, pattern: string): boolean {
    return SearchUtils.matchesAnySearchPattern(filterOption.filterText, pattern);
  },

  formatFilterText<T>(filterOption: AlarmedTowerFilterOption<T>): string {
    switch (filterOption.filterType) {
      case AlarmedTowerFilterType.ALARMS:
        return formatStringAsLabel(filterOption.filterText);
      default:
        return filterOption.filterText;
    }
  },

  formatMetadata<T>(filterOption: AlarmedTowerFilterOption<T>): string | null {
    switch (filterOption.filterType) {
      case AlarmedTowerFilterType.FARM:
        const farmFilterOption = filterOption as AlarmedTowerFarmFilterOption;
        return `ID: ${farmFilterOption.metadata.farmId}`;
      default:
        return null;
    }
  },
};

export interface AlarmedTowerFarmFilterOption extends AlarmedTowerFilterOption<{ farmId: string }> {
  filterType: AlarmedTowerFilterType.FARM;
  metadata: { farmId: string };
}

export const AlarmedTowerFarmFilterOptionModel = {
  create(farmName: string, farmId: string): AlarmedTowerFarmFilterOption {
    return {
      filterType: AlarmedTowerFilterType.FARM,
      filterText: farmName,
      metadata: { farmId },
    };
  },
};
