import { throttle } from 'lodash';
import { useEffect, useState } from 'react';

export const NARROW_SCREEN_THRESHOLD = 900;

function useWindowSize() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    const getWindowSize = throttle(() => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }, 100);
    window.addEventListener('resize', getWindowSize);

    return () => window.removeEventListener('resize', getWindowSize);
  }, []);

  const isNarrowScreen = width <= NARROW_SCREEN_THRESHOLD;

  return { width, height, isNarrowScreen };
}

export default useWindowSize;
