import { useCallback, useMemo } from 'react';

import { ObjectKeyType, PartialRecord } from 'src/types';
import { OnOffOption } from 'src/types/filter-types';

type UseOnOffFiltersOperationsProps<K extends ObjectKeyType> = {
  onOffFiltersByType: PartialRecord<K, OnOffOption>;
  setOnOffFiltersByType: (filters: PartialRecord<K, OnOffOption>) => void;
  // NOTE: Default value when the on/off filter type is selected in the dropdown menu
  defaultValue: OnOffOption;
};

const useOnOffFiltersOperations = <K extends ObjectKeyType>({
  onOffFiltersByType,
  setOnOffFiltersByType,
  defaultValue,
}: UseOnOffFiltersOperationsProps<K>) => {
  const handleToggleOnOffFilterType = useCallback(
    (filterType: K) => {
      const updatedFilters = { ...onOffFiltersByType };

      if (updatedFilters[filterType] != null) {
        delete updatedFilters[filterType];
      } else {
        updatedFilters[filterType] = defaultValue;
      }

      setOnOffFiltersByType(updatedFilters);
    },
    [onOffFiltersByType, setOnOffFiltersByType, defaultValue],
  );

  const handleResetOnOffFilters = useCallback(() => {
    setOnOffFiltersByType({});
  }, [setOnOffFiltersByType]);

  const handleResetSingleOnOffFilter = useCallback(
    (filterType: K) => {
      const updatedFilters = { ...onOffFiltersByType };
      delete updatedFilters[filterType];

      setOnOffFiltersByType(updatedFilters);
    },
    [onOffFiltersByType, setOnOffFiltersByType],
  );

  const handleSelectOnOffOption = useCallback(
    (filterType: K, onOffOption: OnOffOption) => {
      const updatedFilters = { ...onOffFiltersByType };
      updatedFilters[filterType] = onOffOption;

      setOnOffFiltersByType(updatedFilters);
    },
    [onOffFiltersByType, setOnOffFiltersByType],
  );

  const selectedOnOffFilterTypes: K[] = useMemo(() => Object.keys(onOffFiltersByType) as K[], [onOffFiltersByType]);

  return {
    selectedOnOffFilterOptionByType: onOffFiltersByType,
    selectedOnOffFilterTypes,
    handleToggleOnOffFilterType,
    handleResetOnOffFilters,
    handleResetSingleOnOffFilter,
    handleSelectOnOffOption,
  };
};

export default useOnOffFiltersOperations;
