import { FunctionComponent, useMemo } from 'react';

import 'react-cmdk/dist/cmdk.css';

import CommandPalette, { filterItems, getItemIndex } from 'react-cmdk';

import { useEnvironment } from 'src/providers/environment';
import { EnvironmentEnum } from 'src/services/configuration.service';

type EnvironmentCommandPaletteProps = {
  searchText: string;
  onNavigateBack: () => void;
};

const EnvironmentCommandPalette: FunctionComponent<EnvironmentCommandPaletteProps> = ({
  searchText,
  onNavigateBack,
}) => {
  const { setEnvironment } = useEnvironment();

  const filteredEnvironments = useMemo(
    () =>
      filterItems(
        [
          {
            id: 'navigation',
            items: [
              {
                id: 'back',
                children: 'Back',
                icon: 'ArrowLeftIcon',
                closeOnSelect: false,
                onClick: onNavigateBack,
              },
            ],
          },
          {
            heading: 'Environment',
            id: 'environment',
            items: [
              {
                id: EnvironmentEnum.DEV,
                children: 'Development',
                icon: 'CodeBracketIcon',
                onClick: () => setEnvironment(EnvironmentEnum.DEV),
              },
              {
                id: EnvironmentEnum.STAGING,
                children: 'Staging',
                icon: 'Square3Stack3DIcon',
                onClick: () => setEnvironment(EnvironmentEnum.STAGING),
              },
              {
                id: EnvironmentEnum.PRODUCTION,
                children: 'Production',
                icon: 'RocketLaunchIcon',
                onClick: () => setEnvironment(EnvironmentEnum.PRODUCTION),
              },
            ],
          },
        ],
        searchText,
      ),
    [searchText, setEnvironment, onNavigateBack],
  );

  return (
    <CommandPalette.Page
      id="environment"
      onEscape={onNavigateBack}
      searchPrefix={['Environment']}
    >
      {(filteredEnvironments.length > 0) ? (
        filteredEnvironments.map((list) => (
          <CommandPalette.List
            key={list.id}
            heading={list.heading}
          >
            {list.items.map(({ id, ...rest }) => (
              <CommandPalette.ListItem
                key={id}
                index={getItemIndex(filteredEnvironments, id)}
                {...rest}
              />
            ))}
          </CommandPalette.List>
        ))
      ) : (
        <CommandPalette.FreeSearchAction />
      )}
    </CommandPalette.Page>
  );
};

export default EnvironmentCommandPalette;
