import { AlarmActionTypeEnum, ICollarAlarmSnoozeActionContextDTO } from '@halter-corp/collar-alarm-service-client';
import moment from 'moment';
import { FunctionComponent } from 'react';
import * as yup from 'yup';

import FormikSelect from 'src/components/forms/formik-select';
import { AlarmedEntity } from 'src/modules/monitoring/@types';
import BaseCollarActionForm from 'src/modules/monitoring/components/alarm-action-forms/collar-action-forms/base-collar-action-form';
import { SNOOZE_DURATION_OPTIONS } from 'src/modules/monitoring/constants/snooze-duration';
import { getExpiresAt } from 'src/modules/monitoring/utils/snooze.util';
import { SERIAL_NUMBERS_INPUT_PATTERN, extractSerialNumbersFromPattern } from 'src/util/collar.util';
import { getDurationFormat } from 'src/util/date-time.util';

import { CollarAlarmActionService } from '../../services/collar-alarm-action.service';

export const DEFAULT_SNOOZE_DURATION_MS = moment.duration(1, 'hour').asMilliseconds();

const snoozeActionSchema = yup.object().shape({
  serialNumbers: yup
    .string()
    .required()
    .matches(SERIAL_NUMBERS_INPUT_PATTERN, 'Each serial number should have valid format saparated by a comma'),
  snoozeDurationMs: yup.number().integer().required(),
  comments: yup.string(),
});

type SnoozeActionFormValues = {
  serialNumbers: string;
  snoozeDurationMs: number;
  comments: string;
};

type SnoozeActionFormProps = {
  selectedIds: string[];
  selectedActiveAlarmEntities: AlarmedEntity<unknown>[];
  onSuccessfulSubmit: () => void;
  onBack: () => void;
  onClose: () => void;
};

const SnoozeActionForm: FunctionComponent<SnoozeActionFormProps> = ({
  onBack,
  onClose,
  onSuccessfulSubmit,
  selectedIds,
  selectedActiveAlarmEntities,
}) => {
  const { sendBatchActionRequests } =
    CollarAlarmActionService.useBatchCollarAlarmActions<ICollarAlarmSnoozeActionContextDTO>({
      actionType: AlarmActionTypeEnum.SNOOZE,
      onSuccess: onSuccessfulSubmit,
    });

  const handleSubmit = async ({
    serialNumbers: serialNumbersString,
    snoozeDurationMs,
    comments,
  }: SnoozeActionFormValues) => {
    const allSerialNumbers = extractSerialNumbersFromPattern(serialNumbersString);

    // Step 1: Snooze all serials for each active alarm type
    const serialNumbersWithAlarmContext = selectedActiveAlarmEntities.map((entity) => entity.entityId);
    const alarmMap: Record<string, string[]> = {};
    selectedActiveAlarmEntities.forEach((entity) => {
      entity.alarms.forEach((alarm) => {
        if (alarmMap[alarm.type] == null) {
          alarmMap[alarm.type] = [];
        }
        alarmMap[alarm.type].push(entity.entityId);
      });
    });

    const alarmList = Object.keys(alarmMap).map((alarm) => ({
      alarm,
      serialNumbers: alarmMap[alarm],
    }));

    const snoozeRequests = alarmList.map(({ alarm, serialNumbers }) => {
      const context: ICollarAlarmSnoozeActionContextDTO = {
        expiresAt: getExpiresAt(snoozeDurationMs),
        alarmTypes: [alarm],
      };
      return sendBatchActionRequests(serialNumbers, context, comments);
    });

    await Promise.all(snoozeRequests);

    // Step 2: Snooze serials that don't have alarm context (e.g. were manually typed)
    const serialsWithoutAlarmContext = allSerialNumbers.filter(
      (serial) => !serialNumbersWithAlarmContext.includes(serial),
    );
    if (serialsWithoutAlarmContext.length > 0) {
      const context: ICollarAlarmSnoozeActionContextDTO = {
        expiresAt: getExpiresAt(snoozeDurationMs),
        alarmTypes: ['ANY'],
      };
      await sendBatchActionRequests(serialsWithoutAlarmContext, context, comments);
    }
  };

  return (
    <BaseCollarActionForm
      title="Snooze Device"
      initialValues={{
        serialNumbers: selectedIds.join(', '),
        snoozeDurationMs: DEFAULT_SNOOZE_DURATION_MS,
        comments: '',
      }}
      validationSchema={snoozeActionSchema}
      onBack={onBack}
      onSubmit={(values) =>
        handleSubmit({
          ...values,
          // NOTE: Formik returns string for number input, so need to parse it
          snoozeDurationMs: Number(values.snoozeDurationMs),
        })
      }
      onClose={onClose}
    >
      {({ values, errors }) => (
        <FormikSelect
          id="snooze-duration"
          label="Snooze Duration"
          name="snoozeDurationMs"
          placeholder="Select snooze duration"
          selectProps={{ value: values.snoozeDurationMs }}
          error={errors.snoozeDurationMs}
          mb={5}
        >
          {SNOOZE_DURATION_OPTIONS.map((durationMs) => (
            <option
              key={durationMs}
              value={durationMs}
            >
              {getDurationFormat(durationMs)}
            </option>
          ))}
        </FormikSelect>
      )}
    </BaseCollarActionForm>
  );
};

export default SnoozeActionForm;
