import {
  AlarmActionApi,
  AlarmSettingsApi,
  TowerActiveAlarmApi,
  TowerAlarmSubscriptionApi,
} from '@halter-corp/tower-alarm-service-client';

import client from '../client';

const towerAlarmApi = {
  towerActiveAlarm: client.resolveOpenApi(TowerActiveAlarmApi),
  towerAlarmSubscription: client.resolveOpenApi(TowerAlarmSubscriptionApi),
  alarmSettings: client.resolveOpenApi(AlarmSettingsApi),
  alarmAction: client.resolveOpenApi(AlarmActionApi),
};

export default towerAlarmApi;
