import React, { FunctionComponent } from 'react';
import { AiFillHome } from 'react-icons/ai';
import { BsBank2, BsClipboardDataFill } from 'react-icons/bs';
import { FaCameraRotate, FaEarthOceania, FaMicrochip, FaTruck } from 'react-icons/fa6';
import { GiOlive } from 'react-icons/gi';
import { GoPackage } from 'react-icons/go';
import { HiOutlineDeviceMobile, HiOutlineDocumentSearch } from 'react-icons/hi';
import { IoIosConstruct, IoMdMap, IoMdSettings } from 'react-icons/io';
import { IoFlagOutline, IoGameController, IoHardwareChipSharp } from 'react-icons/io5';
import { MdOutlineScience, MdTerrain } from 'react-icons/md';
import { RiBatteryChargeLine, RiDashboardFill, RiDatabase2Fill } from 'react-icons/ri';
import { SiCommerzbank, SiOpenapiinitiative } from 'react-icons/si';
import {
  TbBellRingingFilled,
  TbBuildingWarehouse,
  TbDeviceDesktopAnalytics,
  TbTools,
  TbTruckDelivery,
} from 'react-icons/tb';
import { VscDebugAlt, VscRadioTower } from 'react-icons/vsc';

import { ICONS } from 'src/assets';
import { MONITORING_DOMAIN_ICONS } from 'src/modules/monitoring';

import { ROUTES } from './index';

export type RouteItem =
  | {
      type: 'parent';
      text: string;
      path: string;
      icon: FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
      childItems: LeafRouteItem[];
    }
  | {
      type: 'standalone';
      text: string;
      path: string;
      icon: FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
    };

// Route item with no more nested routes
export type LeafRouteItem = {
  text: string;
  path: string;
  icon: FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
};

const DataPlatformRouteItems: LeafRouteItem[] = [
  {
    text: 'Sagemaker',
    path: ROUTES.Sagemaker,
    icon: MdOutlineScience,
  },
  {
    text: 'Dbt Docs',
    path: ROUTES.DbtDocs,
    icon: RiDatabase2Fill,
  },
];

const MonitoringRouteItems: LeafRouteItem[] = [
  {
    text: 'Collar Monitoring',
    path: ROUTES.CollarMonitoring,
    icon: MONITORING_DOMAIN_ICONS.Collar,
  },
  {
    text: 'Farm Ops Monitoring',
    path: ROUTES.FarmOpsMonitoring,
    icon: MONITORING_DOMAIN_ICONS.FarmOps,
  },
  {
    text: 'Welfare Monitoring',
    path: ROUTES.WelfareMonitoring,
    icon: MONITORING_DOMAIN_ICONS.Welfare,
  },
  {
    text: 'Tower Monitoring',
    path: ROUTES.TowerMonitoring,
    icon: MONITORING_DOMAIN_ICONS.Tower,
  },
  {
    text: 'Power Monitoring',
    path: ROUTES.PowerMonitoring,
    icon: MONITORING_DOMAIN_ICONS.Power,
  },
  {
    text: 'Alarm Registration',
    path: ROUTES.AlarmRegistration,
    icon: TbBellRingingFilled,
  },
];

export const LiveViewMapRouteItem = {
  text: 'Live View',
  path: ROUTES.LiveViewMap,
  icon: GiOlive,
};

export const DebugMapRouteItem = {
  text: 'Debug',
  path: ROUTES.DebugMap,
  icon: VscDebugAlt,
};

export const TowerModellingMapRouteItem = {
  text: 'Tower Modelling',
  path: ROUTES.TowerModellingMap,
  icon: MdTerrain,
};

export const FeatureMappingMapRouteItem = {
  text: 'Feature Mapping',
  path: ROUTES.FeatureMappingMap,
  icon: IoMdMap,
};

const MapRouteItems: LeafRouteItem[] = [
  LiveViewMapRouteItem,
  DebugMapRouteItem,
  TowerModellingMapRouteItem,
  FeatureMappingMapRouteItem,
];

export const GeneralMatrixRouteItem = {
  text: 'General',
  path: ROUTES.GeneralMatrix,
  icon: IoMdSettings,
};

export const PowerMatrixRouteItem = {
  text: 'Power',
  path: ROUTES.PowerMatrix,
  icon: RiBatteryChargeLine,
};

const MatrixRouteItems: LeafRouteItem[] = [GeneralMatrixRouteItem, PowerMatrixRouteItem];

const DocumentationRouteItems: LeafRouteItem[] = [
  {
    text: 'Dbt Docs',
    path: ROUTES.DbtDocs,
    icon: RiDatabase2Fill,
  },
  {
    text: 'Protobuf Docs',
    path: ROUTES.ProtobufDocs,
    icon: SiCommerzbank,
  },
  {
    text: 'Openapi Docs',
    path: ROUTES.OpenapiDocs,
    icon: SiOpenapiinitiative,
  },
];

const HardwareRouteItems: LeafRouteItem[] = [
  {
    text: 'Collar Build Plan',
    path: ROUTES.CollarBuildPlan,
    icon: IoIosConstruct,
  },
  {
    text: 'Collar Hardware',
    path: ROUTES.CollarHardware,
    icon: ICONS.CollarShape,
  },
  {
    text: 'Tower Build Plan',
    path: ROUTES.TowerBuildPlan,
    icon: VscRadioTower,
  },
  {
    text: 'NFC Tool',
    path: ROUTES.NfcTool,
    icon: IoGameController,
  },
  {
    text: 'Refurb Prep',
    path: ROUTES.RefurbPrep,
    icon: TbTools,
  },
  {
    text: 'Release Channel',
    path: ROUTES.ReleaseChannel,
    icon: FaMicrochip,
  },
];

const MobileRouteItems: LeafRouteItem[] = [
  {
    text: 'Feature Flags',
    path: ROUTES.FeatureFlags,
    icon: IoFlagOutline,
  },
  {
    text: 'Package Manager',
    path: ROUTES.PackageManager,
    icon: GoPackage,
  },
];

const FleetRouteItems: LeafRouteItem[] = [
  {
    text: 'Warehouse Management',
    path: ROUTES.WarehouseManagment,
    icon: TbBuildingWarehouse,
  },
  {
    text: 'Dispatch',
    path: ROUTES.Dispatch,
    icon: TbTruckDelivery,
  },
  {
    text: 'Farm Snapshot',
    path: ROUTES.FarmSnapshot,
    icon: FaCameraRotate,
  },
  {
    text: 'Finance Collar Usage',
    path: ROUTES.FinanceCollarUsage,
    icon: BsBank2,
  },
];

export const HomeRouteItem: RouteItem = {
  type: 'standalone',
  text: 'Home',
  path: ROUTES.Home,
  icon: AiFillHome,
};

const MainToolRouteItems: RouteItem[] = [
  {
    type: 'parent',
    text: 'Matrix',
    path: ROUTES.Matrix,
    icon: RiDashboardFill,
    childItems: MatrixRouteItems,
  },
  {
    type: 'parent',
    text: 'Map',
    path: ROUTES.Map,
    icon: FaEarthOceania,
    childItems: MapRouteItems,
  },
  {
    type: 'parent',
    text: 'Monitoring',
    path: ROUTES.Monitoring,
    icon: TbDeviceDesktopAnalytics,
    childItems: MonitoringRouteItems,
  },
];

const MiniToolRouteItems: RouteItem[] = [
  {
    type: 'parent',
    text: 'Documentation',
    path: ROUTES.Documentation,
    icon: HiOutlineDocumentSearch,
    childItems: DocumentationRouteItems,
  },
  {
    type: 'parent',
    text: 'Data',
    path: ROUTES.DataTools,
    icon: BsClipboardDataFill,
    childItems: DataPlatformRouteItems,
  },
  {
    type: 'parent',
    text: 'Fleet',
    path: ROUTES.FleetTools,
    icon: FaTruck,
    childItems: FleetRouteItems,
  },
  {
    type: 'parent',
    text: 'Hardware',
    path: ROUTES.HardwareTools,
    icon: IoHardwareChipSharp,
    childItems: HardwareRouteItems,
  },
  {
    type: 'parent',
    text: 'Mobile',
    path: ROUTES.MobileTools,
    icon: HiOutlineDeviceMobile,
    childItems: MobileRouteItems,
  },
];

export const RouteItemsByTool = {
  mainTools: MainToolRouteItems,
  miniTools: MiniToolRouteItems,
};

export const RouteItems = [...MainToolRouteItems, ...MiniToolRouteItems];
