import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { chunk } from 'lodash';
import moment from 'moment';
import Papa, { ParseConfig } from 'papaparse';

import { BffDebugQueries } from 'src/queries/queries/bff-debug-tool.queries';
import { PlatformApiQueries } from 'src/queries/queries/platform-api.queries';
import { Nullable } from 'src/types';

const ITEMS_TO_PUSH_AT_ONCE = 65536;

const AthenaQueryService = {
  useQuery: <T>(
    queryKey: QueryKey,
    queryString: string,
    queryOptions?: Partial<UseQueryOptions<T[]>>,
    queryConfig?: Omit<ParseConfig<T>, 'complete'>,
  ) => {
    return useQuery({
      queryKey: ['athena-query', queryKey],
      queryFn: async () => {
        return AthenaQueryService.query(queryString, queryConfig);
      },
      // NOTE: default do not refetch on mount, reconnect, or window focus since the historical data does not change on these conditions.
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...queryOptions,
    });
  },

  query: async <T>(queryString: string, config?: Omit<ParseConfig<T>, 'complete'>): Promise<T[]> => {
    const queryExecution = await BffDebugQueries.executeAthenaQuery(queryString);
    if (queryExecution.resultUrl == null) {
      throw new Error(`Query execution result url is null; query execution: ${JSON.stringify(queryExecution)}`);
    }

    // Note: get the athena query result as blob
    const { data } = await axios({
      url: queryExecution.resultUrl,
      method: 'GET',
      responseType: 'blob',
    });
    return new Promise((resolve) => {
      const items: T[] = [];
      const reader = new FileReader();
      reader.onload = () => {
        const text = reader.result;
        Papa.parse<T>(String(text), {
          complete(queryResult) {
            // eslint-disable-next-line no-restricted-syntax
            for (const itemChunk of chunk(queryResult.data, ITEMS_TO_PUSH_AT_ONCE)) {
              /**
               * Note: JS has a limit of how many items can be pushed at once. The JS core engine has a limit of 65536 elements.
               * https://www.yuji.page/too-many-arguments-in-javascript/
               */
              items.push(...itemChunk);
            }
          },
          error(error: any) {
            console.error(`Error while parsing: ${JSON.stringify(error)}`);
          },
          header: true, // First row to be interpreted as field names
          dynamicTyping: true, // Automatically convert numeric and boolean data
          skipEmptyLines: true,
          beforeFirstChunk: (result) => result.trim(), // Make sure to trim the result to prevent any empty lines when parsing & processing items.
          ...config,
        });
      };
      reader.onloadend = () => {
        resolve(items);
      };
      reader.readAsText(data as Blob);
    });
  },

  useQueryV2: <T>(
    queryKey: QueryKey,
    queryString: string,
    maxAgeInMinutes: number,
    queryOptions?: Partial<UseQueryOptions<T[]>>,
    queryConfig?: Omit<ParseConfig<T>, 'complete'>,
  ) => {
    return useQuery({
      queryKey: ['athena-query-v2', queryKey],
      queryFn: async () => {
        return AthenaQueryService.queryV2(queryString, maxAgeInMinutes, queryConfig);
      },
      // NOTE: default do not refetch on mount, reconnect, or window focus since the historical data does not change on these conditions.
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: queryString !== '',
      ...queryOptions,
    });
  },

  queryV2: async <T>(
    queryString: string,
    maxAgeInMinutes: number,
    config?: Omit<ParseConfig<T>, 'complete'>,
  ): Promise<T[]> => {
    const queryExecution = await PlatformApiQueries.executeAthenaQuery(queryString, maxAgeInMinutes);
    if (queryExecution.presignedResultUrl == null) {
      throw new Error(`Query execution result url is null; query execution: ${JSON.stringify(queryExecution)}`);
    }

    // Note: get the athena query result as blob
    const { data } = await axios({
      url: queryExecution.presignedResultUrl,
      method: 'GET',
      responseType: 'blob',
    });

    return new Promise((resolve) => {
      const items: T[] = [];
      const reader = new FileReader();
      reader.onload = () => {
        const text = reader.result;

        Papa.parse<T>(String(text), {
          complete(queryResult) {
            // eslint-disable-next-line no-restricted-syntax
            for (const itemChunk of chunk(queryResult.data, ITEMS_TO_PUSH_AT_ONCE)) {
              /**
               * Note: JS has a limit of how many items can be pushed at once. The JS core engine has a limit of 65536 elements.
               * https://www.yuji.page/too-many-arguments-in-javascript/
               */
              items.push(...itemChunk);
            }
          },
          error(error: any) {
            console.error(`Error while parsing: ${JSON.stringify(error)}`);
          },
          header: true, // First row to be interpreted as field names
          dynamicTyping: true, // Automatically convert numeric and boolean data
          skipEmptyLines: true,
          beforeFirstChunk: (result) => result.trim(), // Make sure to trim the result to prevent any empty lines when parsing & processing items.
          ...config,
        });
      };
      reader.onloadend = () => {
        resolve(items);
      };
      reader.readAsText(data as Blob);
    });
  },

  getTimestampFilter: (from: Date, to: Date): string =>
    `cast(partition_utc_timestamp as varchar) between '${moment.utc(from).format('YYYY-MM-DD')}' and '${moment
      .utc(to)
      .format('YYYY-MM-DD')}z'
     and cast(utc_timestamp as varchar) between '${from.toISOString().replace('T', ' ')}' and '${to
      .toISOString()
      .replace('T', ' ')}'`,

  formatTimestamp: (timestamp: Date): string => timestamp.toISOString().replace('T', ' '),

  getDynamicMaxAgeInMinutesForQueryResults: (endTime: Nullable<Date>): number => {
    if (endTime == null || endTime.getTime() >= Date.now()) {
      return 10;
    }
    const timeDiffFromNowInMinutes = Math.abs(moment.duration(moment(endTime).diff(moment())).asMinutes());
    // If the end time is less than a day old, use cached query results for 10 minutes
    if (timeDiffFromNowInMinutes <= 60 * 24) {
      return 10;
    }
    // If the end time is more than a day old, use cached query results for 1 day
    return 60 * 24;
  },
};

export default AthenaQueryService;
