import { InputGroup, InputRightElement, Spinner } from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteCreatable,
  AutoCompleteInput,
  AutoCompleteInputProps,
  AutoCompleteItem,
  AutoCompleteItemProps,
  AutoCompleteList,
  AutoCompleteListProps,
  AutoCompleteProps,
} from '@choc-ui/chakra-autocomplete';
import { FunctionComponent, useRef } from 'react';
import { BiCaretDown } from 'react-icons/bi';

import { Colors } from 'src/styles';

import FormikFormField, { FormikFormFieldProps } from './formik-form-field';

type FormikAutoCompleteProps = {
  id: string;
  onChangeText?: (value: string) => void;
  autoCompleteProps: Omit<AutoCompleteProps, 'children'>;
  autoCompleteInputProps?: AutoCompleteInputProps;
  autoCompleteListProps?: AutoCompleteListProps;
  autoCompleteItemsProps: AutoCompleteItemProps[];
  isLoading?: boolean;
  creatable?: boolean;
  renderCreatable?: (value: any) => JSX.Element;
} & Omit<FormikFormFieldProps, 'children' | 'htmlFor'>;

const FormikAutoComplete: FunctionComponent<FormikAutoCompleteProps> = ({
  onChangeText,
  autoCompleteProps,
  autoCompleteInputProps,
  autoCompleteListProps,
  autoCompleteItemsProps,
  isLoading,
  id,
  creatable = false,
  renderCreatable = (value) => value,
  ...otherProps
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const handleInputFocus = () => ref.current?.focus();

  return (
    <FormikFormField
      htmlFor={id}
      {...otherProps}
    >
      {([fieldProps, { error }]) => (
        <AutoComplete
          openOnFocus
          listAllValuesOnFocus
          {...fieldProps}
          {...autoCompleteProps}
          creatable={creatable}
          onChange={onChangeText}
        >
          <InputGroup>
            <AutoCompleteInput
              ref={ref}
              id={id}
              error={error}
              bgColor={Colors.gray100}
              {...autoCompleteInputProps}
            />
            <InputRightElement onClick={handleInputFocus}>
              {isLoading === true ? <Spinner size="sm" /> : <BiCaretDown />}
            </InputRightElement>
          </InputGroup>
          <AutoCompleteList
            width="100%"
            top="85%"
            error={error}
            {...autoCompleteListProps}
          >
            {autoCompleteItemsProps.map((itemProps) => (
              <AutoCompleteItem
                key={itemProps.value}
                {...itemProps}
              >
                {itemProps.children}
              </AutoCompleteItem>
            ))}
            {creatable && (
              <AutoCompleteCreatable key="Custom">{({ value }) => renderCreatable(value)}</AutoCompleteCreatable>
            )}
          </AutoCompleteList>
        </AutoComplete>
      )}
    </FormikFormField>
  );
};

export default FormikAutoComplete;
