import { Badge, BadgeProps } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { FC } from 'react';

import { Colors } from 'src/styles';
import { GrafanaPeriod } from 'src/types/grafana-types';

import GrafanaPanelPopover from '../../../../components/grafana-panel.popover';
import { Alarm } from '../../@types';

type AlarmBadgeProps = {
  alarm: Alarm;
  formatAlarm: (alarm: Alarm) => string;
  dashboardIds?: string[];
  /**
   * @summary variables that need to be passed to the grafana dashboard URL e.g. serialNumber, mobId etc.
   */
  grafanaVariables?: Record<string, string>;
  grafanaTimestampFrom?: Date;
  grafanaTimestampTo?: Date;
  grafanaPeriod?: GrafanaPeriod;
  bgColor?: string;
} & BadgeProps;

const AlarmBadge: FC<AlarmBadgeProps> = ({
  alarm,
  formatAlarm,
  dashboardIds,
  grafanaVariables,
  grafanaTimestampFrom,
  grafanaTimestampTo,
  grafanaPeriod,
  bgColor = Colors.gray600,
  ...rest
}) => {
  const badge = (
    <Badge
      as="button"
      px={2}
      py={0.25}
      fontSize="xx-small"
      borderRadius={100}
      bg={bgColor}
      color="white"
      style={{ cursor: isEmpty(dashboardIds) ? 'default' : 'pointer' }}
      {...rest}
    >
      {formatAlarm(alarm)}
    </Badge>
  );

  if (dashboardIds == null) return badge;

  return (
    <GrafanaPanelPopover
      dashboardIds={dashboardIds}
      grafanaVariables={grafanaVariables}
      grafanaTimestampFrom={grafanaTimestampFrom}
      grafanaTimestampTo={grafanaTimestampTo}
      grafanaPeriod={grafanaPeriod}
    >
      {badge}
    </GrafanaPanelPopover>
  );
};

export default AlarmBadge;
