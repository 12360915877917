import { Box, Flex, Heading } from '@chakra-ui/react';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import pluralize from 'pluralize';

import ActionButtons from 'src/components/actions/action-buttons';
import BackButton from 'src/components/buttons/back-button';
import FormikTextarea from 'src/components/forms/formik-text-area';
import { Colors } from 'src/styles';
import { extractSerialNumbersFromPattern } from 'src/util/collar.util';

import { slideRight } from '../../../../../components/actions/animations';
import { MAX_ENTITY_COUNT_FOR_ACTION } from '../../../constants';
import CollarActionAlarmSnoozeAndRemovalEffectMessage from './collar-action-alarm-snooze-and-removaleffect-message';
import SerialNumbersTextarea from './serial-numbers-textarea';

type BaseCollarActionFormValues = FormikValues & {
  serialNumbers: string;
  comments?: string;
};

type ChildrenFunction<T> = (props: FormikProps<T>) => React.ReactNode;

type BaseCollarActionFormProps<T> = {
  title: string;
  /**
   * @summary Description of the action that will be placed under the title.
   */
  description?: React.ReactNode;
  initialValues: T;
  validationSchema: any;
  onClose: () => void;
  onBack: () => void;
  onSubmit: (values: T) => Promise<void>;
  commentsRequired?: boolean;
  children?: ChildrenFunction<T>;
  /**
   * @summary Number of selected event alarms.
   *  The purpose of this prop is to show how many history event alarms will be removed after the action is taken.
   */
  selectedEventAlarmCount?: number;
  additionalSection?: React.ReactNode;
};

const BaseCollarActionForm = <T extends BaseCollarActionFormValues>({
  title,
  description,
  onBack,
  initialValues,
  validationSchema,
  onClose,
  onSubmit,
  commentsRequired = true,
  children,
  selectedEventAlarmCount,
  additionalSection,
}: BaseCollarActionFormProps<T>) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={async (values, { setSubmitting }) => {
      await onSubmit(values);
      setSubmitting(false);
    }}
  >
    {(props: FormikProps<T>) => {
      const { submitForm, values, errors, setFieldError, isSubmitting } = props;
      const currentSerialNumberCount: number = extractSerialNumbersFromPattern(values.serialNumbers).length;

      if (errors.serialNumbers == null && currentSerialNumberCount > MAX_ENTITY_COUNT_FOR_ACTION) {
        setFieldError(
          'serialNumbers',
          `Cannot take action on more than ${MAX_ENTITY_COUNT_FOR_ACTION} collars at once`,
        );
      }

      return (
        <>
          <Box
            key="form-action"
            as={motion.div}
            p={4}
            flex={1}
            overflowY="auto"
            variants={slideRight}
            initial="hidden"
            animate="show"
            exit="exit"
          >
            <Flex
              flexDir="column"
              rowGap={3}
              mx={6}
              my={1}
              color={Colors.gray600}
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Heading
                  as="h3"
                  fontSize="lg"
                >
                  {title}
                </Heading>
                <BackButton onBack={onBack} />
              </Flex>

              {description != null && <Box mb={2}>{description}</Box>}

              <Form>
                <SerialNumbersTextarea
                  onTriggerSubmit={submitForm}
                  error={errors.serialNumbers as string}
                />
                {currentSerialNumberCount > 0 && (
                  <CollarActionAlarmSnoozeAndRemovalEffectMessage
                    serialNumberCount={currentSerialNumberCount}
                    selectedEventAlarmCount={selectedEventAlarmCount}
                    mt={-2.5}
                    mb={4}
                  />
                )}
                {children?.(props)}
                {commentsRequired && (
                  <FormikTextarea
                    id="comments"
                    mb={5}
                    label="Comments (Optional)"
                    name="comments"
                    textareaProps={{ value: values.comments }}
                    onTriggerSubmit={submitForm}
                    error={errors.comments as string}
                  />
                )}

                {additionalSection}
              </Form>
            </Flex>
          </Box>

          <ActionButtons
            confirmButtonText={`Apply to ${currentSerialNumberCount} ${pluralize('collar', currentSerialNumberCount)}`}
            onCancel={onClose}
            onSubmit={submitForm}
            isSubmitting={isSubmitting}
            actionButtonDisabled={!isEmpty(errors)}
          />
        </>
      );
    }}
  </Formik>
);

export default BaseCollarActionForm;
