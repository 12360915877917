import { Box, Popover, PopoverArrow, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { IconType } from 'react-icons';

import GrafanaPanel from '../../../../components/grafana-panel';
import DebugButton from '../buttons/debug-button';

type QuickDebugBadgeProps = {
  icon: IconType;
  dashboardIds?: string[];
  dashboardVariables?: Record<string, string>;
  link?: string;
  tooltipLabel?: string;
};

const QuickDebugBadge: FunctionComponent<QuickDebugBadgeProps> = ({
  icon,
  dashboardIds,
  dashboardVariables,
  link,
  tooltipLabel,
}) => {
  const usePopover = dashboardIds != null && dashboardVariables != null;

  return (
    <Popover
      placement="top-start"
      trigger="hover"
      closeDelay={50}
      offset={[0, 4]}
      isLazy
      lazyBehavior="keepMounted"
    >
      <PopoverTrigger>
        <Box>
          <DebugButton
            tooltipLabel={tooltipLabel}
            debugLink={link}
            icon={icon}
          />
        </Box>
      </PopoverTrigger>
      {usePopover && (
        <PopoverContent
          height={`${dashboardIds.length * 200 + 100}px`}
          width="600px"
          bgColor="rgb(24, 27, 31)"
          borderRadius="15px"
          border="none"
          boxShadow="4px 4px 10px black"
        >
          {dashboardIds.map((dashboardId) => (
            <GrafanaPanel
              key={dashboardId}
              dashboardId={dashboardId}
              panelNumber={1}
              variables={dashboardVariables}
              style={{ zIndex: -1 }}
            />
          ))}
          <PopoverArrow
            bg="rgb(24, 27, 31)"
            border="none"
            boxShadow="none"
            zIndex={10}
          />
        </PopoverContent>
      )}
    </Popover>
  );
};
export default QuickDebugBadge;
