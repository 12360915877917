import { ChakraTheme, baseTheme, extendTheme } from '@chakra-ui/react';

import Colors from '../colors';
import badgeTheme from './badge-theme';
import buttonTheme from './button-theme';
import inputTheme from './input-theme';
import textareaTheme from './textarea-theme';

const theme: ChakraTheme = {
  ...baseTheme,
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  fonts: {
    ...baseTheme.fonts,
    body: "'Inter', sans-serif",
    heading: "'Inter', sans-serif",
  },
  colors: {
    ...Colors,
    gray: {
      50: Colors.gray50,
      100: Colors.gray100,
      200: Colors.gray200,
      300: Colors.gray300,
      400: Colors.gray400,
      500: Colors.gray500,
      600: Colors.gray600,
      700: Colors.gray700,
      800: Colors.gray800,
      900: Colors.gray900,
    },

    red: {
      50: Colors.red50,
      100: Colors.red100,
      200: Colors.red200,
      300: Colors.red300,
      400: Colors.red400,
      500: Colors.red500,
      600: Colors.red600,
      700: Colors.red700,
      800: Colors.red800,
      900: Colors.red900,
    },

    orange: {
      50: Colors.orange50,
      100: Colors.orange100,
      200: Colors.orange200,
      300: Colors.orange300,
      400: Colors.orange400,
      500: Colors.orange500,
      // 600: Colors.orange600,
      // 700: Colors.orange700,
      // 800: Colors.orange800,
      // 900: Colors.orange900,
    },

    yellow: {
      50: Colors.yellow50,
      100: Colors.yellow100,
      200: Colors.yellow200,
      300: Colors.yellow300,
      400: Colors.yellow400,
      500: Colors.yellow500,
      600: Colors.yellow600,
      700: Colors.yellow700,
      800: Colors.yellow800,
      900: Colors.yellow900,
    },

    green: {
      50: Colors.green50,
      100: Colors.green100,
      200: Colors.green200,
      300: Colors.green300,
      400: Colors.green400,
      500: Colors.green500,
      600: Colors.green600,
      700: Colors.green700,
      800: Colors.green800,
      900: Colors.green900,
    },

    teal: {
      50: Colors.teal50,
      100: Colors.teal100,
      200: Colors.teal200,
      300: Colors.teal300,
      400: Colors.teal400,
      500: Colors.teal500,
      600: Colors.teal600,
      // 700: Colors.teal700,
      // 800: Colors.teal800,
      // 900: Colors.teal900,
    },

    blue: {
      50: Colors.blue50,
      100: Colors.blue100,
      200: Colors.blue200,
      300: Colors.blue300,
      400: Colors.blue400,
      500: Colors.blue500,
      600: Colors.blue600,
      700: Colors.blue700,
      800: Colors.blue800,
      900: Colors.blue900,
    },

    cyan: {
      50: Colors.cyan50,
      100: Colors.cyan100,
      200: Colors.cyan200,
      300: Colors.cyan300,
      400: Colors.cyan400,
      500: Colors.cyan500,
      // 600: Colors.cyan600,
      // 700: Colors.cyan700,
      // 800: Colors.cyan800,
      // 900: Colors.cyan900,
    },
    purple: {
      50: Colors.purple50,
      100: Colors.purple100,
      200: Colors.purple200,
      300: Colors.purple300,
      400: Colors.purple400,
      500: Colors.purple500,
      // 600: Colors.purple600,
      // 700: Colors.purple700,
      // 800: Colors.purple800,
      // 900: Colors.purple900,
    },
  },
  components: {
    Button: buttonTheme,
    Input: inputTheme,
    Select: inputTheme,
    Textarea: textareaTheme,
    Radio: { baseStyle: { container: { borderColor: Colors.gray300 } } },
    Badge: badgeTheme,
  },
};

export default extendTheme(theme);
