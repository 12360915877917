import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { IconType } from 'react-icons';
import { AiOutlineCopy } from 'react-icons/ai';
import { BsClipboard2Check } from 'react-icons/bs';

import useCopyEffect from 'src/hooks/use-copy-effect';
import { Colors } from 'src/styles';

import { copyToClipboard } from '../../util/clipboard.util';

type CopyClipboardButtonProps = IconButtonProps & {
  copyText?: string;
  CopyIcon?: IconType;
  onCopyAction?: () => void;
  /**
   * @summary If true, the button will be dark themed.
   * @default false
   */
  dark?: boolean;
};

const CopyClipboardButton: FunctionComponent<CopyClipboardButtonProps> = ({
  copyText,
  CopyIcon = AiOutlineCopy,
  onCopyAction,
  dark = false,
  ...rest
}) => {
  const { copiedEffect, showCopiedEffect } = useCopyEffect();

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (copyText != null) {
      void copyToClipboard(copyText);
    }
    if (onCopyAction != null) {
      onCopyAction();
    }

    showCopiedEffect();
  };

  const copyIcon = copiedEffect ? (
    <BsClipboard2Check color={dark ? Colors.green300 : Colors.green600} />
  ) : (
    <CopyIcon color={dark ? Colors.gray300 : Colors.gray600} />
  );

  return (
    <IconButton
      size="sm"
      borderRadius={5}
      boxShadow="sm"
      icon={copyIcon}
      onClick={handleCopy}
      fontSize="20px"
      variant={dark ? 'dark' : 'light'}
      {...rest}
    />
  );
};

export default CopyClipboardButton;
