import {
  InfraProductApi,
  InfraProductGroupApi,
  PaddockApi,
  TowerApi,
  TowerCoverageApi,
  TowerDependencyApi,
  TowerMappingApi,
} from '@halter-corp/infrastructure-service-client';

import client from '../client';

const InfrastructureApi = {
  towerApi: client.resolveOpenApi(TowerApi),
  towerDependencyApi: client.resolveOpenApi(TowerDependencyApi),
  towerCoverageApi: client.resolveOpenApi(TowerCoverageApi),
  towerMappingApi: client.resolveOpenApi(TowerMappingApi),
  infraProductApi: client.resolveOpenApi(InfraProductApi),
  infraProductGroupApi: client.resolveOpenApi(InfraProductGroupApi),
  paddockApi: client.resolveOpenApi(PaddockApi),
};

export default InfrastructureApi;
