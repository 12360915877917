import { Center, CenterProps, Spinner, SpinnerProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

import { Colors } from 'src/styles';

type CommonLoadingSpinnerProps = {
  width?: string;
  height?: string;
  spinnerProps?: SpinnerProps;
} & CenterProps;

const CommonLoadingSpinner = forwardRef<HTMLDivElement, CommonLoadingSpinnerProps>(
  ({ width = '100%', height = '100%', spinnerProps, ...rest }, ref) => (
    <Center
      width={width}
      height={height}
      ref={ref}
      {...rest}
    >
      <Spinner
        size="xl"
        thickness="4px"
        speed="0.65s"
        emptyColor={Colors.gray200}
        color={Colors.gray900}
        {...spinnerProps}
      />
    </Center>
  ),
);

export default CommonLoadingSpinner;
