import { Tooltip } from '@chakra-ui/react';
import { FC } from 'react';
import { FaSatelliteDish, FaSolarPanel, FaWifi } from 'react-icons/fa';
import { MdOutlineElectricalServices } from 'react-icons/md';

import { Colors } from 'src/styles';

const MainsPoweredLabel: FC<{ mainsPowered: boolean }> = ({ mainsPowered }) => {
  const label = mainsPowered ? 'Mains Powered' : 'Solar Powered';
  const Icon = mainsPowered ? MdOutlineElectricalServices : FaSolarPanel;

  return (
    <Tooltip
      label={label}
      hasArrow
    >
      <div>
        <Icon color={Colors.blue900} />
      </div>
    </Tooltip>
  );
};

const RouterLabel: FC<{ router: boolean }> = ({ router }) => {
  if (!router) return null;

  return (
    <Tooltip
      label="Router"
      hasArrow
    >
      <div>
        <FaWifi color={Colors.blue600} />
      </div>
    </Tooltip>
  );
};

const StarlinkLabel: FC<{ starlink: boolean }> = ({ starlink }) => {
  if (!starlink) return null;

  return (
    <Tooltip
      label="Starlink"
      hasArrow
    >
      <div>
        <FaSatelliteDish color={Colors.slate600} />
      </div>
    </Tooltip>
  );
};

export const TowerFlags = {
  MainsPoweredLabel,
  RouterLabel,
  StarlinkLabel,
};
