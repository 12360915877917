import { createContext } from 'react';

export type FarmSwitcherContextType = {
  farmId: string;
  setFarmId: (farmId: string) => void;
};

const FarmSwitcherContext = createContext<FarmSwitcherContextType | null>(null);

export default FarmSwitcherContext;
