import {
  AlarmActionApi,
  AlarmSettingsApi,
  WelfareAlarmApi,
  WelfareAlarmSubscriptionApi,
  WelfareEventAlarmApi,
} from '@halter-corp/welfare-alarm-service-client';

import client from '../client';

const welfareAlarmApi = {
  welfareAlarm: client.resolveOpenApi(WelfareAlarmApi),
  welfareEventAlarms: client.resolveOpenApi(WelfareEventAlarmApi),
  welfareAlarmSubscription: client.resolveOpenApi(WelfareAlarmSubscriptionApi),
  alarmAction: client.resolveOpenApi(AlarmActionApi),
  alarmSettings: client.resolveOpenApi(AlarmSettingsApi),
};

export default welfareAlarmApi;
