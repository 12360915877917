import { AlarmActionTypeEnum, IPowerAlarmSnoozeActionContextDTO } from '@halter-corp/power-alarm-service-client';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { FunctionComponent } from 'react';
import * as yup from 'yup';

import ActionButtons from 'src/components/actions/action-buttons';
import ActionFormWrapper from 'src/components/actions/action-form-wrapper';
import FormikSelect from 'src/components/forms/formik-select';
import FormikTextarea from 'src/components/forms/formik-text-area';
import { AlarmActionFormProps } from 'src/modules/monitoring/@types';
import { SNOOZE_DURATION_OPTIONS } from 'src/modules/monitoring/constants/snooze-duration';
import { getExpiresAt } from 'src/modules/monitoring/utils/snooze.util';
import { getDurationFormat } from 'src/util/date-time.util';

import { PowerAlarmActionService } from '../../services/power-alarm-action.service';

export const DEFAULT_SNOOZE_DURATION_MS = moment.duration(1, 'hour').asMilliseconds();

const snoozeActionSchema = yup.object().shape({
  entityIds: yup.string().required(),
  snoozeDurationMs: yup.number().integer().required(),
});

type SnoozeActionFormValues = {
  entityIds: string;
  snoozeDurationMs: number;
};

const SnoozeActionForm: FunctionComponent<AlarmActionFormProps> = ({
  onBack,
  onClose,
  onSuccessfulSubmit,
  selectedIds,
  selectedActiveAlarmEntities,
}) => {
  const { sendBatchActionRequests } =
    PowerAlarmActionService.useBatchPowerAlarmActions<IPowerAlarmSnoozeActionContextDTO>({
      actionType: AlarmActionTypeEnum.SNOOZE,
      onSuccess: onSuccessfulSubmit,
    });

  const handleSubmit = async ({ entityIds: idsString, snoozeDurationMs }: SnoozeActionFormValues) => {
    const allEntities = idsString.split(',').map((id) => id.trim());

    // Step 1: Snooze all entities for each active alarm type
    const entitiesWithAlarmContext = selectedActiveAlarmEntities.map((entity) => entity.entityId);
    const alarmMap: Record<string, string[]> = {};
    selectedActiveAlarmEntities.forEach((entity) => {
      entity.alarms.forEach((alarm) => {
        if (alarmMap[alarm.type] == null) {
          alarmMap[alarm.type] = [];
        }
        alarmMap[alarm.type].push(entity.entityId);
      });
    });

    const alarmList = Object.keys(alarmMap).map((alarm) => ({
      alarm,
      serialNumbers: alarmMap[alarm],
    }));

    const snoozeRequests = alarmList.map(({ alarm, serialNumbers }) => {
      const context: IPowerAlarmSnoozeActionContextDTO = {
        expiresAt: getExpiresAt(snoozeDurationMs),
        alarmTypes: [alarm],
      };
      return sendBatchActionRequests(serialNumbers, context);
    });

    await Promise.all(snoozeRequests);

    // Step 2: Snooze serials that don't have alarm context (e.g. were manually typed)
    const entitiesWithoutAlarmContext = allEntities.filter((entity) => !entitiesWithAlarmContext.includes(entity));
    if (entitiesWithoutAlarmContext.length > 0) {
      const context: IPowerAlarmSnoozeActionContextDTO = {
        expiresAt: getExpiresAt(snoozeDurationMs),
        alarmTypes: ['ANY'],
      };
      await sendBatchActionRequests(entitiesWithoutAlarmContext, context);
    }
  };

  return (
    <Formik
      initialValues={
        {
          entityIds: selectedIds.join(', '),
          snoozeDurationMs: DEFAULT_SNOOZE_DURATION_MS,
          comments: '',
        } as SnoozeActionFormValues
      }
      validationSchema={snoozeActionSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values, errors, submitForm, isSubmitting }) => {
        return (
          <>
            <ActionFormWrapper
              title="Snooze"
              onBack={onBack}
            >
              <FormikTextarea
                id="entity-ids"
                mb={5}
                label="Collar/Mob/Farm IDs"
                name="entityIds"
                textareaProps={{
                  placeholder: 'Enter IDs separated by comma e.g. 004-1234-12345,004-5678-56789',
                  _placeholder: { fontSize: 'sm' },
                }}
                onTriggerSubmit={submitForm}
                error={errors.entityIds as string}
              />

              <FormikSelect
                id="snooze-duration"
                label="Snooze Duration"
                name="snoozeDurationMs"
                placeholder="Select snooze duration"
                selectProps={{ value: values.snoozeDurationMs }}
                error={errors.snoozeDurationMs}
                mb={5}
              >
                {SNOOZE_DURATION_OPTIONS.map((durationMs) => (
                  <option
                    key={durationMs}
                    value={durationMs}
                  >
                    {getDurationFormat(durationMs)}
                  </option>
                ))}
              </FormikSelect>
            </ActionFormWrapper>

            <ActionButtons
              confirmButtonText="Confirm"
              onCancel={onClose}
              onSubmit={submitForm}
              isSubmitting={isSubmitting}
              actionButtonDisabled={!isEmpty(errors)}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default SnoozeActionForm;
