import { Box, BoxProps, Flex, IconButton, Icon as IconComponent, Text, useDisclosure } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, FunctionComponent, useState } from 'react';
import { FaAngleRight } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';

import { LeafRouteItem } from 'src/constants/route-items';
import { Colors } from 'src/styles';

export type MenuItemProps = {
  collapsed?: boolean;
  onPress?: () => void;
  text: string;
  icon: FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  path?: string;
  childItems?: LeafRouteItem[];
  isChild?: boolean;
} & Partial<BoxProps>;

const MenuItem: FC<MenuItemProps> = (props) => {
  const { collapsed = false, onPress, text, icon, path, childItems, isChild = false, ...rest } = props;
  const [isHovered, setIsHovered] = useState(false);
  const { isOpen: isNestedItemsOpen, onToggle: onNestedItemsToggle } = useDisclosure();

  const location = useLocation();
  const navigate = useNavigate();
  const isSelected = path === location.pathname;

  const handlePress = () => {
    onPress?.();
    if (path != null) {
      navigate(path);
    }
  };

  const handleToggleNestedItems = (e: React.MouseEvent) => {
    e.stopPropagation();
    onNestedItemsToggle();
  };

  const displayNestedItems = isNestedItemsOpen && !collapsed && childItems != null;

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...rest}
    >
      <Flex
        onClick={handlePress}
        bg={isSelected ? Colors.gray700 : 'transparent'}
        px={4}
        py={2}
        cursor="pointer"
        _active={{ bg: 'gray.700' }}
        _hover={{
          bg: 'gray.800',
          color: 'gray.200',
        }}
        color={isSelected ? 'gray.300' : 'gray.500'}
        justifyContent="flex-tart"
        overflow="hidden"
        flexWrap="nowrap"
        alignItems="center"
        pl={isChild && !collapsed ? 8 : 4}
      >
        <IconComponent
          as={icon}
          boxSize={isChild ? 5 : 6}
        />
        {/* Menu item text */}
        <AnimatePresence>
          {!collapsed && (
            <Flex
              flex={1}
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              as={motion.div}
              flexWrap="nowrap"
              alignItems="center"
              px={2}
            >
              <Text
                whiteSpace="nowrap"
                fontWeight="semibold"
                fontSize={isChild ? '15px' : '16px'}
              >
                {text}
              </Text>
            </Flex>
          )}
        </AnimatePresence>

        {/* Toggle button */}
        {!collapsed && childItems != null && (
          <IconButton
            aria-label="arrow-right"
            my={-1}
            borderRadius={100}
            icon={<FaAngleRight />}
            onClick={handleToggleNestedItems}
            color={Colors.gray200}
            bgColor={Colors.gray700}
            opacity={isHovered ? 1 : 0}
            transform={isNestedItemsOpen ? 'rotate(90deg)' : 'rotate(0deg)'}
            transition="all 0.2s ease-in-out"
            _hover={{
              bgColor: Colors.gray900,
              color: Colors.white,
            }}
            size="sm"
          />
        )}
      </Flex>

      {/* Child (nested) menu items */}
      <AnimatePresence>
        {displayNestedItems && (
          <Box
            as={motion.div}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1, transition: { duration: 0.2, ease: 'easeInOut' } }}
            exit={{ height: 0, opacity: 0, transition: { duration: 0.15, ease: 'easeInOut' } }}
            overflow="hidden"
          >
            {childItems.map((childItem) => (
              <MenuItem
                key={childItem.path}
                collapsed={collapsed}
                {...childItem}
                isChild
              />
            ))}
          </Box>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default MenuItem;
