import { chain } from 'lodash';
import { TbProgressCheck } from 'react-icons/tb';

import { Colors } from 'src/styles';
import { formatStringAsLabel } from 'src/util/string-format.util';

import { RankSectionConfig } from '../../../@types';
import { AlarmedCollar, AlarmedCollarFilterType } from '../@types';

export const onFarmStatusRankConfig: RankSectionConfig<AlarmedCollar> = {
  name: 'Most Common Status',
  associatedFilterType: AlarmedCollarFilterType.STATUS,
  icon: (
    <TbProgressCheck
      color={Colors.gray500}
      fontSize="1.3em"
    />
  ),
  formatRankItem: (itemName) => formatStringAsLabel(itemName),
  deriveRanks: (items, selectedIdsOfRelatedFilter) =>
    chain(items)
      .filter((item) => item.context.onFarmStatus != null)
      .groupBy((item) => item.context.onFarmStatus)
      .map((devicesInFarm, onFarmStatus) => ({
        name: onFarmStatus,
        value: devicesInFarm.length,
        selected: selectedIdsOfRelatedFilter.has(onFarmStatus),
      }))
      .orderBy((item) => item.value, ['desc'])
      .value(),
};
