import { IMobDTO } from '@halter-corp/cattle-service-client';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import moment from 'moment';

import { MobsApi, client } from 'src/data';

export const MobsQueryKeys = {
  mobs: (farmId: string) => ['farms', farmId, 'mobs'] as const,
  mob: (farmId: string, mobId: string) => ['farms', farmId, 'mobs', 'mob', mobId] as const,
  farmIdForMobId: (mobId: string) => ['mobs', mobId, 'farmId'] as const,
};

export const MobsQueries = {
  useMobsByFarmId: ({ farmId }: { farmId: string }, options?: Partial<UseQueryOptions<Record<string, IMobDTO>>>) =>
    useQuery({
      queryKey: MobsQueryKeys.mobs(farmId),
      queryFn: async () => {
        if (farmId === '') {
          return {};
        }
        const { data } = await MobsApi.findAll(undefined, undefined, client.getRequestOptionsForFarmId(farmId));
        return keyBy(data, ({ id }) => id);
      },
      refetchOnWindowFocus: false,
      staleTime: moment.duration(3, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(3, 'minutes').asMilliseconds(),
      ...options,
    }),

  useMobByFarmId: (
    { farmId, mobId }: { farmId: string; mobId: string },
    options?: Partial<UseQueryOptions<IMobDTO | null>>,
  ) =>
    useQuery({
      queryKey: MobsQueryKeys.mob(farmId, mobId),
      queryFn: async () => {
        if (farmId === '') {
          return null;
        }
        const { data } = await MobsApi.findbyId(mobId, client.getRequestOptionsForFarmId(farmId));
        return data;
      },
      refetchOnWindowFocus: false,
      staleTime: moment.duration(3, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(3, 'minutes').asMilliseconds(),
      ...options,
    }),

  useFarmIdFromMobId: ({ mobId }: { mobId: string }, options?: Partial<UseQueryOptions<string | null>>) =>
    useQuery({
      queryKey: MobsQueryKeys.farmIdForMobId(mobId),
      queryFn: async () => {
        const { data } = await MobsApi.findFarmForMob(mobId);
        return data;
      },
      refetchOnWindowFocus: false,
      staleTime: moment.duration(3, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(3, 'minutes').asMilliseconds(),
      ...options,
    }),
};
