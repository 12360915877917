import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export type FilterMenuContextState = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const FilterMenuContext = createContext<FilterMenuContextState>(null!);

export const useFilterMenuContext = () => useContext(FilterMenuContext);

export default FilterMenuContext;
