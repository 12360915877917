/* eslint-disable no-await-in-loop */
import {
  IAlarmSettingsDTO,
  ICreateWelfareAlarmActionRequestDTO,
  ICreateWelfareAlarmSubscriptionRequestDTO,
  IDeleteAlarmSettingsRequestDTO,
  IPutAlarmSettingsRequestDTO,
  IWelfareAlarmsContextDTO,
  IWelfareEventAlarmsContextDTO,
} from '@halter-corp/welfare-alarm-service-client';
import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { WelfareAlarmApi } from 'src/data';
import { AlarmSettingsUtils } from 'src/modules/monitoring';

import { queryClient } from '..';
import client from '../query-client';

const WELFARE_ALARM_KEY = 'welfare-alarm';

type DeleteAlarmSettingsRequestIdentifier = IDeleteAlarmSettingsRequestDTO & {
  expiresAt?: string;
};

export const WelfareAlarmQueryKeys = {
  welfareAlarmsContexts: [WELFARE_ALARM_KEY, 'welfare-alarms-contexts'] as const,
  welfareEventAlarmsContexts: [WELFARE_ALARM_KEY, 'welfare-event-alarms-contexts'] as const,
  alarmSettingsList: [WELFARE_ALARM_KEY, 'alarm-settings'] as const,
};

export type WelfareAlarmQueriesState = {
  welfareAlarmsContexts: IWelfareAlarmsContextDTO[];
  welfareEventAlarmsContexts: IWelfareEventAlarmsContextDTO[];
  welfareAlarmSettingsList: IAlarmSettingsDTO[];
};

export const WelfareAlarmQueries = {
  // Queries
  useActionRequiredWelfareActiveAlarmsContexts: (options?: Partial<UseQueryOptions<IWelfareAlarmsContextDTO[]>>) =>
    useQuery({
      queryKey: WelfareAlarmQueryKeys.welfareAlarmsContexts,
      queryFn: async () => {
        let paginationKey: string | undefined;

        const welfareAlarmsContexts: IWelfareAlarmsContextDTO[] = [];
        do {
          const { data } = await WelfareAlarmApi.welfareAlarm.getActionRequiredWelfareAlarmsContexts(paginationKey);
          welfareAlarmsContexts.push(...data.items);
          paginationKey = data.nextReferenceId;
        } while (paginationKey != null);

        return welfareAlarmsContexts;
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      ...options,
    }),

  useWelfareEventAlarmsContexts: (options?: Partial<UseQueryOptions<IWelfareEventAlarmsContextDTO[]>>) =>
    useQuery({
      queryKey: WelfareAlarmQueryKeys.welfareEventAlarmsContexts,
      queryFn: async () => {
        let paginationKey: string | undefined;

        const welfareEventAlarmsContexts: IWelfareEventAlarmsContextDTO[] = [];
        do {
          const { data } = await WelfareAlarmApi.welfareEventAlarms.getWelfareEventAlarmsContexts(paginationKey);
          welfareEventAlarmsContexts.push(...data.alarms);
          paginationKey = data.nextReferenceId;
        } while (paginationKey != null);

        return welfareEventAlarmsContexts;
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      ...options,
    }),

  useAllAlarmSettings: (options?: Partial<UseQueryOptions<IAlarmSettingsDTO[]>>) =>
    useQuery({
      queryKey: WelfareAlarmQueryKeys.alarmSettingsList,
      queryFn: async () => {
        const { data } = await WelfareAlarmApi.alarmSettings.getAllAlarmSettings();
        return data;
      },
      staleTime: 10_000,
      ...options,
    }),

  // Mutations
  useCreateWelfareAlarmSubscriptionMutation: () =>
    useMutation({
      mutationFn: async (request: ICreateWelfareAlarmSubscriptionRequestDTO) => {
        const { data } = await WelfareAlarmApi.welfareAlarmSubscription.createWelfareAlarmSubscription(request);
        return data;
      },
    }),

  useCreateWelfareAlarmActionMutations: () =>
    useMutation({
      mutationFn: async (welfareAlarmActionRequests: ICreateWelfareAlarmActionRequestDTO[]) => {
        await Promise.all(
          welfareAlarmActionRequests.map(async (request) => {
            const { data } = await WelfareAlarmApi.alarmAction.createWelfareAlarmAction(request);
            return data;
          }),
        );
      },
      onSuccess: () => {
        void Promise.all([
          queryClient.invalidateQueries(WelfareAlarmQueryKeys.welfareAlarmsContexts),
          queryClient.invalidateQueries(WelfareAlarmQueryKeys.welfareEventAlarmsContexts),
        ]);
      },
    }),

  usePutAlarmSettingsMutation: () =>
    useMutation({
      mutationFn: async (request: IPutAlarmSettingsRequestDTO) => {
        const { data: alarmSettings } = await WelfareAlarmApi.alarmSettings.putAlarmSettings(request);
        return alarmSettings;
      },
      onSuccess: WelfareAlarmQueries.setAlarmSettingsToCache,
    }),

  useDeleteAlarmSettingsMutation: () =>
    useMutation({
      mutationFn: async (request: DeleteAlarmSettingsRequestIdentifier) => {
        await WelfareAlarmApi.alarmSettings.deleteAlarmSettings(request);
        return { ...request };
      },
      onSuccess: WelfareAlarmQueries.removeAlarmSettingsFromCache,
    }),

  // Cache
  setAlarmSettingsToCache: (alarmSettings: IAlarmSettingsDTO) => {
    const currentAlarmSettingsList = client.getClientData<'welfareAlarmSettingsList'>(
      WelfareAlarmQueryKeys.alarmSettingsList,
    );
    if (currentAlarmSettingsList == null) return;

    const updatedAlarmSettingsList = currentAlarmSettingsList
      .filter((as) => AlarmSettingsUtils.areAlarmSettingsEqual(as, alarmSettings) === false)
      .concat(alarmSettings);

    client.setClientData<'welfareAlarmSettingsList'>(WelfareAlarmQueryKeys.alarmSettingsList, updatedAlarmSettingsList);
  },

  removeAlarmSettingsFromCache: (alarmSettingsIdentifier: DeleteAlarmSettingsRequestIdentifier) => {
    const currentAlarmSettingsList = client.getClientData<'welfareAlarmSettingsList'>(
      WelfareAlarmQueryKeys.alarmSettingsList,
    );
    if (currentAlarmSettingsList == null) return;

    const updatedAlarmSettingsList = currentAlarmSettingsList.filter(
      (as) => AlarmSettingsUtils.areAlarmSettingsEqual(as, alarmSettingsIdentifier) === false,
    );

    client.setClientData<'welfareAlarmSettingsList'>(WelfareAlarmQueryKeys.alarmSettingsList, updatedAlarmSettingsList);
  },
};
