import { ISettingsTriggerRuleDTO } from '@halter-corp/power-alarm-service-client';

export enum PowerAlarmSettingsFilterType {
  ALARM_TYPE = 'Alarm Type',
  FILTER_TYPE = 'Filter Type',

  CREATED_TIME = 'Created Time',
  UPDATED_TIME = 'Updated Time',
}

export type PowerSettingsTriggerRule = ISettingsTriggerRuleDTO;
