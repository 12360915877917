import { Flex, Heading, SimpleGrid, StackProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import { AlarmedCollar } from '../../../@types';
import { QUICK_DEBUG_PANELS } from '../../../constants';
import AlarmPanels from './alarm-panels';
import QuickDebugPanels from './quick-debug-panels';

type DebugPanelsSectionProps = {
  collar: AlarmedCollar;
} & StackProps;

const DebugPanelsSection: FunctionComponent<DebugPanelsSectionProps> = ({ collar, ...rest }) => (
  <Flex
    rowGap={2}
    flexDir="column"
    {...rest}
  >
    <Heading
      as="h5"
      fontSize="lg"
      display="flex"
    >
      Alarms & Debug Panels
    </Heading>

    {/* Alarms debug panels */}
    <SimpleGrid
      gridTemplateColumns={{ base: '1fr', md: 'repeat(auto-fit, minmax(600px, 1fr))' }}
      rowGap={3}
      columnGap={{ base: 4, lg: 8 }}
    >
      {collar.alarms.map((alarm) => (
        <AlarmPanels
          key={alarm.type}
          alarm={alarm}
          serialNumber={collar.entityId}
          mobId={collar.context.mobId}
        />
      ))}
    </SimpleGrid>

    {/* Quick debug panels */}
    <SimpleGrid
      mt={2}
      gridTemplateColumns={{ base: '1fr', md: 'repeat(auto-fit, minmax(600px, 1fr))' }}
      rowGap={3}
      columnGap={{ base: 4, lg: 8 }}
    >
      {Object.entries(QUICK_DEBUG_PANELS).map(([name, panelContent]) => (
        <QuickDebugPanels
          key={name}
          serialNumber={collar.entityId}
          {...panelContent}
        />
      ))}
    </SimpleGrid>
  </Flex>
);

export default DebugPanelsSection;
