import { FieldHookConfig, useField } from 'formik';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik/dist/types';
import React, { ReactNode } from 'react';

import FormField, { FormFieldProps } from './form-field';

export type FormikFormFieldProps<T = string> = {
  name: string;
  label?: React.ReactNode;
  children: (props: [FieldInputProps<T>, FieldMetaProps<T>, FieldHelperProps<T>]) => ReactNode;
  fieldConfig?: Partial<FieldHookConfig<T>>;
} & Omit<FormFieldProps, 'children'>;

export default function FormikFormField<T = string>(props: FormikFormFieldProps<T>) {
  const { name, children, fieldConfig, ...otherProps } = props;

  const field = useField<T>({
    ...fieldConfig,
    name,
  });
  const [, { error }] = field;

  return (
    <FormField
      name={name}
      error={error}
      {...otherProps}
    >
      {children(field)}
    </FormField>
  );
}
