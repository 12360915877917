import { ComponentStyleConfig } from '@chakra-ui/react';

import { Colors } from '..';

const textareaTheme: ComponentStyleConfig = {
  baseStyle: {
    backgroundColor: `${Colors.gray100}`,
    border: `1px solid ${Colors.gray300}`,
    boxShadow: 'sm',
    _focus: {
      border: `1px solid ${Colors.blue700}`,
      outline: `1px solid ${Colors.blue700}`,
      outlineOffset: '0px',
      boxShadow: 'md',
    },
  },
  defaultProps: {
    variant: null,
  },
};

export default textareaTheme;
