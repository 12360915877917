import { Button, Image, Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import { ICON_URLS } from 'src/assets';
import CommonLoadingSpinner from 'src/components/common-loading-spinner';
import { BffDebugQueries } from 'src/queries/queries/bff-debug-tool.queries';

const SagemakerAccessButton: FunctionComponent = () => {
  const { data: presignedUrl, isLoading } = BffDebugQueries.useSageMakerPresignedUrl();

  const handleButtonClick = () => {
    if (isLoading) {
      return;
    }
    try {
      window.open(presignedUrl, '_blank', 'noreferrer');
    } catch (error) {
      console.error('Error fetching the presigned URL: ', error);
    }
  };

  return (
    <Button
      h="120px"
      w="180px"
      p="16px"
      boxShadow="1px 1px 2px rgba(0, 0, 0, 0.1)"
      flexDirection="column"
      alignItems="center"
      onClick={handleButtonClick}
      isDisabled={isLoading}
    >
      {isLoading ? (
        <CommonLoadingSpinner />
      ) : (
        <Image
          boxSize="60px"
          objectFit="contain"
          src={ICON_URLS.SagemakerLogoUrl}
          alt="Open Sagemaker"
          mb={2}
        />
      )}
      <Text>Open Sagemaker</Text>
    </Button>
  );
};

export default SagemakerAccessButton;
