import { Flex } from '@chakra-ui/react';
import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import useDrawer from 'src/hooks/use-drawer';
import useWindowSize from 'src/hooks/use-window-size';
import ErrorScreen from 'src/modules/error/screens/error.screen';

import Drawer from './drawers/drawer';

const Dashboard: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const mainScreenRef = useRef<HTMLDivElement>(null);
  const { drawerCollapsed, setDrawerCollapsed, drawerWidth } = useDrawer();
  const { isNarrowScreen } = useWindowSize();

  useEffect(() => {
    if (mainScreenRef.current != null) {
      mainScreenRef.current.style.width = `calc(100% - ${drawerWidth}px)`;
    }
  }, [drawerWidth]);

  return (
    <Flex
      width="full"
      height="full"
      overflow="hidden"
    >
      {/* In a narrow screen, the fixed positioned sidebar will be displayed by clicking the hamburger menu in the header */}
      {isNarrowScreen === false && (
        <Drawer
          width={drawerWidth}
          collapsed={drawerCollapsed}
          setCollapsed={setDrawerCollapsed}
        />
      )}
      <Flex
        ref={mainScreenRef}
        flex={1}
        position="relative"
        maxH="100%"
        minH="100%"
      >
        <ErrorBoundary FallbackComponent={ErrorScreen}>{children}</ErrorBoundary>
      </Flex>
    </Flex>
  );
};

export default Dashboard;
