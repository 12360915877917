import { Button, Flex } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import { Colors } from 'src/styles';

type ActionButtonsProps = {
  onCancel: () => void;
  onSubmit?: () => void;
  confirmButtonText?: string;
  isSubmitting?: boolean;
  actionButtonDisabled?: boolean;
};

const ActionButtons: FunctionComponent<ActionButtonsProps> = ({
  onCancel,
  onSubmit,
  confirmButtonText = 'Confirm Action',
  isSubmitting = false,
  actionButtonDisabled = false,
}) => (
  <Flex
    mt="auto"
    px={4}
    py={5}
    justifyContent="flex-end"
    bgColor={Colors.gray200}
    borderTop="1px"
    borderTopColor={Colors.gray200}
    boxShadow="dark-lg"
  >
    <Button
      mr={3}
      onClick={onCancel}
      bgColor="transparent"
    >
      Cancel
    </Button>
    <Button
      variant="dark"
      minWidth="175px"
      px={4}
      isDisabled={actionButtonDisabled}
      onClick={onSubmit}
      isLoading={isSubmitting}
      loadingText="Submitting"
    >
      {confirmButtonText}
    </Button>
  </Flex>
);

export default ActionButtons;
