import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import { AlarmActionTypeEnum, ICollarAlarmChangeFarmActionContextDTO } from '@halter-corp/collar-alarm-service-client';
import { FunctionComponent, useMemo } from 'react';
import * as yup from 'yup';

import CommonLoadingSpinner from 'src/components/common-loading-spinner';
import FormikAutoComplete from 'src/components/forms/formik-auto-complete';
import { AlarmActionFormProps } from 'src/modules/monitoring/@types';
import BaseCollarActionForm from 'src/modules/monitoring/components/alarm-action-forms/collar-action-forms/base-collar-action-form';
import { BusinessQueries } from 'src/queries';
import { Colors } from 'src/styles';
import { SERIAL_NUMBERS_INPUT_PATTERN, extractSerialNumbersFromPattern } from 'src/util/collar.util';

import { CollarAlarmActionService } from '../../services/collar-alarm-action.service';

const changeFarmActionSchema = yup.object().shape({
  serialNumbers: yup
    .string()
    .required()
    .matches(SERIAL_NUMBERS_INPUT_PATTERN, 'Each serial number should have valid format separated by a comma'),
  farmId: yup.string().required(),
  comments: yup.string(),
});

const NONE_OPTION = 'None';
const DEPROVISION_COLLAR_OPTION = 'Unassign from farm';

type ChangeFarmActionFormValues = {
  serialNumbers: string;
  farmId: string;
  comments: string;
};

const ChangeFarmActionForm: FunctionComponent<AlarmActionFormProps> = ({
  onBack,
  onClose,
  onSuccessfulSubmit,
  selectedIds,
  selectedEventAlarmEntities,
  additionalSection,
}) => {
  const { sendBatchActionRequests: sendChangeFarmActionRequests } =
    CollarAlarmActionService.useBatchCollarAlarmActions<ICollarAlarmChangeFarmActionContextDTO>({
      actionType: AlarmActionTypeEnum.CHANGE_FARM,
      onSuccess: () => removeCollarEventAlarms(selectedEventAlarmEntities),
    });
  const { sendBatchActionRequests: sendDeprovisionDeviceActionRequests } =
    CollarAlarmActionService.useBatchCollarAlarmActions<object>({
      actionType: AlarmActionTypeEnum.DEPROVISION_COLLAR,
      onSuccess: () => removeCollarEventAlarms(selectedEventAlarmEntities),
    });
  const removeCollarEventAlarms = CollarAlarmActionService.useBatchRemoveCollarEventAlarmActions({
    onSuccess: onSuccessfulSubmit,
  });

  const { data: farmById = {}, isLoading } = BusinessQueries.useFindAllFarmsQuery();
  const farms = useMemo(() => Object.values(farmById), [farmById]);
  const toast = useToast();

  if (isLoading)
    return (
      <CommonLoadingSpinner
        width="100%"
        height="300px"
      />
    );

  const handleSubmit = async ({ serialNumbers: serialNumbersString, farmId, comments }: ChangeFarmActionFormValues) => {
    const serialNumbers = extractSerialNumbersFromPattern(serialNumbersString);
    if (farmId === NONE_OPTION) {
      toast({
        title: 'Please select an option to change farm',
        status: 'error',
        position: 'top-right',
      });
      return;
    }
    if (farmId === DEPROVISION_COLLAR_OPTION) {
      await sendDeprovisionDeviceActionRequests(serialNumbers, {}, comments);
      return;
    }

    await sendChangeFarmActionRequests(serialNumbers, { farmId }, comments);
  };

  return (
    <BaseCollarActionForm
      title="Change Farm For Collars"
      description={
        <Text fontSize="sm">
          The collars should be{' '}
          <Text
            as="strong"
            color={Colors.gray700}
          >
            tagged off
          </Text>{' '}
          before making change farm requests. <br />
          If tagged on, the request will be rejected.
        </Text>
      }
      initialValues={{
        serialNumbers: selectedIds.join(', '),
        farmId: NONE_OPTION,
        comments: '',
      }}
      validationSchema={changeFarmActionSchema}
      onSubmit={handleSubmit}
      onBack={onBack}
      onClose={onClose}
      selectedEventAlarmCount={selectedEventAlarmEntities.length}
      additionalSection={additionalSection}
    >
      {({ values, setFieldValue }) => (
        <Box mb={5}>
          <FormikAutoComplete
            id="farm-id"
            name="farmId"
            label="Farm"
            onChangeText={(farmId) => setFieldValue('farmId', farmId)}
            autoCompleteProps={{
              defaultValue: farmById[values.farmId]?.name ?? NONE_OPTION,
              openOnFocus: true,
              listAllValuesOnFocus: true,
            }}
            autoCompleteInputProps={{
              textTransform: 'capitalize',
              bg: Colors.gray100,
            }}
            autoCompleteListProps={{
              width: '100%',
              top: '85%',
              maxH: '225px',
            }}
            autoCompleteItemsProps={[
              {
                key: 'none',
                value: NONE_OPTION,
              },
              {
                key: 'deprovision-device',
                value: DEPROVISION_COLLAR_OPTION,
                children: (
                  <ChangeFarmOptionLabel
                    mainLabel={DEPROVISION_COLLAR_OPTION}
                    subLabel="Deprovision collars"
                  />
                ),
              },
              ...farms.map((farm) => ({
                key: farm.id,
                value: farm.id,
                label: farm.name,
                children: (
                  <ChangeFarmOptionLabel
                    mainLabel={farm.name ?? 'Name Unknown'}
                    subLabel={`ID: ${farm.id}`}
                  />
                ),
              })),
            ]}
          />
        </Box>
      )}
    </BaseCollarActionForm>
  );
};

type ChangeFarmOptionLabelProps = {
  mainLabel: string;
  subLabel: string;
};

const ChangeFarmOptionLabel: FunctionComponent<ChangeFarmOptionLabelProps> = ({ mainLabel, subLabel }) => (
  <Flex flexDir="column">
    <Text color={Colors.gray700}>{mainLabel}</Text>
    <Text
      color={Colors.gray500}
      fontSize="sm"
    >
      {subLabel}
    </Text>
  </Flex>
);

export default ChangeFarmActionForm;
