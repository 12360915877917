import * as yup from 'yup';

import { DateRangeFilterState, FilterInclusionType, OnOffOption, SearchFilterState } from 'src/types/filter-types';

const searchFilterStateSchema = yup.object().shape({
  inclusionType: yup.string().oneOf(Object.values(FilterInclusionType)).required(),
  selectedOptions: yup.array().of(yup.string()).required(),
});

const onOffOptionSchema = yup.string().oneOf(Object.values(OnOffOption)).required();

const dateRangeFilterStateSchema = yup.object().shape({
  startDate: yup.string().optional(),
  endDate: yup.string().optional(),
});

export class FilterStateValidator {
  public static isSearchFilterState(value: unknown): value is SearchFilterState {
    try {
      searchFilterStateSchema.validateSync(value);
      return true;
    } catch (error) {
      return false;
    }
  }

  public static isOnOffFilterState(value: unknown): value is OnOffOption {
    try {
      onOffOptionSchema.validateSync(value);
      return true;
    } catch (error) {
      return false;
    }
  }

  public static isDateRangeFilterState(value: unknown): value is DateRangeFilterState {
    try {
      dateRangeFilterStateSchema.validateSync(value);
      return true;
    } catch (error) {
      return false;
    }
  }
}
