import { ChakraProvider } from '@chakra-ui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider, EnvironmentProvider } from 'src/providers';
import { queryClient } from 'src/queries';
import { ChakraTheme } from 'src/styles';

import Application from './application';

import './index.css';

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = await import('@welldone-software/why-did-you-render');

  whyDidYouRender.default(React, {
    logOnDifferentValues: true,
    trackAllPureComponents: true,
  });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <BrowserRouter>
    <ChakraProvider theme={ChakraTheme}>
      <QueryClientProvider client={queryClient}>
        <EnvironmentProvider>
          <AuthProvider>
            <Application />
          </AuthProvider>
        </EnvironmentProvider>
        <ReactQueryDevtools
          position="bottom-right"
          initialIsOpen={false}
        />
      </QueryClientProvider>
    </ChakraProvider>
  </BrowserRouter>,
);
