import { CommandApi, DevicePayloadApi, DevicesApi } from '@halter-corp/cowtroller-service-client';

import client from '../client';

const cowtrollerApi = {
  commandApi: client.resolveOpenApi(CommandApi),
  devicePayloadApi: client.resolveOpenApi(DevicePayloadApi),
  devicesApi: client.resolveOpenApi(DevicesApi),
};

export default cowtrollerApi;
