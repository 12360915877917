import React, { FC, SVGProps } from 'react';

const CollarIcon: FC<SVGProps<any>> = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 4C8.5 4 9.33978 7.75091 7.12293 9.91042C4.90609 12.0699 4.60502 13.3405 4.13262 15.5C3.66021 17.6595 1.00001 16.7957 1 15.5C0.999989 14.2043 1.11326 14.0914 2 11.5C2.88674 8.90859 5.96304 10.3405 5.0763 12.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12.0327 4C15.5327 4 14.6929 7.75091 16.9098 9.91042C19.1266 12.0699 19.4277 13.3405 19.9001 15.5C20.3725 17.6595 23.0327 16.7957 23.0327 15.5C23.0327 14.2043 22.9195 14.0914 22.0327 11.5C21.146 8.90859 18.0697 10.3405 18.9564 12.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CollarIcon;
