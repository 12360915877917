import { useCallback, useMemo } from 'react';

import { ObjectKeyType, PartialRecord } from 'src/types';
import { FilterInclusionType, FilterUnionType, SearchFilterState } from 'src/types/filter-types';

const DEFAULT_INCLUSION_TYPE = FilterInclusionType.INCLUDE;
const DEFAULT_UNION_TYPE = FilterUnionType.OR;

type UseSearchFiltersOperations<T extends ObjectKeyType> = {
  searchFiltersByType: PartialRecord<T, SearchFilterState>;
  setSearchFiltersByType: (filters: PartialRecord<T, SearchFilterState>) => void;
};

const useSearchFiltersOperations = <K extends ObjectKeyType>({
  searchFiltersByType,
  setSearchFiltersByType,
}: UseSearchFiltersOperations<K>) => {
  const handleToggleSearchFilterType = useCallback(
    (filterType: K) => {
      const prevFilters = searchFiltersByType;
      const updatedFilters = { ...prevFilters };
      if (prevFilters[filterType] != null) {
        delete updatedFilters[filterType];
      } else {
        updatedFilters[filterType] = { inclusionType: DEFAULT_INCLUSION_TYPE, unionType: DEFAULT_UNION_TYPE, selectedOptions: [] };
      }
      setSearchFiltersByType(updatedFilters);
    },
    [searchFiltersByType, setSearchFiltersByType],
  );

  const handleSetSearchFiltersByType = useCallback(
    (updatedSearchFiltersByType: PartialRecord<K, SearchFilterState>) => {
      setSearchFiltersByType(updatedSearchFiltersByType);
    },
    [setSearchFiltersByType],
  );

  const handleSetSearchStateByType = useCallback(
    (filterType: K, filterState: SearchFilterState) => {
      const prevFilters = searchFiltersByType;
      const updatedFilters = { ...prevFilters, [filterType]: filterState };
      setSearchFiltersByType(updatedFilters);
    },
    [searchFiltersByType, setSearchFiltersByType],
  );

  const handleToggleSearchFilterOption = useCallback(
    (filterType: K, option: string) => {
      const prevFilters = searchFiltersByType;
      const inclusionType = prevFilters[filterType]?.inclusionType ?? DEFAULT_INCLUSION_TYPE;
      const unionType = prevFilters[filterType]?.unionType ?? DEFAULT_UNION_TYPE;
      let updatedSelectedOptions = prevFilters[filterType]?.selectedOptions ?? [];

      if (updatedSelectedOptions.includes(option)) {
        updatedSelectedOptions = updatedSelectedOptions.filter((selectedOption) => selectedOption !== option);
      } else {
        updatedSelectedOptions = [...updatedSelectedOptions, option];
      }

      const updatedFilters: PartialRecord<K, SearchFilterState> = {
        ...prevFilters,
        [filterType]: { inclusionType, unionType, selectedOptions: updatedSelectedOptions },
      };
      setSearchFiltersByType(updatedFilters);
    },
    [searchFiltersByType, setSearchFiltersByType],
  );

  const handleUpdateSearchFilterInclusionType = useCallback(
    (filterType: K, inclusionType: FilterInclusionType) => {
      const prevFilters = searchFiltersByType;
      if (prevFilters[filterType] == null) return;

      const updatedFilters: PartialRecord<K, SearchFilterState> = {
        ...prevFilters,
        [filterType]: { ...prevFilters[filterType], inclusionType },
      };
      setSearchFiltersByType(updatedFilters);
    },
    [searchFiltersByType, setSearchFiltersByType],
  );

  const handleUpdateSearchFilterUnionType = useCallback(
    (filterType: K, unionType: FilterUnionType) => {
      const prevFilters = searchFiltersByType;
      if (prevFilters[filterType] == null) return;

      const updatedFilters: PartialRecord<K, SearchFilterState> = {
        ...prevFilters,
        [filterType]: { ...prevFilters[filterType], unionType },
      };
      setSearchFiltersByType(updatedFilters);
    },
    [searchFiltersByType, setSearchFiltersByType],
  );

  const handleResetSearchFilters = useCallback(() => {
    setSearchFiltersByType({});
  }, [setSearchFiltersByType]);

  const handleResetSingleSearchFilter = useCallback(
    (filterType: K, options: { clearOptionsOnly?: boolean } = {}) => {
      const { clearOptionsOnly = false } = options;

      const updatedFilters = { ...searchFiltersByType };
      if (clearOptionsOnly) {
        updatedFilters[filterType] = {
          inclusionType: updatedFilters[filterType]?.inclusionType ?? DEFAULT_INCLUSION_TYPE,
          unionType: updatedFilters[filterType]?.unionType ?? DEFAULT_UNION_TYPE,
          selectedOptions: [],
        };
      } else {
        delete updatedFilters[filterType];
      }

      setSearchFiltersByType(updatedFilters);
    },
    [searchFiltersByType, setSearchFiltersByType],
  );

  const selectedSearchFilterTypes: K[] = useMemo(() => Object.keys(searchFiltersByType) as K[], [searchFiltersByType]);

  return {
    selectedSearchFiltersByType: searchFiltersByType,
    selectedSearchFilterTypes,
    handleToggleSearchFilterType,
    handleToggleSearchFilterOption,
    handleSetSearchStateByType,
    handleUpdateSearchFilterInclusionType,
    handleUpdateSearchFilterUnionType,
    handleSetSearchFiltersByType,
    handleResetSearchFilters,
    handleResetSingleSearchFilter,
  };
};

export default useSearchFiltersOperations;
