import { Query, QueryKey, UseQueryOptions as RQUseQueryOptions } from '@tanstack/react-query';

export declare type DataUpdateFunction<TInput, TOutput> = (input: TInput) => TOutput;
export declare type Updater<TInput, TOutput> = TOutput | DataUpdateFunction<TInput, TOutput>;

export interface QueryFilters {
  active?: boolean;
  exact?: boolean;
  inactive?: boolean;
  predicate?: (query: Query) => boolean;
  queryKey?: QueryKey;
  stale?: boolean;
  fetching?: boolean;
}

export type CompiledQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<RQUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'context'>;

type DefaultQueryOptions<QueryState, DefaultApiResponse = QueryState, SelectState = QueryState> = {
  promise: () => Promise<{ data: DefaultApiResponse }>;
  mutate: (data: DefaultApiResponse) => QueryState | Promise<QueryState>;
} & RQUseQueryOptions<QueryState, unknown, SelectState>;

export type UseQueryOptions<QueryState, SelectState = QueryState, QueryParams = object> = QueryParams &
  Partial<CompiledQueryOptions<QueryState, unknown, SelectState>>;

export type DefaultOptions<QueryState, QueryParams, DefaultApiResponse> = <SelectState>(
  options: UseQueryOptions<QueryState, SelectState, QueryParams>,
) => DefaultQueryOptions<QueryState, DefaultApiResponse, SelectState>;

export const createQueryOptions =
  <QueryState, QueryParams = object, DefaultApiResponse = QueryState>(
    defaultOptions: DefaultOptions<QueryState, QueryParams, DefaultApiResponse>,
  ) =>
  <SelectState = QueryState>(options: UseQueryOptions<QueryState, SelectState, QueryParams>) => {
    const { queryKey, promise, mutate, ...otherOptions } = defaultOptions(options);

    const compiledOptions: CompiledQueryOptions<QueryState, unknown, SelectState> = {
      ...otherOptions,
      ...options,
      queryKey,
      queryFn: async () => {
        const { data } = await promise();
        return mutate(data);
      },
      onSuccess: (data) => {
        otherOptions.onSuccess?.(data);
        options.onSuccess?.(data);
      },
      onError: (err) => {
        otherOptions.onError?.(err);
        options.onError?.(err);
      },
      onSettled: (data, err) => {
        otherOptions.onSettled?.(data, err);
        options.onSettled?.(data, err);
      },
    };

    return compiledOptions;
  };
