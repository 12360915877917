import { chain } from 'lodash';
import { GiBarn } from 'react-icons/gi';

import { Colors } from 'src/styles';

import { AlarmedEntity, RankSectionConfig } from '../@types';
import { COMMON_FILTER_TYPE } from '../constants';

export const farmRankConfig: RankSectionConfig<AlarmedEntity<any>> = {
  name: 'Most Common Farms',
  associatedFilterType: COMMON_FILTER_TYPE.FARM,
  icon: (
    <GiBarn
      color={Colors.gray500}
      fontSize="1.3em"
    />
  ),
  deriveRanks: (items, selectedIdsOfRelatedFilter) =>
    chain(items)
      .filter(({ farm }) => farm?.farmName != null)
      .groupBy(({ farm }) => farm?.farmName)
      .map((devicesInFarm, farmName) => ({
        name: farmName,
        value: devicesInFarm.length,
        selected: selectedIdsOfRelatedFilter.has(farmName),
      }))
      .orderBy((a) => a.value, ['desc'])
      .value(),
};
