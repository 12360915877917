import {
  DomainNameEnum,
  IAlarmDefinitionDTO,
  IAlarmJobDTO,
  IAlarmJobTestSuiteDTO,
  IAlarmSubscriptionDTO,
  ICreateAlarmDefinitionRequestDTO,
  ICreateAlarmJobRequestDTO,
  ICreateAlarmSubscriptionRequestDTO,
  IUpdateAlarmDefinitionRequestDTO,
  IUpdateAlarmJobRequestDTO,
  IUpdateAlarmSubscriptionRequestDTO,
} from '@halter-corp/alarm-registry-service-client';
import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import moment from 'moment';

import { AlarmRegistryApi } from 'src/data';

import client from '../query-client';

const ALARM_REGISTRY_KEY = 'alarm-registry';

export const AlarmRegistryQueryKeys = {
  alarmDefinitions: [ALARM_REGISTRY_KEY, 'alarmDefinitions'],
  alarmDefinition: (alarmType: string) => [ALARM_REGISTRY_KEY, 'alarmDefinitions', alarmType],
  alarmSubscriptions: [ALARM_REGISTRY_KEY, 'alarmSubscriptions'],
  alarmSubscriptionsByAlarmType: (alarmType: string) => [
    ALARM_REGISTRY_KEY,
    'alarmSubscriptions',
    'alarmType',
    alarmType,
  ],
  alarmSubscriptionsByDomainName: (domainName: DomainNameEnum) => [
    ALARM_REGISTRY_KEY,
    'alarmSubscriptions',
    'domainName',
    domainName,
  ],
  alarmJobs: [ALARM_REGISTRY_KEY, 'alarmJobs'],
  alarmJob: (alarmType: string) => [ALARM_REGISTRY_KEY, 'alarmJobs', alarmType],
  alarmJobTestSuite: (alarmType: string) => [ALARM_REGISTRY_KEY, 'alarmJobTestSuite', alarmType],
};

export type AlarmRegistryQueriesState = {
  alarmDefinitions: Record<string, IAlarmDefinitionDTO>;
  alarmDefinition: IAlarmDefinitionDTO;
  alarmSubscriptions: IAlarmSubscriptionDTO[];
  alarmSubscriptionsByAlarmType: IAlarmSubscriptionDTO[];
  alarmJob: IAlarmJobDTO;
  alarmJobs: Record<string, IAlarmJobDTO>;
  alarmJobTestSuite: IAlarmJobTestSuiteDTO;
};

export const AlarmRegistryQueries = {
  // Queries
  useAlarmDefinitions: (options?: Partial<UseQueryOptions<Record<string, IAlarmDefinitionDTO>>>) =>
    useQuery({
      queryKey: AlarmRegistryQueryKeys.alarmDefinitions,
      queryFn: async () => {
        const { data } = await AlarmRegistryApi.alarmDefinition.getAllAlarmDefinitions();
        return keyBy(data, ({ alarmType }) => alarmType);
      },
      staleTime: moment.duration(1, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(1, 'minutes').asMilliseconds(),
      refetchOnWindowFocus: false,
      ...options,
    }),

  useAlarmDefinitionByAlarmType: (alarmType: string, options?: Partial<UseQueryOptions<IAlarmDefinitionDTO>>) =>
    useQuery({
      queryKey: AlarmRegistryQueryKeys.alarmDefinition(alarmType),
      queryFn: async () => {
        const { data } = await AlarmRegistryApi.alarmDefinition.getAlarmDefinitionByAlarmType(alarmType);
        return data;
      },
      staleTime: moment.duration(1, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(1, 'minutes').asMilliseconds(),
      refetchOnWindowFocus: false,
      ...options,
    }),

  useAlarmSubscriptions: (options?: Partial<UseQueryOptions<IAlarmSubscriptionDTO[]>>) =>
    useQuery({
      queryKey: AlarmRegistryQueryKeys.alarmSubscriptions,
      queryFn: async () => {
        const { data } = await AlarmRegistryApi.alarmSubscription.getAllAlarmSubscriptions();
        return data;
      },
      staleTime: moment.duration(1, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(1, 'minutes').asMilliseconds(),
      refetchOnWindowFocus: false,
      ...options,
    }),

  useAlarmSubscriptionsByAlarmType: (alarmType: string, options?: Partial<UseQueryOptions<IAlarmSubscriptionDTO[]>>) =>
    useQuery({
      queryKey: AlarmRegistryQueryKeys.alarmSubscriptionsByAlarmType(alarmType),
      queryFn: async () => {
        const { data } = await AlarmRegistryApi.alarmSubscription.getAllAlarmSubscriptionsByAlarmType(alarmType);
        return data;
      },
      staleTime: moment.duration(1, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(1, 'minutes').asMilliseconds(),
      refetchOnWindowFocus: false,
      ...options,
    }),

  useAlarmSubscriptionsByDomainName: (
    domainName: DomainNameEnum,
    options?: Partial<UseQueryOptions<IAlarmSubscriptionDTO[]>>,
  ) =>
    useQuery({
      queryKey: AlarmRegistryQueryKeys.alarmSubscriptionsByAlarmType(domainName),
      queryFn: async () => {
        const { data } = await AlarmRegistryApi.alarmSubscription.getAllAlarmSubscriptionsByDomainName(domainName);
        return data;
      },
      staleTime: moment.duration(1, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(1, 'minutes').asMilliseconds(),
      refetchOnWindowFocus: false,
      ...options,
    }),

  useAlarmJobsByAlarmType: (options?: Partial<UseQueryOptions<Record<string, IAlarmJobDTO>>>) =>
    useQuery({
      queryKey: AlarmRegistryQueryKeys.alarmJobs,
      queryFn: async () => {
        const { data } = await AlarmRegistryApi.alarmJob.getAllAlarmJobs();
        return keyBy(data, ({ alarmType }) => alarmType);
      },
      staleTime: moment.duration(1, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(1, 'minutes').asMilliseconds(),
      refetchOnWindowFocus: false,
      ...options,
    }),

  useAlarmJobByAlarmType: (alarmType: string, options?: Partial<UseQueryOptions<IAlarmJobDTO | null>>) =>
    useQuery({
      queryKey: AlarmRegistryQueryKeys.alarmJob(alarmType),
      queryFn: async () => {
        try {
          const { data } = await AlarmRegistryApi.alarmJob.getAlarmJobByAlarmType(alarmType);
          return data;
        } catch (err: any) {
          if (err.response?.status === 404) {
            return null;
          }
          throw err;
        }
      },
      staleTime: moment.duration(1, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(1, 'minutes').asMilliseconds(),
      refetchOnWindowFocus: false,
      ...options,
    }),

  useAlarmJobTestSuiteByAlarmType: (
    alarmType: string,
    options?: Partial<UseQueryOptions<IAlarmJobTestSuiteDTO | null>>,
  ) =>
    useQuery({
      queryKey: AlarmRegistryQueryKeys.alarmJobTestSuite(alarmType),
      queryFn: async () => {
        try {
          const { data } = await AlarmRegistryApi.alarmJob.getAlarmJobTestSuiteByAlarmType(alarmType);
          return data;
        } catch (err: any) {
          if (err.response?.status === 404) {
            return null;
          }
          throw err;
        }
      },
      ...options,
    }),

  // Mutations
  useCreateAlarmDefinitionMutation: () =>
    useMutation({
      mutationFn: async (request: ICreateAlarmDefinitionRequestDTO) => {
        const { data } = await AlarmRegistryApi.alarmDefinition.createAlarmDefinition(request);
        return data;
      },
      onSuccess: AlarmRegistryQueries.setAlarmDefinition,
    }),

  useUpdateAlarmDefinitionMutation: () =>
    useMutation({
      mutationFn: async (payload: IUpdateAlarmDefinitionRequestDTO & { alarmType: string }) => {
        const { alarmType, ...request } = payload;
        const { data } = await AlarmRegistryApi.alarmDefinition.updateAlarmDefinitionByAlarmType(alarmType, request);
        return data;
      },
      onSuccess: AlarmRegistryQueries.setAlarmDefinition,
    }),

  useCreateAlarmSubscriptionMutation: () =>
    useMutation({
      mutationFn: async (request: ICreateAlarmSubscriptionRequestDTO) => {
        const { data } = await AlarmRegistryApi.alarmSubscription.createAlarmSubscription(request);
        return data;
      },
      onSuccess: AlarmRegistryQueries.setAlarmSubscription,
    }),

  useUpdateAlarmSubscriptionMutation: () =>
    useMutation({
      mutationFn: async (
        payload: IUpdateAlarmSubscriptionRequestDTO & { alarmType: string; domainName: DomainNameEnum },
      ) => {
        const { alarmType, domainName, ...request } = payload;
        const { data } = await AlarmRegistryApi.alarmSubscription.updateAlarmSubscriptionByAlarmTypeAndDomainName(
          alarmType,
          domainName,
          request,
        );
        return data;
      },
      onSuccess: AlarmRegistryQueries.setAlarmSubscription,
    }),

  useCreateAlarmJobMutation: () =>
    useMutation({
      mutationFn: async (createAlarmJobRequest: ICreateAlarmJobRequestDTO) => {
        const { data } = await AlarmRegistryApi.alarmJob.createAlarmJob(createAlarmJobRequest);
        return data;
      },
      onSuccess: AlarmRegistryQueries.setAlarmJob,
    }),

  useUpdateAlarmJobMutation: () =>
    useMutation({
      mutationFn: async (updateAlarmJobRequest: IUpdateAlarmJobRequestDTO & { alarmType: string }) => {
        const { data } = await AlarmRegistryApi.alarmJob.updateAlarmJobByAlarmType(
          updateAlarmJobRequest.alarmType,
          updateAlarmJobRequest,
        );
        return data;
      },
      onSuccess: AlarmRegistryQueries.setAlarmJob,
    }),

  usePutAlarmJobTestSuiteMutation: () =>
    useMutation({
      mutationFn: async (alarmJobTestSuite: IAlarmJobTestSuiteDTO) => {
        const { data } = await AlarmRegistryApi.alarmJob.putAlarmJobTestSuite(alarmJobTestSuite);
        return data;
      },
      onSuccess: AlarmRegistryQueries.setAlarmJobTestSuite,
    }),

  useScheduleAlarmJobAssessmentMutation: () =>
    useMutation({
      mutationFn: async (alarmType: string) => {
        await AlarmRegistryApi.alarmJob.scheduleAlarmJobAssessmentByAlarmType(alarmType);
      },
    }),

  useScheduleAlarmJobExecutionMutation: () =>
    useMutation({
      mutationFn: async (alarmType: string) => {
        await AlarmRegistryApi.alarmJob.scheduleAlarmJobExecutionByAlarmType(alarmType);
      },
    }),

  useCleanUpAlarmByAlarmTypeMutation: () =>
    useMutation({
      mutationFn: async (alarmType: string) => {
        await AlarmRegistryApi.cleanUp.cleanUpAlarmByAlarmType(alarmType);
      },
    }),

  refreshAlarmDefinitionByAlarmType: (alarmType: string) => {
    void client.invalidateQueries({
      queryKey: AlarmRegistryQueryKeys.alarmDefinition(alarmType),
    });
  },

  refreshAlarmSubscriptionsByAlarmType: (alarmType: string) => {
    void client.invalidateQueries({
      queryKey: AlarmRegistryQueryKeys.alarmSubscriptionsByAlarmType(alarmType),
    });
  },

  refreshAlarmJobByAlarmType: (alarmType: string) => {
    void client.invalidateQueries({
      queryKey: AlarmRegistryQueryKeys.alarmJob(alarmType),
    });
  },

  // Cache
  setAlarmDefinition: (alarmDefinition: IAlarmDefinitionDTO) => {
    client.setClientData<'alarmDefinitions'>(AlarmRegistryQueryKeys.alarmDefinitions, (prevState) => ({
      ...prevState,
      [alarmDefinition.alarmType]: alarmDefinition,
    }));

    void client.invalidateQueries({
      queryKey: AlarmRegistryQueryKeys.alarmDefinition(alarmDefinition.alarmType),
    });
  },

  setAlarmSubscription: (alarmSubscription: IAlarmSubscriptionDTO) => {
    client.setClientData<'alarmSubscriptions'>(AlarmRegistryQueryKeys.alarmSubscriptions, (prevState) => {
      if (prevState == null) return [];

      const newState = [...prevState];
      const existingIndex = newState.findIndex(
        (as) => as.alarmType === alarmSubscription.alarmType && as.domainName === alarmSubscription.domainName,
      );
      if (existingIndex >= 0) {
        newState.splice(existingIndex, 1, alarmSubscription);
      } else {
        newState.push(alarmSubscription);
      }
      return newState;
    });

    void client.invalidateQueries({
      queryKey: AlarmRegistryQueryKeys.alarmSubscriptionsByAlarmType(alarmSubscription.alarmType),
    });
  },

  setAlarmJob: (alarmJob: IAlarmJobDTO) => {
    client.setClientData<'alarmJobs'>(AlarmRegistryQueryKeys.alarmJobs, (prevState) => ({
      ...prevState,
      [alarmJob.alarmType]: alarmJob,
    }));

    client.setClientData<'alarmJob'>(AlarmRegistryQueryKeys.alarmJob(alarmJob.alarmType), alarmJob);
  },

  setAlarmJobTestSuite: (alarmJobTestSuite: IAlarmJobTestSuiteDTO) => {
    client.setClientData<'alarmJobTestSuite'>(
      AlarmRegistryQueryKeys.alarmJobTestSuite(alarmJobTestSuite.alarmType),
      alarmJobTestSuite,
    );
  },
};
