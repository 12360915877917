import { Spinner, Text } from '@chakra-ui/react';
import { FC, useMemo } from 'react';

import { InfraProduct } from 'src/features/infra-product';
import { getTowerType } from 'src/features/tower';
import { Colors } from 'src/styles';

import { AlarmedTower } from '../@types';

type TowerTypeCellProps = {
  tower: AlarmedTower;
};

const TowerTypeCell: FC<TowerTypeCellProps> = ({ tower }) => {
  const infraProductsResponse = InfraProduct.useFetchByTowerIds([tower.entityId]);

  const towerType = useMemo(() => {
    if (infraProductsResponse.isLoading) return null;

    return getTowerType({ infraProducts: infraProductsResponse.data ?? [], starlink: tower.context.starlink });
  }, [infraProductsResponse.data, infraProductsResponse.isLoading, tower.context.starlink]);

  if (infraProductsResponse.isLoading) return <Spinner size="sm" />;
  if (infraProductsResponse.isError)
    return (
      <Text
        fontSize="sm"
        color={Colors.red600}
      >
        Error
      </Text>
    );

  return <Text fontSize="sm">{towerType}</Text>;
};

export default TowerTypeCell;
