import { Input, InputProps } from '@chakra-ui/react';
import React, { FunctionComponent, RefObject } from 'react';

import FormikFormField, { FormikFormFieldProps } from './formik-form-field';

export type FormikInputProps = {
  id: string;
  inputProps?: InputProps;
  inputRef?: RefObject<HTMLInputElement>;
} & Omit<FormikFormFieldProps, 'children' | 'htmlFor'>;

const FormikInput: FunctionComponent<FormikInputProps> = (props) => {
  const { inputProps, inputRef, id, ...otherProps } = props;

  return (
    <FormikFormField
      htmlFor={id}
      {...otherProps}
    >
      {([fieldProps, { error }]) => (
        <Input
          id={id}
          ref={inputRef}
          {...fieldProps}
          value={fieldProps.value ?? ''}
          isInvalid={error != null}
          {...inputProps}
        />
      )}
    </FormikFormField>
  );
};

export default FormikInput;
