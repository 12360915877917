import { createContext } from 'react';

import { UserSession } from './types';

export type AuthContextType = {
  loading: boolean;
  session: UserSession | null;
};

const AuthContext = createContext<AuthContextType>(null!);

export default AuthContext;
