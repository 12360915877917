import { polygon } from '@turf/turf';

import { Colors } from 'src/styles';

import { MapPosition, MapScope } from '../@types';

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiYmVuamFtaW4tc3dlbmV5LWhhbHRlciIsImEiOiJja3Y1d2N0cW0yNDRtMm9xMWowYTN1dTBvIn0.IJN324g19cwXGMnpOE73ww';

export const MAX_PITCH_ALLOWED = 80;

export const WORLD_POLYGON = polygon([
  [
    [-180, -90],
    [-180, 90],
    [180, 90],
    [180, -90],
    [-180, -90],
  ],
]);

export const HOME_POSITION: MapPosition = {
  latitude: -40.9006,
  longitude: 162.7246,
  zoom: 4,
};

export const MAP_SOURCE_IDS = {
  MAPBOX_DEM: 'mapbox-dem',
  COMMON_ORTHO: 'common-ortho',
  TERMINATOR: 'terminator',

  REGION_BOUNDARIES: 'region-boundaries',

  FARM_DATUMS: 'farm-datums',
  FARM_BOUNDARIES: 'farm-boundaries',
  FARM_ORTHO: 'farm-ortho',

  PADDOCKS: 'paddocks',
  PADDOCK_LABELS: 'paddock-labels',
  WATERWAYS: 'waterways',

  COLLAR_LOCATIONS: 'collar-locations',
  CATTLE_LOCATIONS: 'cattle-locations',
  BREAK_FENCES: 'break-fences',
  BREAK_FENCE_EDGES: 'break-fence-edges',
  SHIFTS: 'shifts',

  CATTLE_PATHS: 'cattle-paths',
  CATTLE_PATHWAY_DIRECTIONS: 'cattle-pathway-directions',
  COWG_EVENTS: 'cattle-events',

  SHOCK_HEATMAP: 'shock-heatmap',
  PIEZO_HEATMAP: 'piezo-heatmap',
  VIBE_HEATMAP: 'vibe-heatmap',
  COLLAR_LOCATION_HEATMAP: 'collar-location-heatmap',

  RAIN_RADAR: 'rain-radar',
  CLOUD: 'cloud',

  PROPERTY_PARCELS: 'property-parcels',

  LOCAL_TILES: 'local-tiles',

  // Note: virtual map layers
  Z_INDEX: 'z-index',
} as const;

export const MAP_MODE_COLORS = {
  LIVE_VIEW: { light: '#FFFFFF', dark: '#141518' },
  DEBUG: { light: '#ffe5e5' },
  TOWER_MODELLING: { light: '#fff7d5' },
  FEATURE_MAPPING: { light: '#E3FDCB' },
} as const;

export const SCOPE_COLORS: Record<MapScope, string | undefined> = {
  FLEET: undefined,
  REGION: undefined,
  FARM: Colors.slate200,
  PADDOCK: Colors.green200,
  BREAK_FENCE: Colors.cyan200,
  SHIFT: Colors.pink200,
  COMMAND: Colors.purple200,
  MOB: Colors.orange200,
  COW: Colors.yellow200,
  COLLAR: Colors.cyan200,
  TOWER: Colors.blue200,
  TOWER_LINK: Colors.orange200,
  COW_EVENT: Colors.amber200, // e.g. cowg events
};

export const SCOPE_MENU_SHORTCUTS = {
  REGION: 'r',
  FARM: 'f',
  MOB_OR_PADDOCK: 'm',
  COLLAR_OR_COW: 'c',
};
