import { Stack, StackProps, Text, TextProps } from '@chakra-ui/react';
import React, { FC, JSXElementConstructor, ReactElement } from 'react';

import { Colors } from 'src/styles';

type EmptyStateProps = {
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
  textProps?: TextProps;
  message: string;
} & StackProps;

const EmptyState: FC<EmptyStateProps> = ({ icon, message, textProps, ...rest }) => (
  <Stack
    justifyContent="center"
    alignItems="center"
    {...rest}
  >
    {icon}
    <Text
      fontSize="sm"
      textTransform="capitalize"
      color={Colors.gray500}
      {...textProps}
    >
      {message}
    </Text>
  </Stack>
);

export default EmptyState;
