import {
  DeviceContextApi,
  DeviceStatusApi,
  NetworkDeviceContextApi,
  TowerApi as TowerServiceTowerApi,
} from '@halter-corp/tower-service-client';

import client from '../client';

const TowerApi = {
  deviceContext: client.resolveOpenApi(DeviceContextApi),
  networkDeviceContext: client.resolveOpenApi(NetworkDeviceContextApi),
  deviceStatus: client.resolveOpenApi(DeviceStatusApi),
  tower: client.resolveOpenApi(TowerServiceTowerApi),
};

export default TowerApi;
