import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
} from '@chakra-ui/react';
import { PropsWithChildren, ReactNode } from 'react';

export type FormFieldProps = Omit<FormControlProps, 'label'> &
  PropsWithChildren<{
    htmlFor: string;
    name: string;
    label?: ReactNode;
    labelProps?: FormLabelProps;
    helperText?: string | null;
    error?: string | null;
  }>;

export default function FormField(props: FormFieldProps) {
  const { htmlFor, label, labelProps, helperText, error, children, ...rest } = props;
  return (
    <FormControl
      isInvalid={error != null}
      {...rest}
    >
      {label != null && (
        <FormLabel
          htmlFor={htmlFor}
          color="gray.900"
          size="2xs"
          {...labelProps}
        >
          {label}
        </FormLabel>
      )}
      {children}
      {helperText != null && <FormHelperText>{helperText}</FormHelperText>}
      {error != null && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
}
