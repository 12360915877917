import { AlertStatus, UseToastOptions, useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

const COMMON_TOAST_PROPS = {
  duration: 3000,
  isClosable: true,
  position: 'top-right',
} as const;

function useCommonToast() {
  const toast = useToast();

  const displayToast = useCallback(
    (
      status: AlertStatus,
      title: string,
      message?: string,
      options?: Omit<UseToastOptions, 'id' | 'title' | 'description'>,
    ) => {
      toast({
        status,
        title,
        description: message,
        ...COMMON_TOAST_PROPS,
        ...options,
      });
    },
    [toast],
  );

  const displayToastWithId = useCallback(
    (
      id: string,
      status: AlertStatus,
      title: string,
      message?: string,
      options?: Omit<UseToastOptions, 'id' | 'title' | 'description'>,
    ) => {
      if (toast.isActive(id) === false) {
        toast({
          id,
          status,
          title,
          description: message,
          ...COMMON_TOAST_PROPS,
          ...options,
        });
      }
    },
    [toast],
  );

  const updateToastWithId = useCallback(
    (id: string, status: AlertStatus, title: string, message?: string) => {
      toast.update(id, {
        status,
        title,
        description: message,
      });
    },
    [toast],
  );

  const closeToastById = useCallback(
    (id: string) => {
      toast.close(id);
    },
    [toast],
  );

  const checkIsActive = useCallback(
    (id: string) => {
      return toast.isActive(id);
    },
    [toast],
  );

  return { displayToast, displayToastWithId, updateToastWithId, closeToastById, checkIsActive };
}

export default useCommonToast;
