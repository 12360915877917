import { ComponentStyleConfig } from '@chakra-ui/react';

import { Colors } from '..';

const buttonTheme: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 10,
    fontWeight: 600,
  },
  variants: {
    light: {
      color: Colors.gray900,
      bgColor: Colors.gray200,
      boxShadow: 'md',
      _hover: {
        bgColor: Colors.gray300,
        boxShadow: 'lg',
      },
    },
    dark: {
      color: Colors.gray50,
      bgColor: Colors.gray900,
      boxShadow: 'md',
      _hover: {
        bgColor: Colors.gray800,
        boxShadow: 'lg',
      },
      _disabled: {
        opacity: 0.6,
        bgColor: `${Colors.gray500}!important`,
      },
    },
  },
};

export default buttonTheme;
