import { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';

type PageProps = PropsWithChildren<{
  /**
   * @summary The title of the page.
   */
  title: string;
  /**
   * @summary The description of the page. This will be added to the page's meta tags.
   */
  description?: string;
}>;

const Page: FC<PageProps> = ({ title, description, children }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        {description != null && (
          <meta
            name="description"
            content={description}
          />
        )}
      </Helmet>
      {children}
    </>
  );
};

export default Page;
