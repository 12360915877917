export const SearchUtils = {
  /**
   * Checks if a given string matches any of the provided search patterns.
   * The patterns can be a case-insensitive substring, a regex pattern, or a wildcard pattern.
   *
   * @param {string} inputString - The string to be searched.
   * @param {string} pattern - The pattern to search for.
   * @return {boolean} Returns true if the inputString matches the rule in any of the three search patterns, otherwise returns false.
   */
  matchesAnySearchPattern(inputString: string, pattern: string) {
    try {
      return (
        this.containsStringCaseInsensitive(inputString, pattern) ||
        this.regexMatchString(inputString, pattern) ||
        this.wildcardMatchString(inputString, pattern)
      );
    } catch (error) {
      return false;
    }
  },

  containsStringCaseInsensitive(inputString: string, textPattern: string) {
    return inputString.toLowerCase().includes(textPattern.trim().toLowerCase());
  },

  regexMatchString(inputString: string, regexPattern: string) {
    const regex = new RegExp(regexPattern, 'i');
    return regex.test(inputString);
  },

  /**
   * Escape special regex characters in a string.
   * Matches the following characters: . * + ? ^ = ! : $ { } ( ) | [ ] / \
   */
  escapeRegexCharacters(input: string) {
    return input.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  },

  wildcardMatchString(inputString: string, wildcardPattern: string) {
    // Convert wildcard '*' in rule to '.*' in regex (matches any number of any characters)
    const partialPattern = wildcardPattern.split('*').map(this.escapeRegexCharacters).join('.*');

    // '^' and '$' require the match to be at the start and end of the string, respectively
    const fullPattern = `^${partialPattern}$`;

    const regex = new RegExp(fullPattern, 'i');

    return regex.test(inputString);
  },
};
