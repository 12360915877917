import { Flex } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { FallbackProps } from 'react-error-boundary';

import Header from 'src/components/header';

import ErrorPanel from '../components/error-panel';

const ErrorScreen: FunctionComponent<FallbackProps> = (props) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    width="100%"
    height="100vh"
  >
    {/* Header for navigation (mobile screen) */}
    <Header
      position="absolute"
      top="0"
      left="0"
    />

    {/* Panel for displaying error message and actions */}
    <ErrorPanel {...props} />
  </Flex>
);

export default ErrorScreen;
