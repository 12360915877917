import React, { FunctionComponent } from 'react';

import CommonLoadingSpinner from 'src/components/common-loading-spinner';

const PageLoadingSpinner: FunctionComponent = () => (
  <CommonLoadingSpinner
    width="100%"
    height="80vh"
  />
);

export default PageLoadingSpinner;
