import { DomainNameEnum } from '@halter-corp/alarm-registry-service-client';
import { IFarmDTO } from '@halter-corp/business-service-client';
import { AlarmScopeEnum, IPowerActiveAlarmsContextDTO } from '@halter-corp/power-alarm-service-client';
import { chain, isEmpty, maxBy, minBy } from 'lodash';
import { useMemo } from 'react';

import { AlarmRegistryQueries, BusinessQueries, PowerAlarmQueries } from 'src/queries';
import { FilterOption } from 'src/types/filter-types';
import { CollarUtils } from 'src/util/collar.util';

import { AlarmScope, FarmContext } from '../../../@types';
import { PowerAlarmEntity, PowerAlarmFilterType, PowerCollarContext, PowerEntityScopeContext } from '../@types';
import { PowerAlarmFilterOptionModel } from '../models/power-alarm-filter-option.model';

const ALARM_SCOPES: string[] = Object.values(AlarmScopeEnum);

const PowerMonitoringService = {
  usePowerAlarmEntities: () => {
    return PowerMonitoringService.usePowerActiveAlarmEntities();
  },

  usePowerActiveAlarmEntities: () => {
    const { data = [], isLoading, isError, refetch } = PowerAlarmQueries.useActionRequiredPowerActiveAlarmsContexts();
    const { data: farmById } = BusinessQueries.useFindAllFarmsQuery();
    const powerAlarmEntities: PowerAlarmEntity[] = useMemo(
      () => mapActiveAlarmDTOsToPowerAlarmEntities(data, farmById ?? {}),
      [data, farmById],
    );

    return { data: powerAlarmEntities, isLoading, isError, refetchData: refetch };
  },

  usePowerAlarmTypes: () => {
    const { data = [] } = AlarmRegistryQueries.useAlarmSubscriptionsByDomainName(DomainNameEnum.POWER);

    return useMemo(
      () =>
        chain(data)
          .map(({ alarmType }) => alarmType)
          .value(),
      [data],
    );
  },

  usePowerAlarmFilterOptionsByType: () => {
    const { data: farmById } = BusinessQueries.useFindAllFarmsQuery();
    const farms = useMemo(() => Object.values(farmById ?? {}), [farmById]);
    const alarmTypes = PowerMonitoringService.usePowerAlarmTypes();

    const filterOptionsByType: Map<PowerAlarmFilterType, FilterOption<unknown>[]> = useMemo(
      () =>
        new Map([
          [
            PowerAlarmFilterType.SCOPE,
            Object.values(AlarmScopeEnum).map((status) =>
              PowerAlarmFilterOptionModel.create(PowerAlarmFilterType.SCOPE, status),
            ),
          ],
          [
            PowerAlarmFilterType.ALARMS,
            alarmTypes.map((alarmType) => PowerAlarmFilterOptionModel.create(PowerAlarmFilterType.ALARMS, alarmType)),
          ],
          [
            PowerAlarmFilterType.FARM,
            farms.map((farm) =>
              PowerAlarmFilterOptionModel.create(PowerAlarmFilterType.FARM, farm.name ?? '', { farmId: farm.id }),
            ),
          ],
        ]),
      [alarmTypes, farms],
    );

    return filterOptionsByType;
  },
};

const mapActiveAlarmDTOsToPowerAlarmEntities = (
  powerAlarmContexts: IPowerActiveAlarmsContextDTO[],
  farmById: Record<string, IFarmDTO>,
) => {
  const powerAlarmEntities: PowerAlarmEntity[] = powerAlarmContexts.flatMap((alarmContext) => {
    const scope = findAlarmScope(alarmContext.alarmScope);
    if (scope == null) {
      console.error(`could not find alarm scope for alarm context: ${JSON.stringify(alarmContext)}`);
      return [];
    }

    const firstLoggedDate = minBy(alarmContext.alarms, (alarm) => alarm.createdAt)?.createdAt;
    const lastLoggedDate = maxBy(alarmContext.alarms, (alarm) => alarm.createdAt)?.createdAt;
    const debugLink =
      alarmContext.alarmScope === AlarmScopeEnum.COLLAR
        ? CollarUtils.getDebugLink(alarmContext.referenceId)
        : undefined;

    const entityContext = getScopeContext(alarmContext, farmById);
    if (entityContext == null) return [];

    return {
      entityId: alarmContext.referenceId,
      uniqueKey: alarmContext.referenceId,
      alarmBehaviour: 'active',
      farm: getFarmContext(alarmContext, farmById),
      alarms: alarmContext.alarms.map((alarm) => ({ type: alarm.type, eventDateTime: alarm.createdAt })),
      context: entityContext,
      scope,
      debugLink,
      firstLoggedDate: firstLoggedDate != null ? new Date(firstLoggedDate) : undefined,
      lastLoggedDate: lastLoggedDate != null ? new Date(lastLoggedDate) : undefined,
    };
  });

  return powerAlarmEntities;
};

const findAlarmScope = (alarmScope: AlarmScopeEnum): AlarmScope | undefined =>
  ALARM_SCOPES.find((scope): scope is AlarmScope => scope === alarmScope);

const getScopeContext = (
  alarmContext: IPowerActiveAlarmsContextDTO,
  farmById: Record<string, IFarmDTO>,
): PowerEntityScopeContext | null => {
  switch (alarmContext.alarmScope) {
    case AlarmScopeEnum.COLLAR:
      const collarContextDTO = alarmContext.context?.collarContext;
      if (collarContextDTO == null) return null;

      const powerCollarContext: PowerCollarContext = {
        farmId: isEmpty(collarContextDTO.farmId) ? undefined : collarContextDTO.farmId,
        mobId: isEmpty(collarContextDTO.mobId) ? undefined : collarContextDTO.mobId,
        cattleId: isEmpty(collarContextDTO.cattleId) ? undefined : collarContextDTO.cattleId,
        cattleName: isEmpty(collarContextDTO.cattleName) ? undefined : collarContextDTO.cattleName,
      };
      return { collarContext: powerCollarContext };
    case AlarmScope.MOB:
      const mobContextDTO = alarmContext.context?.mobContext;
      if (mobContextDTO == null) return null;
      return { mobContext: mobContextDTO };
    case AlarmScope.FARM:
      return { farmContext: { name: farmById[alarmContext.referenceId]?.name } };
    default:
      return {};
  }
};

const getFarmContext = (
  alarmContext: IPowerActiveAlarmsContextDTO,
  farmById: Record<string, IFarmDTO>,
): FarmContext | null => {
  switch (alarmContext.alarmScope) {
    case AlarmScopeEnum.COLLAR:
      const collarContext = alarmContext.context?.collarContext;
      if (collarContext == null) return null;

      const collarFarm = farmById[collarContext.farmId];
      if (collarFarm == null) return null;
      return {
        farmId: collarFarm.id,
        farmName: collarFarm.name,
      };
    case AlarmScopeEnum.MOB:
      const mobContext = alarmContext.context?.mobContext;
      if (mobContext == null) return null;

      const mobFarm = farmById[mobContext.farmId];
      if (mobFarm == null) return null;
      return {
        farmId: mobFarm.id,
        farmName: mobFarm.name,
      };
    case AlarmScopeEnum.FARM:
      const farm = farmById[alarmContext.referenceId];
      if (farm == null) return null;

      return {
        farmId: alarmContext.referenceId,
        farmName: farm.name,
      };
    default:
      return null;
  }
};

export default PowerMonitoringService;
