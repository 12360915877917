import { FaBroadcastTower } from 'react-icons/fa';
import { FaCow, FaEarthOceania } from 'react-icons/fa6';
import { GiBarn } from 'react-icons/gi';
import { ImCommand, ImShift } from 'react-icons/im';
import { MdOutlineGroupWork } from 'react-icons/md';
import { RiShapeLine } from 'react-icons/ri';
import { TbFence } from 'react-icons/tb';

import CollarIcon from 'src/components/icons/collar-icon';

export const APP_NAME = 'Farmhand';
export const AUCKLAND_OFFICE_LOCATION_ID = 'auckland-office';

export const FARM_SCOPE_ICONS = {
  collar: CollarIcon,
  cow: FaCow,
  tower: FaBroadcastTower,
  breakFence: TbFence,
  shift: ImShift,
  command: ImCommand,
  paddock: RiShapeLine,
  mob: MdOutlineGroupWork,
  farm: GiBarn,
};

// NOTE: use unified icon for map link for consistency
export const MAP_LINK_ICON = FaEarthOceania;
