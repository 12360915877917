import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import { uniq } from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';

import useAsyncEffect from 'src/hooks/use-async-effect';
import { EnvironmentEnum } from 'src/services/configuration.service';
import safeJsonParse from 'src/util/safe-json-parse.util';

import * as envConfig from '../env-config';
import { ACCOUNT_ATTRIBUTES_MAP, AuthContext, AuthContextType, UserFarm, UserSession } from '../providers/auth';

const AuthService = {
  useProvidedState: (): AuthContextType => {
    const [userSession, setUserSession] = useState<UserSession | null>(null);
    const [loading, setLoading] = useState(true);

    const handleCognitoUser = useCallback(async (cognitoUser: CognitoUser) => {
      const cognitoUserSession = await AuthService.getUserSession(cognitoUser);
      setUserSession(cognitoUserSession);
    }, []);

    useAsyncEffect(async () => {
      const authListener = Hub.listen('auth', (data) => {
        switch (data.payload.event) {
          case 'signIn': {
            const cognitoUser = data.payload.data as CognitoUser;
            void handleCognitoUser(cognitoUser);
            break;
          }
          case 'signOut':
            setUserSession(null);
            break;
          default:
            break;
        }
      });

      try {
        const cognitoUser = (await Auth.currentAuthenticatedUser()) as CognitoUser;
        await handleCognitoUser(cognitoUser);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }

      return authListener;
    }, [handleCognitoUser]);

    return useMemo(
      () => ({
        session: userSession,
        loading,
      }),
      [loading, userSession],
    );
  },

  useAuth: () => {
    const auth = useContext(AuthContext);
    if (auth == null || auth.session == null) {
      throw new Error('User is not authenticated');
    }
    return auth.session;
  },

  isHalterUser: (email: string): boolean =>
    /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(halter\.co\.nz|halter\.io)$/.test(email),

  getUserSession: async (cognitoUser: CognitoUser): Promise<UserSession> =>
    new Promise((resolve) => {
      cognitoUser.getUserAttributes((error, cognitoAttributes) => {
        if (error != null) {
          throw error;
        }

        if (cognitoAttributes == null) {
          throw new Error('User is setup incorrectly, no user attributes found.');
        }

        const attributes: {
          [key: string]: string;
        } = cognitoAttributes.reduce((previous, cognitoAttribute) => {
          const name = cognitoAttribute.getName();
          const value = cognitoAttribute.getValue();
          return {
            ...previous,
            [name]: value,
          };
        }, {});

        const email = attributes[ACCOUNT_ATTRIBUTES_MAP.email];
        const isHalterUser = AuthService.isHalterUser(email);

        const farmId = attributes[ACCOUNT_ATTRIBUTES_MAP.farmId];
        const availableFarms = safeJsonParse<UserFarm[]>(attributes[ACCOUNT_ATTRIBUTES_MAP.farms]);

        resolve({
          farmId,
          firstName: attributes[ACCOUNT_ATTRIBUTES_MAP.firstName],
          lastName: attributes[ACCOUNT_ATTRIBUTES_MAP.lastName],
          email: attributes[ACCOUNT_ATTRIBUTES_MAP.email],
          phoneNumber: attributes[ACCOUNT_ATTRIBUTES_MAP.phoneNumber],
          farms: uniq([farmId, ...(availableFarms?.map((farm) => farm.farmId) ?? [])]),
          isHalterUser,
        });
      });
    }),

  signIn: async () => {
    await Auth.federatedSignIn({ customProvider: 'Okta' });
  },

  signOut: (environment: EnvironmentEnum) => {
    window.localStorage.clear();
    const { awsConfig } = envConfig[environment];
    window.location.href = `https://${awsConfig.oauth.domain}/logout?client_id=${awsConfig.aws_user_pools_web_client_id}&redirect_uri=${window.location.origin}&logout_uri=${window.location.origin}&response_type=${awsConfig.oauth.responseType}&scope=openid+email+aws.cognito.signin.user.admin`;
  },
};

export default AuthService;
