import {
  AlarmActionTypeEnum,
  ICollarAlarmSnoozeActionContextDTO,
  ICreateCollarAlarmActionRequestDTO,
} from '@halter-corp/collar-alarm-service-client';
import { chunk } from 'lodash';

import useCommonToast from 'src/hooks/use-common-toast';
import { AlarmedEntity } from 'src/modules/monitoring/@types';
import { CollarAlarmQueries, RemoveAlarmActionRequest } from 'src/queries/queries/collar-alarm.queries';
import { AuthService } from 'src/services';
import { formatStringAsLabel } from 'src/util/string-format.util';

type BatchCollarAlarmActionProps = {
  actionType: AlarmActionTypeEnum;
  onSuccess: () => void;
};

export const CollarAlarmActionService = {
  useBatchCollarAlarmActions: <T>({ actionType, onSuccess }: BatchCollarAlarmActionProps) => {
    const createCollarAlarmActionMutation = CollarAlarmQueries.useCreateCollarAlarmActionMutation();
    const authUser = AuthService.useAuth();
    const { displayToast } = useCommonToast();

    const sendBatchActionRequests = async (serialNumbers: string[], actionContext: T, comments?: string) => {
      const userName = `${authUser.firstName} ${authUser.lastName}`;

      const collarAlarmActionRequests: ICreateCollarAlarmActionRequestDTO[] = serialNumbers.map((serialNumber) => ({
        serialNumber,
        actionType,
        createdBy: userName,
        actionContext: JSON.stringify(actionContext),
        comments,
      }));

      try {
        for (const requestChunks of chunk(collarAlarmActionRequests, 50)) {
          // eslint-disable-next-line no-await-in-loop
          await Promise.all(requestChunks.map((request) => createCollarAlarmActionMutation.mutateAsync(request)));
        }

        onSuccess?.();
        return {
          status: 'success',
        };
      } catch (err: any) {
        const message = err.response?.data?.message ?? err.message;
        displayToast('error', `Failed to send "${formatStringAsLabel(actionType)}" request`, message as string);
        return {
          status: 'error',
          message,
        };
      }
    };

    return { sendBatchActionRequests };
  },

  useBatchRemoveCollarEventAlarmActions: ({ onSuccess }: { onSuccess: () => void }) => {
    const removeCollarAlarmActionMutation = CollarAlarmQueries.useCreateRemoveAlarmActionMutation();
    const authUser = AuthService.useAuth();
    const { displayToast } = useCommonToast();

    const sendBatchActionRequests = async (eventAlarmEntities: AlarmedEntity<unknown>[]) => {
      const userName = `${authUser.firstName} ${authUser.lastName}`;

      try {
        for (const requestChunks of chunk(eventAlarmEntities, 50)) {
          const removeActionRequests: RemoveAlarmActionRequest[] = requestChunks.flatMap((entity) => {
            // NOTE: event alarms should have one and only one alarm
            if (entity.alarms.length !== 1) return [];

            return {
              serialNumber: entity.entityId,
              targetAlarm: {
                type: entity.alarms[0].type,
                createdAt: entity.alarms[0].eventDateTime,
              },
              createdBy: userName,
            };
          });
          // eslint-disable-next-line no-await-in-loop
          await Promise.all(
            removeActionRequests.map((request) => removeCollarAlarmActionMutation.mutateAsync(request)),
          );
        }

        onSuccess?.();
        return {
          status: 'success',
        };
      } catch (err: any) {
        const message = err.response?.data?.message ?? err.message;
        displayToast(
          'error',
          `Failed to delete collar event alarms after the action was completed...`,
          message as string,
        );
        return {
          status: 'error',
          message,
        };
      }
    };

    return sendBatchActionRequests;
  },

  useCollarSnoozeAlarmAction: () => {
    const createDeviceAlarmActionMutation = CollarAlarmQueries.useCreateCollarAlarmActionMutation();
    const authUser = AuthService.useAuth();
    const { displayToast } = useCommonToast();

    const sendActionRequest = async (
      serialNumber: string,
      expiresAt: string,
      alarmsList: string[],
      comments?: string,
    ): Promise<{ status: 'success' | 'error' }> => {
      const userName = `${authUser.firstName} ${authUser.lastName}`;

      const snoozeActionContext: ICollarAlarmSnoozeActionContextDTO = {
        expiresAt,
        alarmTypes: alarmsList,
      };
      const alarmActionRequest: ICreateCollarAlarmActionRequestDTO = {
        serialNumber,
        actionType: AlarmActionTypeEnum.SNOOZE,
        createdBy: userName,
        actionContext: JSON.stringify(snoozeActionContext),
        comments,
      } as ICreateCollarAlarmActionRequestDTO;

      try {
        await createDeviceAlarmActionMutation.mutateAsync(alarmActionRequest);
        displayToast('success', `Snooze collar action successful!`);
        return { status: 'success' };
      } catch (err: any) {
        console.log(err);
        displayToast('error', `Snooze collar action failed...`);
        return { status: 'error' };
      }
    };

    return sendActionRequest;
  },
};
