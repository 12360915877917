import { BaseFilterModel } from 'src/models/base-filter.model';
import { GenericAlarmSettings } from 'src/modules/monitoring/templates/alarm-settings/@types';
import { OnOffOption, SearchFilterState } from 'src/types/filter-types';

import { CollarAlarmSettingsFilterType, CollarSettingsTriggerRule } from '../@types';

export class CollarAlarmSettingsFilterModel extends BaseFilterModel<
  GenericAlarmSettings<CollarSettingsTriggerRule>,
  CollarAlarmSettingsFilterType
> {
  private static instance: CollarAlarmSettingsFilterModel;

  private constructor() {
    super();
  }

  static getInstance(): CollarAlarmSettingsFilterModel {
    if (this.instance == null) {
      this.instance = new CollarAlarmSettingsFilterModel();
    }
    return this.instance;
  }

  static ALARM_SETTINGS_FILTERS: CollarAlarmSettingsFilterType[] = Object.values(CollarAlarmSettingsFilterType);

  /**
   * Alarm settings filter that have search options such as status, alarms, and farm.
   */
  static SEARCH_ALARM_SETTINGS_FILTERS: CollarAlarmSettingsFilterType[] = [
    CollarAlarmSettingsFilterType.ALARM_TYPE,
    CollarAlarmSettingsFilterType.ACTION_TYPE,
    CollarAlarmSettingsFilterType.FILTER_TYPE,
    CollarAlarmSettingsFilterType.ON_FARM_STATUS,
    CollarAlarmSettingsFilterType.FARM,
    CollarAlarmSettingsFilterType.FIRMWARE,
  ];

  /**
   * Alarm settings filter that have on/off options such as provisioned to farm and tagged on cattle.
   */
  static ON_OFF_ALARM_SETTINGS_FILTERS: CollarAlarmSettingsFilterType[] = [
    CollarAlarmSettingsFilterType.TAGGED_ON_CATTLE,
    CollarAlarmSettingsFilterType.PROVISIONED_TO_FARM,
    CollarAlarmSettingsFilterType.PROVISIONED_TO_LIVE_FARM,
  ];

  private SEARCH_FILTER_FUNCTIONS_BY_ALARM_SETTINGS_FILTER_TYPE: Record<
    string,
    (alarmSettings: GenericAlarmSettings<CollarSettingsTriggerRule>, filterState: SearchFilterState) => boolean
  > = {
    [CollarAlarmSettingsFilterType.ON_FARM_STATUS]: (alarmSettings, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === alarmSettings.triggerRule?.onFarmStatus),
    [CollarAlarmSettingsFilterType.ALARM_TYPE]: (alarmSettings, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === alarmSettings.alarmType),
    [CollarAlarmSettingsFilterType.FARM]: (alarmSettings, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === alarmSettings.triggerRule?.farmId),
    [CollarAlarmSettingsFilterType.ACTION_TYPE]: (alarmSettings, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === alarmSettings.actionType),
    [CollarAlarmSettingsFilterType.FILTER_TYPE]: (alarmSettings, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === alarmSettings.filterType),
    [CollarAlarmSettingsFilterType.FIRMWARE]: (alarmSettings, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === alarmSettings.triggerRule?.firmwareVersion),
  };

  // eslint-disable-next-line arrow-body-style
  private createOnOffFilterFunction = (
    extractValue: (triggerRule: CollarSettingsTriggerRule) => boolean | undefined,
  ) => {
    return (alarmSettings: GenericAlarmSettings<CollarSettingsTriggerRule>, onOffOption: OnOffOption) => {
      if (alarmSettings.triggerRule != null) {
        if (onOffOption === OnOffOption.ON) return extractValue(alarmSettings.triggerRule) === true;
        if (onOffOption === OnOffOption.OFF) return extractValue(alarmSettings.triggerRule) === false;
        // If ALL, filter the alarm settings with the specific criteria being existed
        if (onOffOption === OnOffOption.ALL) return extractValue(alarmSettings.triggerRule) != null;
      }

      return false;
    };
  };

  private ON_OFF_FILTER_FUNCTIONS_BY_ALARM_SETTINGS_FILTER_TYPE: Record<
    string,
    (alarmSettings: GenericAlarmSettings<CollarSettingsTriggerRule>, onOffOption: OnOffOption) => boolean
  > = {
    [CollarAlarmSettingsFilterType.TAGGED_ON_CATTLE]: this.createOnOffFilterFunction(
      ({ taggedOnCattle }) => taggedOnCattle,
    ),
    [CollarAlarmSettingsFilterType.PROVISIONED_TO_FARM]: this.createOnOffFilterFunction(
      ({ provisionedToFarm }) => provisionedToFarm,
    ),
    [CollarAlarmSettingsFilterType.PROVISIONED_TO_LIVE_FARM]: this.createOnOffFilterFunction(
      ({ provisionedToLiveFarm }) => provisionedToLiveFarm,
    ),
  };

  getSearchFilterFunction(filterType: CollarAlarmSettingsFilterType) {
    return this.SEARCH_FILTER_FUNCTIONS_BY_ALARM_SETTINGS_FILTER_TYPE[filterType];
  }

  getOnOffFilterFunction(filterType: CollarAlarmSettingsFilterType) {
    return this.ON_OFF_FILTER_FUNCTIONS_BY_ALARM_SETTINGS_FILTER_TYPE[filterType];
  }

  getDateRangeFilterFunction() {
    return null;
  }
}
