import {
  DeviceBoxRecordApi,
  DeviceOffFarmApi,
  DeviceOnFarmApi,
  FleetTrackingApi,
  NonSerialProductInventoryApi,
} from '@halter-corp/fleet-service-client';

import client from '../client';

const FleetApi = {
  deviceOnFarmApi: client.resolveOpenApi(DeviceOnFarmApi),
  deviceOffFarmApi: client.resolveOpenApi(DeviceOffFarmApi),
  deviceBoxRecordApi: client.resolveOpenApi(DeviceBoxRecordApi),
  fleetTrackingApi: client.resolveOpenApi(FleetTrackingApi),
  nonSerialProductInventoryApi: client.resolveOpenApi(NonSerialProductInventoryApi),
};

export default FleetApi;
