import { MdBattery50, MdError, MdMyLocation, MdOutlineNetworkCell } from 'react-icons/md';

import { AlarmScope } from 'src/modules/monitoring/@types';

import { CollarGrafanaPanelEnum, getGrafanaPanelId } from '../../../utils/grafana-dashboards.util';

export const QUICK_DEBUG_PANELS = {
  LOCATION: {
    icon: MdMyLocation,
    dashboardIds: [
      getGrafanaPanelId(CollarGrafanaPanelEnum.ODBA, AlarmScope.COLLAR),
      getGrafanaPanelId(CollarGrafanaPanelEnum.LORA_LOCATION, AlarmScope.COLLAR),
    ],
    label: 'Location',
  },
  LORA: {
    icon: MdOutlineNetworkCell,
    dashboardIds: [
      getGrafanaPanelId(CollarGrafanaPanelEnum.LORA_RSSI, AlarmScope.COLLAR),
      getGrafanaPanelId(CollarGrafanaPanelEnum.LORA_CHECKINS, AlarmScope.COLLAR),
    ],
    label: 'LoRa',
  },
  BATTERY: {
    icon: MdBattery50,
    dashboardIds: [
      getGrafanaPanelId(CollarGrafanaPanelEnum.BATTERY_SOC, AlarmScope.COLLAR),
      getGrafanaPanelId(CollarGrafanaPanelEnum.BATTERY_VOLTAGE, AlarmScope.COLLAR),
    ],
    label: 'Battery',
  },
  ERROR: {
    icon: MdError,
    dashboardIds: [
      getGrafanaPanelId(CollarGrafanaPanelEnum.UP_TIME, AlarmScope.COLLAR),
      getGrafanaPanelId(CollarGrafanaPanelEnum.BIT_ERROR, AlarmScope.COLLAR),
    ],
    label: 'Errors',
  },
};
