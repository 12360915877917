import { Flex, FlexProps, Text, Tooltip } from '@chakra-ui/react';
import React, { FC } from 'react';
import { FaBatteryEmpty, FaBatteryFull, FaTriangleExclamation } from 'react-icons/fa6';
import { PiCellSignalFullBold, PiCellSignalSlashBold } from 'react-icons/pi';

import GrafanaPanelPopover from 'src/components/grafana-panel.popover';
import { TowerGrafanaPanelEnum, getTowerGrafanaPanelId } from 'src/modules/monitoring/utils/grafana-dashboards.util';
import { Colors } from 'src/styles';
import { GrafanaPeriod } from 'src/types';

const OfflineStatus: FC<
  {
    offline?: boolean;
  } & StatusWithGrafanaPanelProps &
    FlexProps
> = ({ offline, text, textTooltip, grafanaVariables, ...props }) => {
  if (offline == null) return null;

  const icon = offline ? (
    <PiCellSignalSlashBold
      color={Colors.red500}
      fontSize="1.2em"
    />
  ) : (
    <PiCellSignalFullBold
      color={Colors.green500}
      fontSize="1.2em"
    />
  );

  return (
    <StatusWithGrafanaPanel
      icon={icon}
      text={text}
      textTooltip={textTooltip}
      grafanaPanel={TowerGrafanaPanelEnum.TOWER_METRICS}
      grafanaVariables={grafanaVariables}
      {...props}
    />
  );
};

const BatteryStatus: FC<{ outOfBattery?: boolean } & StatusWithGrafanaPanelProps & FlexProps> = ({
  outOfBattery,
  text,
  textTooltip,
  grafanaVariables,
  ...props
}) => {
  if (outOfBattery == null) return null;

  const icon = outOfBattery ? (
    <FaBatteryEmpty
      color={Colors.red500}
      fontSize="1.2em"
    />
  ) : (
    <FaBatteryFull
      color={Colors.green500}
      fontSize="1.2em"
    />
  );

  return (
    <StatusWithGrafanaPanel
      icon={icon}
      text={text}
      textTooltip={textTooltip}
      grafanaPanel={TowerGrafanaPanelEnum.BATTERY_VOLTAGE}
      grafanaVariables={grafanaVariables}
      {...props}
    />
  );
};

const FaultyStatus: FC<{ faulty?: boolean; showText?: boolean } & FlexProps> = ({
  faulty,
  showText = false,
  ...props
}) => {
  if (faulty == null || faulty === false) return null;

  return (
    <Flex
      columnGap={2}
      alignItems="center"
      {...props}
    >
      <FaTriangleExclamation color={Colors.red500} />
      {showText === true && <Text fontSize="sm">Faulty</Text>}
    </Flex>
  );
};

type StatusWithGrafanaPanelProps = {
  text?: string;
  textTooltip?: string;
  grafanaPanel?: TowerGrafanaPanelEnum;
  grafanaStartTime?: Date;
  grafanaEndTime?: Date;
  grafanaPeriod?: GrafanaPeriod;
  grafanaVariables?: Record<string, string>;
};

const StatusWithGrafanaPanel: FC<
  {
    icon: React.ReactNode;
    grafanaPanel?: TowerGrafanaPanelEnum;
  } & StatusWithGrafanaPanelProps &
    FlexProps
> = ({
  icon,
  text,
  textTooltip,
  grafanaPanel,
  grafanaVariables,
  grafanaPeriod,
  grafanaStartTime,
  grafanaEndTime,
  ...props
}) => {
  return (
    <Flex
      gap={2}
      alignItems="center"
      {...props}
    >
      {grafanaPanel != null ? (
        <GrafanaPanelPopover
          dashboardIds={[getTowerGrafanaPanelId(grafanaPanel)]}
          grafanaVariables={grafanaVariables}
          grafanaTimestampFrom={grafanaStartTime}
          grafanaTimestampTo={grafanaEndTime}
          grafanaPeriod={grafanaPeriod}
        >
          <div>
            <Flex
              columnGap={2}
              alignItems="center"
              cursor="pointer"
              {...props}
            >
              {icon}
            </Flex>
          </div>
        </GrafanaPanelPopover>
      ) : (
        <Flex
          columnGap={2}
          alignItems="center"
          {...props}
        >
          {icon}
        </Flex>
      )}
      {text !== null &&
        (textTooltip != null ? (
          <Tooltip label={textTooltip}>
            <Text cursor="pointer">{text}</Text>
          </Tooltip>
        ) : (
          <Text>{text}</Text>
        ))}
    </Flex>
  );
};

export const TowerStatus = {
  OfflineStatus,
  BatteryStatus,
  FaultyStatus,
};
