import { createContext } from 'react';

import { useOnOffFiltersOperations, useSearchFiltersOperations } from 'src/hooks/filter-hooks';
import useDateRangeFilterOperations from 'src/hooks/filter-hooks/use-date-range-filter-operations';

export type IEntityFilterContext<T> = {
  filteredItems: T[];
  useSearchFilters: () => ReturnType<typeof useSearchFiltersOperations<string>>;
  useOnOffFilters: () => ReturnType<typeof useOnOffFiltersOperations<string>>;
  useDateRangeFilters: () => ReturnType<typeof useDateRangeFilterOperations<string>>;
};

export const EntityFilterContext = createContext<IEntityFilterContext<unknown>>(null!);
