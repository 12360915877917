import { pickBy } from 'lodash';
import { useContext, useMemo } from 'react';

import { useOnOffFiltersOperations, useSearchFiltersOperations } from 'src/hooks/filter-hooks';
import useDateRangeFilterOperations from 'src/hooks/filter-hooks/use-date-range-filter-operations';
import useDateRangeFiltersSearchParams from 'src/hooks/filter-hooks/use-date-range-filters-search-params';
import useOnOffFiltersSearchParams from 'src/hooks/filter-hooks/use-on-off-filters-search-params';
import useSearchFilterSearchParams from 'src/hooks/filter-hooks/use-search-filters-params';
import { OnOffOption } from 'src/types/filter-types';
import { FilterStateValidator } from 'src/util/filter-state-validator';

import { EntityFilterContext, IEntityFilterContext } from './context';
import { createEntityFilterStateStore } from './create-entity-filter-state-store';

/**
 * @summary This hook is used to get the entity filter context.
 */
export const useEntityFilterContext = <T>() => {
  const context = useContext(EntityFilterContext) as IEntityFilterContext<T>;
  if (context == null) throw new Error('EntityFilterContext has not been provided');
  return context;
};

/**
 * @summary This hook is used to generate the filter state hooks for a given entity key.
 * Should be used only within entity filter provider to generate filter state hooks.
 * Not for general use outside the entity filter provider.
 */
export const useGenerateFilterStateHooks = ({ entityKey }: { entityKey: string }) => {
  const searchFilterQueryKey = `${entityKey}-search-filters`;
  const onOffFilterQueryKey = `${entityKey}-on-off-filters`;
  const dateRangeFilterQueryKey = `${entityKey}-date-range-filters`;

  const useFilterStore = useMemo(() => createEntityFilterStateStore(entityKey), [entityKey]);
  const { searchFilters, onOffFilters, dateRangeFilters, setSearchFilters, setOnOffFilters, setDateRangeFilters } =
    useFilterStore();

  const useSearchFilters = () => {
    const validSearchFilters = pickBy(searchFilters, FilterStateValidator.isSearchFilterState);

    const { setSearchFilters: setSearchFiltersWithQueryParams } = useSearchFilterSearchParams({
      queryParamKey: searchFilterQueryKey,
      searchFiltersByType: validSearchFilters,
      setSearchFiltersByType: setSearchFilters,
    });

    return useSearchFiltersOperations({
      searchFiltersByType: validSearchFilters,
      setSearchFiltersByType: setSearchFiltersWithQueryParams,
    });
  };

  const useOnOffFilters = () => {
    const validOnOffFilters = pickBy(onOffFilters, FilterStateValidator.isOnOffFilterState);

    const { setOnOffFilters: setOnOffFiltersWithQueryParams } = useOnOffFiltersSearchParams({
      queryParamKey: onOffFilterQueryKey,
      setOnOffFiltersByType: setOnOffFilters,
    });

    return useOnOffFiltersOperations({
      onOffFiltersByType: validOnOffFilters,
      setOnOffFiltersByType: setOnOffFiltersWithQueryParams,
      defaultValue: OnOffOption.ALL,
    });
  };

  const useDateRangeFilters = () => {
    const validDateRangeFilters = pickBy(dateRangeFilters, FilterStateValidator.isDateRangeFilterState);

    const { setDateRangeFilters: setDateRangeFiltersWithParams } = useDateRangeFiltersSearchParams({
      queryParamKey: dateRangeFilterQueryKey,
      setDateRangeFiltersByType: setDateRangeFilters,
    });

    return useDateRangeFilterOperations({
      dateRangeFiltersByType: validDateRangeFilters,
      setDateRangeFiltersByType: setDateRangeFiltersWithParams,
    });
  };

  return {
    useSearchFilters,
    useOnOffFilters,
    useDateRangeFilters,
  };
};
