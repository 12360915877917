import { Box, Flex, Grid } from '@chakra-ui/react';
import { IAlarmDefinitionDTO } from '@halter-corp/alarm-registry-service-client';
import { chain, sortBy } from 'lodash';
import moment from 'moment';
import { FC, useState } from 'react';
import { FaChartLine } from 'react-icons/fa';
import { MdBlurOn, MdBugReport, MdMyLocation } from 'react-icons/md';

import { Alarm, AlarmScope } from 'src/modules/monitoring/@types';
import AlarmBadge from 'src/modules/monitoring/components/badges/alarm.badge';
import QuickDebugBadge from 'src/modules/monitoring/components/badges/quick-debug-badge';
import { MonitoringContexts } from 'src/modules/monitoring/contexts';
import { GrafanaPanelEnum, getGrafanaPanelId } from 'src/modules/monitoring/utils/grafana-dashboards.util';
import { AlarmRegistryQueries } from 'src/queries';
import { GrafanaPeriod } from 'src/types/grafana-types';
import { formatStringAsLabel } from 'src/util/string-format.util';

import { WelfareAlarmEntity } from '../../../@types';
import { WelfareEntityUtils } from '../../../utils/welfare-entity.util';

type WelfareAlarmsCellProps = {
  welfareAlarmEntity: WelfareAlarmEntity;
};

const WelfareAlarmsCell: FC<WelfareAlarmsCellProps> = ({ welfareAlarmEntity }) => {
  const [quickDebugVisible, setQuickDebugVisible] = useState(false);
  const { data: alarmDefinitionByType } = AlarmRegistryQueries.useAlarmDefinitions();
  const [grafanaPeriod] = MonitoringContexts.useGrafanaPeriod();

  const farmId = welfareAlarmEntity.farm?.farmId;
  const mobId = WelfareEntityUtils.getMobId(welfareAlarmEntity);
  const cattleId = WelfareEntityUtils.getCattleContext(welfareAlarmEntity)?.cattleId;
  const toDate = new Date(welfareAlarmEntity.alarms[0].eventDateTime);

  const fromDate = inferFromDateForMapLink(welfareAlarmEntity.alarms);

  const commonSearchParams = {
    farmId: farmId ?? '',
    mobId: mobId ?? '',
    cattleId: cattleId ?? '',
    minDate: fromDate?.toISOString() ?? '',
    maxDate: toDate?.toISOString() ?? '',
  };

  const debugSearchParams = new URLSearchParams({
    ...commonSearchParams,
    mapMode: 'debug',
  });

  const heatmapSearchParams = new URLSearchParams({
    ...commonSearchParams,
    mapMode: 'heatmap',
  });

  const baseUrl = window.location.origin;
  const debugUrl = `${baseUrl}/map/debug?${debugSearchParams.toString()}`;
  const heatmapUrl = `${baseUrl}/map/debug?${heatmapSearchParams.toString()}`;

  const farmVar = farmId != null ? `farmId=${farmId}` : '';
  const mobVar = mobId != null ? `mobId=${mobId}` : '';
  const cattleVar = cattleId != null ? `cattleId=${cattleId}` : '';
  const liveViewUrl = `${baseUrl}/map/live-view?${[farmVar, mobVar, cattleVar].join('&')}`;

  const cattleNameVar =
    welfareAlarmEntity.context.cattleContext?.cattleName != null
      ? `cattleName=${welfareAlarmEntity.context.cattleContext?.cattleName}`
      : '';
  const fromDateVar = `fromDate=${fromDate?.toISOString()}`;
  const toDateVar = `toDate=${toDate.toISOString()}`;
  const shockCurveUrl = `${baseUrl}/shock-curves?${[farmVar, cattleNameVar, fromDateVar, toDateVar].join('&')}`;

  const grafanaVariables = {
    ...(welfareAlarmEntity.scope === AlarmScope.COLLAR && { serialNumber: welfareAlarmEntity.entityId }),
    ...(welfareAlarmEntity.scope === AlarmScope.COW && { cattleId: welfareAlarmEntity.entityId }),
    ...(welfareAlarmEntity.scope === AlarmScope.MOB && { mobId: welfareAlarmEntity.entityId }),
    ...(welfareAlarmEntity.scope === AlarmScope.FARM && { farmId: welfareAlarmEntity.entityId }),
    ...(welfareAlarmEntity.scope === AlarmScope.COMMAND && {
      commandId: welfareAlarmEntity.entityId,
      collarCommandId: welfareAlarmEntity.context.commandContext?.collarCommandId?.toString(),
    }),
  };

  return (
    <Flex
      gap="1rem"
      onMouseEnter={() => setQuickDebugVisible(true)}
      onMouseLeave={() => setQuickDebugVisible(false)}
    >
      <Flex
        rowGap={1}
        width="min-content"
        flexWrap="wrap"
        alignItems="center"
      >
        {welfareAlarmEntity.alarmBehaviour === 'active' && (
          <ActiveAlarmBadgeList
            welfareAlarmEntity={welfareAlarmEntity}
            alarmDefinitionByType={alarmDefinitionByType}
            grafanaPeriod={grafanaPeriod}
            grafanaVariables={grafanaVariables}
          />
        )}
        {welfareAlarmEntity.alarmBehaviour === 'event' && (
          <EventAlarmBadgeList
            welfareAlarmEntity={welfareAlarmEntity}
            alarmDefinitionByType={alarmDefinitionByType}
            grafanaPeriod={grafanaPeriod}
            grafanaVariables={grafanaVariables}
          />
        )}
      </Flex>
      <Box opacity={quickDebugVisible ? 1 : 0}>
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={1}
        >
          <QuickDebugBadge
            icon={MdMyLocation}
            link={liveViewUrl}
            tooltipLabel="Live View"
          />
          <QuickDebugBadge
            icon={FaChartLine}
            link={shockCurveUrl}
            tooltipLabel="Live View"
          />
          <QuickDebugBadge
            icon={MdBugReport}
            link={debugUrl}
            tooltipLabel="Debug"
          />
          <QuickDebugBadge
            icon={MdBlurOn}
            link={heatmapUrl}
            tooltipLabel="Heat Map"
          />
        </Grid>
      </Box>
    </Flex>
  );
};

const inferFromDateForMapLink = (alarms: Alarm[]) => {
  if (alarms.length === 0) return null;

  for (const alarm of alarms) {
    const alarmTypeLower = alarm.type.toLowerCase();
    // Alarms of minute/hour/daily window are event (history) alarms so there will be only one item in the alarms array
    if (alarmTypeLower.includes('minute')) {
      return moment(alarm.eventDateTime).subtract(30, 'minutes').toDate();
    }
    if (alarmTypeLower.includes('hourly')) {
      return moment(alarm.eventDateTime).subtract(1, 'hour').toDate();
    }
    if (alarmTypeLower.includes('daily')) {
      return moment(alarm.eventDateTime).subtract(1, 'day').toDate();
    }
  }

  return moment(alarms[0].eventDateTime).subtract(1, 'day').toDate();
};

type AlarmBadgeListProps = {
  welfareAlarmEntity: WelfareAlarmEntity;
  alarmDefinitionByType: Record<string, IAlarmDefinitionDTO> | undefined;
  grafanaVariables: Record<string, string>;
  grafanaPeriod: GrafanaPeriod;
};

const ActiveAlarmBadgeList: FC<AlarmBadgeListProps> = ({
  welfareAlarmEntity,
  alarmDefinitionByType,
  grafanaPeriod,
  grafanaVariables,
}) => {
  return (
    <>
      {welfareAlarmEntity.alarms.map((alarm) => {
        const definition = alarmDefinitionByType?.[alarm.type];
        const grafanaPanelIds = definition?.relevantMetricNames?.flatMap(
          (metricName) => getGrafanaPanelId(metricName as GrafanaPanelEnum, welfareAlarmEntity.scope) ?? [],
        );

        return (
          <AlarmBadge
            key={alarm.type}
            size="sm"
            alarm={alarm}
            formatAlarm={({ type }) => formatStringAsLabel(type)}
            bgColor={definition?.colour}
            dashboardIds={grafanaPanelIds}
            grafanaVariables={grafanaVariables}
            grafanaPeriod={grafanaPeriod}
          />
        );
      })}
    </>
  );
};

const EventAlarmBadgeList: FC<AlarmBadgeListProps> = ({
  welfareAlarmEntity,
  alarmDefinitionByType,
  grafanaPeriod,
  grafanaVariables,
}) => {
  const firstOccurredAlarmOfEachType = chain(welfareAlarmEntity.alarms)
    .groupBy((a) => a.type)
    .mapValues((alarms) => sortBy(alarms, (a) => new Date(a.eventDateTime).getTime()))
    .map((alarms) => alarms[0])
    .value();

  return (
    <>
      {firstOccurredAlarmOfEachType.map((alarm) => {
        const definition = alarmDefinitionByType?.[alarm.type];
        const grafanaPanelIds = definition?.relevantMetricNames?.flatMap(
          (metricName) => getGrafanaPanelId(metricName as GrafanaPanelEnum, welfareAlarmEntity.scope) ?? [],
        );

        return (
          <AlarmBadge
            size="sm"
            key={alarm.type}
            alarm={alarm}
            formatAlarm={({ type }) => formatStringAsLabel(type)}
            bgColor={definition?.colour}
            dashboardIds={grafanaPanelIds}
            grafanaVariables={grafanaVariables}
            grafanaPeriod={grafanaPeriod}
            grafanaTimestampFrom={inferFromDateForGrafanaPanel(alarm)} // This should only be set for event alarms
            grafanaTimestampTo={new Date(alarm.eventDateTime)} // This should only be set for event alarms
          />
        );
      })}
    </>
  );
};

const inferFromDateForGrafanaPanel = (alarm: Alarm) => {
  const alarmTypeLower = alarm.type.toLowerCase();

  if (alarmTypeLower.includes('daily')) {
    return moment(alarm.eventDateTime).subtract(1, 'day').toDate();
  }
  if (alarmTypeLower.includes('weekly')) {
    return moment(alarm.eventDateTime).subtract(7, 'days').toDate();
  }
  if (alarmTypeLower.includes('monthly')) {
    return moment(alarm.eventDateTime).subtract(30, 'days').toDate();
  }

  return moment(alarm.eventDateTime).subtract(1, 'day').toDate();
};

export default WelfareAlarmsCell;
