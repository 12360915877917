import { Button, Flex, FlexProps, Link, SimpleGrid, Text } from '@chakra-ui/react';
import { HTMLMotionProps } from 'framer-motion';
import { FunctionComponent } from 'react';

import { ICONS } from 'src/assets';
import { Colors } from 'src/styles';

import { AlarmedCollar } from '../../@types';
import DeviceInfoDetail from './collar-info/collar-info-detail';
import CollarStatusDetail from './collar-info/collar-status-detail';
import DebugPanelsSection from './debug-panels/debug-panels-section';

type DevicePropType = {
  collar: AlarmedCollar | undefined;
};

type DeviceDetailsPanelProps = DevicePropType & {
  onTriggerAction: () => void;
} & FlexProps &
  HTMLMotionProps<'div'>;

const DeviceDetailsPanel: FunctionComponent<DeviceDetailsPanelProps> = ({ collar, onTriggerAction, ...rest }) => {
  if (collar == null) return null;

  return (
    <Flex
      flexDir="column"
      width="100%"
      pt={1}
      pb={10}
      px={{ base: '7%', lg: '3%' }}
      gap={2}
      color={Colors.gray700}
      scrollBehavior="smooth"
      fontSize="sm"
      {...rest}
    >
      <Flex mb={1}>
        <Button
          ml="-10px"
          mr={3}
          size="sm"
          p={1}
          as={Link}
          color={Colors.gray600}
          href={collar.debugLink}
          variant="ghost"
          _hover={{ color: Colors.blue600, textDecoration: 'underline' }}
          target="_blank"
        >
          <ICONS.GrafanaLogo
            height="16px"
            width="16px"
          />
          <Text ml={1}>Grafana</Text>
        </Button>

        <Button
          ml="auto"
          size="sm"
          variant="light"
          onClick={onTriggerAction}
          boxShadow="xs"
        >
          Action
        </Button>
      </Flex>

      {/* Device overview section */}
      <SimpleGrid
        gridTemplateColumns={{ base: '1fr', md: 'repeat(auto-fit, minmax(250px, 1fr))' }}
        rowGap={8}
        columnGap={{ base: 4, md: 8 }}
      >
        <DeviceInfoDetail collar={collar} />
        <CollarStatusDetail collar={collar} />
      </SimpleGrid>

      {/* Device debug panel section */}
      <DebugPanelsSection
        mt={4}
        collar={collar}
      />
    </Flex>
  );
};

export default DeviceDetailsPanel;
