import { AlarmActionTypeEnum, ICreateWelfareAlarmActionRequestDTO } from '@halter-corp/welfare-alarm-service-client';

import useCommonToast from 'src/hooks/use-common-toast';
import { WelfareAlarmQueries } from 'src/queries';
import { AuthService } from 'src/services';
import { formatStringAsLabel } from 'src/util/string-format.util';

type UseWelfareAlarmActionProps = {
  actionType: AlarmActionTypeEnum;
  onSuccess?: () => void;
};

export type AlarmActionRequestPayload<T> = {
  entityId: string;
  actionContext: T;
};

function useWelfareAlarmActions<T>({ actionType, onSuccess }: UseWelfareAlarmActionProps) {
  const createWelfareAlarmActions = WelfareAlarmQueries.useCreateWelfareAlarmActionMutations();
  const authUser = AuthService.useAuth();
  const { displayToast } = useCommonToast();

  const sendBatchActionRequests = async (
    payloads: AlarmActionRequestPayload<T>[],
  ): Promise<{ status: 'success' | 'error'; message?: string }> => {
    const userName = `${authUser.firstName} ${authUser.lastName}`;

    const welfareAlarmActionRequests: ICreateWelfareAlarmActionRequestDTO[] = payloads.map(
      ({ entityId, actionContext }) => ({
        referenceId: entityId,
        actionType,
        createdBy: userName,
        actionContext: JSON.stringify(actionContext),
      }),
    );

    try {
      await createWelfareAlarmActions.mutateAsync(welfareAlarmActionRequests);
      onSuccess?.();
      return {
        status: 'success',
      };
    } catch (err: any) {
      const message = err.response?.data?.message ?? err.message;
      displayToast('error', `Failed to send "${formatStringAsLabel(actionType)}" request`, message as string);
      return {
        status: 'error',
        message,
      };
    }
  };

  return { sendBatchActionRequests };
}

export default useWelfareAlarmActions;
