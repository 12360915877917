import { Button, Flex, Heading, Stack } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { AiTwotoneHome } from 'react-icons/ai';
import { FiRefreshCcw } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { ICONS } from 'src/assets';
import { ROUTES } from 'src/constants';
import { Colors } from 'src/styles';

const ErrorPanel: FunctionComponent<FallbackProps> = ({ resetErrorBoundary, error }) => {
  const navigate = useNavigate();
  const errorMessage = error instanceof Error ? error.message : 'Oops, something went wrong...';

  return (
    <Stack
      px={10}
      py={10}
      flex={1}
      alignItems="center"
      justifyContent="center"
      rowGap="2rem"
    >
      {/* Error Icon */}
      <ICONS.SweatyCow
        width="200px"
        height="200px"
      />
      <Heading
        textAlign="center"
        maxWidth="20ch"
        textTransform="capitalize"
        fontSize="3xl"
        color={Colors.gray700}
      >
        {errorMessage}
      </Heading>

      {/* Error actions */}
      <Flex
        gap="1rem"
        flexDir={{ base: 'column', md: 'row' }}
        justifyContent="center"
      >
        <Button
          width={{ base: '80vw', md: 'max(10rem,8vw)' }}
          size="lg"
          onClick={() => {
            resetErrorBoundary();
            navigate(ROUTES.Home);
          }}
        >
          <AiTwotoneHome fontSize="1.2em" /> &nbsp; Home
        </Button>
        <Button
          width={{ base: '80vw', md: 'max(10rem,8vw)' }}
          size="lg"
          onClick={resetErrorBoundary}
          variant="dark"
        >
          <FiRefreshCcw /> &nbsp; Refresh
        </Button>
      </Flex>
    </Stack>
  );
};

export default ErrorPanel;
