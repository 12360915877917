import { Box, Flex } from '@chakra-ui/react';
import { FC, useState } from 'react';
import {MdBattery50, MdMyLocation, MdOutlineHistoryEdu} from 'react-icons/md';

import { AlarmScope } from 'src/modules/monitoring/@types';
import QuickDebugBadge from 'src/modules/monitoring/components/badges/quick-debug-badge';
import { MonitoringContexts } from 'src/modules/monitoring/contexts';
import { CollarGroupGrafanaPanelEnum, getGrafanaPanelId } from 'src/modules/monitoring/utils/grafana-dashboards.util';

import { PowerAlarmEntity } from '../@types';
import PowerAlarmBadgeList from './power-alarm-badge.list';
import moment from "moment";
import {pickBy} from "lodash";

type PowerAlarmsCellProps = {
  powerAlarmEntity: PowerAlarmEntity;
};

const PowerAlarmsCell: FC<PowerAlarmsCellProps> = ({ powerAlarmEntity }) => {
  const [quickDebugVisible, setQuickDebugVisible] = useState(false);
  const [grafanaPeriod] = MonitoringContexts.useGrafanaPeriod();

  const grafanaVariables = {
    ...(powerAlarmEntity.scope === AlarmScope.COLLAR && { serialNumber: powerAlarmEntity.entityId }),
    ...(powerAlarmEntity.scope === AlarmScope.MOB && { mobId: powerAlarmEntity.entityId }),
    ...(powerAlarmEntity.scope === AlarmScope.FARM && { farmId: powerAlarmEntity.entityId }),
  };

  // TODO: Make this work with scopes other than mob
  const farmId = powerAlarmEntity.scope === AlarmScope.MOB ? powerAlarmEntity.context?.mobContext?.farmId : undefined;
  const mobId = powerAlarmEntity.scope === AlarmScope.MOB ? powerAlarmEntity.entityId : undefined;
  const farmVar = farmId != null ? `farmId=${farmId}` : '';
  const mobVar = mobId != null ? `mobId=${mobId}` : '';
  const liveViewUrl = `/map/live-view?${[farmVar, mobVar].join('&')}`;

  const tNow = moment();

  const searchParamsContent = pickBy(
      {
        timeMode: 'history',
        endDate: tNow.toISOString(),
        currentTime: tNow.toISOString(),
        startDate: tNow.subtract(24, 'hours').toISOString(),
        farmId,
        mobId,
      },
      (value) => value != null,
  ) as Record<string,string>;

  const searchParams = new URLSearchParams(searchParamsContent);

  const lastDayViewUrl = `/map/live-view?${searchParams.toString()}`;


  return (
    <Flex
      rowGap={1}
      maxWidth="200px"
      width="min-content"
      flexWrap="wrap"
      alignItems="center"
    >
      <Flex
        gap="1rem"
        onMouseEnter={() => setQuickDebugVisible(true)}
        onMouseLeave={() => setQuickDebugVisible(false)}
      >
        <PowerAlarmBadgeList
          grafanaVariables={grafanaVariables}
          grafanaPeriod={grafanaPeriod}
          alarms={powerAlarmEntity.alarms}
          alarmScope={powerAlarmEntity.scope}
        />
        <Box opacity={quickDebugVisible ? 1 : 0}>
          <Flex gap={1}>
            <QuickDebugBadge
              icon={MdMyLocation}
              link={liveViewUrl}
              tooltipLabel="Live View"
            />
            <QuickDebugBadge
                icon={MdOutlineHistoryEdu}
                link={lastDayViewUrl}
                tooltipLabel="Last 24 Hours"
            />
            <QuickDebugBadge
              icon={MdBattery50}
              dashboardIds={[
                getGrafanaPanelId(CollarGroupGrafanaPanelEnum.BATTERY_PERCENTILES, powerAlarmEntity.scope),
              ]}
              dashboardVariables={grafanaVariables}
              tooltipLabel="Batteries"
            />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default PowerAlarmsCell;
