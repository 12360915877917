import { BaseFilterModel } from 'src/models/base-filter.model';
import { OnOffOption, SearchFilterState } from 'src/types/filter-types';
import { CollarUtils } from 'src/util/collar.util';
import { SearchUtils } from 'src/util/search.util';
import { createSingleton } from 'src/util/singleton.util';

import { AlarmedCollar, AlarmedCollarFilterType } from '../@types';

export class AlarmedCollarFilterModel extends BaseFilterModel<AlarmedCollar, AlarmedCollarFilterType> {
  private constructor() {
    super();
  }

  static getInstance = createSingleton(() => new AlarmedCollarFilterModel());

  /**
   * Device filters that have multi-choice options such as status, alarms, and farm.
   * Devices (collars) are not applied for this as there are too many of them.
   */
  static SEARCH_FILTERS: AlarmedCollarFilterType[] = [
    AlarmedCollarFilterType.STATUS,
    AlarmedCollarFilterType.ALARMS,
    AlarmedCollarFilterType.ALARM_BEHAVIOUR,
    AlarmedCollarFilterType.FARM,
    AlarmedCollarFilterType.BATCH,
    AlarmedCollarFilterType.FIRMWARE,
    AlarmedCollarFilterType.RELEASE_CHANNEL,
  ];

  /**
   * Device filters that have on/off options such as provisioned to farm and tagged on cattle.
   */
  static ON_OFF_FILTERS: AlarmedCollarFilterType[] = [
    AlarmedCollarFilterType.PROVISIONED_TO_FARM,
    AlarmedCollarFilterType.TAGGED_ON_CATTLE,
    AlarmedCollarFilterType.BATCH_84_PLUS_COLLARS,
  ];

  /**
   * Device filters that are time range filters such as first logged date.
   */
  static DATE_RANGE_FILTERS: AlarmedCollarFilterType[] = [
    AlarmedCollarFilterType.FIRST_LOGGED_DATE,
    AlarmedCollarFilterType.LAST_LOGGED_DATE,
  ];

  private SEARCH_FILTER_FUNCTIONS_BY_FILTER_TYPE: Record<
    string,
    (collar: AlarmedCollar, filterState: SearchFilterState) => boolean
  > = {
    [AlarmedCollarFilterType.STATUS]: (collar, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === collar.context.onFarmStatus),
    [AlarmedCollarFilterType.ALARMS]: (collar, filterState) =>
      this.filterBySearchFilterState(filterState, (option) =>
        collar.alarms.map((alarm) => alarm.type).includes(option),
      ),
    [AlarmedCollarFilterType.ALARM_BEHAVIOUR]: (collar, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => collar.alarmBehaviour === option),
    [AlarmedCollarFilterType.FARM]: (collar, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === collar.farm?.farmName),
    [AlarmedCollarFilterType.CATTLE]: (collar, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === collar.context.cattle?.cattleName),
    [AlarmedCollarFilterType.BATCH]: (collar, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === collar.context.batch),
    [AlarmedCollarFilterType.FIRMWARE]: (collar, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === collar.context.firmwareVersion),
    [AlarmedCollarFilterType.RELEASE_CHANNEL]: (collar, filterState) =>
      this.filterBySearchFilterState(filterState, (option) => option === collar.context.releaseChannel),
    [AlarmedCollarFilterType.ENTITY_ID]: (collar, filterState) =>
      this.filterBySearchFilterState(filterState, (searchText) =>
        SearchUtils.matchesAnySearchPattern(collar.entityId, searchText),
      ),
  };

  private createOnOffFilterFunction =
    (extractValue: (collar: AlarmedCollar) => boolean | undefined) =>
    (collar: AlarmedCollar, onOffOption: OnOffOption) => {
      if (onOffOption === OnOffOption.ON) return extractValue(collar) === true;
      if (onOffOption === OnOffOption.OFF) return extractValue(collar) === false;
      // If ALL, filter the alarm settings with the specific criteria being existed
      if (onOffOption === OnOffOption.ALL) return extractValue(collar) != null;
      return false;
    };

  private ON_OFF_FILTER_FUNCTIONS_BY_FILTER_TYPE: Record<
    string,
    (collar: AlarmedCollar, onOffOption: OnOffOption) => boolean
  > = {
    [AlarmedCollarFilterType.PROVISIONED_TO_FARM]: this.createOnOffFilterFunction(({ farm }) => farm != null),
    [AlarmedCollarFilterType.TAGGED_ON_CATTLE]: this.createOnOffFilterFunction(
      ({ context: { cattle } }) => cattle != null,
    ),
    [AlarmedCollarFilterType.BATCH_84_PLUS_COLLARS]: this.createOnOffFilterFunction(({ context: { batch } }) =>
      CollarUtils.isBatch84PlusCollar(batch),
    ),
  };

  getSearchFilterFunction(filterType: AlarmedCollarFilterType) {
    return this.SEARCH_FILTER_FUNCTIONS_BY_FILTER_TYPE[filterType];
  }

  getOnOffFilterFunction(filterType: AlarmedCollarFilterType) {
    return this.ON_OFF_FILTER_FUNCTIONS_BY_FILTER_TYPE[filterType];
  }

  getDateRangeFilterFunction(filterType: AlarmedCollarFilterType) {
    switch (filterType) {
      case AlarmedCollarFilterType.FIRST_LOGGED_DATE:
        return this.getDateRangeFilterMatcher((item) => item.firstLoggedDate);
      case AlarmedCollarFilterType.LAST_LOGGED_DATE:
        return this.getDateRangeFilterMatcher((item) => item.lastLoggedDate);
      default:
        return null;
    }
  }
}
