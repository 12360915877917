import { MetadataApi, TileApi } from '@halter-corp/terrain-service-client';

import client from '../client';

const TerrainApi = {
  tileApi: client.resolveOpenApi(TileApi),
  metadataApi: client.resolveOpenApi(MetadataApi),
};

export default TerrainApi;
