import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { GrafanaPeriod } from 'src/types/grafana-types';

import { AlarmSearchMode } from '../@types';

interface MonitoringSettingsStoreState {
  alarmSearchMode: AlarmSearchMode;
  setAlarmSearchMode: (mode: AlarmSearchMode) => void;
  grafanaPeriod: GrafanaPeriod;
  setGrafanaPeriod: (period: GrafanaPeriod) => void;
  itemsPerPage: number;
  setItemsPerPage: (newItemsPerPage: number) => void;
  snoozeAlarmsVisible: boolean | undefined;
  setSnoozeAlarmsVisible: (visible: boolean) => void;
}

export const createMonitoringSettingsStore = (entityKey: string) => {
  const storageKey = `${entityKey}-monitoring-settings-store`;

  return create(
    persist<MonitoringSettingsStoreState>(
      (set) => ({
        alarmSearchMode: AlarmSearchMode.ACTION_REQUIRED,
        setAlarmSearchMode: (mode) => {
          set({ alarmSearchMode: mode });
        },
        grafanaPeriod: '7d',
        setGrafanaPeriod: (period) => {
          set({ grafanaPeriod: period });
        },
        itemsPerPage: 30,
        setItemsPerPage: (newItemsPerPage) => {
          set({ itemsPerPage: newItemsPerPage });
        },
        snoozeAlarmsVisible: undefined,
        setSnoozeAlarmsVisible: (visible) => {
          set({ snoozeAlarmsVisible: visible });
        },
      }),
      {
        name: storageKey,
      },
    ),
  );
};
