import { IQueryExecutionDTO, QueryExecutionStatusEnum } from '@halter-corp/bff-debug-tool-service-client';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';

import { BffDebugApi } from 'src/data';
import { ConfigurationService } from 'src/services';
import { wait } from 'src/util/promise.util';

const BFF_DEBUG_TOOL_KEY = 'bff-debug';

export const BffDebugToolQueryKeys = {
  dbtDocs: [BFF_DEBUG_TOOL_KEY, 'dbt-docs'] as const,
  protobufDocs: [BFF_DEBUG_TOOL_KEY, 'protobuf-docs'] as const,
  openapiClientNames: [BFF_DEBUG_TOOL_KEY, 'openapi-client-names'] as const,
  openapiClientDocsByName: (clientName: string) => [BFF_DEBUG_TOOL_KEY, 'openapi-client-docs', clientName] as const,
  sagemakerPresignedUrl: [BFF_DEBUG_TOOL_KEY, 'sagemaker-presigned-url'] as const,
};

export type BffDebugQueriesState = {
  dbtDocs: {
    indexHtml: string;
  };
  protobufDocs: {
    indexHtml: string;
  };
  openapiClientNames: string[];
  openapiClientDocs: string;
  sageMakerPresignedUrl: string;
};

type IBffDebugQueries = {
  executeAthenaQuery: (query: string) => Promise<IQueryExecutionDTO>;

  useDbtDocs: (
    options?: Partial<UseQueryOptions<BffDebugQueriesState['dbtDocs']>>,
  ) => UseQueryResult<BffDebugQueriesState['dbtDocs']>;

  useProtobufDocs: (
    options?: Partial<UseQueryOptions<BffDebugQueriesState['protobufDocs']>>,
  ) => UseQueryResult<BffDebugQueriesState['protobufDocs']>;

  useOpenApiClientNames: (
    options?: Partial<UseQueryOptions<BffDebugQueriesState['openapiClientNames']>>,
  ) => UseQueryResult<BffDebugQueriesState['openapiClientNames']>;

  useOpenApiClientDocsByName: (
    clientName: string,
    options?: Partial<UseQueryOptions<BffDebugQueriesState['openapiClientDocs']>>,
  ) => UseQueryResult<BffDebugQueriesState['openapiClientDocs']>;

  useSageMakerPresignedUrl: (
    options?: Partial<UseQueryOptions<BffDebugQueriesState['sageMakerPresignedUrl']>>,
  ) => UseQueryResult<BffDebugQueriesState['sageMakerPresignedUrl']>;
};

export const BffDebugQueries: IBffDebugQueries = {
  executeAthenaQuery: async (query) => {
    let { data: queryExection } = await BffDebugApi.bffDebugApi.startQueryExecution({ query });

    while (queryExection.resultUrl == null) {
      if (queryExection.status === QueryExecutionStatusEnum.FAILED) {
        throw new Error(
          `Query failed to execute. Message: ${queryExection.statusChangeReason ?? 'No reason provided'}`,
        );
      }

      // eslint-disable-next-line no-await-in-loop
      await wait(500);
      // eslint-disable-next-line no-await-in-loop
      queryExection = (await BffDebugApi.bffDebugApi.findQueryExecution(queryExection.id)).data;
    }

    return queryExection;
  },

  useDbtDocs: (options) =>
    useQuery({
      queryKey: BffDebugToolQueryKeys.dbtDocs,
      queryFn: async () => {
        const { data: indexHtml } = await BffDebugApi.docsApi.getDataDocumentation('index.html');
        const { halterConfig } = ConfigurationService.retrieveCurrentConfiguration();

        const credentials = await Auth.currentSession();

        // NOTE: Need to replace looking for manifest.json and catalog.json locally with an api call, am hacker man >:D
        const modifiedIndexHtml = indexHtml
          .replace('manifest.json', `${halterConfig.halter_api_url}/bff-debug-tool/docs/dbt/manifest.json`)
          .replace('catalog.json', `${halterConfig.halter_api_url}/bff-debug-tool/docs/dbt/catalog.json`)
          .replace(
            '{Accept:"application/json, text/plain, */*"}',
            `{Accept:"application/json, text/plain, */*",Authorization:"Bearer ${credentials
              .getIdToken()
              .getJwtToken()}"}`,
          );

        return { indexHtml: modifiedIndexHtml };
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
    }),

  useProtobufDocs: (options) =>
    useQuery({
      queryKey: BffDebugToolQueryKeys.protobufDocs,
      queryFn: async () => {
        const { data: indexHtml } = await BffDebugApi.docsApi.getProtobufDocumentation('index.html');

        return { indexHtml };
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
    }),

  useOpenApiClientNames: (options) =>
    useQuery({
      queryKey: BffDebugToolQueryKeys.openapiClientNames,
      queryFn: async () => {
        const { data: clientNames } = await BffDebugApi.docsApi.getOpenApiClientNames();
        return clientNames;
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
    }),

  useOpenApiClientDocsByName: (clientName, options) =>
    useQuery({
      queryKey: BffDebugToolQueryKeys.openapiClientDocsByName(clientName),
      queryFn: async () => {
        const { data: openapiDocs } = await BffDebugApi.docsApi.getOpenApiClientDocumentation(clientName);
        return openapiDocs;
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
    }),

  useSageMakerPresignedUrl: () =>
    useQuery({
      queryKey: BffDebugToolQueryKeys.sagemakerPresignedUrl,
      queryFn: async () => {
        const response = await BffDebugApi.bffDebugApi.getSagemakerStudioPresignedUrl();
        const presignedUrl = response.data;
        return presignedUrl;
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }),
};
