import { Flex } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

const ToolCollectionContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      width="100%"
      height="100vh"
      overflowY="auto"
      flexDir="column"
    >
      {children}
    </Flex>
  );
};

export default ToolCollectionContainer;
