import { Box, Flex, Radio, RadioGroup, Text } from '@chakra-ui/react';
import { AlarmBehaviourTypeEnum } from '@halter-corp/alarm-registry-service-client';
import { AlarmActionTypeEnum, AlarmFilterTypeEnum, AlarmScopeEnum } from '@halter-corp/welfare-alarm-service-client';
import { Form, Formik } from 'formik';
import { isEmpty, omitBy } from 'lodash';
import moment from 'moment';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import * as yup from 'yup';

import CommonLoadingSpinner from 'src/components/common-loading-spinner';
import FormSectionWrapper from 'src/components/form-section-wrapper';
import FormikAutoComplete from 'src/components/forms/formik-auto-complete';
import FormikFarmAutoComplete from 'src/components/forms/formik-farm-auto-complete';
import FormikSelect from 'src/components/forms/formik-select';
import FormikTextarea from 'src/components/forms/formik-text-area';
import FormModalActions from 'src/components/modals/form-modal-actions';
import useCommonToast from 'src/hooks/use-common-toast';
import { ANY_ALARM_TYPE } from 'src/modules/monitoring/constants';
import { AlarmSettingsFormProps, GenericAlarmSettings } from 'src/modules/monitoring/templates/alarm-settings/@types';
import { AlarmRegistryQueries, BusinessQueries } from 'src/queries';
import { AuthService } from 'src/services';
import { Colors } from 'src/styles';
import { getDurationFormat } from 'src/util/date-time.util';
import { formatStringAsLabel } from 'src/util/string-format.util';

import WelfareMonitoringService from '../../../services/welfare-monitoring.service';
import { WelfareSettingsTriggerRule } from '../@types';
import { WelfareAlarmSettingsService } from '../services/welfare-alarm-settings.service';
import { safeParseBoolean } from 'src/util/string-parse.util';

type NoneOptionValue = 'none';
const NONE_OPTION_VALUE: NoneOptionValue = 'none';
const ALLOWED_ALARM_FILTER_TYPE_OPTIONS = Object.values(AlarmFilterTypeEnum);
const ALLOWED_ALARM_ACTION_TYPE_OPTIONS = [AlarmActionTypeEnum.REMOVE_ALARM];

export const DURATION_MS_OPTIONS = [
  moment.duration(10, 'minute').asMilliseconds(),
  moment.duration(30, 'minute').asMilliseconds(),
  moment.duration(1, 'hour').asMilliseconds(),
  moment.duration(12, 'hour').asMilliseconds(),
  moment.duration(1, 'day').asMilliseconds(),
  moment.duration(2, 'day').asMilliseconds(),
  moment.duration(1, 'week').asMilliseconds(),
  moment.duration(2, 'week').asMilliseconds(),
  moment.duration(1, 'month').asMilliseconds(),
  moment.duration(3.02, 'month').asMilliseconds(), // hack it get it to say 3 months and not 2 month 30 days
  moment.duration(6, 'month').asMilliseconds(),
  moment.duration(1.002, 'year').asMilliseconds(), // hack it get it to say 1 year and not 11 months 30 days
];

const PROVISIONED_TO_FARM_OPTIONS = [
  { label: 'None', value: NONE_OPTION_VALUE },
  { label: 'Provisioned', value: true },
  { label: 'Not Provisioned', value: false },
] as const;

const welfareAlarmSettingsSchema = yup.object().shape({
  alarmType: yup.string().required(),
  filterType: yup.string().oneOf(ALLOWED_ALARM_FILTER_TYPE_OPTIONS).optional(),
  actionType: yup.string().oneOf(ALLOWED_ALARM_ACTION_TYPE_OPTIONS).optional(),
  snoozeDurationMs: yup.number().integer().optional(), // Only for snooze filter type

  // trigger rule criteria
  farmId: yup.string().optional(),

  // collar trigger rule criteria
  provisionedOnFarm: yup.boolean().optional(),

  reason: yup.string().required(),
});

export type WelfareAlarmSettingsFormValues = {
  alarmType: string;
  alarmSettingsType: 'action' | 'filter';
  filterType: string | undefined;
  actionType: string | undefined;
  snoozeDurationMs?: number;
  alarmScope: AlarmScopeEnum;

  // trigger rule criteria
  farmId: string;

  // collar trigger rule criteria
  provisionedToFarm?: boolean | NoneOptionValue;

  reason: string;
};

type WelfareAlarmSettingsFormProps = AlarmSettingsFormProps<WelfareSettingsTriggerRule>;

const isNoneOrNullable = (value: unknown) => value == null || /^none$/i.test(String(value));

const WelfareAlarmSettingsForm: FC<WelfareAlarmSettingsFormProps> = ({
  onClose,
  onSuccessfulSubmit,
  existingAlarmSettings,
}) => {
  const putAlarmSettings = WelfareAlarmSettingsService.usePutAlarmSettings();
  const authUser = AuthService.useAuth();
  const { data: farmById = {} } = BusinessQueries.useFindAllFarmsQuery();
  const farms = useMemo(() => Object.values(farmById), [farmById]);

  const {
    data: alarmDefinitionByType,
    isLoading: isAlarmDefinitionsLoading,
    isError: isAlarmDefinitionsError,
  } = AlarmRegistryQueries.useAlarmDefinitions();
  // NOTE: if alarm definition fetch has error, throw error so that it is caught by error boundary on the modal.
  if (isAlarmDefinitionsError) {
    throw new Error('Could not fetch alarm definitions...');
  }

  const { displayToast } = useCommonToast();
  const welfareAlarmTypes = WelfareMonitoringService.useWelfareAlarmTypes();
  const combinedAlarmTypeOptions = [ANY_ALARM_TYPE, ...welfareAlarmTypes];

  const [requestErrorMessage, setRequestErrorMessage] = useState<string | undefined>();
  const requestErrorMessageRef = useRef<HTMLParagraphElement>(null);

  const handleSubmit = async (formValues: WelfareAlarmSettingsFormValues) => {
    const { alarmType, alarmSettingsType, farmId, reason } = formValues;

    const actionType = alarmSettingsType === 'action' ? formValues.actionType : undefined;
    const filterType = alarmSettingsType === 'filter' ? formValues.filterType : undefined;
    if (actionType == null && filterType == null) {
      displayToast('error', 'Either action type or filter type is required!');
      return;
    }

    const triggerRule: WelfareSettingsTriggerRule = (() => {
      const filteredRules: WelfareSettingsTriggerRule = omitBy({ farmId }, (value) => isEmpty(value));

      if (formValues.alarmScope === AlarmScopeEnum.COLLAR) {
        const collarScopeTriggerRule = omitBy({ provisionedToFarm: formValues.provisionedToFarm }, isNoneOrNullable);

        if (!isEmpty(collarScopeTriggerRule)) {
          filteredRules.scopeTriggerRule = {
            scope: AlarmScopeEnum.COLLAR,
            collarTriggerRule: collarScopeTriggerRule,
          }
        }
      }

      return filteredRules;
    })();


    // NOTE: Expires at only exists if the alarm settings is snooze filter type.
    // Otherwise, set expires at to undefined.
    if (formValues.filterType === AlarmFilterTypeEnum.SNOOZE && formValues.snoozeDurationMs == null) {
      setRequestErrorMessage('Snooze duration is required if the filter type is snooze!');
      return;
    }
    const expiresAt =
      formValues.filterType === AlarmFilterTypeEnum.SNOOZE
        ? new Date(Date.now() + formValues.snoozeDurationMs!)
        : undefined;

    const madeBy = `${authUser.firstName} ${authUser.lastName}`;

    const { status, message, data } = await putAlarmSettings({
      alarmType,
      actionType,
      filterType,
      triggerRule,
      expiresAt: expiresAt?.toISOString() ?? undefined,
      context: {
        reason,
        madeBy,
      },
    });

    if (status === 'success') {
      displayToast('success', `Successfully created alarm settings with status ${status}`);
      if (data != null) onSuccessfulSubmit?.(data);
    } else {
      setRequestErrorMessage(message);
      displayToast('error', `Error: could not save alarm settings.`);
    }
  };

  useEffect(() => {
    if (requestErrorMessage != null) requestErrorMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [requestErrorMessage]);

  const initialFormValues = useMemo(() => getInitialFormValues(existingAlarmSettings), [existingAlarmSettings]);

  // Render
  //-----------------------------------------------------

  if (isAlarmDefinitionsLoading) return <CommonLoadingSpinner height="250px" />;

  return (
    <Box>
      <Formik
        initialValues={initialFormValues}
        validationSchema={welfareAlarmSettingsSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values);
          setSubmitting(false);
        }}
        validateOnChange={false}
      >
        {({ values, handleChange, errors, isSubmitting, setFieldValue, submitForm }) => {
          const currentAlarmType = values.alarmType;
          const alarmBehaviour: AlarmBehaviourTypeEnum | undefined =
            alarmDefinitionByType?.[currentAlarmType]?.alarmBehaviour;

          // NOTE: for event alarms, filters are not allowed. Only automated actions are allowed to remove them.
          if (alarmBehaviour === AlarmBehaviourTypeEnum.EVENT) {
            if (values.alarmSettingsType === 'filter') {
              setFieldValue('alarmSettingsType', 'action');
              setFieldValue('actionType', undefined);
            }
          }

          if (values.alarmSettingsType === 'action' && isEmpty(values.actionType)) {
            // Set default action type
            setFieldValue('actionType', AlarmActionTypeEnum.REMOVE_ALARM);
          }
          if (values.alarmSettingsType === 'filter' && isEmpty(values.filterType)) {
            // Set default filter type
            setFieldValue('filterType', AlarmFilterTypeEnum.HIDE);
          }

          return (
            <Form>
              <Flex
                px={8}
                py={4}
                mb={2}
                maxHeight="550px"
                overflowY="auto"
                rowGap={5}
                flexDir="column"
              >
                {/* Section 1: base settings */}
                <FormSectionWrapper title="General Settings">
                  <FormikAutoComplete
                    id="alarm-type"
                    name="alarmType"
                    label="Alarm Type"
                    onChangeText={(newAlarmType) => {
                      setFieldValue('alarmType', newAlarmType)
                      if (alarmDefinitionByType[newAlarmType]) {
                        setFieldValue('alarmScope', alarmDefinitionByType[newAlarmType].alarmScope)
                      }
                    }
                    }
                    autoCompleteProps={{ defaultValue: formatFormDefaultValue(values.alarmType) }}
                    autoCompleteInputProps={{ name: 'alarmType' }}
                    autoCompleteItemsProps={combinedAlarmTypeOptions.map((alarmType) => ({
                      key: alarmType,
                      value: alarmType,
                      label: formatStringAsLabel(alarmType),
                    }))}
                  />

                  <Flex
                    flexDir={{ base: 'column', md: 'row' }}
                    gap={4}
                  >
                    {values.alarmSettingsType === 'action' && (
                      <FormikSelect
                        id="action-type"
                        label="Action Type"
                        name="actionType"
                        selectProps={{ bg: Colors.gray100 }}
                        error={errors.actionType}
                        onChange={handleChange}
                      >
                        {ALLOWED_ALARM_ACTION_TYPE_OPTIONS.map((alarmActionType) => (
                          <option
                            key={alarmActionType}
                            value={alarmActionType}
                          >
                            {formatStringAsLabel(alarmActionType)}
                          </option>
                        ))}
                      </FormikSelect>
                    )}

                    {values.alarmSettingsType === 'filter' && (
                      <FormikSelect
                        id="filter-type"
                        label="Filter Type"
                        name="filterType"
                        selectProps={{
                          bg: Colors.gray100,
                        }}
                        error={errors.filterType}
                        onChange={handleChange}
                      >
                        {ALLOWED_ALARM_FILTER_TYPE_OPTIONS.map((alarmFilterType) => (
                          <option
                            key={alarmFilterType}
                            value={alarmFilterType}
                          >
                            {formatStringAsLabel(alarmFilterType)}
                          </option>
                        ))}
                      </FormikSelect>
                    )}

                    {values.filterType === AlarmFilterTypeEnum.SNOOZE && (
                      <FormikSelect
                        id="snooze-duration"
                        label="Snooze Duration"
                        name="snoozeDurationMs"
                        selectProps={{
                          bg: Colors.gray100,
                          onChange: (e) => setFieldValue('snoozeDurationMs', +e.target.value),
                        }}
                        error={errors.snoozeDurationMs}
                      >
                        <option value="">-</option>
                        {DURATION_MS_OPTIONS.map((durationMs) => (
                          <option
                            key={durationMs}
                            value={durationMs}
                          >
                            {getDurationFormat(durationMs)}
                          </option>
                        ))}
                      </FormikSelect>
                    )}
                  </Flex>

                  {/* Event alarms cannot have filters. So there is no reason to display this selection if event alarms. */}
                  {alarmBehaviour !== AlarmBehaviourTypeEnum.EVENT && (
                    <RadioGroup
                      name="alarmSettingsType"
                      value={values.alarmSettingsType}
                    >
                      <Flex gap={5}>
                        <Radio
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue('filterType', undefined);
                            setFieldValue('snoozeDurationMs', undefined);
                          }}
                          value="action"
                        >
                          Action
                        </Radio>
                        <Radio
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue('actionType', undefined);
                          }}
                          value="filter"
                        >
                          Filter
                        </Radio>
                      </Flex>
                    </RadioGroup>
                  )}
                </FormSectionWrapper>

                {/* Section 2: settings trigger rule */}
                <FormSectionWrapper title="Trigger Rule">
                  <FormikFarmAutoComplete
                    defaultValue={farmById[values.farmId]?.name ?? '-'}
                    onChange={(farmId) => setFieldValue('farmId', farmId)}
                    noneOptionValue="-"
                    farms={farms}
                  />

                  {values.alarmScope === AlarmScopeEnum.COLLAR && (
                    <FormikSelect
                      id="provisioned-to-farm"
                      label="Provisioned To Farm"
                      name="provisionedToFarm"
                      selectProps={{
                        bg: Colors.gray100,
                        onChange: (e) => setFieldValue('provisionedToFarm', safeParseBoolean(e.target.value)),
                      }}
                      error={errors.provisionedToFarm}
                    >
                      {PROVISIONED_TO_FARM_OPTIONS.map(({ label, value }) => (
                        <option
                          key={label}
                          value={String(value)}
                        >
                          {formatStringAsLabel(label)}
                        </option>
                      ))}
                    </FormikSelect>
                  )}

                </FormSectionWrapper>

                {/* Section 3: alarm settings context */}
                <FormSectionWrapper title="Context">
                  <FormikTextarea
                    id="Reason"
                    label="Reason"
                    name="reason"
                    textareaProps={{ value: values.reason }}
                    onTriggerSubmit={submitForm}
                  />
                </FormSectionWrapper>

                {/* Request error message */}
                <Text
                  ref={requestErrorMessageRef}
                  display={requestErrorMessage == null ? 'none' : 'block'}
                  mt={1}
                  fontWeight={600}
                  color={Colors.red500}
                >
                  Error occured: <br />
                  {requestErrorMessage}
                </Text>
              </Flex>

              <FormModalActions
                onClose={onClose}
                isSubmitting={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

const getInitialFormValues = (
  existingAlarmSettings?: GenericAlarmSettings<WelfareSettingsTriggerRule>,
): WelfareAlarmSettingsFormValues => {
  if (existingAlarmSettings == null) return DEFAULT_ALARM_SETTINGS_FORM_VALUES;
  const formValues: WelfareAlarmSettingsFormValues = {
    alarmSettingsType: existingAlarmSettings.actionType != null ? 'action' : 'filter',
    alarmType: existingAlarmSettings.alarmType,
    actionType: existingAlarmSettings.actionType,
    filterType: existingAlarmSettings.filterType,
    alarmScope: existingAlarmSettings.triggerRule?.scopeTriggerRule?.scope as AlarmScopeEnum,
    reason: existingAlarmSettings.context.reason,
    farmId: existingAlarmSettings.triggerRule?.farmId ?? '',
    provisionedToFarm: existingAlarmSettings.triggerRule?.scopeTriggerRule?.collarTriggerRule?.provisionedToFarm,
  };

  return formValues;
};

const DEFAULT_ALARM_SETTINGS_FORM_VALUES = {
  alarmType: '',
  alarmSettingsType: 'action',
  filterType: undefined,
  actionType: undefined,
  alarmScope: '',
  farmId: '',
  provisionedToFarm: NONE_OPTION_VALUE,
} as unknown as WelfareAlarmSettingsFormValues;

const formatFormDefaultValue = (value: string) => {
  if (value === '') return '-';
  return formatStringAsLabel(value);
};

export default WelfareAlarmSettingsForm;
