import { DomainNameEnum } from '@halter-corp/alarm-registry-service-client';
import { IFarmDTO } from '@halter-corp/business-service-client';
import {
  ICollarActiveAlarmsContextDTO,
  ICollarContextDTO,
  ICollarEventAlarmsContextDTO,
} from '@halter-corp/collar-alarm-service-client';
import { DeviceOnFarmStatusEnum } from '@halter-corp/fleet-service-client';
import { chain, isEmpty, mapValues, maxBy, minBy, sortBy, uniq } from 'lodash';
import moment from 'moment';
import { useCallback, useMemo } from 'react';

import { AlarmScope } from 'src/modules/monitoring/@types';
import { ALARM_BEHAVIOURS, FARM_OPS_ALARM_TYPES } from 'src/modules/monitoring/constants';
import { MonitoringContexts } from 'src/modules/monitoring/contexts';
import { AlarmRegistryQueries, BusinessQueries } from 'src/queries';
import { CollarAlarmQueries } from 'src/queries/queries/collar-alarm.queries';
import { OtaQueries } from 'src/queries/queries/ota.queries';
import { OtaService } from 'src/services';
import { FilterOption } from 'src/types';
import { CollarUtils } from 'src/util/collar.util';

import { AlarmedCollar, AlarmedCollarContext, AlarmedCollarFilterType } from '../@types';
import {
  AlarmedCollarFilterOptionModel,
  AlarmedDeviceFarmFilterOptionModel,
} from '../models/alarmed-collar-filter-option.model';

const CollarMonitoringService = {
  useCollarAlarmEntities: () => {
    const collarActiveAlarmsResponse = CollarMonitoringService.useCollarActiveAlarmEntities();
    const collarEventAlarmsResponse = CollarMonitoringService.useCollarEventAlarmEntities();

    const data = useMemo(() => {
      const filteredActiveAlarms = collarActiveAlarmsResponse.data.flatMap((collar) => {
        const farmOpsFilteredAlarms = collar.alarms.filter((alarm) => !FARM_OPS_ALARM_TYPES.has(alarm.type));
        if (farmOpsFilteredAlarms.length === 0) return [];

        return {
          ...collar,
          alarms: farmOpsFilteredAlarms,
        };
      });
      return [...filteredActiveAlarms, ...collarEventAlarmsResponse.data];
    }, [collarActiveAlarmsResponse.data, collarEventAlarmsResponse.data]);

    const refetchData = useCallback(async () => {
      await Promise.all([collarActiveAlarmsResponse.refetch(), collarEventAlarmsResponse.refetch()]);
    }, [collarActiveAlarmsResponse, collarEventAlarmsResponse]);

    return {
      data,
      isLoading: collarActiveAlarmsResponse.isLoading || collarEventAlarmsResponse.isLoading,
      isError: collarActiveAlarmsResponse.isError || collarEventAlarmsResponse.isError,
      refetchData,
    };
  },

  useCollarActiveAlarmEntities: () => {
    const [snoozeAlarmsVisible] = MonitoringContexts.useSnoozeAlarmVisibility();
    const {
      data: alarmsContexts,
      isLoading: isAlarmsContextsLoading,
      isError: isAlarmsContextsError,
      refetch,
    } = CollarAlarmQueries.useActionRequiredActiveCollarAlarmContexts(snoozeAlarmsVisible);
    const { data: farmById } = BusinessQueries.useFindAllFarmsQuery();

    const releaseChannelByFarmId = OtaService.useReleaseChannelByFarmId();

    const { data: existingFarmLotaJobByFarmId, isLoading: isOtaJobsLoading } = OtaQueries.useFarmLotaJobs();
    /**
     * NOTE: temporarily hide lora rx too high alarms if there was farm lota job created at 24 hours agos
     * When the collar is has lota session, the collar will be running at high lora rx for lora OTA. It's safe to ignore the lora rx too high alarm in this case.
     * TODO :The future fix can be made in the backend.
     * https://halternz.slack.com/archives/C051ULGE86L/p1706813693452379
     */
    const alarmedCollars: AlarmedCollar[] = useMemo(() => {
      const existingFarmLotaJobCreatedAtByFarmId = mapValues(
        existingFarmLotaJobByFarmId,
        (item) => new Date(item.createdAt),
      );
      const persistingFarmLotaJobCreatedAtByFarmIdString = localStorage.getItem('farmLotaJobCreatedAtByFarmId');
      const persistingFarmLotaJobCreatedAtByFarmId: Record<string, Date> = {
        ...JSON.parse(persistingFarmLotaJobCreatedAtByFarmIdString ?? '{}'),
        ...existingFarmLotaJobCreatedAtByFarmId,
      };
      localStorage.setItem('farmLotaJobCreatedAtByFarmId', JSON.stringify(persistingFarmLotaJobCreatedAtByFarmId));

      if (alarmsContexts == null || persistingFarmLotaJobCreatedAtByFarmId == null) return [];

      const loraRxFilteredAlarmsContexts = alarmsContexts.map((alarmContext) => {
        if (
          alarmContext.context.farmId == null &&
          persistingFarmLotaJobCreatedAtByFarmId[alarmContext.context.farmId] == null
        )
          return alarmContext;

        const hasLotaJob = moment(persistingFarmLotaJobCreatedAtByFarmId[alarmContext.context.farmId]).isBefore(
          moment().subtract(24, 'hours'),
        );
        if (!hasLotaJob) return alarmContext;

        return {
          ...alarmContext,
          alarms: alarmContext.alarms.filter((alarm) => alarm.type !== 'LORA_RX_TOO_HIGH'),
        };
      });

      return mapActiveAlarmDTOsToCollarAlarmEntities(
        loraRxFilteredAlarmsContexts,
        farmById ?? {},
        releaseChannelByFarmId,
      );
    }, [alarmsContexts, existingFarmLotaJobByFarmId, releaseChannelByFarmId, farmById]);

    return {
      data: alarmedCollars,
      isLoading: isAlarmsContextsLoading || isOtaJobsLoading,
      isError: isAlarmsContextsError,
      refetch,
    };
  },

  useCollarEventAlarmEntities: () => {
    const collarEventAlarmsResponse = CollarAlarmQueries.useActionRequiredEventCollarAlarmContexts();
    const { data: farmById } = BusinessQueries.useFindAllFarmsQuery();

    const collarAlarmEntities: AlarmedCollar[] = useMemo(() => {
      return mapEventAlarmDTOsToCollarAlarmEntities(collarEventAlarmsResponse.data ?? [], farmById ?? {});
    }, [collarEventAlarmsResponse.data, farmById]);

    return {
      data: collarAlarmEntities,
      isLoading: collarEventAlarmsResponse.isLoading,
      isError: collarEventAlarmsResponse.isError,
      refetch: collarEventAlarmsResponse.refetch,
    };
  },

  extractFirmwareVersions: (alarmedCollars: AlarmedCollar[]) => {
    return chain(alarmedCollars)
      .flatMap((alarmContext) => alarmContext.context.firmwareVersion ?? [])
      .uniq()
      .orderBy((fwv) => fwv, ['asc'])
      .value();
  },

  useExistingFirmwareVersions: (options: { sortDir?: 'asc' | 'desc' } = {}) => {
    const { data: alarmedCollars } = CollarMonitoringService.useCollarAlarmEntities();

    return useMemo(() => {
      const existingFirmwareVersions = CollarMonitoringService.extractFirmwareVersions(alarmedCollars);
      if (options.sortDir == null) return existingFirmwareVersions;
      return sortBy(existingFirmwareVersions, (fwv) => fwv.toLowerCase(), [options.sortDir]);
    }, [alarmedCollars, options.sortDir]);
  },

  useSubscribedCollarAlarmTypes: () => {
    const { data } = AlarmRegistryQueries.useAlarmSubscriptionsByDomainName(DomainNameEnum.COLLAR);

    return useMemo(() => data?.map(({ alarmType }) => alarmType) ?? [], [data]);
  },

  useAlarmedCollarFilterOptionsByType: () => {
    const { data: farmById } = BusinessQueries.useFindAllFarmsQuery();
    const farms = useMemo(() => Object.values(farmById ?? {}), [farmById]);

    const { data: alarmedCollars = [] } = CollarMonitoringService.useCollarAlarmEntities();
    const { data: releaseChannels } = OtaService.useReleaseChannels({ sortDir: 'asc' });

    const firmwareVersionOptions = useMemo(
      () => CollarMonitoringService.extractFirmwareVersions(alarmedCollars),
      [alarmedCollars],
    );

    const batchOptions = useMemo(() => {
      const uniqueBatches = uniq(alarmedCollars.flatMap((a) => CollarUtils.getCollarBatch(a.entityId) ?? []));
      return sortBy(uniqueBatches, (batch) => parseInt(batch, 10));
    }, [alarmedCollars]);

    const subscribedAlarmTypes = CollarMonitoringService.useSubscribedCollarAlarmTypes();
    const filteredAlarmTypes = useMemo(() => {
      return subscribedAlarmTypes.filter((alarmType) => !FARM_OPS_ALARM_TYPES.has(alarmType));
    }, [subscribedAlarmTypes]);

    const filterOptionsByType: Map<AlarmedCollarFilterType, FilterOption<unknown>[]> = useMemo(
      () =>
        new Map([
          [
            AlarmedCollarFilterType.STATUS,
            Object.values(DeviceOnFarmStatusEnum).map((status) =>
              AlarmedCollarFilterOptionModel.create(AlarmedCollarFilterType.STATUS, status),
            ),
          ],
          [
            AlarmedCollarFilterType.ALARMS,
            filteredAlarmTypes.map((alarm) =>
              AlarmedCollarFilterOptionModel.create(AlarmedCollarFilterType.ALARMS, alarm),
            ),
          ],
          [
            AlarmedCollarFilterType.ALARM_BEHAVIOUR,
            ALARM_BEHAVIOURS.map((alarmBehaviour) =>
              AlarmedCollarFilterOptionModel.create(AlarmedCollarFilterType.ALARM_BEHAVIOUR, alarmBehaviour),
            ),
          ],
          [
            AlarmedCollarFilterType.FARM,
            farms.map((farm) => AlarmedDeviceFarmFilterOptionModel.create(farm.name ?? '', farm.id)),
          ],
          [
            AlarmedCollarFilterType.BATCH,
            batchOptions.map((batch) => AlarmedCollarFilterOptionModel.create(AlarmedCollarFilterType.BATCH, batch)),
          ],
          [
            AlarmedCollarFilterType.FIRMWARE,
            firmwareVersionOptions.map((firmwareVersion) =>
              AlarmedCollarFilterOptionModel.create(AlarmedCollarFilterType.FIRMWARE, firmwareVersion),
            ),
          ],
          [
            AlarmedCollarFilterType.RELEASE_CHANNEL,
            (releaseChannels ?? []).map((releaseChannel) =>
              AlarmedCollarFilterOptionModel.create(AlarmedCollarFilterType.RELEASE_CHANNEL, releaseChannel.id),
            ),
          ],
        ]),
      [farms, firmwareVersionOptions, releaseChannels, batchOptions, filteredAlarmTypes],
    );

    return filterOptionsByType;
  },
};

const mapActiveAlarmDTOsToCollarAlarmEntities = (
  collarActiveAlarmContexts: ICollarActiveAlarmsContextDTO[],
  farmById: Record<string, IFarmDTO>,
  releaseChannelByFarmId: Record<string, string | undefined>,
) => {
  const collarAlarmEntities: AlarmedCollar[] = collarActiveAlarmContexts.map((alarmContext) => {
    const commonProperties = getCollarAlarmCommonProperties(alarmContext);

    const farmDTO = alarmContext.context.farmId != null ? farmById[alarmContext.context.farmId] : undefined;
    const releaseChannel = farmDTO != null ? releaseChannelByFarmId[farmDTO.id] : undefined;

    return {
      entityId: alarmContext.serialNumber,
      uniqueKey: alarmContext.serialNumber,
      scope: AlarmScope.COLLAR,
      alarmBehaviour: 'active' as const,
      farm: farmDTO != null ? { farmId: farmDTO.id, farmName: farmDTO.name } : undefined,
      mobId: alarmContext.context.mobId,
      alarms: alarmContext.alarms.map((alarm) => ({ type: alarm.type, eventDateTime: alarm.createdAt })),
      context: convertToAlarmedCollarContext(alarmContext.context, releaseChannel),
      ...commonProperties,
    };
  });

  return collarAlarmEntities;
};

const mapEventAlarmDTOsToCollarAlarmEntities = (
  collarEventAlarmContexts: ICollarEventAlarmsContextDTO[],
  farmById: Record<string, IFarmDTO>,
) => {
  const collarAlarmEntities: AlarmedCollar[] = collarEventAlarmContexts.flatMap((alarmContext) => {
    const commonProperties = getCollarAlarmCommonProperties(alarmContext);

    return alarmContext.alarms.map((alarm) => {
      const farmDTO = alarm.context.farmId != null ? farmById[alarm.context.farmId] : undefined;
      return {
        entityId: alarmContext.serialNumber,
        uniqueKey: `${alarmContext.serialNumber}-${alarm.type}-${alarm.createdAt}`,
        scope: AlarmScope.COLLAR,
        alarmBehaviour: 'event' as const,
        farm: farmDTO != null ? { farmId: farmDTO.id, farmName: farmDTO.name } : undefined,
        mobId: alarm.context.mobId,
        alarms: [{ type: alarm.type, eventDateTime: alarm.createdAt }],
        context: convertToAlarmedCollarContext(alarm.context),
        ...commonProperties,
      };
    });
  });

  return collarAlarmEntities;
};

const convertToAlarmedCollarContext = (
  contextDTO: ICollarContextDTO,
  releaseChannel?: string,
): AlarmedCollarContext => {
  const batch = CollarUtils.getCollarBatch(contextDTO.serialNumber);
  const cattle = isEmpty(contextDTO.cattleId)
    ? undefined
    : {
        cattleId: contextDTO.cattleId,
        cattleName: isEmpty(contextDTO.cattleName) ? undefined : contextDTO.cattleName,
      };

  return {
    onFarmStatus: isEmpty(contextDTO.onFarmStatus) ? undefined : (contextDTO.onFarmStatus as DeviceOnFarmStatusEnum),
    cattle,
    mobId: isEmpty(contextDTO.mobId) ? undefined : contextDTO.mobId,
    firmwareVersion: isEmpty(contextDTO.firmwareVersion) ? undefined : contextDTO.firmwareVersion,
    batch,
    releaseChannel,
  };
};

const getCollarAlarmCommonProperties = (
  collarAlarmContext: ICollarActiveAlarmsContextDTO | ICollarEventAlarmsContextDTO,
) => {
  const firstLoggedDate = minBy(collarAlarmContext.alarms, (alarm) => alarm.createdAt)?.createdAt;
  const lastLoggedDate = maxBy(collarAlarmContext.alarms, (alarm) => alarm.createdAt)?.createdAt;

  const debugLink = CollarUtils.getDebugLink(collarAlarmContext.serialNumber);

  return {
    id: collarAlarmContext.serialNumber,
    firstLoggedDate: firstLoggedDate != null ? new Date(firstLoggedDate) : undefined,
    lastLoggedDate: lastLoggedDate != null ? new Date(lastLoggedDate) : undefined,
    debugLink,
  };
};

export default CollarMonitoringService;
