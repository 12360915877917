import { Flex, Text } from '@chakra-ui/react';
import {
  AlarmActionTypeEnum,
  IWelfareAlarmRemoveAlarmActionContextDTO,
} from '@halter-corp/welfare-alarm-service-client';
import { Formik } from 'formik';
import pluralize from 'pluralize';
import { FC } from 'react';

import ActionButtons from 'src/components/actions/action-buttons';
import ActionFormWrapper from 'src/components/actions/action-form-wrapper';
import { AlarmActionFormProps } from 'src/modules/monitoring/@types';

import useWelfareAlarmActions, { AlarmActionRequestPayload } from '../hooks/use-batch-welfare-alarm-action';

const WelfareRemoveEventAlarmActionForm: FC<AlarmActionFormProps> = ({
  onClose,
  onBack,
  selectedEventAlarmEntities,
  onSuccessfulSubmit,
}) => {
  const { sendBatchActionRequests } = useWelfareAlarmActions<IWelfareAlarmRemoveAlarmActionContextDTO>({
    actionType: AlarmActionTypeEnum.REMOVE_ALARM,
    onSuccess: onSuccessfulSubmit,
  });

  const handleSubmit = async () => {
    const removeActionRequests: AlarmActionRequestPayload<IWelfareAlarmRemoveAlarmActionContextDTO>[] =
      selectedEventAlarmEntities.flatMap((eventAlarmContext) => {
        return eventAlarmContext.alarms.map((alarm) => {
          return {
            entityId: eventAlarmContext.entityId,
            actionContext: {
              targetAlarm: { type: alarm.type, createdAt: alarm.eventDateTime },
            },
          };
        });
      });

    await sendBatchActionRequests(removeActionRequests);
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={async (_, { setSubmitting }) => {
        await handleSubmit();
        setSubmitting(false);
      }}
    >
      {({ submitForm, isSubmitting }) => {
        return (
          <>
            <ActionFormWrapper
              title="Remove Alarms"
              onBack={onBack}
            >
              <Flex
                flexDir="column"
                fontSize="sm"
                mb={2}
              >
                <Text
                  mb={2}
                  fontSize="sm"
                >
                  This action will completely remove <Text as="strong">{selectedEventAlarmEntities.length}</Text> event{' '}
                  {pluralize('alarm', selectedEventAlarmEntities.length)}.
                  <br />
                  Note that you can only remove event alarms.
                </Text>
              </Flex>
            </ActionFormWrapper>

            <ActionButtons
              confirmButtonText="Confirm"
              onCancel={onClose}
              onSubmit={submitForm}
              isSubmitting={isSubmitting}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default WelfareRemoveEventAlarmActionForm;
