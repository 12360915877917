import { AlarmScope, CattleContext } from '../../../@types';
import { WelfareAlarmEntity } from '../@types';

export const WelfareEntityUtils = {
  getCollarId: (welfareEntity: WelfareAlarmEntity): string | undefined => {
    switch (welfareEntity.scope) {
      case AlarmScope.COLLAR:
        return welfareEntity.entityId;
      case AlarmScope.COW:
        return welfareEntity.context.cattleContext!.collarId;
      default:
        return undefined;
    }
  },

  getCattleId: (welfareEntity: WelfareAlarmEntity): string | undefined => {
    switch (welfareEntity.scope) {
      case AlarmScope.COLLAR:
        return welfareEntity.context.collarContext!.cattleId;
      case AlarmScope.COW:
        return welfareEntity.entityId;
      default:
        return undefined;
    }
  },

  getCattleContext: (welfareEntity: WelfareAlarmEntity): CattleContext | undefined => {
    switch (welfareEntity.scope) {
      case AlarmScope.COLLAR:
        const collarContext = welfareEntity.context.collarContext!;
        if (collarContext.cattleId == null) return undefined;
        return { cattleId: collarContext.cattleId, cattleName: collarContext.cattleName };
      case AlarmScope.COW:
        const cattleContext = welfareEntity.context.cattleContext!;
        return { cattleId: welfareEntity.entityId, cattleName: cattleContext.cattleName };
      default:
        return undefined;
    }
  },

  getMobId: (welfareEntity: WelfareAlarmEntity): string | undefined => {
    switch (welfareEntity.scope) {
      case AlarmScope.COLLAR:
        return welfareEntity.context.collarContext!.mobId;
      case AlarmScope.COW:
        return welfareEntity.context.cattleContext!.mobId;
      case AlarmScope.MOB:
        return welfareEntity.entityId;
      default:
        return undefined;
    }
  },

  getFarmId: (welfareEntity: WelfareAlarmEntity): string | undefined => welfareEntity.farm?.farmId,
};
