import { BreakFenceGroupsApi, BreakFencesApi } from '@halter-corp/break-fence-service-client';

import client from '../client';

const breakFenceApi = {
  breakFences: client.resolveOpenApi(BreakFencesApi),
  breakFenceGroups: client.resolveOpenApi(BreakFenceGroupsApi),
};

export default breakFenceApi;
