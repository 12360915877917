import { FilterOption, FilterOptionConfig } from 'src/types/filter-types';
import { SearchUtils } from 'src/util/search.util';
import { formatStringAsLabel } from 'src/util/string-format.util';

import { PowerAlarmFilterType } from '../@types';

export interface PowerAlarmFilterOption<T> extends FilterOption<T> {
  filterType: PowerAlarmFilterType;
}

export const PowerAlarmFilterOptionModel: FilterOptionConfig = {
  create<T>(filterType: PowerAlarmFilterType, filterText: string, metadata?: T): PowerAlarmFilterOption<T> {
    return { filterType, filterText, metadata };
  },

  equals<T>(filterOption: PowerAlarmFilterOption<T>, text: string): boolean {
    switch (filterOption.filterType) {
      case PowerAlarmFilterType.FARM:
        const farmFilterOption = filterOption as PowerAlarmFarmFilterOption;
        const equalFarmName = farmFilterOption.filterText.toLowerCase() === text.trim().toLowerCase();
        const equalFarmId = farmFilterOption.metadata.farmId.toLowerCase() === text.trim().toLowerCase();
        return equalFarmName || equalFarmId;
      default:
        return filterOption.filterText.toLowerCase() === text.trim().toLowerCase();
    }
  },

  equalsAny<T>(filterOption: PowerAlarmFilterOption<T>, textList: string[]): boolean {
    return textList.some((text) => PowerAlarmFilterOptionModel.equals(filterOption, text));
  },

  matchesPattern<T>(filterOption: PowerAlarmFilterOption<T>, pattern: string): boolean {
    switch (filterOption.filterType) {
      case PowerAlarmFilterType.FARM:
        const farmFilterOption = filterOption as PowerAlarmFarmFilterOption;
        const matchesFarmName = SearchUtils.matchesAnySearchPattern(farmFilterOption.filterText, pattern);
        const matchesFarmId = SearchUtils.matchesAnySearchPattern(farmFilterOption.metadata.farmId, pattern);
        return matchesFarmName || matchesFarmId;
      case PowerAlarmFilterType.SCOPE:
      case PowerAlarmFilterType.ALARMS:
        return SearchUtils.matchesAnySearchPattern(formatStringAsLabel(filterOption.filterText), pattern);
      default:
        return SearchUtils.matchesAnySearchPattern(filterOption.filterText, pattern);
    }
  },

  formatFilterText<T>(filterOption: PowerAlarmFilterOption<T>): string {
    switch (filterOption.filterType) {
      case PowerAlarmFilterType.ALARMS:
      case PowerAlarmFilterType.SCOPE:
        return formatStringAsLabel(filterOption.filterText);
      default:
        return filterOption.filterText;
    }
  },

  formatMetadata<T>(filterOption: PowerAlarmFilterOption<T>): string | null {
    switch (filterOption.filterType) {
      case PowerAlarmFilterType.FARM:
        const farmFilterOption = filterOption as PowerAlarmFarmFilterOption;
        return `ID: ${farmFilterOption.metadata.farmId}`;
      default:
        return null;
    }
  },
};

export interface PowerAlarmFarmFilterOption extends PowerAlarmFilterOption<{ farmId: string }> {
  filterType: PowerAlarmFilterType.FARM;
  metadata: { farmId: string };
}
