import { useDisclosure } from '@chakra-ui/react';
import { FunctionComponent, useMemo, useState } from 'react';

import BaseModal from 'src/components/modals/base-modal';
import AlarmActionModal from 'src/modules/monitoring/components/alarm-actions/alarm-action-modal';
import { MonitoringContexts } from 'src/modules/monitoring/contexts';

import CollarMonitoringService from '../../services/collar-monitoring.service';
import DeviceDetailsPanel from './collar-details.panel';

type AlarmedCollarDetailsModalProps = {
  serialNumber: string;
  onClose: () => void;
  isOpen: boolean;
};

const AlarmedCollarDetailsModal: FunctionComponent<AlarmedCollarDetailsModalProps> = ({
  serialNumber,
  onClose,
  isOpen,
}) => {
  const { data: collars } = CollarMonitoringService.useCollarAlarmEntities();
  const collar = collars.find(({ entityId }) => entityId === serialNumber);

  const { isOpen: isActionModalOpen, onOpen: onActionModalOpen, onClose: onActionModalClose } = useDisclosure();
  const [selectedAlarmAction, setSelectedAlarmAction] = useState<string | null>(null);

  const alarmActionConfigs = MonitoringContexts.useAllowedActionConfigsForUser();
  // NOTE: only allow active alarm actions when in collar details.
  const activeAlarmActions = useMemo(() => {
    return alarmActionConfigs
      .filter((config) => config.associatedAlarmBehaviour === 'active')
      .map((config) => config.action);
  }, [alarmActionConfigs]);

  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      headerText="Collar Details"
      modalContentProps={{
        display: 'flex',
        flexDir: 'column',
        borderRadius: { base: 'none', lg: '5px' },
        height: '98vh',
        width: { base: '100vw', lg: '90vw' },
      }}
      size="" // NOTE: set the size to empty string allows us to set the custom width and height. If not set, default size of md will apply instead.
    >
      <DeviceDetailsPanel
        onTriggerAction={onActionModalOpen}
        collar={collar}
        mt={2}
        flex={1}
        overflowY="auto"
      />

      {collar != null && (
        <AlarmActionModal
          isOpen={isActionModalOpen}
          allowedAlarmActions={activeAlarmActions}
          selectedAlarmAction={selectedAlarmAction}
          setSelectedAlarmAction={setSelectedAlarmAction}
          onClose={onActionModalClose}
          onSuccessfulAction={onClose}
          selectedAlarmEntities={[collar]}
        />
      )}
    </BaseModal>
  );
};

export default AlarmedCollarDetailsModal;
