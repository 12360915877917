import {
  AlarmActionTypeEnum,
  ICreatePowerAlarmActionRequestDTO,
  IPowerAlarmSnoozeActionContextDTO,
} from '@halter-corp/power-alarm-service-client';
import { chunk } from 'lodash';

import useCommonToast from 'src/hooks/use-common-toast';
import { PowerAlarmQueries } from 'src/queries/queries/power-alarm.queries';
import { AuthService } from 'src/services';
import { formatStringAsLabel } from 'src/util/string-format.util';

type BatchPowerAlarmActionProps = {
  actionType: AlarmActionTypeEnum;
  onSuccess: () => void;
};

export const PowerAlarmActionService = {
  useBatchPowerAlarmActions: <T>({ actionType, onSuccess }: BatchPowerAlarmActionProps) => {
    const createPowerAlarmActionMutation = PowerAlarmQueries.useCreatePowerAlarmActionMutation();
    const authUser = AuthService.useAuth();
    const { displayToast } = useCommonToast();

    const sendBatchActionRequests = async (ids: string[], actionContext: T) => {
      const userName = `${authUser.firstName} ${authUser.lastName}`;

      const powerAlarmActionRequests: ICreatePowerAlarmActionRequestDTO[] = ids.map((referenceId) => ({
        referenceId,
        actionType,
        createdBy: userName,
        actionContext: JSON.stringify(actionContext),
      }));

      try {
        for (const requestChunks of chunk(powerAlarmActionRequests, 50)) {
          // eslint-disable-next-line no-await-in-loop
          await Promise.all(requestChunks.map((request) => createPowerAlarmActionMutation.mutateAsync(request)));
        }

        onSuccess?.();
        return {
          status: 'success',
        };
      } catch (err: any) {
        const message = err.response?.data?.message ?? err.message;
        displayToast('error', `Failed to send "${formatStringAsLabel(actionType)}" request`, message as string);
        return {
          status: 'error',
          message,
        };
      }
    };

    return { sendBatchActionRequests };
  },

  usePowerSnoozeAlarmAction: () => {
    const createDeviceAlarmActionMutation = PowerAlarmQueries.useCreatePowerAlarmActionMutation();
    const authUser = AuthService.useAuth();
    const { displayToast } = useCommonToast();

    const sendActionRequest = async (
      referenceId: string,
      expiresAt: string,
      alarmsList: string[],
      comments?: string,
    ): Promise<{ status: 'success' | 'error' }> => {
      const userName = `${authUser.firstName} ${authUser.lastName}`;

      const snoozeActionContext: IPowerAlarmSnoozeActionContextDTO = {
        expiresAt,
        alarmTypes: alarmsList,
      };
      const alarmActionRequest: ICreatePowerAlarmActionRequestDTO = {
        referenceId,
        actionType: AlarmActionTypeEnum.SNOOZE,
        createdBy: userName,
        actionContext: JSON.stringify(snoozeActionContext),
        comments,
      } as ICreatePowerAlarmActionRequestDTO;

      try {
        await createDeviceAlarmActionMutation.mutateAsync(alarmActionRequest);
        displayToast('success', `Snooze power action successful!`);
        return { status: 'success' };
      } catch (err: any) {
        console.log(err);
        displayToast('error', `Snooze power action failed...`);
        return { status: 'error' };
      }
    };

    return sendActionRequest;
  },
};
