import { Box, Flex, Text } from '@chakra-ui/react';
import { AlarmFilterTypeEnum } from '@halter-corp/power-alarm-service-client';
import { Form, Formik } from 'formik';
import { isEmpty, omitBy } from 'lodash';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import * as yup from 'yup';

import FormSectionWrapper from 'src/components/form-section-wrapper';
import FormikAutoComplete from 'src/components/forms/formik-auto-complete';
import FormikFarmAutoComplete from 'src/components/forms/formik-farm-auto-complete';
import FormikSelect from 'src/components/forms/formik-select';
import FormikTextarea from 'src/components/forms/formik-text-area';
import FormModalActions from 'src/components/modals/form-modal-actions';
import useCommonToast from 'src/hooks/use-common-toast';
import { ANY_ALARM_TYPE, SNOOZE_DURATION_OPTIONS } from 'src/modules/monitoring/constants';
import { AlarmSettingsFormProps, GenericAlarmSettings } from 'src/modules/monitoring/templates/alarm-settings/@types';
import { BusinessQueries } from 'src/queries';
import { AuthService } from 'src/services';
import { Colors } from 'src/styles';
import { getDurationFormat } from 'src/util/date-time.util';
import { formatStringAsLabel } from 'src/util/string-format.util';

import PowerMonitoringService from '../../services/power-monitoring.service';
import { PowerSettingsTriggerRule } from '../@types';
import { PowerAlarmSettingsService } from '../services/power-alarm-settings.service';

const ALARM_FILTER_TYPE_OPTIONS = Object.values(AlarmFilterTypeEnum);

const powerAlarmSettingsSchema = yup.object().shape({
  alarmType: yup.string().required(),
  filterType: yup.string().oneOf(ALARM_FILTER_TYPE_OPTIONS).required(),
  snoozeDurationMs: yup.number().integer().optional(), // Only for snooze filter type

  // trigger rule criteria
  farmId: yup.string().optional(),

  // settings context
  reason: yup.string().required(),
});

export type PowerAlarmSettingsFormValues = {
  alarmType: string;
  filterType: AlarmFilterTypeEnum;
  snoozeDurationMs?: number;

  // trigger rule criteria
  farmId: string;

  // settings context
  reason: string;
};

type PowerAlarmSettingsFormProps = AlarmSettingsFormProps<PowerSettingsTriggerRule>;

const PowerAlarmSettingsForm: FC<PowerAlarmSettingsFormProps> = ({
  onClose,
  onSuccessfulSubmit,
  existingAlarmSettings,
}) => {
  const putAlarmSettings = PowerAlarmSettingsService.usePutAlarmSettings();
  const authUser = AuthService.useAuth();
  const { data: farmById = {} } = BusinessQueries.useFindAllFarmsQuery();
  const farms = useMemo(() => Object.values(farmById), [farmById]);

  const { displayToast } = useCommonToast();
  const powerAlarmTypes = PowerMonitoringService.usePowerAlarmTypes();
  const combinedAlarmTypeOptions = [ANY_ALARM_TYPE, ...powerAlarmTypes];

  const [requestErrorMessage, setRequestErrorMessage] = useState<string | undefined>();
  const requestErrorMessageRef = useRef<HTMLParagraphElement>(null);

  const handleSubmit = async (formValues: PowerAlarmSettingsFormValues) => {
    const { filterType, alarmType, farmId, reason } = formValues;

    const triggerRule: PowerSettingsTriggerRule = (() => {
      return omitBy({ farmId }, (value) => isEmpty(value));
    })();

    // NOTE: Expires at only exists if the alarm settings is snooze filter type.
    // Otherwise, set expires at to undefined.
    if (formValues.filterType === AlarmFilterTypeEnum.SNOOZE && formValues.snoozeDurationMs == null) {
      setRequestErrorMessage('Snooze duration is required if the filter type is snooze!');
      return;
    }
    const expiresAt =
      formValues.filterType === AlarmFilterTypeEnum.SNOOZE
        ? new Date(Date.now() + formValues.snoozeDurationMs!)
        : undefined;

    const madeBy = `${authUser.firstName} ${authUser.lastName}`;

    const { status, message, data } = await putAlarmSettings({
      alarmType,
      filterType,
      triggerRule,
      expiresAt: expiresAt?.toISOString() ?? undefined,
      context: {
        reason,
        madeBy,
      },
    });

    if (status === 'success') {
      displayToast('success', `Successfully created alarm settings with status ${status}`);
      if (data != null) onSuccessfulSubmit?.(data);
    } else {
      setRequestErrorMessage(message);
      displayToast('error', `Error: could not save alarm settings.`);
    }
  };

  useEffect(() => {
    if (requestErrorMessage != null) requestErrorMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [requestErrorMessage]);

  const initialFormValues = useMemo(() => getInitialFormValues(existingAlarmSettings), [existingAlarmSettings]);

  // Render
  //-----------------------------------------------------

  return (
    <Box>
      <Formik
        initialValues={initialFormValues}
        validationSchema={powerAlarmSettingsSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values);
          setSubmitting(false);
        }}
        validateOnChange={false}
      >
        {({ values, handleChange, errors, isSubmitting, setFieldValue, submitForm }) => (
          <Form>
            <Flex
              px={8}
              py={4}
              mb={2}
              maxHeight="550px"
              overflowY="auto"
              rowGap={5}
              flexDir="column"
            >
              {/* Section 1: base settings */}
              <FormSectionWrapper title="General Settings">
                <FormikAutoComplete
                  id="alarm-type"
                  name="alarmType"
                  label="Alarm Type"
                  onChangeText={(newAlarmType) => setFieldValue('alarmType', newAlarmType)}
                  autoCompleteProps={{ defaultValue: formatFormDefaultValue(values.alarmType) }}
                  autoCompleteInputProps={{ name: 'alarmType' }}
                  autoCompleteItemsProps={combinedAlarmTypeOptions.map((alarmType) => ({
                    key: alarmType,
                    value: alarmType,
                    label: formatStringAsLabel(alarmType),
                  }))}
                />

                <Flex
                  flexDir={{ base: 'column', md: 'row' }}
                  gap={4}
                >
                  <FormikSelect
                    id="filter-type"
                    label="Filter Type"
                    name="filterType"
                    selectProps={{
                      bg: Colors.gray100,
                    }}
                    error={errors.filterType}
                    onChange={handleChange}
                  >
                    {ALARM_FILTER_TYPE_OPTIONS.map((alarmFilterType) => (
                      <option
                        key={alarmFilterType}
                        value={alarmFilterType}
                      >
                        {formatStringAsLabel(alarmFilterType)}
                      </option>
                    ))}
                  </FormikSelect>

                  {values.filterType === AlarmFilterTypeEnum.SNOOZE && (
                    <FormikSelect
                      id="snooze-duration"
                      label="Snooze Duration"
                      name="snoozeDurationMs"
                      selectProps={{
                        bg: Colors.gray100,
                        onChange: (e) => setFieldValue('snoozeDurationMs', +e.target.value),
                      }}
                      error={errors.snoozeDurationMs}
                    >
                      <option value="">-</option>
                      {SNOOZE_DURATION_OPTIONS.map((durationMs) => (
                        <option
                          key={durationMs}
                          value={durationMs}
                        >
                          {getDurationFormat(durationMs)}
                        </option>
                      ))}
                    </FormikSelect>
                  )}
                </Flex>
              </FormSectionWrapper>

              {/* Section 2: settings trigger rule */}
              <FormSectionWrapper title="Trigger Rule">
                <FormikFarmAutoComplete
                  defaultValue={farmById[values.farmId]?.name ?? '-'}
                  onChange={(farmId) => setFieldValue('farmId', farmId)}
                  noneOptionValue="-"
                  farms={farms}
                />
              </FormSectionWrapper>

              {/* Section 3: alarm settings context */}
              <FormSectionWrapper title="Context">
                <FormikTextarea
                  id="Reason"
                  label="Reason"
                  name="reason"
                  textareaProps={{ value: values.reason }}
                  onTriggerSubmit={submitForm}
                />
              </FormSectionWrapper>

              {/* Request error message */}
              <Text
                ref={requestErrorMessageRef}
                display={requestErrorMessage == null ? 'none' : 'block'}
                mt={1}
                fontWeight={600}
                color={Colors.red500}
              >
                Error occured: <br />
                {requestErrorMessage}
              </Text>
            </Flex>

            <FormModalActions
              onClose={onClose}
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const DEFAULT_FILTER_TYPE = AlarmFilterTypeEnum.HIDE;

const getInitialFormValues = (
  existingAlarmSettings?: GenericAlarmSettings<PowerSettingsTriggerRule>,
): PowerAlarmSettingsFormValues => {
  if (existingAlarmSettings == null) return DEFAULT_ALARM_SETTINGS_FORM_VALUES;
  const formValues: PowerAlarmSettingsFormValues = {
    alarmType: existingAlarmSettings.alarmType,
    filterType: isValidFilterType(existingAlarmSettings.filterType)
      ? existingAlarmSettings.filterType
      : DEFAULT_FILTER_TYPE,
    reason: existingAlarmSettings.context.reason,
    farmId: existingAlarmSettings.triggerRule?.farmId ?? '',
  };

  return formValues;
};

const DEFAULT_ALARM_SETTINGS_FORM_VALUES: PowerAlarmSettingsFormValues = {
  alarmType: '',
  filterType: DEFAULT_FILTER_TYPE,
  farmId: '',
  reason: '',
};

const isValidFilterType = (filterType: string | undefined): filterType is AlarmFilterTypeEnum => {
  if (filterType == null) return false;
  return ALARM_FILTER_TYPE_OPTIONS.includes(filterType as AlarmFilterTypeEnum);
};

const formatFormDefaultValue = (value: string) => {
  if (value === '') return '-';
  return formatStringAsLabel(value);
};

export default PowerAlarmSettingsForm;
