/* eslint-disable no-await-in-loop */
import {
  AlarmActionTypeEnum,
  IAlarmSettingsDTO,
  ICreateTowerAlarmActionRequestDTO,
  ICreateTowerAlarmSubscriptionRequestDTO,
  IDeleteAlarmSettingsRequestDTO,
  IPutAlarmSettingsRequestDTO,
  ITowerActiveAlarmsContextDTO,
} from '@halter-corp/tower-alarm-service-client';
import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { TowerAlarmApi } from 'src/data';
import { AlarmSettingsUtils } from 'src/modules/monitoring';

import client from '../query-client';

const TOWER_ALARM_KEY = 'tower-alarm';

export const TowerAlarmQueryKeys = {
  towerActiveAlarmsContexts: [TOWER_ALARM_KEY, 'tower-active-alarms-contexts'] as const,
  alarmSettingsList: [TOWER_ALARM_KEY, 'alarm-settings'] as const,
};

type DeleteAlarmSettingsRequestIdentifier = IDeleteAlarmSettingsRequestDTO & {
  expiresAt?: string;
};

export type TowerAlarmQueriesState = {
  towerActiveAlarmsContexts: ITowerActiveAlarmsContextDTO[];
  towerAlarmSettingsList: IAlarmSettingsDTO[];
};

export const TowerAlarmQueries = {
  // Queries
  useActionRequiredTowerActiveAlarmsContexts: (options?: Partial<UseQueryOptions<ITowerActiveAlarmsContextDTO[]>>) =>
    useQuery({
      queryKey: TowerAlarmQueryKeys.towerActiveAlarmsContexts,
      queryFn: async () => {
        let paginationKey: string | undefined;

        const towerActiveAlarmsContexts: ITowerActiveAlarmsContextDTO[] = [];
        do {
          const { data } = await TowerAlarmApi.towerActiveAlarm.getActionRequiredTowerActiveAlarmsContexts(
            paginationKey,
          );
          towerActiveAlarmsContexts.push(...data.items);
          paginationKey = data.nextId;
        } while (paginationKey != null);

        return towerActiveAlarmsContexts;
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      ...options,
    }),

  useAllAlarmSettings: (options?: Partial<UseQueryOptions<IAlarmSettingsDTO[]>>) =>
    useQuery({
      queryKey: TowerAlarmQueryKeys.alarmSettingsList,
      queryFn: async () => {
        const { data } = await TowerAlarmApi.alarmSettings.getAllAlarmSettings();
        return data;
      },
      staleTime: 10_000,
      ...options,
    }),

  // Mutations
  useCreateTowerAlarmSubscriptionMutation: () =>
    useMutation({
      mutationFn: async (request: ICreateTowerAlarmSubscriptionRequestDTO) => {
        const { data } = await TowerAlarmApi.towerAlarmSubscription.createTowerAlarmSubscription(request);
        return data;
      },
    }),

  useCreateTowerAlarmActionMutation: () =>
    useMutation({
      mutationFn: async (request: ICreateTowerAlarmActionRequestDTO) => {
        const { data } = await TowerAlarmApi.alarmAction.createTowerAlarmAction(request);
        return data;
      },
      onSuccess: (alarmAction) => {
        switch (alarmAction.actionType) {
          case AlarmActionTypeEnum.SNOOZE:
            TowerAlarmQueries.removeTowerActiveAlarmsContextFromCache(alarmAction.id);
            break;
        }
      },
    }),

  usePutAlarmSettingsMutation: () =>
    useMutation({
      mutationFn: async (request: IPutAlarmSettingsRequestDTO) => {
        const { data: alarmSettings } = await TowerAlarmApi.alarmSettings.putAlarmSettings(request);
        return alarmSettings;
      },
      onSuccess: TowerAlarmQueries.setAlarmSettingsToCache,
    }),

  useDeleteAlarmSettingsMutation: () =>
    useMutation({
      mutationFn: async (request: DeleteAlarmSettingsRequestIdentifier) => {
        await TowerAlarmApi.alarmSettings.deleteAlarmSettings(request);
        return { ...request };
      },
      onSuccess: TowerAlarmQueries.removeAlarmSettingsFromCache,
    }),

  // Cache
  removeTowerActiveAlarmsContextFromCache: (idToRemove: string) => {
    const queryKey = TowerAlarmQueryKeys.towerActiveAlarmsContexts;

    const currentPowerActiveAlarmsContexts = client.getClientData<'towerActiveAlarmsContexts'>(queryKey);
    if (currentPowerActiveAlarmsContexts == null) return;

    const updatedTowerActiveAlarmsContexts = currentPowerActiveAlarmsContexts.filter(
      (context) => context.id !== idToRemove,
    );

    client.setClientData<'towerActiveAlarmsContexts'>(queryKey, updatedTowerActiveAlarmsContexts);
  },

  setAlarmSettingsToCache: (alarmSettings: IAlarmSettingsDTO) => {
    const currentAlarmSettingsList = client.getClientData<'towerAlarmSettingsList'>(
      TowerAlarmQueryKeys.alarmSettingsList,
    );
    if (currentAlarmSettingsList == null) return;

    const updatedAlarmSettingsList = currentAlarmSettingsList
      .filter((as) => AlarmSettingsUtils.areAlarmSettingsEqual(as, alarmSettings) === false)
      .concat(alarmSettings);

    client.setClientData<'towerAlarmSettingsList'>(TowerAlarmQueryKeys.alarmSettingsList, updatedAlarmSettingsList);
  },

  removeAlarmSettingsFromCache: (alarmSettingsIdentifier: DeleteAlarmSettingsRequestIdentifier) => {
    const currentAlarmSettingsList = client.getClientData<'towerAlarmSettingsList'>(
      TowerAlarmQueryKeys.alarmSettingsList,
    );
    if (currentAlarmSettingsList == null) return;

    const updatedAlarmSettingsList = currentAlarmSettingsList.filter(
      (as) => AlarmSettingsUtils.areAlarmSettingsEqual(as, alarmSettingsIdentifier) === false,
    );

    client.setClientData<'towerAlarmSettingsList'>(TowerAlarmQueryKeys.alarmSettingsList, updatedAlarmSettingsList);
  },
};
