import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ObjectKeyType } from 'src/types';
import { OnOffOption } from 'src/types/filter-types';
import { FilterStateValidator } from 'src/util/filter-state-validator';

type UseOnOffFiltersSearchParamsProps<K extends ObjectKeyType> = {
  queryParamKey: string;
  setOnOffFiltersByType: (updatedOnOffFiltersByType: Partial<Record<K, OnOffOption>>) => void;
};

function useOnOffFiltersSearchParams<K extends ObjectKeyType>({
  queryParamKey,
  setOnOffFiltersByType,
}: UseOnOffFiltersSearchParamsProps<K>) {
  const [searchParams, setSearchParams] = useSearchParams(window.location.search);

  const setOnOffFilters = useCallback(
    (updatedOnOffFiltersByType: Partial<Record<K, OnOffOption>>) => {
      if (setOnOffFiltersByType == null) return;

      setOnOffFiltersByType(updatedOnOffFiltersByType);
      const updatedFiltersEncoded = btoa(JSON.stringify(updatedOnOffFiltersByType));
      setSearchParams((prevSearchParams) => {
        prevSearchParams.set(queryParamKey, updatedFiltersEncoded);
        return prevSearchParams.toString();
      });
    },
    [queryParamKey, setSearchParams, setOnOffFiltersByType],
  );

  const encodedFilters: string | null = searchParams.get(queryParamKey);

  useEffect(() => {
    if (encodedFilters == null) return;

    try {
      const storedFiltersByType: Record<string, unknown> = JSON.parse(atob(encodedFilters));
      const storedOnOffFiltersByType: Record<ObjectKeyType, OnOffOption> = {};

      Object.entries(storedFiltersByType).forEach(([key, value]) => {
        if (FilterStateValidator.isOnOffFilterState(value)) {
          storedOnOffFiltersByType[key] = value;
        } else {
          console.log('Unknown filter type:', key, value);
        }
      });

      setOnOffFiltersByType(storedOnOffFiltersByType);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error parsing stored filters');
    }
  }, [encodedFilters, setOnOffFiltersByType]);

  return {
    setOnOffFilters,
  };
}

export default useOnOffFiltersSearchParams;
