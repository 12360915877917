import { Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import GridSectionContainer from 'src/components/grid-section-contanier';
import LabelValueGroup from 'src/components/label-value-group';
import FarmLabel from 'src/components/labels/farm-label';
import LabelWithCopy from 'src/components/labels/label-with-copy';
import { Colors } from 'src/styles';

import { AlarmedCollar } from '../../../@types';
import MobInfoSections from './mob-info-sections';

type CollarInfoDetailProps = {
  collar: AlarmedCollar;
};

const DeviceInfoDetail: FunctionComponent<CollarInfoDetailProps> = ({ collar }) => (
  <GridSectionContainer heading="Collar Info">
    <LabelValueGroup
      label="Serial Number"
      labelColor={Colors.teal600}
    >
      <Text>{collar.entityId}</Text>
    </LabelValueGroup>

    <LabelValueGroup
      label="Farm"
      labelColor={Colors.teal600}
      width="fit-content"
    >
      <FarmLabel farm={{ id: collar.farm?.farmId ?? '', name: collar.farm?.farmName ?? 'Unassigned' }} />
    </LabelValueGroup>

    <LabelValueGroup
      label="Cattle"
      labelColor={Colors.teal600}
    >
      {collar.context.cattle?.cattleId != null ? (
        <LabelWithCopy
          tooltipLabel={`Cattle ID: ${collar.context.cattle.cattleId}`}
          text={collar.context.cattle.cattleName ?? 'Unknown'}
          copyText={collar.context.cattle.cattleId}
        />
      ) : (
        'Unassigned'
      )}
    </LabelValueGroup>

    {collar.context.mobId != null && collar.farm != null && (
      <MobInfoSections
        farmId={collar.farm?.farmId}
        mobId={collar.context.mobId}
      />
    )}

    <LabelValueGroup
      label="Firmware Version"
      labelColor={Colors.teal600}
    >
      <Text>{collar.context.firmwareVersion ?? 'None'}</Text>
    </LabelValueGroup>

    <LabelValueGroup
      label="Release Channel"
      labelColor={Colors.teal600}
    >
      <Text>{collar.context.releaseChannel ?? 'None'}</Text>
    </LabelValueGroup>
  </GridSectionContainer>
);

export default DeviceInfoDetail;
