import { ButtonProps, IconButton } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';

type BackButtonProps = ButtonProps & {
  onBack: () => void;
  ariaLabel?: string;
};

const BackButton: FunctionComponent<BackButtonProps> = ({ onBack, ...rest }) => (
  <IconButton
    borderRadius={100}
    icon={<MdOutlineArrowBackIosNew />}
    onClick={onBack}
    aria-label="Go back"
    {...rest}
  />
);

export default BackButton;
