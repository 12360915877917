import {
  AlarmActionTypeEnum,
  AlarmFilterTypeEnum,
  IAlarmDTO,
  IAlarmSettingsDTO,
  ICollarActiveAlarmsContextDTO,
  ICollarAlarmsDTO,
  ICollarEventAlarmsContextDTO,
  ICreateCollarAlarmActionRequestDTO,
  ICreateCollarAlarmSubscriptionRequestDTO,
  IDeleteAlarmSettingsRequestDTO,
  IPutAlarmSettingsRequestDTO,
} from '@halter-corp/collar-alarm-service-client';
import { QueryKey, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';

import { APP_NAME } from 'src/constants';
import { CollarAlarmApi } from 'src/data';
import { AlarmSettingsUtils } from 'src/modules/monitoring';

import client from '../query-client';

type DeleteAlarmSettingsRequestIdentifier = IDeleteAlarmSettingsRequestDTO & {
  expiresAt?: string;
};

export type RemoveAlarmActionRequest = Pick<ICreateCollarAlarmActionRequestDTO, 'serialNumber' | 'createdBy'> & {
  targetAlarm: IAlarmDTO;
};

const COLLAR_ALARM_KEY = 'collar-alarm';

export const CollarAlarmQueryKeys = {
  collarActiveAlarmsByFarmId: (farmId: string) => [COLLAR_ALARM_KEY, 'active', farmId] as const,
  collarActiveAlarmContexts: (includeSnoozedAlarms: boolean) =>
    [COLLAR_ALARM_KEY, 'active', 'include-snooze-alarms', includeSnoozedAlarms] as const,
  collarEventAlarmContexts: [COLLAR_ALARM_KEY, 'event'] as const,
  collarAlarmSettingsList: [COLLAR_ALARM_KEY, 'alarm-settings'] as const,
};

export type CollarAlarmQueriesState = {
  collarActiveAlarms: ICollarAlarmsDTO[];
  collarActiveAlarmContexts: ICollarActiveAlarmsContextDTO[];
  collarEventAlarmContexts: ICollarEventAlarmsContextDTO[];
  collarAlarmSettingsList: IAlarmSettingsDTO[];
};

export const CollarAlarmQueries = {
  // Queries
  useActionRequiredActiveCollarAlarmsByFarmId: (
    farmId: string,
    options?: Partial<UseQueryOptions<CollarAlarmQueriesState['collarActiveAlarms']>>,
  ) =>
    useQuery({
      queryKey: CollarAlarmQueryKeys.collarActiveAlarmsByFarmId(farmId),
      queryFn: async () => {
        const { data } = await CollarAlarmApi.collarActiveAlarm.getAllActionRequiredActiveCollarAlarmsByFarmID(farmId);
        return data;
      },
      staleTime: moment.duration(3, 'minutes').asMilliseconds(),
      refetchInterval: moment.duration(3, 'minutes').asMilliseconds(),
      ...options,
    }),

  useActionRequiredActiveCollarAlarmContexts: (
    includeSnoozedAlarms = false,
    options?: Partial<UseQueryOptions<CollarAlarmQueriesState['collarActiveAlarmContexts']>>,
  ) =>
    useQuery({
      queryKey: CollarAlarmQueryKeys.collarActiveAlarmContexts(includeSnoozedAlarms),
      queryFn: async () => {
        let paginationKey: string | undefined;

        const collarAlarmsContexts: ICollarActiveAlarmsContextDTO[] = [];
        do {
          // eslint-disable-next-line no-await-in-loop
          const { data } = await CollarAlarmApi.collarActiveAlarm.getActionRequiredCollarActiveAlarmsContexts(
            paginationKey,
            includeSnoozedAlarms,
          );
          collarAlarmsContexts.push(...data.items);
          paginationKey = data.nextSerialNumber;
        } while (paginationKey != null);

        return collarAlarmsContexts;
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      ...options,
    }),

  useActionRequiredEventCollarAlarmContexts: (
    options?: Partial<UseQueryOptions<CollarAlarmQueriesState['collarEventAlarmContexts']>>,
  ) =>
    useQuery({
      queryKey: CollarAlarmQueryKeys.collarEventAlarmContexts,
      queryFn: async () => {
        let paginationKey: string | undefined;

        const collarAlarmsContexts: ICollarEventAlarmsContextDTO[] = [];
        do {
          // eslint-disable-next-line no-await-in-loop
          const { data } = await CollarAlarmApi.collarEventAlarm.getCollarEventAlarmsContexts(paginationKey);
          collarAlarmsContexts.push(...data.items);
          paginationKey = data.nextSerialNumber;
        } while (paginationKey != null);

        return collarAlarmsContexts;
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      ...options,
    }),

  useAllAlarmSettings: (options?: Partial<UseQueryOptions<IAlarmSettingsDTO[]>>) =>
    useQuery({
      queryKey: CollarAlarmQueryKeys.collarAlarmSettingsList,
      queryFn: async () => {
        const { data } = await CollarAlarmApi.alarmSettings.getAllAlarmSettings();
        return data;
      },
      staleTime: 10_000,
      ...options,
    }),

  // Mutations
  useCreateCollarAlarmSubscriptionMutation: () =>
    useMutation({
      mutationFn: async (request: ICreateCollarAlarmSubscriptionRequestDTO) => {
        const { data } = await CollarAlarmApi.collarAlarmSubscription.createCollarAlarmSubscription(request);
        return data;
      },
    }),

  useCreateCollarAlarmActionMutation: () =>
    useMutation({
      mutationFn: async (request: ICreateCollarAlarmActionRequestDTO) => {
        const { data } = await CollarAlarmApi.alarmAction.createAlarmAction(request);
        return data;
      },
      onSuccess: async (alarmAction) => {
        // NOTE: for actions that are taken on 'ACTIVE' alarms, we snooze those collars to reduce noise for the user.
        // This will snooze active alarms of the serial number for 1 hour.
        // Also, update the query cache by removing active alarm context to give immediate update to the user.
        switch (alarmAction.actionType) {
          case AlarmActionTypeEnum.SNOOZE:
            CollarAlarmQueries.removeCollarActiveAlarmsContextFromCache(alarmAction.serialNumber);
            break;
          // snooze and remove from cache for other actions such as TAG_OFF, POWER_OFF etc.
          default:
            await temporarySnoozeDevice(alarmAction.serialNumber);
            CollarAlarmQueries.removeCollarActiveAlarmsContextFromCache(alarmAction.serialNumber);
            break;
        }
      },
    }),

  /**
   * @summary This mutation is used to remove an event alarm. Cannot be used for active alarms.
   */
  useCreateRemoveAlarmActionMutation: () =>
    useMutation({
      mutationFn: async (payload: RemoveAlarmActionRequest) => {
        const actionRequest: ICreateCollarAlarmActionRequestDTO = {
          serialNumber: payload.serialNumber,
          actionType: AlarmActionTypeEnum.REMOVE_ALARM,
          createdBy: payload.createdBy,
          actionContext: JSON.stringify({ targetAlarm: payload.targetAlarm }),
        };
        await CollarAlarmApi.alarmAction.createAlarmAction(actionRequest);

        return payload;
      },
      onSuccess: ({ serialNumber, targetAlarm }) => {
        CollarAlarmQueries.removeCollarEventAlarmFromCache(serialNumber, targetAlarm.type, targetAlarm.createdAt);
      },
    }),

  usePutAlarmSettingsMutation: () =>
    useMutation({
      mutationFn: async (request: IPutAlarmSettingsRequestDTO) => {
        const { data: alarmSettings } = await CollarAlarmApi.alarmSettings.putAlarmSettings(request);
        return alarmSettings;
      },
      onSuccess: CollarAlarmQueries.setAlarmSettingsToCache,
    }),

  useDeleteAlarmSettingsMutation: () =>
    useMutation({
      mutationFn: async (request: DeleteAlarmSettingsRequestIdentifier) => {
        await CollarAlarmApi.alarmSettings.deleteAlarmSettings(request);
        return { ...request };
      },
      onSuccess: CollarAlarmQueries.removeAlarmSettingsFromCache,
    }),

  // Cache
  removeCollarActiveAlarmsContextFromCache: (serialNumberToRemove: string) => {
    const removeFromCollarActiveAlarmContext = (queryKey: QueryKey) => {
      const currentCollarActiveAlarmsContexts = client.getClientData<'collarActiveAlarmContexts'>(queryKey);
      if (currentCollarActiveAlarmsContexts == null) return;
      const updatedCollarActiveAlarmsContexts = currentCollarActiveAlarmsContexts.filter(
        (context) => context.serialNumber !== serialNumberToRemove,
      );
      client.setClientData<'collarActiveAlarmContexts'>(queryKey, updatedCollarActiveAlarmsContexts);
    };

    // NOTE: remove item from both snooze and non-snooze active alarms context query results.
    // Most of the time, only one of them will be present in the cache. So performance difference is minimal.
    const includeSnoozeAlarmsQueryKey = CollarAlarmQueryKeys.collarActiveAlarmContexts(true);
    const excludeSnoozeAlarmsQueryKey = CollarAlarmQueryKeys.collarActiveAlarmContexts(false);

    removeFromCollarActiveAlarmContext(includeSnoozeAlarmsQueryKey);
    removeFromCollarActiveAlarmContext(excludeSnoozeAlarmsQueryKey);
  },

  removeCollarEventAlarmFromCache: (serialNumber: string, alarmType: string, createdAt: string) => {
    const queryKey = CollarAlarmQueryKeys.collarEventAlarmContexts;

    const currentCollarEventAlarmsContexts = client.getClientData<'collarEventAlarmContexts'>(queryKey);
    if (currentCollarEventAlarmsContexts == null) return;

    const updatedCollarEventAlarmsContexts: ICollarEventAlarmsContextDTO[] = currentCollarEventAlarmsContexts.flatMap(
      (eventAlarmContext) => {
        if (eventAlarmContext.serialNumber !== serialNumber) return eventAlarmContext;

        const filteredEventAlarms = eventAlarmContext.alarms.filter(
          (alarm) => alarm.type !== alarmType || alarm.createdAt !== createdAt,
        );

        if (filteredEventAlarms.length === 0) return [];

        return {
          ...eventAlarmContext,
          alarms: filteredEventAlarms,
        };
      },
    );

    client.setClientData<'collarEventAlarmContexts'>(queryKey, updatedCollarEventAlarmsContexts);
  },

  setAlarmSettingsToCache: (alarmSettings: IAlarmSettingsDTO) => {
    const currentAlarmSettingsList = client.getClientData<'collarAlarmSettingsList'>(
      CollarAlarmQueryKeys.collarAlarmSettingsList,
    );
    if (currentAlarmSettingsList == null) return;

    const updatedAlarmSettingsList = currentAlarmSettingsList
      .filter((as) => AlarmSettingsUtils.areAlarmSettingsEqual(as, alarmSettings) === false)
      .concat(alarmSettings);

    client.setClientData<'collarAlarmSettingsList'>(
      CollarAlarmQueryKeys.collarAlarmSettingsList,
      updatedAlarmSettingsList,
    );
  },

  removeAlarmSettingsFromCache: (alarmSettingsIdentifier: DeleteAlarmSettingsRequestIdentifier) => {
    const currentAlarmSettingsList = client.getClientData<'collarAlarmSettingsList'>(
      CollarAlarmQueryKeys.collarAlarmSettingsList,
    );
    if (currentAlarmSettingsList == null) return;

    const updatedAlarmSettingsList = currentAlarmSettingsList.filter(
      (as) => AlarmSettingsUtils.areAlarmSettingsEqual(as, alarmSettingsIdentifier) === false,
    );

    client.setClientData<'collarAlarmSettingsList'>(
      CollarAlarmQueryKeys.collarAlarmSettingsList,
      updatedAlarmSettingsList,
    );
  },
};

const temporarySnoozeDevice = async (serialNumber: string, durationInHours = 1) => {
  const snoozeAlarmSettingsRequest: IPutAlarmSettingsRequestDTO = {
    alarmType: 'ANY',
    triggerRule: { serialNumber },
    filterType: AlarmFilterTypeEnum.SNOOZE,
    expiresAt: moment().add(durationInHours, 'hour').toISOString(),
    context: {
      madeBy: APP_NAME,
      reason: 'Create snooze alarm settings for a device that was taken an action.',
    },
  };

  await CollarAlarmApi.alarmSettings.putAlarmSettings(snoozeAlarmSettingsRequest);
};
