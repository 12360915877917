import { Flex, Spinner } from '@chakra-ui/react';
import { Amplify } from 'aws-amplify';
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { queryClient } from 'src/queries';
import ConfigurationService, { EnvironmentEnum } from 'src/services/configuration.service';

import { EnvironmentContext, EnvironmentContextType } from './context';

const EnvironmentProvider: FC<PropsWithChildren> = ({ children }) => {
  const environment = ConfigurationService.retrieveCurrentEnvironment();

  const setEnvironment = (newEnvironment: EnvironmentEnum) => {
    if (process.env.NODE_ENV === 'production') {
      window.location.href = window.location.href.replace(
        /(dev.halter.io|staging.halter.io|prod.halter.io)/,
        `${newEnvironment}.halter.io`,
      );
    }
  };

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setInitialized(false);

    // Load environment
    const config = ConfigurationService.retrieveCurrentConfiguration();
    if (process.env.NODE_ENV !== 'production') {
      config.awsConfig.oauth.redirectSignIn = window.location.origin;
      config.awsConfig.oauth.redirectSignOut = window.location.origin;
    }
    Amplify.configure(ConfigurationService.retrieveCurrentConfiguration().awsConfig);

    // Clear environment
    void queryClient.resetQueries();
    queryClient.clear();

    ConfigurationService.loadConfiguration(environment);

    // window.location.reload();
    setTimeout(() => setInitialized(true), 1000);
  }, [environment]);

  const value: EnvironmentContextType = useMemo(
    () => ({
      initialized,
      environment,
      setEnvironment,
    }),
    [environment, initialized],
  );

  return (
    <EnvironmentContext.Provider value={value}>
      {initialized ? (
        children
      ) : (
        <Flex
          flex={1}
          bg="gray.900"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner color="white" />
        </Flex>
      )}
    </EnvironmentContext.Provider>
  );
};

export default EnvironmentProvider;
