import {
  AlarmActionTypeEnum,
  ICreateTowerAlarmActionRequestDTO,
  ITowerAlarmSnoozeActionContextDTO,
} from '@halter-corp/tower-alarm-service-client';
import { chunk } from 'lodash';

import useCommonToast from 'src/hooks/use-common-toast';
import { TowerAlarmQueries } from 'src/queries/queries/tower-alarm.queries';
import { AuthService } from 'src/services';
import { formatStringAsLabel } from 'src/util/string-format.util';

type BatchTowerAlarmActionProps = {
  actionType: AlarmActionTypeEnum;
  onSuccess: () => void;
};

export const TowerAlarmActionService = {
  useBatchTowerAlarmActions: <T>({ actionType, onSuccess }: BatchTowerAlarmActionProps) => {
    const createTowerAlarmActionMutation = TowerAlarmQueries.useCreateTowerAlarmActionMutation();
    const authUser = AuthService.useAuth();
    const { displayToast } = useCommonToast();

    const sendBatchActionRequests = async (ids: string[], actionContext: T) => {
      const userName = `${authUser.firstName} ${authUser.lastName}`;

      const towerAlarmActionRequests: ICreateTowerAlarmActionRequestDTO[] = ids.map((id) => ({
        id,
        actionType,
        createdBy: userName,
        actionContext: JSON.stringify(actionContext),
      }));

      try {
        for (const requestChunks of chunk(towerAlarmActionRequests, 50)) {
          // eslint-disable-next-line no-await-in-loop
          await Promise.all(requestChunks.map((request) => createTowerAlarmActionMutation.mutateAsync(request)));
        }

        onSuccess?.();
        return {
          status: 'success',
        };
      } catch (err: any) {
        const message = err.response?.data?.message ?? err.message;
        displayToast('error', `Failed to send "${formatStringAsLabel(actionType)}" request`, message as string);
        return {
          status: 'error',
          message,
        };
      }
    };

    return { sendBatchActionRequests };
  },

  useTowerSnoozeAlarmAction: () => {
    const createDeviceAlarmActionMutation = TowerAlarmQueries.useCreateTowerAlarmActionMutation();
    const authUser = AuthService.useAuth();
    const { displayToast } = useCommonToast();

    const sendActionRequest = async (
      id: string,
      expiresAt: string,
      alarmsList: string[],
      comments?: string,
    ): Promise<{ status: 'success' | 'error' }> => {
      const userName = `${authUser.firstName} ${authUser.lastName}`;

      const snoozeActionContext: ITowerAlarmSnoozeActionContextDTO = {
        expiresAt,
        alarmTypes: alarmsList,
      };
      const alarmActionRequest: ICreateTowerAlarmActionRequestDTO = {
        id,
        actionType: AlarmActionTypeEnum.SNOOZE,
        createdBy: userName,
        actionContext: JSON.stringify(snoozeActionContext),
        comments,
      } as ICreateTowerAlarmActionRequestDTO;

      try {
        await createDeviceAlarmActionMutation.mutateAsync(alarmActionRequest);
        displayToast('success', `Snooze tower action successful!`);
        return { status: 'success' };
      } catch (err: any) {
        console.log(err);
        displayToast('error', `Snooze tower action failed...`);
        return { status: 'error' };
      }
    };

    return sendActionRequest;
  },
};
