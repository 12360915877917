import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import pluralize from 'pluralize';
import { FunctionComponent } from 'react';
import { AiOutlineRight } from 'react-icons/ai';

import ActionButtons from 'src/components/actions/action-buttons';
import { Colors } from 'src/styles';
import { formatStringAsLabel } from 'src/util/string-format.util';

import { slideLeft } from '../../../../components/actions/animations';

type AlarmActionSelectProps = {
  entityName: string;
  alarmActions: string[];
  selectedAlarmEntityCount: number;
  onSelectAction: (action: string) => void;
  onClose: () => void;
};

const AlarmActionSelect: FunctionComponent<AlarmActionSelectProps> = ({
  entityName,
  alarmActions,
  selectedAlarmEntityCount,
  onSelectAction,
  onClose,
}) => {
  if (alarmActions.length === 0) return null;

  return (
    <>
      <Box
        key="form-select"
        as={motion.div}
        variants={slideLeft}
        initial="hidden"
        animate="show"
        exit="exit"
        p={4}
        height="450px"
        overflowY="auto"
      >
        <Text
          px={4}
          color={Colors.gray500}
          fontSize="sm"
          fontWeight={600}
        >
          Take one of the following actions
          {selectedAlarmEntityCount >= 1 && (
            <>
              &nbsp; on <Text as="b">{selectedAlarmEntityCount}</Text> selected {entityName.toLowerCase()}{' '}
              {pluralize('alarm', selectedAlarmEntityCount)}.
            </>
          )}
        </Text>

        <Flex
          mt={3}
          px={4}
          flexDirection="column"
          bgColor="inherit"
        >
          {alarmActions.map((action) => (
            <Flex
              key={action}
              p={4}
              justifyContent="space-between"
              alignItems="center"
              borderRadius={5}
              _hover={{ bgColor: Colors.gray200 }}
              cursor="pointer"
              onClick={() => onSelectAction(action)}
            >
              <Text>{formatStringAsLabel(action)}</Text>
              <IconButton
                aria-label="Select action"
                size="sm"
                bgColor={Colors.gray100}
                borderRadius={100}
                icon={<AiOutlineRight />}
              />
            </Flex>
          ))}
        </Flex>
      </Box>
      <ActionButtons
        confirmButtonText="Action"
        onCancel={onClose}
        actionButtonDisabled
      />
    </>
  );
};

export default AlarmActionSelect;
