import {
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
} from '@chakra-ui/react';
import { FC } from 'react';

import { Colors } from 'src/styles';

type NumberInputGroupProps = {
  numberInputProps: NumberInputProps;
  numberInputRef?: React.RefObject<HTMLInputElement>;
  unit?: string;
};

const NumberInputGroup: FC<NumberInputGroupProps> = ({ numberInputProps, numberInputRef, unit }) => {
  return (
    <InputGroup
      bgColor={Colors.gray100}
      borderRadius="md"
      border={`1px solid ${Colors.gray300}`}
      boxShadow="sm"
      _focusWithin={{
        border: `1px solid ${Colors.blue700}`,
        outline: `1px solid ${Colors.blue700}`,
        outlineOffset: '0px',
        boxShadow: 'md',
      }}
    >
      {unit != null && (
        <InputLeftAddon
          fontWeight="semibold"
          color={Colors.gray600}
          bgColor={Colors.gray200}
        >
          {unit}
        </InputLeftAddon>
      )}
      <NumberInput
        width="100%"
        {...numberInputProps}
      >
        <NumberInputField
          border="none"
          _focus={{ boxShadow: 'none' }}
          ref={numberInputRef}
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </InputGroup>
  );
};

export default NumberInputGroup;
