import { CattleApi, CattleHeatApi } from '@halter-corp/cattle-service-client';
import { GroupsApi, ProtrackDraftingApi } from '@halter-corp/cowtroller-service-client';

import client from '../client';

const cattlesApi = {
  cattles: client.resolveOpenApi(CattleApi),
  heats: client.resolveOpenApi(CattleHeatApi),
  protrack: client.resolveOpenApi(ProtrackDraftingApi),
  groups: client.resolveOpenApi(GroupsApi),
};

export default cattlesApi;
